import { Slate } from './jsonTypes/index';

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export const cloneJSON = <T>(json: T): T => JSON.parse(JSON.stringify(json));

export function getEmptySlateBlock(): Slate.BlockJSON {
  return {
    uid: '',
    id: '',
    parentId: null,
    blockId: '',
    cacheKey: '',
    hidden: false,
    locked: false,
    inAnimation: '',
    outAnimation: '',
    tStart: 0,
    tIn: 0,
    tOut: 0,
    tEnd: 0,
    width: 0,
    zoom: null,
    pan: null,
    height: 0,
    blur: 0,
    opacity: 0,
    filterType: null,
    blendMode: 'normal',
    offsetX: 0,
    offsetY: 0,
    angle: 0,
    absoluteAngle: 0,
    tEdit: 0,
    name: '',
    ui: {
      selected: false,
      forceVisible: false,
      forceInvisible: false,
    },
    inTransition: null,
    inTransitionParameters: {},
    outTransition: null,
    outTransitionParameters: {},
    thumbnailUrl: '',
    type: 'block',
    tSceneStart: 0,
    tSceneEnd: 0,
    index: 0,
    componentIds: [],
    flipHorizontally: false,
    flipVertically: false,
  };
}

export const getEmptySlateTransition = (): Slate.TransitionJSON => ({
  type: 'transition',
  transitionType: 'none',
  id: '',
  index: 0,
  cacheKey: '',
  transitionId: 0,
  tStart: 0,
  tEnd: 0,
  prevRefId: '',
  nextRefId: '',
});

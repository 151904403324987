import { BlockJSON } from '../jsonTypes/MasterJSON';

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
function getBlockJSONforHash(block: BlockJSON) {
  const json = { ...block };
  delete json.thumbnail_hash;
  delete json.thumbnail_updated_at;
  delete json.thumbnail_url;
  return json;
}
export function hashBlockForThumbnail(block: BlockJSON) {
  return hashCode(block ? JSON.stringify(getBlockJSONforHash(block)) : '');
}

import { cloneJSON } from '../utils';
import { Master, Shadow } from '../jsonTypes';
import { Subject } from 'rxjs';
import { orderedDimensions } from './constants';

export const observables = {
  storyJson: {
    changed$: new Subject<{ json: Shadow.StoryJSON }>(),
  },
  json: {
    changed$: new Subject<{ json: Shadow.JSON }>(),
  },
  dimension: {
    changed$: new Subject<{ dim: Shadow.DimensionJSON }>(),
  },
  block: {
    added$: new Subject<{ id: string; block: Shadow.BlockJSON }>(),
    removed$: new Subject<{ id: string }>(),
    swapped$: new Subject<{ id1: string; id2: string }>(),
    changed$: new Subject<{ id: string; block: Shadow.BlockJSON }>(),
    shadowChanged$: new Subject<{ id: string; block: Shadow.BlockJSON }>(),
  },
  layer: {
    added$: new Subject<{ id: string; layer: Shadow.LayerJSON }>(),
    removed$: new Subject<{ id: string }>(),
    swapped$: new Subject<{ id1: string; id2: string }>(),
    changed$: new Subject<{ id: string; layer: Shadow.LayerJSON }>(),
    shadowChanged$: new Subject<{ id: string; layer: Shadow.LayerJSON }>(),
  },
  audioBlock: {
    added$: new Subject<{ id: string; audioBlock: Shadow.AudioBlockJSON }>(),
    removed$: new Subject<{ id: string }>(),
    swapped$: new Subject<{ id1: string; id2: string }>(),
    changed$: new Subject<{ id: string; audioBlock: Shadow.AudioBlockJSON }>(),
    shadowChanged$: new Subject<{ id: string; audioBlock: Shadow.AudioBlockJSON }>(),
  },
  component: {
    added$: new Subject<{ id: string; component: Shadow.ComponentJSON }>(),
    removed$: new Subject<{ id: string }>(),
    swapped$: new Subject<{ id1: string; id2: string }>(),
    changed$: new Subject<{ id: string; component: Shadow.ComponentJSON }>(),
    shadowChanged$: new Subject<{ id: string; component: Shadow.ComponentJSON }>(),
  },
};

type Fireable = () => void;
const observableQueue: (Fireable | null)[] = [];

interface Reference<S extends Shadow.AnyJSON | Shadow.DimensionJSON> {
  type: Shadow.TypeOf<S>;
  tUpdated: number;
  id: string;
  uid: string; // a short easy to read id unique within a video
  shadowSnapshot: string | null;
  snapshot: string | null;
  shadow: S;
  shadowComponentIds: string[]; // at the time of shadow snapshot
}

interface DimensionReference extends Reference<Shadow.DimensionJSON> {
  type: 'dimension';
  shadowBlockIds: string[]; // at the time of shadow snapshot
  shadowIgnoredBlockIds: string[]; // at the time of shadow snapshot
  shadowAudioBlockIds: string[]; // at the time of shadow snapshot
  shadowLayerIds: string[]; // at the time of shadow snapshot
  shadowComponentIds: []; // at the time of shadow snapshot
}

type BlockReference = Reference<Shadow.BlockJSON>;
type LayerReference = Reference<Shadow.LayerJSON>;
type AudioBlockReference = Reference<Shadow.AudioBlockJSON>;
type ComponentReference = Reference<Shadow.ComponentJSON>;
type IdReference = LayerReference | BlockReference | AudioBlockReference | ComponentReference;

interface Registry {
  shadowStory: Shadow.StoryJSON | null;
  storySnapshot: string | null;
  shadowStorySnapshot: string | null;
  shadow: Shadow.JSON | null;
  snapshot: string | null;
  shadowSnapshot: string | null;
  dimRef: DimensionReference | null;
  ids: {
    [id: string]: IdReference;
  };
  uidPrefixes: {
    [prefix: string]: number;
  };
}

const _registry: Registry = {
  shadowStory: null,
  storySnapshot: null,
  shadowStorySnapshot: null,
  shadow: null,
  snapshot: null,
  shadowSnapshot: null,
  dimRef: null,
  ids: {},
  uidPrefixes: {},
};

export const fireObservables = () => {
  const queue = observableQueue.filter(isNotEmpty);
  observableQueue.length = 0;
  queue.forEach((f) => f());
};

//// Public

export const getMasterJson = (): Master.JSON => {
  if (!_registry.shadow) {
    throw new Error(`shadow cannot be converted before registering.`);
  }

  return scrub(_registry.shadow);
};

export const getStoryJson = (): Master.StoryJSON => {
  if (!_registry.shadowStory) {
    throw new Error(`shadowStory cannot be converted before registering.`);
  }

  return scrubStory(_registry.shadowStory);
};

export const reset = () => {
  _registry.shadow = null;
  _registry.dimRef = null;
  _registry.snapshot = null;
  _registry.shadowSnapshot = null;
  _registry.ids = {};
  _registry.uidPrefixes = {};
};

export const resetStory = () => {
  _registry.shadowStory = null;
  _registry.shadowStorySnapshot = null;
};

export const markShadowAsMutated = (
  tNow: number,
  options: { isStory: boolean } = { isStory: false }
): void => {
  if (_registry.shadowStory) {
    const newStorySnapshot = fullSnapshot(_registry.shadowStory);
    if (newStorySnapshot !== _registry.shadowStorySnapshot) {
      _registry.shadowStory.tUpdated = tNow;
      _registry.shadowStorySnapshot = fullSnapshot(_registry.shadowStory);
      observableQueue.push(() =>
        observables.storyJson.changed$.next({ json: getShadowStoryJson() })
      );
    }
  }
  if (!_registry.shadow) {
    if (options.isStory) {
      return;
    }
    throw new Error(`shadow cannot be mutated before registering.`);
  }
  const newSnapshot = fullSnapshot(_registry.shadow);
  if (newSnapshot === _registry.shadowSnapshot) return;
  observableQueue.push(() => observables.json.changed$.next({ json: getShadowJson() }));
  _registry.shadow.tUpdated = tNow;
  markDimensionAsMutated(tNow);
  _registry.shadowSnapshot = fullSnapshot(_registry.shadow);
  validateShadow();
};
export const hasMasterJsonChanged = (json: Master.JSON) => {
  if (!_registry.shadow) return true;
  const newSnapshot = fullSnapshot(json);
  const newShadowSnapshot = fullSnapshot(_registry.shadow);
  return newShadowSnapshot !== _registry.shadowSnapshot || newSnapshot !== _registry.snapshot;
};

export const update = (mJson: Master.JSON, tNow: number) => {
  const json = JSON.parse(JSON.stringify(mJson)) as Master.JSON;
  const dimension = orderedDimensions.find((d) => json[d]);
  if (!dimension) {
    throw new Error(`Failed to update video: must have a dimension`);
  }
  if (!_registry.shadow) {
    reset();
    _registry.shadow = injectShadow(json, tNow);
  }
  markShadowAsMutated(tNow);
  const shadow = _registry.shadow!;
  const newSnapshot = fullSnapshot(json);
  if (newSnapshot === _registry.snapshot) return;
  _registry.snapshot = newSnapshot;
  _registry.shadowSnapshot = fullSnapshot(shadow);
  observableQueue.push(() => observables.json.changed$.next({ json: getShadowJson() }));
  Object.assign(
    shadow,
    json,
    { '16:9': undefined, '9:16': undefined, '1:1': undefined },
    { [dimension]: shadow[dimension], uid: shadow.uid, tUpdated: tNow, isDestroyed: false }
  );
  orderedDimensions.forEach((dimension) => {
    if (!shadow[dimension]) delete shadow[dimension];
  });

  updateDimension(json[dimension]!, dimension.replaceAll(':', '_'), tNow);
  validateShadow();
};

export const updateStory = (sJson: Master.StoryJSON, tNow: number) => {
  const json = JSON.parse(JSON.stringify(sJson)) as Master.StoryJSON;
  if (!_registry.shadowStory) {
    resetStory();
    _registry.shadowStory = injectStory(json, tNow);
  }
  markShadowAsMutated(tNow, { isStory: true });
  const shadowStory = _registry.shadowStory!;
  const newSnapshot = fullSnapshot(json);
  if (newSnapshot === _registry.storySnapshot) return;
  _registry.storySnapshot = newSnapshot;
  shadowStory.tUpdated = tNow;
  _registry.shadowStorySnapshot = fullSnapshot(shadowStory);
  observableQueue.push(() => observables.storyJson.changed$.next({ json: getShadowStoryJson() }));
  //validateShadow();
};

export const validateShadow = () => {
  const dim = _registry.dimRef?.shadow;
  if (!dim) {
    throw new Error(`Failed to update video: validate called before update.`);
  }
  // if (dim.blocks.length < 1) {
  //   throw new Error(`Failed to update video: must have at least one block.`);
  // }
  if (dim.blocks.some((b) => b.components.length < 1)) {
    const badBlockIds = dim.blocks
      .filter((b) => b.components.length < 1)
      .map((b) => b.id)
      .join(', ');
    throw new Error(
      `Failed to update video: each block must have one index. Blocks (${badBlockIds}) have zero components.`
    );
  }
};

export const getShadowJson = (): Shadow.JSON => {
  if (!_registry.shadow) {
    throw new Error(`Cannot request shadow before registering.`);
  }
  return _registry.shadow;
};
export const getShadowStoryJson = (): Shadow.StoryJSON => {
  if (!_registry.shadowStory) {
    throw new Error(`Cannot request shadowStory before registering.`);
  }
  return _registry.shadowStory;
};
export const getShadowDimensionJson = (): Shadow.DimensionJSON => {
  if (!_registry.dimRef || !_registry.dimRef.shadow) {
    throw new Error(`Cannot request shadow before registering.`);
  }
  return _registry.dimRef.shadow;
};

export const getShadowById = (
  id: string
): Shadow.BlockJSON | Shadow.LayerJSON | Shadow.AudioBlockJSON | Shadow.ComponentJSON | null => {
  const ref = _registry.ids[id];
  if (!ref || !ref.shadow || ref.shadow.isDestroyed) {
    return null;
  }
  return ref.shadow;
};

export const getShadowBlockById = (id: string): Shadow.BlockJSON | null => {
  const ref = _registry.ids[id];
  if (!ref || !ref.shadow || ref.shadow.isDestroyed || ref.type !== 'block') {
    return null;
  }
  return ref.shadow;
};

export const getShadowLayerById = (id: string): Shadow.LayerJSON | null => {
  const ref = _registry.ids[id];
  if (!ref || !ref.shadow || ref.shadow.isDestroyed || ref.type !== 'layer') {
    return null;
  }
  return ref.shadow;
};

export const getShadowAudioBlockById = (id: string): Shadow.AudioBlockJSON | null => {
  const ref = _registry.ids[id];
  if (!ref || !ref.shadow || ref.shadow.isDestroyed || ref.type !== 'audioBlock') {
    return null;
  }
  return ref.shadow;
};

export const getShadowComponentById = (id: string): Shadow.ComponentJSON | null => {
  const ref = _registry.ids[id];
  if (!ref || !ref.shadow || ref.shadow.isDestroyed || ref.type !== 'component') {
    return null;
  }
  return ref.shadow;
};

export const scrub = (json: Shadow.JSON): Master.JSON => {
  const clone = cloneJSON(json);
  unsafeScrub(clone);
  return clone;
};

export const scrubStory = (json: Shadow.StoryJSON): Master.StoryJSON => {
  const clone = cloneJSON(json);
  unsafeScrub(clone);
  return clone;
};

export const scrubDimension = (json: Shadow.DimensionJSON): Master.DimensionJSON => {
  const clone = cloneJSON(json);
  unsafeScrubDimension(clone);
  return clone;
};

export const scrubComponentOrBlock = (json: Shadow.AnyJSON | Master.AnyJSON): Master.AnyJSON => {
  const clone = cloneJSON(json);
  unsafeScrubComponentOrBlock(clone);
  return clone;
};

export const scrubComponent = (
  json: Shadow.ComponentJSON | Master.AnyComponentJSON
): Master.AnyComponentJSON => {
  const clone = cloneJSON(json);
  unsafeScrubComponentOrBlock(clone);
  return clone;
};

export const scrubBlock = (json: Shadow.BlockJSON | Master.BlockJSON): Master.BlockJSON => {
  const clone = cloneJSON(json);
  unsafeScrubComponentOrBlock(clone);
  return clone;
};

export const scrubAudioBlock = (
  json: Shadow.AudioBlockJSON | Master.AudioBlockJSON
): Master.AudioBlockJSON => {
  const clone = cloneJSON(json);
  unsafeScrubComponentOrBlock(clone);
  return clone;
};

//// Private

//
//// scrub
//

const unsafeScrub = (json: any) => {
  delete json.index;
  delete json.shadowType;
  delete json.uid;
  delete json.tUpdated;
  delete json.isDestroyed;
  delete json.is_colors_applied;
  const dimension = orderedDimensions.find((d) => json[d]);
  if (dimension) {
    unsafeScrubDimension(json[dimension]!);
  }
};

const unsafeScrubDimension = (json: any) => {
  delete json.index;
  delete json.shadowType;
  delete json.uid;
  delete json.tUpdated;
  delete json.isDestroyed;
  json.blocks = [...(json.blocks ?? []), ...(json.ignoredBlocks ?? [])];
  json.audio_blocks = [...(json.audio_blocks ?? [])];
  json.blocks.forEach((b: unknown) => unsafeScrubComponentOrBlock(b));
  json.audio_blocks.forEach((b: unknown) => unsafeScrubComponentOrBlock(b));
  delete json.ignoredBlocks;
};

const unsafeScrubComponentOrBlock = (json: any) => {
  delete json.index;
  delete json.shadowType;
  delete json.uid;
  delete json.tUpdated;
  delete json.isDestroyed;
  (json.components ?? []).map((c: unknown) => unsafeScrubComponentOrBlock(c));
};

//
//// mark as mutated
//

const markDimensionAsMutated = (tNow: number): void => {
  if (!_registry.dimRef || !_registry.dimRef.shadow) {
    throw new Error(`shadow cannot be mutated before registering.`);
  }
  const dimRef = _registry.dimRef!;
  const newSnapshot = dimSnapshot(dimRef.shadow);
  if (newSnapshot === dimRef.shadowSnapshot) return;
  observableQueue.push(() =>
    observables.dimension.changed$.next({ dim: getShadowDimensionJson() })
  );
  dimRef.tUpdated = tNow;

  markArrayAsMutatedFor<Shadow.LayerJSON>(
    dimRef.shadow.layers,
    [],
    dimRef.shadowLayerIds,
    dimRef.uid,
    tNow,
    'layer'
  );
  markArrayAsMutatedFor<Shadow.BlockJSON>(
    dimRef.shadow.blocks,
    dimRef.shadow.ignoredBlocks,
    dimRef.shadowBlockIds,
    dimRef.uid,
    tNow,
    'block'
  );
  markArrayAsMutatedFor<Shadow.AudioBlockJSON>(
    dimRef.shadow.audio_blocks,
    [],
    dimRef.shadowAudioBlockIds,
    dimRef.uid,
    tNow,
    'audioBlock'
  );
  dimRef.shadowBlockIds = dimRef.shadow.blocks.map((b) => b.id);
  dimRef.shadowIgnoredBlockIds = dimRef.shadow.ignoredBlocks.map((b) => b.id);
  dimRef.shadowAudioBlockIds = dimRef.shadow.audio_blocks.map((b) => b.id);
  dimRef.shadowLayerIds = dimRef.shadow.layers.map((l) => l.id);
  dimRef.shadowSnapshot = dimSnapshot(dimRef.shadow);
};

const markArrayAsMutatedFor = <T extends Shadow.AnyJSON>(
  shadowArray: T[],
  ignoredShadowArray: T[],
  oldIds: string[],
  parentUid: string,
  tNow: number,
  shadowType: Shadow.TypeOf<T>
): void => {
  if (oldIds.length === shadowArray.length) {
    const withChangedIndex = shadowArray.filter((o, i) => oldIds[i] !== o.id);
    if (withChangedIndex.length >= 2) {
      observableQueue.push(() =>
        observables[shadowType].swapped$?.next({
          id1: withChangedIndex[0].id,
          id2: withChangedIndex[1].id,
        })
      );
    }
  }
  const newIds = shadowArray.map((o) => o.id);
  const deletedIds = oldIds.filter((id) => !newIds.includes(id));
  deletedIds.forEach((id) => {
    observableQueue.push(() => observables[shadowType].removed$?.next({ id }));
    const ref = _registry.ids[id];
    if (ref) {
      ref.shadow.isDestroyed = true;
      ref.shadow.tUpdated = tNow;
      ref.snapshot = null;
      ref.shadowSnapshot = null;
    }
  });

  const fullShadow = [...shadowArray, ...ignoredShadowArray];
  let newShadow: T[] = [];
  let newIgnoredShadow: T[] = [];
  if (shadowType === 'block') {
    newShadow = ((fullShadow as Shadow.BlockJSON[]) ?? []).filter(isNotIgnoredBlock) as T[];
    newIgnoredShadow = ((fullShadow as Shadow.BlockJSON[]) ?? []).filter(isIgnoredBlock) as T[];
  }
  if (shadowType === 'component') {
    newShadow = ((fullShadow as Shadow.ComponentJSON[]) ?? []).filter(isNotIgnored) as T[];
    newIgnoredShadow = ((fullShadow as Shadow.ComponentJSON[]) ?? []).filter(isIgnored) as T[];
  }
  if (shadowType === 'audioBlock') {
    newShadow = ((fullShadow as Shadow.AudioBlockJSON[]) ?? []).filter(
      isNotIgnoredAudioBlock
    ) as T[];
    newIgnoredShadow = ((fullShadow as Shadow.AudioBlockJSON[]) ?? []).filter(
      isIgnoredAudioBlock
    ) as T[];
  }

  if (shadowType === 'layer') {
    newShadow = ((fullShadow as Shadow.LayerJSON[]) ?? []).filter(isNotEmpty) as T[];
    shadowArray.length = 0;
    ignoredShadowArray.length = 0;
    newShadow
      .map((obj) => markAsMutatedFor<T>(obj, parentUid, tNow, obj.index, shadowType))
      .filter(isNotEmpty)
      .filter((s) => !s.isDestroyed)
      .forEach((obj) => {
        shadowArray.push(obj);
      });
    return;
  }

  shadowArray.length = 0;
  ignoredShadowArray.length = 0;
  newShadow
    .map((obj, i) => markAsMutatedFor<T>(obj, parentUid, tNow, i, shadowType))
    .filter(isNotEmpty)
    .filter((s) => !s.isDestroyed)
    .forEach((obj, i) => {
      obj.index = i;
      shadowArray.push(obj);
    });
  newIgnoredShadow
    .map((obj, i) => markAsMutatedFor<T>(obj, parentUid, tNow, i, shadowType))
    .filter(isNotEmpty)
    .filter((s) => !s.isDestroyed)
    .forEach((obj, i) => {
      obj.index = i;
      ignoredShadowArray.push(obj);
    });
};

const markAsMutatedFor = <T extends Shadow.AnyJSON>(
  element: T,
  parentUid: string,
  tNow: number,
  index: number,
  shadowType: Shadow.TypeOf<T>
): T => {
  const ref = _registry.ids[element.id];
  if (!ref || ref.snapshot === null) {
    // fireAdded<T>({ id: element.id, shadowType });
    return assignShadowFor<T>(element, parentUid, tNow, index, shadowType);
  }
  if (ref.type !== shadowType) {
    throw new Error(`${ref.type} illegally changed to ${shadowType} for id: ${ref.id}`);
  }
  const newSnapshot = snapshot(ref.shadow);
  if (newSnapshot === ref.shadowSnapshot) return ref.shadow as T;
  fireChanged<T>({ id: element.id, shadowType }, { shadowOnly: true });
  ref.tUpdated = tNow;
  Object.assign(ref.shadow, {
    tUpdated: tNow,
    uid: ref.uid ?? uidFor(ref.shadow.id, parentUid),
    isDestroyed: false,
    index,
  });
  markArrayAsMutatedFor<Shadow.ComponentJSON>(
    ref.shadow.components,
    [],
    ref.shadowComponentIds,
    ref.shadow.uid,
    tNow,
    'component'
  );
  ref.shadowSnapshot = snapshot(ref.shadow);
  ref.shadowComponentIds = ref.shadow.components.map((c) => c.id);
  return ref.shadow as T;
};

const assignShadowFor = <T extends Shadow.AnyJSON>(
  element: T,
  parentPrefix: string,
  tNow: number,
  index: number,
  shadowType: Shadow.TypeOf<T>
): T => {
  const uid = uidFor(element.id, parentPrefix);
  Object.assign(element, {
    shadowType,
    index,
    uid,
    tUpdated: tNow,
    isDestroyed: false,
    components: (element.components ?? [])
      .filter(isNotIgnored)
      .map((c, i) => assignShadowFor<Shadow.ComponentJSON>(c, uid, tNow, i, 'component')),
  });
  const ref: Reference<T> = {
    type: shadowType,
    tUpdated: element.tUpdated,
    id: element.id,
    uid: element.uid,
    snapshot: null,
    shadowSnapshot: snapshot(element),
    shadow: element,
    shadowComponentIds: (element.components ?? []).map((c) => c.id),
  };
  _registry.ids[element.id] = ref as IdReference; // coerce as a meaningful type is checked above.

  return element;
};

//
//// update*
//

const updateDimension = (dJson: Master.DimensionJSON, key: string, tNow: number) => {
  if (!_registry.dimRef || !_registry.dimRef.shadow) {
    injectShadowDimension(dJson, key, tNow);
  }
  const dimRef = _registry.dimRef!;
  const newSnapshot = dimSnapshot(dJson);
  if (dimRef.snapshot === newSnapshot) return;
  dimRef.snapshot = newSnapshot;
  observableQueue.push(() =>
    observables.dimension.changed$.next({ dim: getShadowDimensionJson() })
  );
  dimRef.tUpdated = tNow;
  Object.assign(dimRef.shadow, dJson, {
    blocks: dimRef.shadow.blocks,
    ignoredBlocks: dimRef.shadow.ignoredBlocks,
    audio_blocks: dimRef.shadow.audio_blocks,
    uid: dimRef.uid,
    tUpdated: tNow,
    isDestroyed: false,
  });
  dimRef.shadowSnapshot = dimSnapshot(dimRef.shadow);
  updateArrayFor<Shadow.LayerJSON>(
    dJson.layers || [],
    dimRef.shadow.layers,
    dimRef.uid,
    tNow,
    'layer'
  );
  dimRef.shadow.layers.forEach((l, i) => {
    l.index = JSON.parse(newSnapshot).layers[i].index;
  });
  updateArrayFor<Shadow.BlockJSON>(
    dJson.blocks.filter(isNotIgnoredBlock),
    dimRef.shadow.blocks,
    dimRef.uid,
    tNow,
    'block'
  );
  updateArrayFor<Shadow.BlockJSON>(
    dJson.blocks.filter(isIgnoredBlock),
    dimRef.shadow.ignoredBlocks,
    dimRef.uid,
    tNow,
    'block'
  );
  updateArrayFor<Shadow.AudioBlockJSON>(
    dJson.audio_blocks || [],
    dimRef.shadow.audio_blocks,
    dimRef.uid,
    tNow,
    'audioBlock'
  );
  dimRef.shadowLayerIds = dimRef.shadow.layers.map((l) => l.id);
  dimRef.shadowBlockIds = dimRef.shadow.blocks.map((b) => b.id);
  dimRef.shadowAudioBlockIds = dimRef.shadow.audio_blocks.map((b) => b.id);
  dimRef.shadowIgnoredBlockIds = dimRef.shadow.ignoredBlocks.map((b) => b.id);
  dimRef.shadowComponentIds = [];
};

const updateArrayFor = <T extends Shadow.AnyJSON>(
  masterArray: Shadow.MasterOf<T>[], // remove ignored before sending
  shadowArray: T[],
  parentUid: string,
  tNow: number,
  shadowType: Shadow.TypeOf<T>
): void => {
  const masterIds = masterArray.map((o) => o.id);
  if (masterIds.length === shadowArray.length) {
    const withChangedIndex = shadowArray.filter((o, i) => masterIds[i] !== o.id);
    if (withChangedIndex.length === 2) {
      observableQueue.push(() =>
        observables[shadowType].swapped$?.next({
          id1: withChangedIndex[0].id,
          id2: withChangedIndex[1].id,
        })
      );
    }
  }
  const deletedElements = shadowArray.filter((o) => !masterIds.includes(o.id));
  deletedElements.forEach((element) => {
    observableQueue.push(() => observables[shadowType].removed$?.next({ id: element.id }));
    const ref = _registry.ids[element.id];
    ref.shadow.isDestroyed = true;
    ref.shadow.tUpdated = tNow;
    ref.snapshot = null;
    ref.shadowSnapshot = null;
  });

  const newShadow: T[] = masterArray
    .map((obj, i) => updateAndFetchFor<T>(obj, parentUid, tNow, shadowType, i))
    .filter(isNotEmpty);
  shadowArray.length = 0;
  newShadow.forEach((o, i) => {
    o.index = i;
    shadowArray.push(o);
  });
};
const updateAndFetchFor = <T extends Shadow.AnyJSON>(
  master: Shadow.MasterOf<T>,
  parentUid: string,
  tNow: number,
  shadowType: Shadow.TypeOf<T>,
  index: number
): T => {
  const ref = _registry.ids[master.id];
  if (!ref || ref.snapshot === null) {
    fireAdded({ id: master.id, shadowType });
  }
  if (!ref || !ref.shadow) {
    return injectShadowFor<T>(master, parentUid, tNow, shadowType);
  }
  if (ref.type !== shadowType) {
    throw new Error(`${ref.type} illegally changed to ${shadowType} for id: ${ref.id}`);
  }
  const newSnapshot = snapshot(master);
  if (newSnapshot === ref.snapshot && ref.shadow.index === index) return ref.shadow as T;
  fireChanged<T>({ id: master.id, shadowType });
  ref.snapshot = newSnapshot;
  ref.tUpdated = tNow;
  Object.assign(ref.shadow, master, {
    shadowType: ref.shadow.shadowType,
    components: ref.shadow.components,
    tUpdated: ref.tUpdated,
    uid: ref.uid,
    isDestroyed: false,
    index,
  });
  updateArrayFor<Shadow.ComponentJSON>(
    (master.components ?? []).filter(isNotIgnored) as unknown as Master.ComponentJSON[], // ts analysis fails for no reason
    ref.shadow.components,
    ref.uid,
    tNow,
    'component'
  );
  ref.shadowComponentIds = ref.shadow.components.map((c) => c.id);
  ref.shadowSnapshot = snapshot(ref.shadow);
  return ref.shadow as T;
};

//
// injectShadow (was addShadow)
//

const injectShadow = (json: Master.JSON, tNow: number): Shadow.JSON => {
  _registry.uidPrefixes = {};
  const uid = json.master_video_id.toString();
  const shadow: Shadow.JSON = {
    uid,
    tUpdated: tNow,
    isDestroyed: false,
    index: 0,
    shadowType: 'json',
    is_colors_applied: false,
    ...json,
    '16:9': json['16:9'] ? injectShadowDimension(json['16:9'], '16_9', tNow) : undefined,
    '9:16': json['9:16'] ? injectShadowDimension(json['9:16'], '9_16', tNow) : undefined,
    '1:1': json['1:1'] ? injectShadowDimension(json['1:1'], '1_1', tNow) : undefined,
  };
  orderedDimensions.forEach((dimension) => {
    if (!shadow[dimension]) delete shadow[dimension];
  });
  _registry.shadow = shadow;
  _registry.snapshot = fullSnapshot(json);
  _registry.shadowSnapshot = fullSnapshot(shadow);
  return shadow;
};

const injectStory = (json: Master.StoryJSON, tNow: number): Shadow.StoryJSON => {
  const uid = json.master_video_id.toString();
  const shadow: Shadow.StoryJSON = {
    uid,
    tUpdated: tNow,
    isDestroyed: false,
    index: 0,
    shadowType: 'storyJson',
    ...json,
  };
  _registry.shadowStory = shadow;
  _registry.storySnapshot = fullSnapshot(json);
  _registry.shadowStorySnapshot = fullSnapshot(shadow);
  return shadow;
};

const injectShadowDimension = (
  json: Master.DimensionJSON | undefined,
  key: string,
  tNow: number
): Shadow.DimensionJSON | undefined => {
  if (!json) return undefined;
  const blocks = json.blocks.map((b) => injectShadowFor<Shadow.BlockJSON>(b, key, tNow, 'block'));
  const layers =
    json.layers.map((l) => {
      const shadow = injectShadowFor<Shadow.LayerJSON>(l, key, tNow, 'layer');
      shadow.index = l.index;
      return shadow;
    }) || [];

  const dim: Shadow.DimensionJSON = {
    ...json,
    uid: key,
    index: 0,
    tUpdated: tNow,
    shadowType: 'dimension',
    isDestroyed: false,
    blocks: blocks.filter(isNotIgnoredBlock),
    layers,
    ignoredBlocks: blocks.filter(isIgnoredBlock),
    audio_blocks: json.audio_blocks
      .map((b) => injectShadowFor<Shadow.AudioBlockJSON>(b, key, tNow, 'audioBlock'))
      .filter(isNotEmpty),
  };
  dim.blocks.forEach((b, i) => (b.index = i));
  dim.audio_blocks.forEach((b, i) => (b.index = i));

  _registry.dimRef = {
    type: 'dimension',
    tUpdated: tNow,
    id: key,
    uid: key,
    snapshot: dimSnapshot(json),
    shadowSnapshot: dimSnapshot(dim),
    shadow: dim,
    shadowLayerIds: dim.layers.map((l) => l.id),
    shadowBlockIds: dim.blocks.map((b) => b.id),
    shadowIgnoredBlockIds: dim.ignoredBlocks.map((b) => b.id),
    shadowAudioBlockIds: dim.audio_blocks.map((b) => b.id),
    shadowComponentIds: [],
  };
  return dim;
};

const injectShadowFor = <T extends Shadow.AnyJSON>(
  json: Shadow.MasterOf<T>,
  parentPrefix: string,
  tNow: number,
  shadowType: Shadow.TypeOf<T>
): T => {
  const uid = uidFor(json.id, parentPrefix);

  const tUpdated = shadowType === 'block' ? (json as Shadow.BlockJSON).tUpdated ?? tNow : tNow;
  const inject: Shadow.WithShadow = {
    shadowType,
    index: 0, // to be set by caller
    uid,
    tUpdated: tUpdated,
    isDestroyed: false,
  };
  const untypedShadow = {
    ...json,
    ...inject,
    components: (json.components ?? [])
      .filter(isNotIgnored)
      .map((c) =>
        injectShadowFor<Shadow.ComponentJSON>(c as Master.ComponentJSON, uid, tNow, 'component')
      )
      .filter<Shadow.ComponentJSON>(isNotEmpty),
  };

  untypedShadow.components.forEach((c, i) => {
    (c as any).index = i; // TODO: why coerce
  });

  const shadow = untypedShadow as unknown as T; // coerce
  const reg: Reference<T> = {
    type: shadowType,
    tUpdated: shadow.tUpdated,
    id: shadow.id,
    uid: shadow.uid,
    snapshot: snapshot(json),
    shadowSnapshot: snapshot(shadow),
    shadow,
    shadowComponentIds: shadow.components.map((c) => c.id),
  };
  _registry.ids[shadow.id] = reg as unknown as IdReference; // coerce as we have verified the type above.

  return shadow;
};

//
// Helpers
//
const fireAdded = <T extends Shadow.AnyJSON>({
  id,
  shadowType,
}: {
  id: string;
  shadowType: Shadow.TypeOf<T>;
}) => {
  switch (shadowType) {
    case 'block':
      return observableQueue.push(() =>
        observables.block.added$.next({ id, block: getShadowBlockById(id)! })
      );
    case 'audioBlock':
      return observableQueue.push(() =>
        observables.audioBlock.added$.next({ id, audioBlock: getShadowAudioBlockById(id)! })
      );
    case 'component':
      return observableQueue.push(() =>
        observables.component.added$.next({ id, component: getShadowComponentById(id)! })
      );
  }
};
const fireChanged = <T extends Shadow.AnyJSON>(
  {
    id,
    shadowType,
  }: {
    id: string;
    shadowType: Shadow.TypeOf<T>;
  },
  options: { shadowOnly: boolean } = { shadowOnly: false }
) => {
  switch (shadowType) {
    case 'block':
      return observableQueue.push(
        options.shadowOnly
          ? null
          : () => observables.block.changed$.next({ id, block: getShadowBlockById(id)! }),
        () => observables.block.shadowChanged$.next({ id, block: getShadowBlockById(id)! })
      );
    case 'audioBlock':
      return observableQueue.push(
        options.shadowOnly
          ? null
          : () =>
              observables.audioBlock.changed$.next({
                id,
                audioBlock: getShadowAudioBlockById(id)!,
              }),
        () =>
          observables.audioBlock.shadowChanged$.next({
            id,
            audioBlock: getShadowAudioBlockById(id)!,
          })
      );
    case 'component':
      return observableQueue.push(
        options.shadowOnly
          ? null
          : () =>
              observables.component.changed$.next({ id, component: getShadowComponentById(id)! }),
        () =>
          observables.component.shadowChanged$.next({ id, component: getShadowComponentById(id)! })
      );
  }
};

const fullSnapshot = (json: Master.JSON | Shadow.JSON | Master.StoryJSON): string => {
  return JSON.stringify(json);
};

const dimSnapshot = (json: Master.DimensionJSON | Shadow.DimensionJSON): string => {
  return JSON.stringify(json);
};

const snapshot = (json: Master.AnyJSON | Shadow.AnyJSON): string => {
  return JSON.stringify(json);
};

const uidFor = (id: string, parentPrefix: string) => {
  const idSuffix =
    id
      .split('.')
      .filter((p) => p.length >= 2)
      .slice(-1)[0] ?? 'xx';
  return uniquifyUid(`${parentPrefix}>${idSuffix.substring(0, 2)}`);
};

const uniquifyUid = (uid: string): string => {
  const prefix = uid.replace(/\.\d+$/, '');
  let suffix = 0;
  if (_registry.uidPrefixes[prefix] !== undefined) {
    suffix = _registry.uidPrefixes[prefix] + 1;
  }
  _registry.uidPrefixes[prefix] = suffix;
  if (suffix === 0) return prefix;
  return `${prefix}.${suffix}`;
};

const isNotIgnored = (
  value: Master.ComponentJSON | Master.IgnoredComponentJSON | null
): value is Master.ComponentJSON => {
  if (
    !value ||
    value.duration.absolute_duration === undefined ||
    value.duration.start_time === undefined ||
    value.duration.end_time === undefined ||
    (value.type === 'text' && (value as any).highlighted_text === undefined)
  )
    return false; // ignore when this is the case
  return true;
};
const isIgnored = (
  value: Master.ComponentJSON | Master.IgnoredComponentJSON | null
): value is Master.ComponentJSON => {
  return !isNotIgnored(value);
};

const isNotIgnoredBlock = (
  value: Master.BlockJSON | null | undefined
): value is Master.BlockJSON => {
  if (value === null || value === undefined || value.components.length === 0) {
    return false;
  } // ignore when this is the case

  return true;
};

const isNotIgnoredAudioBlock = (
  value: Master.AudioBlockJSON | null | undefined
): value is Master.AudioBlockJSON => {
  if (value === null || value === undefined) return false; // ignore when this is the case
  return true;
};

const isIgnoredBlock = (value: Master.BlockJSON | null | undefined): value is Master.BlockJSON => {
  return !isNotIgnoredBlock(value);
};

const isIgnoredAudioBlock = (
  value: Master.AudioBlockJSON | null | undefined
): value is Master.AudioBlockJSON => {
  return !isNotIgnoredAudioBlock(value);
};

const isNotEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  if (value === null || value === undefined) return false;
  return true;
};

export const internalsForTesting = {
  _registry,
  injectShadow,
  injectShadowFor,
  isNotIgnored,
  uidFor,
};

import { slice, State } from './userSlice';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import * as MasterStore from '../store/masterStore';
import {
  DeviceInfo,
  DropMetaData,
  NetworkInfo,
  StorageInfo,
  TemplateInfo,
  AccountData,
  PurchasedMedia,
  Experiments,
  YoutubeCopyrightsInfo,
  AppConfig,
  UserData,
  GoogleConnectedInfo,
  PremiumMediaEvent,
  CardDetails,
  ColorPalette,
  FacebookConnectedInfo,
  TwitterConnectedInfo,
  FeatureConfig,
  ExperimentalFeatures,
  Bucket,
  Timestamp,
} from './types';
import { APP_SUMO_PLAN, PAID_PLANS } from './constants';

export class Api {
  constructor(private stateFetcher: () => State, private dispatch: Dispatch<AnyAction>) {}

  get asDebugView() {
    return this.state;
  }

  private get state(): State {
    return this.stateFetcher();
  }

  private get actions() {
    return slice.actions;
  }

  private isGuestUserSelector = (state: State) => {
    let flag = false;
    const user = state.userData?.account_details ?? null;
    if (user === null) {
      return null;
    }
    if ('guest_user' in user) {
      if (user['guest_user'] === true) {
        flag = true;
      }
    }
    return flag;
  };

  private isPaidUserSelector(state: State) {
    const account = state.userData?.account_details;
    return PAID_PLANS.includes(account?.payment_plan ?? '');
  }

  private isAppSumoUserSelector(state: State) {
    const account = state.userData?.account_details;
    return APP_SUMO_PLAN === account?.payment_plan ?? '';
  }

  observables = {
    isGuestUser$: this.sliceObservable(this.isGuestUserSelector),
    isPaidUser$: this.sliceObservable(this.isPaidUserSelector),
    accountData$: this.sliceObservable((state) => state.userData?.account_details),
    purchasedMedia$: this.sliceObservable((state) => state.purchasedMedia),
    advTimelineExperiment$: this.sliceObservable(
      (state) => state.experiments.advTimelineExperiment,
    ),
    teamMembersCount$: this.sliceObservable((state) => state.teamMembersCount),
    isYoutubeWhitelisted$: this.sliceObservable(
      (state) => state.youtubeCopyrightsInfo.isYoutubeWhitelisted,
    ),
    isCopyrightBannerDismissed$: this.sliceObservable(
      (state) => state.youtubeCopyrightsInfo.isCopyrightBannerDismissed,
    ),
    appConfig$: this.sliceObservable((state) => state.appConfig),
    userData$: this.sliceObservable((state) => state.userData),
    premiumMediaEvent$: this.sliceObservable((state) => state.premiumMediaEvent),
    cardDetails$: this.sliceObservable((state) => state.cardDetails),
    countryCode$: this.sliceObservable((state) => state.countryCode),
    featureConfig$: this.sliceObservable((state) => state.featureConfig),
  };

  private sliceObservable<SliceT>(selector: (state: State) => SliceT) {
    return MasterStore.stores.user$.pipe(
      map((st) => selector(st)),
      distinctUntilChanged(),
    );
  }

  get appConfig(): AppConfig | null {
    return this.state.appConfig;
  }

  get featureConfig(): FeatureConfig | null {
    return this.state.featureConfig;
  }

  get device(): DeviceInfo | null {
    return this.state.device;
  }

  set device(info: DeviceInfo | null) {
    this.dispatch(this.actions.updateDevice(info));
  }

  get network(): NetworkInfo | null {
    return this.state.network;
  }

  set network(info: NetworkInfo | null) {
    this.dispatch(this.actions.updateNetwork(info));
  }

  get storage(): StorageInfo | null {
    return this.state.storage;
  }

  set storage(info: StorageInfo | null) {
    this.dispatch(this.actions.updateStorage(info));
  }

  get deviceId(): string | null {
    return this.state.deviceId;
  }

  set deviceId(id: string | null) {
    this.dispatch(this.actions.updateDeviceId(id));
  }

  get templateInfo(): TemplateInfo | null {
    return this.state.templateInfo;
  }

  set templateInfo(info: TemplateInfo | null) {
    this.dispatch(this.actions.updateTemplateInfo(info));
  }

  get templateSearchTerm() {
    return this.state.templateSearchTerm;
  }

  set templateSearchTerm(term: string) {
    this.dispatch(this.actions.updateTemplateSearchTerm(term));
  }

  get templateAnalyticsProperties() {
    return this.state.templateAnalyticsProperties;
  }

  set templateAnalyticsProperties(data: { startedWithRoute: string; startedWithKeyword: string }) {
    this.dispatch(this.actions.updateTemplateAnalyticsProperties(data));
  }

  get fixedAnalyticsProperties() {
    return this.state.fixedAnalyticsProperties;
  }

  set fixedAnalyticsProperties(data: { [name: string]: string }) {
    this.dispatch(this.actions.updateFixedAnalyticsProperties(data));
  }

  get dragEventProperties() {
    return this.state.dragEventProperties;
  }

  set dragEventProperties(properties: DropMetaData | null) {
    this.dispatch(this.actions.updateDragEventProperties(properties));
  }

  get guestUserFlow() {
    return this.state.guestUserFlow;
  }

  set guestUserFlow(value: boolean) {
    this.dispatch(this.actions.updateGuestUserFlow(value));
  }

  set accountData(accountData: AccountData) {
    this.dispatch(this.actions.updatedAccountData(accountData));
  }

  get isDesignRole() {
    const user = this.state.userData;
    return user && user?.role === 'design';
  }

  get isCreatorRole() {
    const user = this.state.userData;
    return user && user?.role === 'creator';
  }

  get isGuestUser() {
    return this.isGuestUserSelector(this.state);
  }

  get isPaidUser() {
    return this.isPaidUserSelector(this.state);
  }

  get isAppSumoUser() {
    return this.isAppSumoUserSelector(this.state);
  }

  get purchasedMedia(): PurchasedMedia {
    return this.state.purchasedMedia;
  }

  set purchasedMedia(media: PurchasedMedia) {
    this.dispatch(this.actions.updatePurchasedMedia(media));
  }

  get advTimelineExperiment(): Experiments['advTimelineExperiment'] {
    return this.state.experiments.advTimelineExperiment;
  }

  set advTimelineExperiment(value: Experiments['advTimelineExperiment']) {
    this.dispatch(this.actions.updateAudioAdvTimelineExperiment(value));
  }

  get sessionId(): string {
    return this.state.sessionId;
  }

  set sessionId(value: string) {
    this.dispatch(this.actions.updateSessionId(value));
  }

  set teamMembersCount(count: number | null) {
    this.dispatch(this.actions.updateTeamMembersCount(count));
  }
  get teamMembersCount() {
    return this.state.teamMembersCount;
  }

  get isYoutubeWhitelisted(): YoutubeCopyrightsInfo['isYoutubeWhitelisted'] {
    return this.state.youtubeCopyrightsInfo.isYoutubeWhitelisted;
  }

  set isYoutubeWhitelisted(value: YoutubeCopyrightsInfo['isYoutubeWhitelisted']) {
    this.dispatch(this.actions.updateIsYoutubeWhitelisted(value));
  }

  get isCopyrightBannerDismissed(): YoutubeCopyrightsInfo['isCopyrightBannerDismissed'] {
    return this.state.youtubeCopyrightsInfo.isCopyrightBannerDismissed;
  }

  set isCopyrightBannerDismissed(value: YoutubeCopyrightsInfo['isCopyrightBannerDismissed']) {
    this.dispatch(this.actions.updateIsCopyrightBannerDismissed(value));
  }
  updateAppConfig(appConfig: AppConfig) {
    this.dispatch(this.actions.updateAppConfig(appConfig));
  }

  updateFeatureConfig(featureConfig: FeatureConfig) {
    this.dispatch(this.actions.updateFeatureConfig(featureConfig));
  }

  get cardDetails(): CardDetails | null {
    return this.state.cardDetails;
  }
  set cardDetails(value: CardDetails | null) {
    this.dispatch(this.actions.updateCardDetails(value));
  }

  updateUserData(userData: UserData) {
    /**
     * Never use this window variable for user Role from React Component,
     * this is only for CompositeType checker
     */
    if (userData?.role) {
      (window as any).user_role = userData.role;
    }
    this.dispatch(this.actions.updatedUserData(userData));
  }

  updateAccountData(accountData: AccountData | null) {
    this.dispatch(this.actions.updatedAccountData(accountData));
  }

  updateBgRemoveCount(bgCount: number) {
    this.dispatch(this.actions.updateBgRemoveCount(bgCount));
  }

  get userData() {
    return this.state.userData;
  }

  get isGoogleConnected(): GoogleConnectedInfo['isGoogleConnected'] {
    return this.state.googleConnectedInfo.isGoogleConnected;
  }

  set isGoogleConnected(value: GoogleConnectedInfo['isGoogleConnected']) {
    this.dispatch(this.actions.updateIsGoogleConnected(value));
  }

  get countryCode(): string {
    return this.state.countryCode;
  }

  set countryCode(value: string) {
    this.dispatch(this.actions.updateCountryCode(value));
  }
  set premiumMediaEvent(event: PremiumMediaEvent | null) {
    this.dispatch(this.actions.updatePremiumMediaEvent(event));
  }

  set colorPalette(value: Array<ColorPalette> | undefined) {
    this.dispatch(this.actions.updateColorPalette(value));
  }

  get colorPalette(): Array<ColorPalette> | undefined {
    return this.state.colorPalette;
  }

  get isFacebookConnected(): FacebookConnectedInfo['isFacebookConnected'] {
    return this.state.facebookConnectedInfo.isFacebookConnected;
  }

  set isFacebookConnected(value: FacebookConnectedInfo['isFacebookConnected']) {
    this.dispatch(this.actions.updateIsFacebookConnected(value));
  }

  get isTwitterConnected(): TwitterConnectedInfo['isTwitterConnected'] {
    return this.state.twitterConnectedInfo.isTwitterConnected;
  }

  set isTwitterConnected(value: TwitterConnectedInfo['isTwitterConnected']) {
    this.dispatch(this.actions.updateIsTwitterConnected(value));
  }

  isFirefox() {
    return this.state.device?.browserName?.toLowerCase() === 'firefox';
  }

  private isUserBucketEligible(
    userBucket: Bucket,
    userCreatedAt: number,
    experimentJsonNew: {
      [key in Bucket]: Timestamp;
    },
  ) {
    if (
      experimentJsonNew &&
      Object.keys(experimentJsonNew).length &&
      Object.keys(experimentJsonNew).includes(userBucket)
    )
      return new Date(experimentJsonNew[userBucket]) < new Date(userCreatedAt * 1000);

    return true;
  }

  isUserEligibleForExperiment(experiment: ExperimentalFeatures) {
    const experimentJson = this.state.featureConfig?.[experiment];

    if (!experimentJson) {
      return false;
    }

    const userData = this.state.userData;

    if (!userData) {
      return false;
    }

    const {
      buckets,
      sub_buckets,
      new: experimentJsonNew,
      blacklist_accounts,
      whitelisted_accounts,
      whitelisted_email_domains,
      status,
    } = experimentJson;

    if (!status) {
      return false;
    }

    const { bucket, sub_bucket, created_at, account, email } = userData;

    const userBucket = bucket?.toUpperCase();
    const userSubBucket = sub_bucket?.toUpperCase();
    const releasedBuckets = buckets?.map((bucket) => bucket.toUpperCase());

    const releasedSubBuckets = sub_buckets?.map((subBucket) => subBucket.toUpperCase());

    if (blacklist_accounts && account && blacklist_accounts.includes(account)) return false;
    if (whitelisted_accounts && account && whitelisted_accounts.includes(account)) return true;
    if (
      whitelisted_email_domains &&
      email &&
      whitelisted_email_domains.includes(email.split('@')[1])
    ) {
      return true;
    }

    return (
      !!userBucket &&
      !!created_at &&
      !!account &&
      !!releasedBuckets &&
      !!releasedSubBuckets &&
      !!Object.keys(experimentJson).length &&
      releasedSubBuckets.includes(userSubBucket) &&
      releasedBuckets.includes(userBucket) &&
      this.isUserBucketEligible(bucket, created_at, experimentJsonNew)
    );
  }
}

import { BehaviorSubject } from 'rxjs';

interface Registry {
  t: number;
  tSlate: number;
  fps: number;
}

export interface TimeCheck extends Registry {
  slateFrame: number;
}

export const t$ = new BehaviorSubject(0);
export const tSlate$ = new BehaviorSubject(0);
export const fps$ = new BehaviorSubject(0);
export const slateFrame$ = new BehaviorSubject(0);

const _registry: Registry = {
  t: 0,
  tSlate: 0,
  fps: 30,
};

export const timeCheck = (): TimeCheck => {
  return {
    ..._registry,
    slateFrame: slateFrame(),
  };
};

export const t = () => {
  return _registry.t;
};
export const tSlate = () => {
  return _registry.tSlate;
};
export const fps = () => {
  return _registry.fps;
};

export const slateFrame = () => {
  return Math.ceil(_registry.fps * _registry.tSlate);
};
export const seek = (time: number) => {
  if (_registry.t === time && _registry.tSlate === time) return;
  _registry.t = time;
  _registry.tSlate = time;
  t$.next(time);
  tSlate$.next(time);
  slateFrame$.next(slateFrame());
};
export const seekSlate = (time: number) => {
  if (_registry.tSlate === time) return;
  _registry.tSlate = time;
  tSlate$.next(time);
  slateFrame$.next(slateFrame());
};
export const setFps = (fps: number) => {
  _registry.fps = Math.max(1, Math.min(60, fps));
};

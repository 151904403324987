import Pusher, { Channel } from 'pusher-js';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Store } from '..';

type CommentsUpdatedEventData = {
  type:
    | 'NewReviewComment'
    | 'ResolvedComment'
    | 'LikedComment'
    | 'UnLikedComment'
    | 'EditedComment'
    | 'ReadComment'
    | 'AddedAReplyToComment'
    | 'DeletedComment'
    | 'ReopenComment';
  payload: { firstName: string; userId: number };
};

export type PusherEvents = 'client-comments-updated' | 'client-masterJSON-updated';

type EventHandler =
  | {
      event: 'MasterJSONUpdated';
      handler: () => void;
    }
  | {
      event: 'client-comments-updated';
      handler: (data: CommentsUpdatedEventData) => void;
    };

type ChannelBind = (
  eventName: 'client-comments-updated',
  callback: (data: CommentsUpdatedEventData) => void,
) => Channel;
type ChannelTrigger = (
  eventName: 'client-comments-updated',
  data: CommentsUpdatedEventData,
) => boolean;
type StoryBoardMode = { status: 'yes'; id: number } | { status: 'no' };
class PusherClient {
  #videoIDChannel?: {
    channel: Channel;
    subscriptionStatus: 'pending' | 'success' | 'error';
  };

  #eventHandlers: Array<EventHandler> = [];
  #appKey: string | null = null;
  #authHost: string | null = null;

  #storyBoardMode: StoryBoardMode = { status: 'no' };

  setStoryBoardMode(storyBoardMode: StoryBoardMode) {
    this.#storyBoardMode = storyBoardMode;
    this.#unsubscribeExistingChannel();
    if (this.#storyBoardMode.status === 'yes') {
      this.#subscribeToChannel(this.#storyBoardMode.id);
    }
  }

  constructor() {
    if (Store.user.userData && Store.user.userData?.account_details) {
      this.#subscribeToChannel(Store.project.videoId);
    }

    Store.user.observables.accountData$
      .pipe(
        map((a) => a?.account_id),
        distinctUntilChanged(),
      )
      .subscribe((accountData) => {
        this.#unsubscribeExistingChannel();
        if (accountData != null) {
          this.#subscribeToChannel(Store.project.videoId);
        } else {
          this.#pusher?.disconnect();
          this.#pusher = null;
        }
      });

    Store.project.observables.videoId$.subscribe((videoId) => {
      this.#unsubscribeExistingChannel();
      this.#subscribeToChannel(videoId);
    });
    Store.user.observables.teamMembersCount$.subscribe((teamMembersCount) => {
      this.#subscribeToChannel(
        this.#storyBoardMode.status === 'yes' ? this.#storyBoardMode.id : Store.project.videoId,
      );
    });
  }

  #unsubscribeExistingChannel() {
    this.#videoIDChannel?.channel.unbind();
    this.#videoIDChannel?.channel.unsubscribe();
    this.#videoIDChannel = undefined;
  }

  initailizePusher({ appKey, authHost }: { appKey: string; authHost: string }) {
    this.#appKey = appKey;
    this.#authHost = authHost;
    this.#unsubscribeExistingChannel();
    this.#subscribeToChannel(Store.project.videoId);
  }

  logPuserToConsole() {
    Pusher.logToConsole = true;
  }

  #pusher: null | Pusher = null;
  #subscribeToChannel(videoId: number) {
    const isDummyVideoId = videoId === 0;
    if (!Store.user.teamMembersCount || Store.user.teamMembersCount <= 1) {
      return;
    }

    if (!isDummyVideoId && this.#appKey !== null && this.#authHost !== null) {
      if (this.#pusher === null) {
        this.#pusher = new Pusher(this.#appKey, {
          cluster: 'ap2',
          authEndpoint: `${this.#authHost}/api/pusher/auth`,

          auth: {
            headers: {
              // We expect authtoken to be the latest one since in the constructor we call
              // this.#subscribeToChannel when the accountData changes.
              Authorization: `Bearer ${localStorage.getItem('authToken')}` || '',
            },
          },
        });
      }
      const channel = this.#pusher.subscribe(`private-video-${videoId}`);
      this.#videoIDChannel = { channel, subscriptionStatus: 'pending' };
      channel.bind('pusher:subscription_succeeded', () => {
        if (this.#videoIDChannel) {
          this.#videoIDChannel.subscriptionStatus = 'success';
          channel.bind('client-masterJSON-updated', (data: any) => {
            this.#eventHandlers.forEach((eventHandler) => {
              if (eventHandler.event === 'MasterJSONUpdated') {
                eventHandler.handler();
              }
            });
          });
          (channel.bind as ChannelBind)('client-comments-updated', (data) => {
            this.#eventHandlers.forEach((eventHandler) => {
              if (eventHandler.event === 'client-comments-updated') {
                eventHandler.handler(data);
              }
            });
          });
        }
      });

      channel.bind('pusher:subscription_error', (error: any) => {
        if (this.#videoIDChannel) {
          this.#videoIDChannel.subscriptionStatus = 'error';
        }
      });
    }
  }

  addEventHandler(eventHandler: EventHandler) {
    this.#eventHandlers.push(eventHandler);
    return {
      removeHandler: () => {
        this.#eventHandlers = this.#eventHandlers.filter((e) => e != eventHandler);
      },
    };
  }

  sendMasterJSONUpdatedEvent() {
    if (this.#videoIDChannel && this.#videoIDChannel.subscriptionStatus === 'success') {
      return this.#videoIDChannel.channel.trigger('client-masterJSON-updated', 'updated');
    } else {
      return false;
    }
  }

  sendCommentsUpdatedEvent(payload: CommentsUpdatedEventData) {
    if (this.#videoIDChannel && this.#videoIDChannel.subscriptionStatus === 'success') {
      return (this.#videoIDChannel.channel.trigger as ChannelTrigger)(
        'client-comments-updated',
        payload,
      );
    } else {
      return false;
    }
  }
}

const pusherClient = new PusherClient();
export { pusherClient };

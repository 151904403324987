import { BLANK_TEMPLATE_ID } from '../project/constants';
import { Template } from '../jsonTypes/';
import { WorkFlowType } from '../project/types';

export function getWorkFlowType(template: Template.JSON): WorkFlowType {
  return template?.id === BLANK_TEMPLATE_ID
    ? WorkFlowType.Scratch
    : template?.id === 14132
    ? WorkFlowType.Stock
    : WorkFlowType.QuickVideo;
}

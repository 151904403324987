import { InteractionBehavior } from '../../../ui/types';
import { Master } from '../../../jsonTypes';

export type id = string;
export enum ActionSource {
  HANDLE = 'handle',
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
  NONE = 'none',
}

export interface ClipDragState {
  action: 'started' | 'dragging' | 'ended' | 'cancel' | 'none';
  source: ActionSource;
  type: 'video' | 'audio';
  compositeType: Master.CompositeType;
  clipId: id;
  sceneId: id;
  behavior: InteractionBehavior;
  initialLayerId: id;
  initialScrollX: number;
  tDeltaFromStart: number;
  overLayerId: id | null;
  overAddNewLayer: boolean;
  addNewLayerData?: {
    above: id | undefined;
    below: id | undefined;
  };
}

import {
  DEFAULT_FIXED_BACKGROUND_URL,
  DEFAULT_MASK_PLACEHOLDER_URL,
  LOGO_PLACEHOLDER_URLS,
  DEFAULT_FIXED_BACKGROUND_CLOUDFRONT_URL,
} from '../../../slateStore/masterCleaner';

import { getBaseComponentFromParentJSON } from '../master-json-utils';
// import { isCreator, isDesign } from '../user-utils';
import { Master } from '../../../jsonTypes';

export const isComposite = (compositeJSON: Master.CompositeJSON) => {
  return compositeJSON.type === 'composite';
};

export const isImageComponent = (
  componentJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
): componentJSON is Master.ImageJSON => {
  return componentJSON.type === 'image';
};

export const isVideoComponent = (
  componentJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
): componentJSON is Master.VideoJSON => {
  return componentJSON.type === 'video';
};

export const isMaskComponent = (
  componentJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
) => {
  return componentJSON.type.includes('svg') && Boolean(componentJSON.layout.layer_properties.mask);
};

export const isElementComposite = (compositeJSON: Master.CompositeJSON) => {
  return (
    isComposite(compositeJSON) &&
    (compositeJSON.sub_type === 'video_composite' || compositeJSON.sub_type === 'icon_composite')
  );
};

export const isIconComposite = (compositeJSON: Master.CompositeJSON) => {
  return isComposite(compositeJSON) && compositeJSON.sub_type === 'icon_composite';
};

export const isGifImageComponent = (
  componentJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
) => {
  return isImageComponent(componentJSON) && componentJSON.sub_type === 'gif_video';
};

export const isGifVideoComponent = (
  componentJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
) => {
  return isVideoComponent(componentJSON) && componentJSON.sub_type === 'gif_video';
};

export const isStickerImageComposite = (compositeJSON: Master.CompositeJSON) => {
  const baseComponent = compositeJSON.components.find(isImageComponent);
  return isIconComposite(compositeJSON) && Boolean(baseComponent);
};

export const isStickerVideoComposite = (compositeJSON: Master.CompositeJSON) => {
  const baseComponent = compositeJSON.components.find(isGifVideoComponent);
  return isElementComposite(compositeJSON) && Boolean(baseComponent);
};

export const isShapeImageComposite = (compositeJSON: Master.CompositeJSON) => {
  const baseComponent = compositeJSON.components.find(isImageComponent);
  return isElementComposite(compositeJSON) && Boolean(baseComponent);
};

export const isShapeVideoComposite = (compositeJSON: Master.CompositeJSON) => {
  const baseComponent = compositeJSON.components.find(isVideoComponent);
  return isElementComposite(compositeJSON) && Boolean(baseComponent);
};

export const isMaskComposite = (compositeJSON: Master.CompositeJSON) => {
  return compositeJSON.components.some(isMaskComponent);
};

export const isUserModifiedMaskComposite = (compositeJSON: Master.CompositeJSON) => {
  if (!isMaskComposite(compositeJSON)) return false;
  const mediaComponent = compositeJSON.components.find(
    (c) => isImageComponent(c) || isVideoComponent(c),
  ) as Master.ImageJSON | Master.VideoJSON;
  return mediaComponent.url !== DEFAULT_MASK_PLACEHOLDER_URL;
};

export const isTextComponent = (
  compositeJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
) => {
  return compositeJSON.type === 'text';
};

export const isTextComposite = (compositeJSON: Master.CompositeJSON) => {
  return isComposite(compositeJSON) && compositeJSON.sub_type === 'text_composite';
};

export const isBgMediaComposite = (compositeJSON: Master.CompositeJSON) => {
  return isComposite(compositeJSON) && compositeJSON.sub_type === 'bg_media_composite';
};

export const isBgImageComponent = (componentJSON: Master.ComponentJSON) => {
  return isImageComponent(componentJSON) && componentJSON.sub_type === 'bg_image';
};

export const isImageComposite = (compositeJSON: Master.CompositeJSON) => {
  const baseComponent = compositeJSON.components.find(isImageComponent);
  return isBgMediaComposite(compositeJSON) && Boolean(baseComponent);
};

export const isVideoComposite = (compositeJSON: Master.CompositeJSON) => {
  const baseComponent = compositeJSON.components.find(isVideoComponent);
  return isBgMediaComposite(compositeJSON) && Boolean(baseComponent);
};

export const isBackgroundComposite = (compositeJSON: Master.CompositeJSON) => {
  const [component] = compositeJSON.components ?? [];
  const isPreVideoComposite = compositeJSON.sub_type === 'pre_video_asset_composite';
  return isPreVideoComposite && (isImageComponent(component) || isVideoComponent(component));
};

export const isLogoComposite = (componentJSON: Master.CompositeJSON) => {
  return componentJSON.sub_type === 'logo_composite' || componentJSON.sub_type === 'logo_image';
};

export const isLogoPlaceholder = (url: string) => {
  const userRole = (window as any).user_role;
  if (userRole === 'design' || userRole === 'creator') {
    return false;
  }

  const getLogoPlaceholderRegex = () => {
    return new RegExp(LOGO_PLACEHOLDER_URLS.join('|'), 'i');
  };
  return getLogoPlaceholderRegex().test(url);
};

export const isLogoPlaceholderURL = (url: string) => {
  const getLogoPlaceholderRegex = () => {
    return new RegExp(LOGO_PLACEHOLDER_URLS.join('|'), 'i');
  };
  return getLogoPlaceholderRegex().test(url);
};

export const isLogoPlaceholderComposite = (composite: Master.CompositeJSON) => {
  const { components = [] } = composite;
  const [component] = components;
  const c = component as Master.ImageJSON;
  if (isLogoComposite(composite) && isLogoPlaceholder(c?.url)) {
    return true;
  }
  return false;
};

export const isTransitionComposite = (composite: Master.CompositeJSON) => {
  return composite.sub_type === 'transition_composite';
};

export const isTransitionComponent = (composite: Master.ComponentJSON) => {
  return composite.sub_type === 'video_transition';
};

export const isInvisibleBackgroundComponent = (
  componentJSON: Master.ComponentJSON | Master.IgnoredComponentJSON,
) => {
  const c = componentJSON as Master.ImageJSON;
  if (
    [DEFAULT_FIXED_BACKGROUND_URL, DEFAULT_FIXED_BACKGROUND_CLOUDFRONT_URL].includes(c?.url ?? '')
  ) {
    return true;
  }
  return false;
};

export const isInvisibleBackgroundComposite = (compositeJSON: Master.CompositeJSON) => {
  const [component] = compositeJSON?.components ?? [];
  return isInvisibleBackgroundComponent(component);
};

export const isGroupComposite = (compositeJSON: Master.CompositeJSON) => {
  return isComposite(compositeJSON) && compositeJSON.sub_type === 'position_composite';
};

const isOverLayComposite = (compositeJSON: Master.CompositeJSON) => {
  return isComposite(compositeJSON) && compositeJSON.sub_type === 'left_overlay_asset_composite';
};

const isWidgetComposite = (compositeJSON: Master.CompositeJSON) => {
  return isComposite(compositeJSON) && compositeJSON.sub_type === 'widget_composite';
};

export const isTrimmableComposite = (composite: Master.CompositeJSON) => {
  const baseComponent = getBaseComponentFromParentJSON(composite);
  const listOfTrimmableMediaSubTypes = ['bg_video', 'widget_video'];
  return listOfTrimmableMediaSubTypes.includes(baseComponent.sub_type ?? '');
};

export const isTrimmableComponent = (component: Master.ComponentJSON) => {
  const listOfTrimmableMediaSubTypes = ['bg_video', 'widget_video'];
  return listOfTrimmableMediaSubTypes.includes(component?.sub_type ?? '');
};

export const isLoopableComponent = (component: Master.ComponentJSON) => {
  const listOfNonLoopableMediaSubTypes = ['overlay_asset', 'gif_video'];
  return !listOfNonLoopableMediaSubTypes.includes(component?.sub_type ?? '');
};

export const isLoopableComposite = (composite: Master.CompositeJSON) => {
  const listOfNonLoopableCompositeSubTypes = [
    'left_overlay_asset_composite',
    'pre_video_asset_composite',
    'text_composite',
  ];
  return !listOfNonLoopableCompositeSubTypes.includes(composite?.sub_type);
};

export const isReplaceableComposite = (compositeJSON: Master.CompositeJSON) => {
  return isBgMediaComposite(compositeJSON);
};

export const getCompositeType = (composite: Master.CompositeJSON): Master.CompositeType => {
  type CompositeTypeMap = {
    [T in Master.CompositeType]?: (composite: Master.CompositeJSON) => boolean;
  };
  // Do not change the order here
  const compositeTypeMap: CompositeTypeMap = {
    [Master.CompositeType.CANVAS_BACKGROUND]: isInvisibleBackgroundComposite,
    [Master.CompositeType.BACKGROUND]: isBackgroundComposite,
    [Master.CompositeType.LOGO_PLACEHOLDER]: isLogoPlaceholderComposite,
    [Master.CompositeType.LOGO]: isLogoComposite,
    [Master.CompositeType.STICKER_VIDEO]: isStickerVideoComposite,
    [Master.CompositeType.STICKER_IMAGE]: isStickerImageComposite,
    [Master.CompositeType.SHAPE_VIDEO]: isShapeVideoComposite,
    [Master.CompositeType.SHAPE_IMAGE]: isShapeImageComposite,
    [Master.CompositeType.ELEMENT]: isElementComposite,
    [Master.CompositeType.TEXT]: isTextComposite,
    [Master.CompositeType.IMAGE]: isImageComposite,
    [Master.CompositeType.VIDEO]: isVideoComposite,
    [Master.CompositeType.GROUP]: isGroupComposite,
    [Master.CompositeType.OVERLAY]: isOverLayComposite,
    [Master.CompositeType.WIDGET]: isWidgetComposite,
    [Master.CompositeType.TRANSITION]: isTransitionComposite,
  };
  for (const [type, fn] of Object.entries(compositeTypeMap)) {
    if (fn(composite)) {
      return type as Master.CompositeType;
    }
  }
  return Master.CompositeType.DEFAULT;
};

export const getAssetType = (composite: Master.CompositeJSON) => {
  const baseComponent = getBaseComponentFromParentJSON(composite);
  const assetType = baseComponent.type;

  return isMaskComposite(composite) ? 'mask' : assetType;
};

export const getAssetUxName = (
  json: Master.CompositeJSON | Master.BgVideoAudioBlockJSON | Master.FileAudioBlockJSON,
) => {
  if (json.type === 'audio') {
    const audioBlock = json as Master.BgVideoAudioBlockJSON | Master.FileAudioBlockJSON;
    return audioBlock.sub_type === 'voice_over' ? 'voiceover' : 'music';
  }

  const composite = json as Master.CompositeJSON;
  const compositeType = getCompositeType(composite).toLowerCase();
  return compositeType;
};

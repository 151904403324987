import { ParagraphMetrics, Skia } from './Skia';
import {
  AlignOffsets,
  SkiaTextMetrics,
  Grains,
  Granularity,
  RichTextStyle,
  StyledTextChunks,
  TextBoxStyle,
  Size,
  Padding,
  StyledTextChunk,
} from './skiaTypes';
import { makeStyledTextChunks, isSurrogateChar } from './SkiaUtil';

const charsToReplace = [{ char: String.fromCharCode(160), replaceChar: ' ' }];

const overrideFontPaddingMultipliers = [
  { font: 'Zapfino1624516131753JLFKJSVV', paddingMultiplier: { h: 2.5, v: 1.5 } },
  { font: 'ShakilaScriptDEMO1649754276887VPFCMFNB', paddingMultiplier: { h: 1.5, v: 1.4 } },
  { font: 'BrittanySignature1647244434988XFYSQWSG', paddingMultiplier: { h: 1.5, v: 1.4 } },
  { font: 'AbugetRegular', paddingMultiplier: { h: 1.3, v: 0.7 } },
  { font: 'Radicalis31636441251683TBLEKXVG', paddingMultiplier: { h: 1.3, v: 0.7 } },
  { font: 'WindSongRegular1628591038035XPLJUUWH', paddingMultiplier: { h: 1.7, v: 1 } },
  { font: 'HerrVonMuellerhoffRegular', paddingMultiplier: { h: 1.3, v: 0.8 } },
  { font: 'MonsieurLaDoulaiseRegular1641365628221DZAQRAWA', paddingMultiplier: { h: 1.6, v: 1 } },
];

export class SkiaMetrics {
  constructor(
    private richText: string,
    private style: RichTextStyle,
    private textBoxStyle: TextBoxStyle,
    private granularity: Granularity,
    private maxFontSize?: number,
  ) {}

  private get maxLayoutSize(): Size {
    const { width, height, paddingX, paddingY } = this.textBoxStyle;
    return { width: width - 2 * paddingX, height: height - 2 * paddingY };
  }

  measure(): SkiaTextMetrics {
    const fonts = Array.from(
      new Set([
        this.style.defaultTextStyle.font,
        this.style.highlightTextStyle.font,
        ...this.style.fallbackFonts,
      ]),
    );
    const unregisteredFonts = fonts.filter((f) => !Skia.singleton.registeredFonts.includes(f));
    if (unregisteredFonts.length > 0) {
      throw new Error(`Fonts not found: ${unregisteredFonts.join(', ')}`);
    }

    let styledTextChunks = makeStyledTextChunks(this.richText);
    styledTextChunks = this.removeTrailingNewLines(styledTextChunks);
    styledTextChunks = this.fixCompatibility(styledTextChunks);

    const { wrappedStyledTextChunks, paragraphMetrics } = Skia.singleton.getGrainsMetrics(
      styledTextChunks,
      this.style,
      this.maxLayoutSize,
      this.granularity,
      this.textBoxStyle.bandPaddingX,
      this.maxFontSize,
    );
    const alignOffsets = this.calcAlignOffsets(paragraphMetrics);

    let grains: Grains;
    switch (this.granularity) {
      case 'chars':
        grains = this.buildCharGrains(styledTextChunks, paragraphMetrics, alignOffsets);
        break;
      case 'words':
        grains = this.buildWordGrains(wrappedStyledTextChunks, paragraphMetrics, alignOffsets);
        break;
      case 'lines':
        grains = this.buildLineGrains(wrappedStyledTextChunks, paragraphMetrics, alignOffsets);
        break;
      default:
        grains = this.buildWholeGrains(styledTextChunks, paragraphMetrics, alignOffsets);
    }
    this.style.defaultTextStyle.fontSize = this.style.highlightTextStyle.fontSize =
      paragraphMetrics.fontSize;

    return { size: paragraphMetrics.size, grains, style: this.style };
  }

  removeTrailingNewLines(styledTextChunks: StyledTextChunks): StyledTextChunks {
    if (styledTextChunks.length === 0) return styledTextChunks;
    if (styledTextChunks[styledTextChunks.length - 1].text !== '\n') return styledTextChunks;

    return this.removeTrailingNewLines(styledTextChunks.slice(0, styledTextChunks.length - 1));
  }

  fixCompatibility(styledTextChunks: StyledTextChunks): StyledTextChunks {
    return styledTextChunks.map((grain, i) => {
      let text = grain.text;
      charsToReplace.forEach((specialChar) => {
        if (text.indexOf(specialChar.char) !== -1) {
          text = text.replaceAll(specialChar.char, specialChar.replaceChar);
        }
      });
      if (text.charAt(text.length - 1) === ' ' && ['whole', 'line'].includes(this.granularity)) {
        if (i === styledTextChunks.length - 1 || styledTextChunks[i + 1]?.text === '\n')
          text = text.slice(0, -1) + String.fromCharCode(160);
      }
      return { ...grain, text };
    });
  }

  calcAlignOffsets(paragraphMetrics: ParagraphMetrics): AlignOffsets {
    const offset = this.calcLayoutOffsets(paragraphMetrics);
    const lineOffsets = this.calcLineOffsets(paragraphMetrics);
    return { offset, lineOffsets };
  }

  calcLayoutOffsets(paragraphMetrics: ParagraphMetrics) {
    const offset: { x: number; y: number } = { x: 0, y: 0 };
    const { paddingX, paddingY, height, width } = this.textBoxStyle;
    const layoutSize = paragraphMetrics.size;

    switch (this.style.verticalAlignment) {
      case 'top':
        offset.y = paddingY;
        break;
      case 'middle':
        offset.y = Math.max(paddingY, (height - layoutSize.height) / 2);
        break;
      case 'bottom':
      default:
        offset.y = Math.max(paddingY, height - layoutSize.height - paddingY);
        break;
    }

    switch (this.style.textAlignment) {
      case 'left':
        offset.x = paddingX;
        break;
      case 'center':
        offset.x =
          (width - layoutSize.width) / 2 -
          2 * this.textBoxStyle.bandPaddingX * paragraphMetrics.fontSize;
        break;
      case 'right':
      default:
        const isItalic = this.style.defaultTextStyle.italic;
        const adjustForRightCrop = paragraphMetrics.fontSize / (isItalic ? 6 : 10);
        const adjustForBand = 4 * this.textBoxStyle.bandPaddingX * paragraphMetrics.fontSize;
        const adjustOffset = adjustForBand === 0 ? adjustForRightCrop : adjustForBand;

        const offsetX = width - layoutSize.width - paddingX;
        const adjustedOffsetX = offsetX - adjustOffset;
        offset.x = Math.max(
          adjustedOffsetX,
          (width - layoutSize.width) / 2 -
            2 * this.textBoxStyle.bandPaddingX * paragraphMetrics.fontSize,
        );
        break;
    }
    return offset;
  }

  calcLineOffsets(paragraphMetrics: ParagraphMetrics): number[] {
    const layoutWidth = paragraphMetrics.size.width;
    return paragraphMetrics.lineMetrics.map((lineMetric, _i) => {
      const lineWidth = lineMetric.width;
      if (this.granularity === 'lines') return 0;
      switch (this.style.textAlignment) {
        case 'left':
          return 0;
        case 'center':
          return (layoutWidth - lineWidth) / 2;
        case 'right':
        default:
          return layoutWidth - lineWidth;
      }
    });
  }

  calcGrainPadding(lineHeight: number, styleTypes?: StyledTextChunk['type'][]): Padding {
    let maxLineHeight = 1;
    styleTypes?.forEach((styleType) => {
      const style =
        styleType === 'default' ? this.style.defaultTextStyle : this.style.highlightTextStyle;
      maxLineHeight = Math.max(maxLineHeight, style.lineHeight);
    });
    const paddingMultiplier = overrideFontPaddingMultipliers.find((fontData) =>
      [this.style.defaultTextStyle.font, this.style.highlightTextStyle.font].includes(
        `/${fontData.font}.ttf`,
      ),
    )?.paddingMultiplier ?? { h: 1.2 / maxLineHeight, v: 0.7 / maxLineHeight };
    return { h: lineHeight * paddingMultiplier.h, v: lineHeight * paddingMultiplier.v };
  }

  buildCharGrains(
    wrappedStyledTextChunks: StyledTextChunks,
    paragraphMetrics: ParagraphMetrics,
    alignOffsets: AlignOffsets,
  ): Grains {
    const grains: Grains = [];
    let currentLineIdx = -1;
    let prevCharX = Number.POSITIVE_INFINITY;
    let foundNewLine = false;
    wrappedStyledTextChunks.forEach((chunk) => {
      if (chunk.text === '\n') {
        if (foundNewLine) {
          currentLineIdx++;
        }
        foundNewLine = true;
      } else {
        foundNewLine = false;
      }
      const chars = Array.from(chunk.text)
        .filter((char) => ![' ', '\n'].includes(char))
        .reduce((acc: string[], curr: string) => {
          if (isSurrogateChar(curr) && acc.length > 0) {
            acc[acc.length - 1] = acc[acc.length - 1] + curr;
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
      chars.forEach((char) => {
        const { x, y, width, height } = paragraphMetrics.grainBounds[grains.length];
        if (x < prevCharX) {
          currentLineIdx++;
        }
        const lineOffset = alignOffsets.lineOffsets[currentLineIdx];
        const padding = this.calcGrainPadding(height, [chunk.type]);
        const grain = {
          richText: [{ text: char, type: chunk.type }],
          size: { width: width, height: height },
          offset: {
            x: (alignOffsets?.offset?.x || 0) + x + lineOffset,
            y: (alignOffsets?.offset?.y || 0) + y,
          },
          padding: padding,
        };
        grains.push(grain);
        prevCharX = x;
      });
    });
    return grains;
  }

  buildWordGrains(
    wrappedStyledTextChunks: StyledTextChunks,
    paragraphMetrics: ParagraphMetrics,
    alignOffsets: AlignOffsets,
  ): Grains {
    const grains: Grains = [];
    let currentWord: StyledTextChunks = [];
    let currentLineIdx = 0;
    let currentWordIdx = 0;
    const pushWord = ({
      x,
      y,
      width,
      height,
    }: {
      x: number;
      y: number;
      width: number;
      height: number;
    }) => {
      const lineOffset = alignOffsets.lineOffsets[currentLineIdx];
      const chunkStyles: StyledTextChunk['type'][] = [];
      currentWord.forEach((chunk) => {
        if (!chunkStyles.includes(chunk.type)) {
          chunkStyles.push(chunk.type);
        }
      });
      grains.push({
        richText: currentWord,
        size: { width: width, height: height },
        offset: {
          x: (alignOffsets?.offset?.x || 0) + x + lineOffset,
          y: (alignOffsets?.offset?.y || 0) + y,
        },
        padding: this.calcGrainPadding(height, chunkStyles),
      });
      currentWord = [];
      currentWordIdx++;
    };

    wrappedStyledTextChunks.forEach((chunk, i) => {
      const grainBound = paragraphMetrics.grainBounds[currentWordIdx];
      if (chunk.text === '\n') {
        if (currentWord.length > 0 && grainBound) {
          pushWord(grainBound);
        }
        currentLineIdx++;
      } else {
        let words = chunk.text.split(' ');
        if (i > 0 && words[0] === '' && currentWord.length > 0) {
          pushWord(grainBound);
          words = words.slice(1, words.length);
        }
        words.forEach((word, i) => {
          const grainBound = paragraphMetrics.grainBounds[currentWordIdx];
          if (word === '' && (i === words.length - 1 || currentWord.length === 0)) {
            return;
          }
          currentWord.push({
            text: word,
            type: chunk.type,
          });
          if (i < words.length - 1) {
            pushWord(grainBound);
          } else if (chunk.text.charAt(chunk.text.length - 1) === ' ') {
            pushWord(grainBound);
          }
        });
      }
    });
    if (currentWord.length > 0) {
      const grainBound = paragraphMetrics.grainBounds[currentWordIdx];
      if (grainBound) {
        pushWord(grainBound);
      }
    }
    return grains;
  }

  buildLineGrains(
    styledTextChunks: StyledTextChunks,
    paragraphMetrics: ParagraphMetrics,
    alignOffsets: AlignOffsets,
  ): Grains {
    const grains: Grains = [];
    const lineHeight =
      paragraphMetrics.size.height /
      (paragraphMetrics.grainBounds.length > 0 ? paragraphMetrics.grainBounds.length : 1);
    let currentLine: StyledTextChunks = [];
    const pushLine = () => {
      const currentLineIdx = grains.length;
      const bounds = paragraphMetrics.grainBounds;
      // Each line is given full width so that grains in line animations are animated with correct values
      const width = paragraphMetrics.size.width;
      const { height } = bounds[Math.min(currentLineIdx, bounds.length - 1)];
      const lineOffset = alignOffsets.lineOffsets?.[currentLineIdx] || 0;
      const chunkStyles: StyledTextChunk['type'][] = [];
      currentLine.forEach((chunk) => {
        if (!chunkStyles.includes(chunk.type)) {
          chunkStyles.push(chunk.type);
        }
      });
      grains.push({
        richText: currentLine,
        size: { width: width, height: height },
        offset: {
          x: (alignOffsets?.offset?.x || 0) + lineOffset,
          y: (alignOffsets?.offset?.y || 0) + lineY,
        },
        padding: this.calcGrainPadding(lineHeight, chunkStyles),
      });
      currentLine = [];
      lineY += height;
    };

    let lineY = 0;
    styledTextChunks.forEach((chunk) => {
      if (chunk.text === '\n') {
        pushLine();
      } else {
        currentLine.push(chunk);
      }
    });
    if (currentLine.length > 0) {
      pushLine();
    }
    return grains;
  }

  buildWholeGrains(
    styledTextChunks: StyledTextChunks,
    paragraphMetrics: ParagraphMetrics,
    alignOffsets: AlignOffsets,
  ): Grains {
    const x = alignOffsets?.offset?.x || 0;
    const y = alignOffsets?.offset?.y || 0;
    const { width, height } = paragraphMetrics.size;
    const linesCount = paragraphMetrics.lineMetrics.length;
    const lineHeight = linesCount === 0 ? 0 : height / linesCount;
    const chunkStyles: StyledTextChunk['type'][] = [];
    styledTextChunks.forEach((chunk) => {
      if (!chunkStyles.includes(chunk.type)) {
        chunkStyles.push(chunk.type);
      }
    });
    const wholeGrain = {
      richText: styledTextChunks,
      offset: { x, y },
      size: {
        width: width,
        height: height,
      },
      padding: this.calcGrainPadding(lineHeight, chunkStyles),
    };
    return [wholeGrain];
  }
}

import { ConfirmationHandler, TrimCompletedHandler } from './types';

interface OverlayRegistry {
  onTrimCompleted: TrimCompletedHandler | null;
  onConfirm: ConfirmationHandler | null;
}

const _registry: OverlayRegistry = {
  onTrimCompleted: null,
  onConfirm: null,
};

export const registerTrimHandlers = (onTrimCompleted: TrimCompletedHandler) => {
  _registry.onTrimCompleted = onTrimCompleted;
};

export const completeTrim = () => {
  if (_registry.onTrimCompleted) _registry.onTrimCompleted('added');
};

export const cancelTrim = () => {
  if (_registry.onTrimCompleted) _registry.onTrimCompleted('cancelled');
};

export const resetTrimHandlers = () => {
  _registry.onTrimCompleted = null;
};

export const registerConfirmHandlers = (onConfirm: ConfirmationHandler) => {
  _registry.onConfirm = onConfirm;
};
export const resetConfirmHandlers = () => {
  _registry.onConfirm = null;
};

export const confirm = (status: 'confirmed' | 'denied') => {
  if (_registry.onConfirm) _registry.onConfirm(status);
};

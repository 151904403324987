import * as Project from '../project';
import * as Ui from '../ui';
import * as Video from '../video';
import * as User from '../user';
import * as Payment from '../payment';

import { Master, Shadow, Slate } from '../jsonTypes';
import * as MasterStore from './masterStore';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UploadPayload } from '../ui';
import { Libraries } from './library';
import { MediaCache } from './mediaCache';
import { TimelineDrag } from './timeline/timelineDrag';
import { TimelineRender } from './timeline/timelineRender';
import { LeftPanelDrag } from './left-panel/leftPanelDrag';
import { LeftPanelRender } from './left-panel/leftPanelRender';

export class Store {
  readonly ui: Ui.Api;
  readonly project: Project.Api;
  readonly video: Video.Api;
  readonly user: User.Api;
  readonly payment: Payment.Api;
  public static readonly libraries = Libraries.shared;
  public static readonly mediaCache = MediaCache.shared;
  public static readonly timelineDrag = TimelineDrag.shared;
  public static readonly timelineRender = TimelineRender.shared;
  public static readonly leftPanelDrag = LeftPanelDrag.shared;
  public static readonly leftPanelRender = LeftPanelRender.shared;
  // To be overriden by retro
  public static isDevelopment = /localhost:/.test((window as any).location.href);

  observables = {
    activeTimeBounds$: MasterStore.store$
      .pipe(
        map((st) => [st.video.previewType, st.project.selectedBlockIndex]),
        distinctUntilChanged((x, y) => x[0] === y[0] && x[1] === y[1]),
      )
      .pipe(
        map(() => Store.shared.activeTimeBounds),
        distinctUntilChanged((x, y) => x.tStart === y.tStart && x.tEnd === y.tEnd),
      ),
    tSeek$: Video.TimeRegistry.tSlate$.pipe(
      filter(() => Store.video.mode !== 'playing' || Store.video.isSeeking),
    ),
  };

  get asDebugView() {
    return {
      ui: this.ui.asDebugView,
      video: this.video.asDebugView,
      project: this.project.asDebugView,
      user: this.user.asDebugView,
      payment: this.payment.asDebugView,
      activeTimeBounds: this.activeTimeBounds,
      observables: this.observables,
    };
  }

  private static instance?: Store;
  private constructor() {
    this.ui = new Ui.Api(
      () => MasterStore.globalStore.getState().ui,
      MasterStore.globalStore.dispatch,
    );
    this.project = new Project.Api(
      () => MasterStore.globalStore.getState().project,
      MasterStore.globalStore.dispatch,
    );
    this.video = new Video.Api(
      () => MasterStore.globalStore.getState().video,
      MasterStore.globalStore.dispatch,
    );
    this.user = new User.Api(
      () => MasterStore.globalStore.getState().user,
      MasterStore.globalStore.dispatch,
    );
    this.payment = new Payment.Api(
      () => MasterStore.globalStore.getState().payment,
      MasterStore.globalStore.dispatch,
    );
  }

  public static get shared(): Store {
    if (!Store.instance) {
      Store.instance = new Store();
    }
    return Store.instance;
  }

  public static get video() {
    return Store.shared.video;
  }

  public static get project() {
    return Store.shared.project;
  }

  public static get ui() {
    return Store.shared.ui;
  }

  public static get user() {
    return Store.shared.user;
  }

  public static get payment() {
    return Store.shared.payment;
  }

  static observables = Store.shared.observables;

  // Seeks to the selected block without triggering seek analytics
  public autoSeekToSelectedBlock({ toMiddle }: { toMiddle: boolean } = { toMiddle: true }) {
    const slateBlock = this.project.getSlateBlock(this.project.selectedBlockId)!;
    this.video.forceSeek(
      toMiddle ? (slateBlock.tStart + slateBlock.tEnd) / 2 : slateBlock.tSceneStart,
    );
  }

  public seekToSelectedBlock(
    seekAction: Video.UserActionSourceForSeekTimeAnalytics,
    { toMiddle }: { toMiddle: boolean } = { toMiddle: true },
  ) {
    const slateBlock = this.project.getSlateBlock(this.project.selectedBlockId)!;
    this.video.scrubTo(
      toMiddle ? (slateBlock.tStart + slateBlock.tEnd) / 2 : slateBlock.tSceneStart,
      seekAction,
    );
  }

  public updateSelectedBlockByTime() {
    const time = this.video.t;
    const blocks = this.project.slateBlocks;
    const slateBlock = this.project.getSlateBlock(this.project.selectedBlockId)!;
    const outOfSelectedBlock = this.video.isPreview
      ? time < slateBlock.tSceneStart || time > slateBlock.tSceneEnd
      : time < slateBlock.tStart || time > slateBlock.tEnd;
    if (!outOfSelectedBlock) return;

    const blockIndex = blocks.findIndex((b) => time <= b.tSceneEnd);
    this.project.selectBlockIndex(blockIndex >= 0 ? blockIndex : blocks.length - 1);
  }

  //
  //// Public API
  //
  // These are the time bounds for the slate playback
  get activeSlateTimeBounds(): Project.TimeBounds {
    const previewType = this.video.previewType;

    if (
      previewType === Video.PreviewType.STORYBOARD_SCENE_PREVIEW ||
      previewType === Video.PreviewType.BASIC_TIMELINE_SCENE_PREVIEW ||
      this.video.isAdvanced
    ) {
      return this.currentTimeBounds;
    }
    return this.project.timeBounds;
  }
  EPSILON = 0.0015; // already applied in tSceneEnd by masterCleaner

  get currentTimeBounds(): Project.TimeBounds {
    const block = this.currentSlateBlock;

    return this.video.isAdvanced
      ? {
          tStart: block.tStart,
          tEnd: block.tEnd,
        }
      : {
          tStart: block.tSceneStart,
          tEnd: block.tSceneEnd,
        };
  }

  // These are the time bounds for the timeline
  get activeTimeBounds(): Project.TimeBounds {
    const previewType = this.video.previewType;
    if (
      previewType === Video.PreviewType.ADVANCED_TIMELINE_PREVIEW_FROM_SEEKER ||
      previewType === Video.PreviewType.ADVANCED_TIMELINE_SCENE_PREVIEW
    ) {
      return this.project.absoluteBlockTimeBounds;
    }
    return this.project.timeBounds;
  }

  get currentSlateBlock(): Slate.BlockJSON {
    const t = this.video.t;
    const blocks = this.project.slateBlocks;
    if ((!Store.video.isPlaying || Store.video.isLoading) && Store.video.isBasic) {
      // ignore selected block when paused
      blocks.find((block) => t <= block.tSceneEnd) ?? this.project.slateBlocks[blocks.length - 1];
    }
    const selectedSlateBlock = this.project.getSlateBlock(this.project.selectedBlockId);
    return selectedSlateBlock ?? blocks[blocks.length - 1];
  }

  //
  //// Deprecated API
  //

  /**
   * @deprecated use project.masterJson instead.
   */
  get masterJSON(): Master.JSON {
    return this.project.masterJson;
  }

  /**
   * @deprecated use project.masterJson instead.
   */
  set masterJSON(json: Master.JSON | null) {
    this.project.masterJson = json;
  }

  /**
   * @deprecated use project.dimensionJson instead.
   */
  get masterDimensionJSON(): Master.DimensionJSON {
    return this.project.dimensionJson;
  }

  /**
   * @deprecated use project.dimensionJson instead.
   */
  set masterDimensionJSON(json: Master.DimensionJSON | null) {
    this.project.dimensionJson = json;
  }

  /**
   * @deprecated use project.shadowJson instead.
   */
  get shadowJSON(): Shadow.JSON | null {
    return this.project.shadowJson;
  }

  /**
   * @deprecated use project.shadowDimensionJson instead.
   */
  get shadowDimensionJSON(): Shadow.DimensionJSON | null {
    return this.project.shadowDimensionJson;
  }

  /**
   * @deprecated use project.markShadowAsMutated instead.
   */
  markShadowAsMutated = () => {
    this.project.markShadowAsMutated();
  };

  /**
   * @deprecated use video.t insted
   */
  get t() {
    return this.video.t;
  }

  /**
   * @deprecated use video.t insted
   */
  set t(time: number) {
    this.video.t = time;
  }

  /**
   * @deprecated use project.selectBlockIndex instead
   */
  set selectedBlockIndex(blockIndex: number) {
    this.project.selectBlockIndex(blockIndex);
  }

  /**
   * @deprecated use project.selectedBlockIndex instead
   */
  get selectedBlockIndex(): number {
    return this.project.selectedBlockIndex;
  }

  /**
   * @deprecated use project.selectBlock instead
   */
  set selectedBlockId(blockID: string) {
    this.project.selectBlock(blockID);
  }

  /**
   * @deprecated use project.selectedBlockId instead
   */
  get selectedBlockId(): string {
    return this.project.selectedBlockId;
  }

  /**
   * @deprecated use project.selectedComponentId instead
   */
  get selectedComponentId(): string {
    return this.project.selectedComponentId;
  }

  /**
   * @deprecated use project.selectComponent instead
   */
  set selectedComponentId(componentId: string) {
    this.project.selectComponent(componentId);
  }

  /**
   * @deprecated use video.slateType
   */
  set selectedVideoType(slateType: Video.SlateType) {
    this.video.slateType = slateType;
  }

  /**
   * @deprecated use video.slateType
   */
  get selectedVideoType() {
    return this.video.slateType;
  }

  /**
   * @deprecated use ui.gridlines.isVisible
   */
  get showGridlines() {
    return this.ui.gridlines.isVisible;
  }

  /**
   * @deprecated use ui.toggleGridlines
   */
  uiToggleGridlines() {
    this.ui.toggleGridlines();
  }

  /**
   * @deprecated use ui.voiceOverFilesForUpload
   */
  get filesForUpload() {
    return this.ui.filesForUploads;
  }

  /**
   * @deprecated use ui.keyboardContext
   */
  get keyboardContext() {
    return this.ui.keyboardContext;
  }

  /**
   * @deprecated use ui.keyboardContext
   */
  set keyboardContext(context: Ui.KeyboardContext) {
    this.ui.keyboardContext = context;
  }

  /**
   * @deprecated use ui.transitionTray
   */
  get transitionTray() {
    return this.ui.transitionsTray;
  }

  /**
   * @deprecated use activeTimeBounds (or project.timeBounds or project.blockTimeBounds)
   */
  get playbackBounds() {
    return this.activeTimeBounds;
  }

  /**
   * @deprecated use video.mode
   */
  get videoMode() {
    return this.video.mode;
  }

  /**
   * @deprecated use video.mode
   */
  set videoMode(mode: Video.Mode) {
    this.video.mode = mode;
  }

  /**
   * @deprecated use ui.recordingCountdown.isVisible
   */
  get showRecordingCountdown() {
    return this.ui.recordingCountdown.isVisible;
  }

  /**
   * @deprecated use video.isEditor
   */
  get isEditor() {
    return this.video.isEditor;
  }

  /**
   * @deprecated use project.duration
   */
  get duration() {
    return this.project.duration;
  }

  /**
   * @deprecated use video.isPlaying
   */
  get isPlaying() {
    return this.video.isPlaying;
  }

  /**
   * @deprecated use video.isPaused
   */
  get isPaused() {
    return this.video.isPaused;
  }

  /**
   * @deprecated use video.isPreview
   */
  get isPreview() {
    return this.video.isPreview;
  }

  /**
   * @deprecated use video
   */
  get videoState() {
    return this.video;
  }

  /**
   * @deprecated use video.tSlate
   */
  get tSlate() {
    return this.video.tSlate;
  }

  /**
   * @deprecated use video.t
   */
  get tSeeker() {
    return this.video.t;
  }

  /**
   * @deprecated use video.t
   */
  set tSeeker(timeInSeconds: number) {
    this.video.t = timeInSeconds;
  }

  /**
   * @deprecated use project.setComponent
   */
  setMasterComponent(component: Master.ComponentJSON) {
    this.project.setComponent(component);
  }

  /**
   * @deprecated use project.publishShadow
   */
  publishShadow(): void {
    this.project.publishShadow();
  }

  /**
   * @deprecated use project.getShadowComponentById
   */
  getShadowComponentByID(id: string) {
    this.project.getShadowComponentById(id);
  }

  /**
   * @deprecated use project.markShadowAsMutated
   */
  markShadowJSONAsMutated() {
    this.project.markShadowAsMutated();
  }

  /**
   * @deprecated use project.slateState
   */
  get slateState() {
    return this.project.slateState;
  }

  /**
   * @deprecated use project.slateErrors
   */
  get slateErrors() {
    return this.project.slateErrors;
  }

  /**
   * @deprecated use project.getSlateRoot
   */
  getSlateRoot() {
    return this.project.slateRoot;
  }

  /**
   * @deprecated use project.getSlateComposite
   */
  getSlateComposite(id: string): Slate.CompositeJSON | null {
    return this.project.getSlateComposite(id);
  }

  /**
   * @deprecated use project.getSlateBlock
   */
  getSlateBlock(id: string): Slate.BlockJSON | null {
    return this.project.getSlateBlock(id);
  }

  /**
   * @deprecated use project.getSlateAudio
   */
  getSlateAudio(id: string): Slate.AudioJSON | null {
    return this.project.getSlateAudio(id);
  }

  /**
   * @deprecated use project.getSlateByType
   */
  getSlateByType<EntityType extends Project.SlateEntities['type']>(type: EntityType, id: string) {
    return this.project.getSlateByType(type, id);
  }

  /**
   * @deprecated use project.getSlateById
   */
  getSlateById(id: string) {
    return this.project.getSlateById(id);
  }

  /**
   * @deprecated use video.play
   */
  play() {
    this.video.play();
  }

  /**
   * @deprecated use video.pause
   */
  pause() {
    this.video.pause();
  }

  /**
   * @deprecated use video.t
   */
  seekTo(time: number) {
    this.video.t = time;
  }

  /**
   * @deprecated use video.tSlate
   */
  seekSlateTo(time: number) {
    this.video.tSlate = time;
  }

  /**
   * @deprecated use video.scrubTo
   */
  seekTime(time: number, seekAction: Video.UserActionSourceForSeekTimeAnalytics) {
    this.video.scrubTo(time, seekAction);
  }

  /**
   * @deprecated use ui.startDrag
   */
  startDrag({ media }: { media: Ui.DraggedMedia }) {
    this.ui.startDrag(media);
  }

  /**
   * @deprecated use ui.cancelDrag
   */
  stopDrag() {
    this.ui.cancelDrag();
  }

  /**
   * @deprecated use ui.completeDrag
   */
  dropElement({ media }: { media: Ui.DraggedMedia }) {
    this.ui.completeDrag(media);
  }

  /**
   * @deprecated use video.togglePlayPause
   */
  togglePlayPause(previewType: Video.PreviewType, actionSource: Video.UserActionSource) {
    this.video.togglePlayPause(previewType, actionSource);
  }

  /**
   * @deprecated use video.mode
   */
  setPreviewMode(mode: Video.Mode) {
    this.video.mode = mode;
  }

  /**
   * @deprecated use this.activeTimeBounds, this no longer needs to be set
   */
  updatePreviewTime(_props: any) {}

  /**
   * @deprecated use project.masterJson = null
   */
  reset() {
    this.project.masterJson = null;
  }

  /**
   * @deprecated use project.selectProperty
   */
  setPropertySelected(selectableProperty: Project.SelectableProperty) {
    this.project.selectProperty(selectableProperty);
  }

  /**
   * @deprecated use project.selectedBlock
   */
  getSelectedBlockJSON(): Master.BlockJSON {
    return this.project.selectedBlock;
  }

  /**
   * @deprecated use video.t
   */
  getSeekTime() {
    return this.video.t;
  }

  /**
   * @deprecated use video.tSlate
   */
  getSlateTime() {
    return this.video.tSlate;
  }

  /**
   * @deprecated use ui.toggleTransitionsTray
   */
  uiToggleTransitionsTray() {
    this.ui.toggleTransitionsTray();
  }

  /**
   * @deprecated use ui.setTransitionIndex
   */
  uiUpdateTransitionIndex(index: number) {
    this.ui.setTransitionIndex(index);
  }

  /**
   * @deprecated use ui.setTransitions
   */
  updateTransitions(transitions: any) {
    this.ui.setTransitions(transitions);
  }

  /**
   * @deprecated use ui.setTransitionData
   */
  uiUpdateTransitionData(data: any) {
    this.ui.setTransitionData(data);
  }

  /**
   * @deprecated use ui.setTransitionNames
   */
  uiUpdateTransitionNames(names: any) {
    this.ui.setTransitionNames(names);
  }

  /**
   * @deprecated use ui.setDefaultTransiton
   */
  uiUpdateDefaultTransiton(data: any) {
    this.ui.setDefaultTransition(data);
  }

  /**
   * @deprecated use ui.transitionsTray.currentTransition.transitions
   */
  getTransitions() {
    return this.ui.transitionsTray.currentTransition.transitions;
  }

  /**
   * @deprecated use ui.transitionsTray.currentTransition.transitionNames;
   */
  getTransitionNames() {
    return this.ui.transitionsTray.currentTransition.transitionNames;
  }

  /**
   * @deprecated use ui.transitionsTray.currentTransition.transitionData;
   */
  getTransitionData() {
    return this.ui.transitionsTray.currentTransition.transitionData;
  }

  /**
   * @deprecated use ui.transitionsTray.currentTransition.defaultTransition;
   */
  getDefaultTransition() {
    return this.ui.transitionsTray.currentTransition.defaultTransition;
  }

  /**
   * @deprecated use ui.AddTransition
   */
  uiAddTransition(props: {
    transitionId: number;
    transitionIndex: number | null;
    addToAll: boolean;
  }) {
    this.ui.setAddTransition(props.transitionId, props.transitionIndex, props.addToAll);
  }

  /**
   * @deprecated use ui.setRemoveTransition
   */
  uiRemoveTransition(index: number) {
    this.ui.setRemoveTransition(index);
  }

  /**
   * @deprecated use ui.resetAddTransition
   */
  uiResetAddTransition() {
    this.ui.resetAddTransition();
  }

  /**
   * @deprecated use ui.resetRemoveTransition
   */
  uiResetRemoveTransition() {
    this.ui.resetRemoveTransition();
  }

  /**
   * @deprecated use ui.resetSelectedTransitionIndex();
   */
  uiResetSelectedTransitionIndex() {
    this.ui.resetSelectedTransitionIndex();
  }

  /**
   * @deprecated use ui.voiceOverFilesForUpload
   */
  updateFilesForUpload(files: UploadPayload) {
    this.ui.filesForUploads = files;
  }

  /**
   * @deprecated use ui.toggleRecordingCountdown
   */
  uiToggleRecordingCountdown() {
    this.ui.toggleRecoredingCountdown();
  }

  /**
   * @deprecated use project.selectedProperty
   */
  get selectedProperty(): Project.SelectableProperty {
    return this.project.selectedProperty;
  }

  /**
   * @deprecated use project.masterJson
   */
  getMasterJSON(): Master.JSON | null {
    return this.project.masterJson;
  }

  /**
   * @deprecated use project.masterJson
   */
  setMasterJSON(json: Master.JSON | null): void {
    this.project.masterJson = json;
  }

  /**
   * @deprecated use project.shadowJson
   */
  getMutableShadowJSON() {
    return this.project.shadowJson;
  }
}

export { MasterStore };

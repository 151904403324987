import { Store } from '../index';
import { ProjectAutoSaveStatus } from '../ui/types';
import { isPresent } from '../utils';
import { head, syncLayers } from '../project/utils/layerManager';
import { Slate } from '../jsonTypes';
import { getCompositiesOfLayer, isGapAvailableInLayer, createVideoLayer } from './layer-utils';

const publishShadow = (selectedBlockIndex?: number) => {
  const json = Store.project.shadowJson;
  if (selectedBlockIndex !== undefined) {
    Store.project.setMasterJson(json, selectedBlockIndex);
  } else {
    Store.project.masterJson = json;
  }
  Store.ui.updateProjectAutoSaveStatus(ProjectAutoSaveStatus.PENDING);
};

const isEmptyLayer = (layerId: string, sceneId: string) => {
  const composities = getCompositiesOfLayer(layerId, sceneId)?.filter(
    (c: Slate.CompositeJSON) => c.visible,
  );

  return !composities?.length;
};

const getLayerAbove = (layerId: string, sceneId: string) => {
  const layers = Store.project
    .getSlateLayers()
    .map((l) => Store.project.getSlateLayer(l.id))
    .filter(isPresent)
    .filter((l) => l.layerType === 'video' && l.visible)
    .filter((l, idx) => {
      if (idx === 0) return true;
      return !isEmptyLayer(l.id, sceneId);
    })
    .sort((a, b) => a.index - b.index);
  const layer = Store.project.getSlateLayer(layerId);
  if (!layer) return null;
  const layerAbove = layers.find((l) => l.index > layer.index);
  if (!layerAbove) return null;
  return layerAbove;
};

const getLayerBelow = (layerId: string, sceneId: string) => {
  const layers = Store.project
    .getSlateLayers()
    .map((l) => Store.project.getSlateLayer(l.id))
    .filter(isPresent)
    .filter((l) => l.layerType === 'video' && l.visible)
    .filter((l, idx) => {
      if (idx === 0) return true;
      return !isEmptyLayer(l.id, sceneId);
    })
    .sort((a, b) => b.index - a.index);
  const layer = Store.project.getSlateLayer(layerId);
  if (!layer) return null;
  const layerBelow = layers.find((l) => l.index < layer.index);
  if (!layerBelow) return null;

  return layerBelow;
};

export const isTopmostLayerClip = (clipId: string) => {
  const clip = Store.project.getSlateComposite(clipId);
  if (!clip || !clip.layerId || !clip.blockId) return false;
  const sceneId = clip.blockId;
  const layerId = clip.layerId;
  const layerAbove = getLayerAbove(layerId, sceneId);
  const layers = Store.project.getSlateLayers();

  const topmostLayer = head(
    [...layers]
      .map((l) => Store.project.getSlateLayer(l.id))
      .filter(isPresent)
      .filter((l) => l.layerType === 'video' && l.visible)
      .filter((l, idx) => {
        if (idx === 0) return true;
        return !isEmptyLayer(l.id, sceneId);
      })
      .sort((a, b) => b.index - a.index),
  );

  if (topmostLayer && topmostLayer.id === layerAbove?.id) return true;

  return false;
};

export const isLowermostLayerClip = (clipId: string) => {
  const clip = Store.project.getSlateComposite(clipId);
  if (!clip || !clip.layerId || !clip.blockId) return false;
  const sceneId = clip.blockId;
  const layerId = clip.layerId;
  const layers = Store.project.getSlateLayers();

  const lowermostLayer = head(
    [...layers]
      .map((l) => Store.project.getSlateLayer(l.id))
      .filter(isPresent)
      .filter((l) => l.layerType === 'video' && l.visible)
      .filter((l, idx) => {
        if (idx === 0) return true;
        return !isEmptyLayer(l.id, sceneId);
      })
      .sort((a, b) => a.index - b.index),
  );

  if (lowermostLayer && lowermostLayer.id === layerId) return true;

  return false;
};

export const moveClipFront = (clipId: string) => {
  const clip = Store.project.getSlateComposite(clipId);

  if (!clip || !clip.visible || !clip.layerId || !clip.blockId) return;

  if (isTopmostLayerClip(clipId)) return;

  const sceneId = clip.blockId;
  const layerAbove = getLayerAbove(clip.layerId, sceneId);
  const clipTimeRange = { start: clip.tStart, end: clip.tEnd };

  if (layerAbove) {
    if (isGapAvailableInLayer(layerAbove.id, sceneId, clipTimeRange)) {
      clip.layerId = layerAbove.id;
    } else {
      const layer = getLayerAbove(layerAbove.id, sceneId);
      const newLayer = createVideoLayer(layer?.id, layerAbove.id);
      if (!newLayer) return;
      clip.layerId = newLayer.id;
    }
  }
  Store.project.patchToNearestFrame(Store.project.fps);
  Store.project.publishTimeMutations();
  syncLayers(Store.project.shadowDimensionJson, Store.ui.timelineInteractionVariant);
  publishShadow();
  Store.ui.requestUndoRedoSnapshot();
};

export const moveClipBack = (clipId: string) => {
  const clip = Store.project.getSlateComposite(clipId);

  if (!clip || !clip.visible || !clip.layerId || !clip.blockId) return;

  if (isLowermostLayerClip(clipId)) return;

  const sceneId = clip.blockId;
  const layerBelow = getLayerBelow(clip.layerId, sceneId);
  const clipTimeRange = { start: clip.tStart, end: clip.tEnd };

  if (layerBelow) {
    if (isGapAvailableInLayer(layerBelow.id, sceneId, clipTimeRange)) {
      clip.layerId = layerBelow.id;
    } else {
      const layer = getLayerBelow(layerBelow.id, sceneId);
      const newLayer = createVideoLayer(layerBelow.id, layer?.id);
      if (!newLayer) return;
      clip.layerId = newLayer.id;
    }
  }

  Store.project.patchToNearestFrame(Store.project.fps);
  Store.project.publishTimeMutations();
  syncLayers(Store.project.shadowDimensionJson, Store.ui.timelineInteractionVariant);
  publishShadow();
  Store.ui.requestUndoRedoSnapshot();
};

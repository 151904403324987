import { Master } from '../jsonTypes';
import { DefaultTransitionConfig } from './types';

export const TEMPLATE_OVERLAY_TRANSITION_ID = 999;
export const TEMPLATE_ANIMATION_TRANSITION_ID = 998;
export const TEMPLATE_MASK_TRANSITION_ID = 997;
export const TIMELINE_DROP_PLACEHOLDER_DURATION = 5;
export const TIMELINE_DROP_PLACEHOLDER_ID = 'TIMELINE_DROP_PLACEHOLDER';
export const COMPOSITE_JSON: Partial<Master.CompositeJSON> = {
  name: 'background_media_1',
  type: 'composite',
  components: [
    {
      name: 'background_media',
      type: 'image',
      components: [],
      layout: {
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: 'None',
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        resize_logic: 'max_size',
        fit_type: 'fit_with_blur',
      },
      // TODO:- Check if this is needed, add to MasterJSON type if so
      // metadata: {
      //   premium: false,
      // },
      duration: {
        minimum_duration: 5,
        split_self: 1,
        split_others: 0,
        delay: 0,
        priority: 1,
        absolute_duration: 8.000000000000002,
        element_duration: 5,

        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 8.000000000000002,
      },
      animation: {
        animation_in: 'None',
        animation_out: 'None',
        animation: 'None',
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'media_input',
        key: 'url',
        value: 'default_image.png',
        placeholder: 'None',
        allow_multiple: 1,
        required: 1,
        hidden: 1,
      },
      id: '',
      url: '',
      word: '',
      // TODO:_ check if needed and json type is to be changed
      // sticker_id: '',
      // entity_type: '',
      sub_type: 'bg_image',
      orientation: '',
      media_properties: {
        url: '',
        possible_animations: ['pan_to_up', 'pan_to_bottom'],
      },
      screen_edit: 1,
      screen_click: 0,
    },
  ],
  layout: {
    fit_type: 'self_position',
    overflow: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
      opacity_ref: {
        user_flag: 0,
        reference: 1,
      },
    },
  },
  duration: {
    split_self: 1,
    split_others: 0,
    priority: 3,
    delay: 0,
    absolute_duration: 8.000000000000002,

    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 8.000000000000002,
  },
  animation: {
    animation_in: 'None',
    animation_out: 'None',
    animation: 'None',
  },
  // TODO:_ check if needed and json type is to be changed
  // audio: {},
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  input: {
    type: 'mandatory',
    allow_multiple: 1,
    placeholder: 'Image Here',
    hidden: 1,
    required: 1,
  },
  id: '',
  sub_type: 'bg_media_composite',
  sr_no: 0,
  // TODO:_ check if needed and json type is to be changed
  // screen_edit: 1,
  // screen_click: 1,
};

export const DUMMY_TEXT_COMPOSITE_JSON: Master.CompositeJSON = {
  name: 'Add a headline',
  layer_visible: false,
  type: 'composite',
  components: [
    {
      name: 'text',
      type: 'text',
      components: [],
      layout: {
        xpad: 0.05,
        ypad: 0.05,
        max_font_factor: 0.05,
        main_font: {
          background_color: ['5', '21', '39', 255],
          foreground_color: ['255', '255', '255', 255],
          font_style: '/Muli-Bold.ttf',
          colors_ref: {
            background_color: {
              user_flag: 0,
              reference: 'tertiary',
            },
            foreground_color: {
              user_flag: 0,
              reference: 'primary',
            },
            band_opacity: {
              user_flag: 0,
              reference: 0,
            },
          },
        },
        highlight_font: {
          background_color: ['5', '21', '39', 255],
          foreground_color: ['248', '228', '120', 255],
          font_style: '/Muli-Bold.ttf',
          highlight_type: 'word',
          colors_ref: {
            background_color: {
              user_flag: 0,
              reference: 'tertiary',
            },
            foreground_color: {
              user_flag: 0,
              reference: 'secondary',
            },
            band_opacity: {
              user_flag: 0,
              reference: 0,
            },
          },
        },
        horizontal_text_alignment: 'center',
        vertical_text_alignment: 'center',
        band_opacity: 0,
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: 'None',
          color_overlay: 'None',
          drop_shadow: {
            enabled: 'true',
            distance: 0,
            name: 'None',
            blur: 0,
            angle: 0,
            opacity: 0,
            drop_shadow_color: [0, 0, 0, 255],
            colors_ref: {
              drop_shadow_color: {
                user_flag: 0,
                reference: [0, 0, 0, 255],
              },
            },
          },
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        fit_type: 'self_position',
        resize_logic: 'max_size',
        font_spec: [],
        lineHeight: '1.4',
        text_transform: 'default',
        font_size: 93,
        fixed: false,
      },
      duration: {
        seconds_per_character: 0.06,
        minimum_duration: 5,
        delay: 0,
        split_self: 1,
        split_others: 0,
        priority: 2,
        absolute_duration: 5,
        element_duration: 5,

        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 5,
      },
      animation: {
        animation_in: 'None',
        animation: 'None',
        animation_out: 'None',
        animation_in_parameters: {
          type: 'whole',
        },
        animation_out_parameters: {
          type: 'whole',
        },
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'text_input',
        key: 'text',
        value: '',
        allow_multiple: 1,
        required: 1,
        placeholder: 'Enter your text here',
        hidden: 0,
        component_config:
          '{"possible_types": "text", "layout": {"xpad": 0.05, "ypad": 0.05, "max_font_factor": 0.05, "main_font": {"foreground_color": "primary", "font_style": "primary"}, "highlight_font": {"foreground_color": "secondary", "font_style": "primary"}, "horizontal_text_alignment": "center", "layer_properties": {"drop_shadow": "None"}}, "duration": {"delay": 0}, "animation": {"animation_in": "None"}, "text": "Text placeholder"}',
      },
      id: '.339d6a67-674e-455f-9349-3b75fe448bd3.032daadd-8021-e3be-77ae-6e5171cc4b01.e13bdd4f-5b2e-a086-bf53-795ba8f09e7f',
      text: 'Text placeholder',
      highlighted_text: 'Text placeholder',
      highlighted_strings: [],
      sub_type: 'text',
      metrics: {
        size: {
          width: 716,
          height: 127,
        },
        grains: [
          {
            richText: [
              {
                text: 'Text placeholder',
                type: 'default',
              },
            ],
            offset: {
              x: 26,
              y: 44.5,
            },
            size: {
              width: 716,
              height: 127,
            },
            padding: {
              h: 108.85714285714286,
              v: 63.5,
            },
          },
        ],
        style: {
          font_size: 91.14,
        },
      },
    },
  ],
  layout: {
    fit_type: 'self_position',
    overflow: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: {
        enabled: 'true',
        distance: 0,
        angle: 0,
        blur: 0,
        name: 'None',
        opacity: 0,
        drop_shadow_color: [0, 0, 0, 255],
        colors_ref: {
          drop_shadow_color: {
            user_flag: 0,
            reference: [0, 0, 0, 255],
          },
        },
      },
      opacity_ref: {
        user_flag: 0,
        reference: 1,
      },
    },
  },
  duration: {
    split_self: 0,
    split_others: 0,
    priority: 20,
    delay: 0.5,
    absolute_duration: 5,
    fixed_start_time: 0,
    fixed_end_time: 1,

    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 5,
  },
  animation: {
    animation_in: 'fadeIn',
    animation_out: 'None',
    animation: 'None',
    animation_in_parameters: {
      effect_time: 0.2,
    },
  },
  position: {
    top_x: 0.3,
    bottom_x: 0.7,
    top_y: 0.4,
    bottom_y: 0.6,
    pivot_x: 0.5,
    pivot_y: 0,
    orientation: 'horizontal',
  },
  input: {
    type: 'mandatory',
    allow_multiple: 0,
    placeholder: 'Blah',
    hidden: 0,
    required: 1,
  },
  id: '.339d6a67-674e-455f-9349-3b75fe448bd3.032daadd-8021-e3be-77ae-6e5171cc4b01',
  sub_type: 'text_composite',
  timeline_config: {
    mode: 'dumb',
  },
  layer_id: '.38744082-187e-4a09-17bd-80472e0ea223',
  compositeType: Master.CompositeType.TEXT,
  is_visible: true,
  is_lock: false,
  layer_name: 'Text placeholder',
};

export const STICKER_COMPOSITE_JSON: Master.CompositeJSON = {
  id: '.21bc05fb-ded8-47c3-97c7-3842c46b5d68.49fef117-7f8d-469b-b8e1-aa8ded40f79d',
  duration: {
    absolute_duration: 9.18,
    delay: 0,
    end_time: 1,
    absolute_end: 9.18,
    priority: 25,

    absolute_start: 0,
    split_self: 0,
    start_time: 0,
    split_others: 0,
  },
  name: 'sticker_video',
  input: {
    type: 'mandatory',
    required: 1,
    hidden: 1,
    allow_multiple: 0,
    placeholder: 'Video Here',
  },
  layout: {
    layer_properties: {
      color_overlay: 'None',
      drop_shadow: 'None',
      color_correction: 'None',
      blend_effect: 'None',
      opacity: 1,
    },
    fit_type: 'self_position',
    overflow: 'hidden',
  },
  animation: {
    animation: 'None',
    animation_in: 'fadeIn',
    animation_out: 'None',
    animation_in_parameters: {
      effect_time: 0.10893246187363835,
    },
  },
  position: {
    bottom_y: 0.64,
    bottom_x: 0.6,
    pivot_y: 0.5,
    top_x: 0.39,
    top_y: 0.32,
    pivot_x: 0.5,
  },
  // screen_edit: 1,
  components: [
    {
      id: '.21bc05fb-ded8-47c3-97c7-3842c46b5d68.49fef117-7f8d-469b-b8e1-aa8ded40f79d.0972f170-6864-4800-a836-6d3c36a2a28a',
      audio: {},
      duration: {
        absolute_end: 9.18,
        delay: 0,
        start_time: 0,
        element_duration: 2,
        precalculated_duration: 2,
        end_time: 1,
        split_others: 0,
        priority: 3,
        absolute_start: 0,
        split_self: 0,

        absolute_duration: 9.18,
      },
      name: 'gif_video',
      sub_type: 'gif_video',
      thumbnail_url:
        'http://invideo-images.s3-website.ap-south-1.amazonaws.com/200x200/sticker-thumbnails/82_Face_With_Hand_Over_Mouth.png',
      input: {
        required: 1,
        type: 'gif_media_input',
        placeholder: 'None',
        allow_multiple: 1,
        key: 'url',
        hidden: 1,
        value: 'default_image.png',
      },
      orientation: 'square',
      layout: {
        blur: 0,
        loop: 1,
        layer_properties: {
          color_overlay: 'None',
          drop_shadow: 'None',
          color_correction: 'None',
          blend_effect: 'None',
          opacity: 1,
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        fit_type: 'fit_with_transparent',
        playback_rate: 1,
        mute: 1,
      },
      animation: {
        animation: 'None',
        animation_in: 'fadeIn',
        animation_out: 'None',
        animation_in_parameters: {
          effect_time: 0.10893246187363835,
        },
      },
      position: {
        bottom_y: 1,
        bottom_x: 1,
        pivot_y: 0.5,
        top_x: 0,
        top_y: 0,
        pivot_x: 0.5,
      },
      screen_edit: 1,
      url: 'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/ICONS/82_Face_With_Hand_Over_Mouth.webm',
      components: [],
      type: 'video',
      screen_click: 0,
      word: '',
    },
  ],
  type: 'composite',
  // screen_click: 1,
  sub_type: 'video_composite',
  compositeType: Master.CompositeType.STICKER_VIDEO,
  layer_id: '',
  layer_name: '',
  layer_visible: true,
};

export const SHAPE_VIDEO_COMPOSITE_JSON = {
  name: 'shape_composite',
  type: 'composite',
  components: [
    {
      name: 'gif_video',
      type: 'video',
      components: [],
      layout: {
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: {
            type: 'duotone_2',
            color_1: [247, 218, 117, 255],
            color_2: [0, 0, 0, 255],
            colors_ref: {
              color_1: {
                user_flag: 0,
                reference: 'secondary',
              },
              color_2: {
                user_flag: 0,
                reference: 'tertiary',
              },
              opacity: {
                user_flag: 0,
                reference: 1,
              },
            },
          },
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
          filter: 'None',
        },
        playback_type: 'speed_factor',
        blur: 0,
        playback_rate: 1,
        fit_type: 'fit_with_transparent',
        loop: 0,
        mute: 0,
      },
      duration: {
        split_self: 0,
        split_others: 0,
        priority: 5,
        delay: 0,
        precalculated_duration: 2,
        element_duration: 2,
        absolute_duration: 6.5,

        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 6.5,
      },
      animation: {
        animation_in: 'None',
        animation: 'None',
        animation_out: 'None',
        animation_in_parameters: {
          type: 'whole',
        },
        animation_out_parameters: {
          type: 'whole',
        },
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'mandatory',
        allow_multiple: 0,
        placeholder: 'Blah',
        hidden: 1,
        required: 0,
      },
      id: '.3ec80f51-84e9-43b9-8f52-b131e619a314.4137dc6d-b563-48b6-84d4-606b871dc95a.caf3b4ab-e8fd-4287-9cad-c034161aa8a0',
      url: 'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/circleSD11560245086833.webm',
      thumbnail_url:
        'https://s3.ap-south-1.amazonaws.com/invideo-images/sticker-thumbnails/final_compressed_videocircleSD11560245086833.png',
      metadata: {
        premium: false,
      },
      sub_type: 'overlay_asset',
      orientation: '',
      word: '',
      entity_type: '',
      screen_edit: 1,
      screen_click: 0,
    },
  ],
  layout: {
    fit_type: 'self_position',
    overflow: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
      opacity_ref: {
        user_flag: 0,
        reference: 1,
      },
    },
  },
  duration: {
    split_self: 0,
    split_others: 0,
    priority: 28,
    delay: 0,
    absolute_duration: 6.5,

    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 6.5,
  },
  animation: {
    animation_in: 'None',
    animation_out: 'None',
    animation: 'None',
    animation_in_parameters: {
      type: 'whole',
    },
    animation_out_parameters: {
      type: 'whole',
    },
  },
  audio: {},
  position: {
    top_x: 0.4,
    bottom_x: 0.6,
    top_y: 0.4,
    bottom_y: 0.6,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  input: {
    type: 'mandatory',
    allow_multiple: 0,
    placeholder: 'Video Here',
    hidden: 1,
    required: 1,
  },
  id: '.3ec80f51-84e9-43b9-8f52-b131e619a314.4137dc6d-b563-48b6-84d4-606b871dc95a',
  sub_type: 'video_composite',
  input_list: [],
  screen_edit: 1,
  screen_click: 1,
};

export const SHAPE_IMAGE_COMPOSITE_JSON = {
  name: 'shape_composite',
  type: 'composite',
  components: [
    {
      name: 'image_asset',
      type: 'image',
      components: [],
      layout: {
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: {
            type: 'duotone',
            colors_ref: {
              opacity: {
                user_flag: 0,
                reference: 1,
              },
              color: {
                user_flag: 0,
                reference: 'primary',
              },
            },
            color: [255, 255, 255, 255],
          },
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
          filter: 'None',
        },
        fit_type: 'stretch_to_screen',
        loop: 0,
        mute: 0,
        resize_logic: 'max_size',
      },
      duration: {
        split_self: 0,
        split_others: 0,
        priority: 5,
        delay: 0,
        element_duration: 5,
        absolute_duration: 6.5,

        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 6.5,
        minimum_duration: 5,
      },
      animation: {
        animation_in: 'None',
        animation: 'None',
        animation_out: 'None',
        animation_in_parameters: {
          type: 'whole',
        },
        animation_out_parameters: {
          type: 'whole',
        },
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'mandatory',
        allow_multiple: 0,
        placeholder: 'Blah',
        hidden: 1,
        required: 1,
      },
      id: '.3ec80f51-84e9-43b9-8f52-b131e619a314.a1d896bf-b2c2-445b-aa47-4e6df1968328.54501ca7-b032-4df1-a753-ba542a5312bd',
      url: 'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/BlackLayerSD21560242747941.png',
      metadata: {
        premium: false,
      },
      sub_type: 'image_asset',
      orientation: '',
      word: '',
      entity_type: '',
      screen_edit: 1,
      screen_click: 0,
    },
  ],
  layout: {
    fit_type: 'self_position',
    overflow: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
      opacity_ref: {
        user_flag: 0,
        reference: 1,
      },
    },
  },
  duration: {
    split_self: 0,
    split_others: 0,
    priority: 28,
    delay: 0,
    absolute_duration: 6.5,

    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 6.5,
  },
  animation: {
    animation_in: 'None',
    animation_out: 'None',
    animation: 'None',
    animation_in_parameters: {
      type: 'whole',
    },
    animation_out_parameters: {
      type: 'whole',
    },
  },
  audio: {},
  position: {
    top_x: 0.4,
    bottom_x: 0.6,
    top_y: 0.4,
    bottom_y: 0.6,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  input: {
    type: 'mandatory',
    allow_multiple: 0,
    placeholder: 'Video Here',
    hidden: 1,
    required: 1,
  },
  id: '.3ec80f51-84e9-43b9-8f52-b131e619a314.a1d896bf-b2c2-445b-aa47-4e6df1968328',
  sub_type: 'video_composite',
  input_list: [],
  screen_edit: 1,
  screen_click: 1,
};

export const OVERLAY_COMPOSITE_JSON: Master.CompositeJSON = {
  name: 'overlay_asset',
  type: 'composite',
  components: [
    {
      name: 'video_asset',
      type: 'video',
      components: [],
      layout: {
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: 'None',
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        blur: 0,
        playback_rate: 1,
        fit_type: 'crop_to_screen',
        loop: 1,
        mute: 1,
      },
      duration: {
        split_self: 0,
        split_others: 0,
        priority: 1,
        delay: 0,
        absolute_duration: 8,
        element_duration: 4.32,

        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 8,
      },
      animation: {
        animation_in: 'None',
        animation: 'None',
        animation_out: 'None',
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
      },
      input: {
        type: 'mandatory',
        key: 'url',
        value: 'default_image.png',
        placeholder: 'None',
        allow_multiple: 1,
        required: 1,
        hidden: 1,
      },
      id: '.de6520e6-009d-4124-98ab-171d4d64a24c.f10e5201-74f5-4a30-b7c2-0fd18d7f8796.b794eac0-9494-42f0-86a4-f7fd26164379',
      url: 'https://s3.ap-south-1.amazonaws.com/invideo-overlays/Fireworks_celebration_festival_2.webm',
      thumbnail_url:
        'https://s3.ap-south-1.amazonaws.com/invideo-overlays/overlay-thumbnails/Fireworks_celebration_festival_2.png',
      sub_type: 'video',
      orientation: '',
      word: '',
      screen_edit: 1,
      screen_click: 0,
    },
  ],
  layout: {
    fit_type: 'self_position',
    overlay: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
    },
  },
  duration: {
    split_self: 0,
    split_others: 0,
    priority: 30,
    delay: 0,
    absolute_duration: 8,

    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 8,
  },
  animation: {
    animation_in: 'None',
    animation_out: 'None',
    animation: 'None',
  },
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
  },
  input: {
    type: 'mandatory',
    allow_multiple: 0,
    placeholder: 'Blah',
    hidden: 1,
    required: 0,
  },
  id: '.de6520e6-009d-4124-98ab-171d4d64a24c.f10e5201-74f5-4a30-b7c2-0fd18d7f8796',
  sub_type: 'left_overlay_asset_composite',
  compositeType: Master.CompositeType.OVERLAY,
  layer_id: '',
  layer_name: '',
  layer_visible: true,
};

export const MASK_COMPOSITE_JSON = {
  name: 'background_media_1',
  type: 'composite',
  components: [
    {
      name: 'svg_image',
      type: 'svg_image',
      components: [],
      layout: {
        layer_properties: {
          mask: {
            height: 488,
            width: 474,
            id: 'mask-Letter_A',
          },
          opacity: 1,
          blend_effect: 'None',
          color_correction: 'None',
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        resize_logic: 'max_size',
        fit_type: 'stretch_to_screen',
      },
      duration: {
        minimum_duration: 5,
        split_self: 0,
        split_others: 0,
        delay: 0,
        priority: 5,
        absolute_duration: 6.5,
        element_duration: 5,

        start_time: 0,
        end_time: 1,
        absolute_start: 5.5,
        absolute_end: 12,
      },
      animation: {
        animation_in: 'None',
        animation_out: 'None',
        animation: 'None',
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'mandatory',
        placeholder: 'Blah',
        allow_multiple: 0,
        required: 1,
        hidden: 1,
      },
      id: '.d82f05fa-6cc6-41cf-9b12-2888affa99fc.fd7ce086-ae87-42f5-bf61-6bab7059b5ad.7f39886f-5891-4deb-aecd-680b4c970af7',
      url: 'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/MASKS/SVG/A.svg',
      sub_type: 'image_asset',
      screen_edit: 1,
      screen_click: 0,
    },
    {
      name: 'background_media',
      type: 'image',
      components: [],
      layout: {
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: 'None',
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        crop_to_shape: 'None',
        resize_logic: 'max_size',
        fit_type: 'fit_with_blur',
      },
      metadata: {
        premium: false,
      },
      duration: {
        minimum_duration: 5,
        split_self: 1,
        split_others: 0,
        delay: 0,
        priority: 1,
        absolute_duration: 8.000000000000002,
        element_duration: 5,

        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 8.000000000000002,
      },
      animation: {
        animation_in: 'None',
        animation_out: 'None',
        animation: 'None',
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'media_input',
        key: 'url',
        value: 'default_image.png',
        placeholder: 'None',
        allow_multiple: 1,
        required: 1,
        hidden: 1,
      },
      id: '',
      url: 'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/MASKS/mask-placeholder.png',
      word: '',
      sticker_id: '',
      entity_type: '',
      sub_type: 'bg_image',
      orientation: '',
      media_properties: {
        url: '',
        width: '',
        height: '',
        possible_animations: ['pan_to_up', 'pan_to_bottom'],
      },
      screen_edit: 1,
      screen_click: 0,
    },
  ],
  layout: {
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
      opacity_ref: {
        user_flag: 0,
        reference: 1,
      },
    },
    fit_type: 'self_position',
    overflow: 'hidden',
  },
  duration: {
    split_self: 1,
    split_others: 0,
    delay: 0,
    priority: 3,
    absolute_duration: 8.000000000000002,

    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 8.000000000000002,
  },
  animation: {
    animation_in: 'None',
    animation_out: 'None',
    animation: 'None',
  },
  audio: {},
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  input: {
    type: 'mandatory',
    allow_multiple: 1,
    placeholder: 'Image Here',
    hidden: 1,
    required: 1,
  },
  id: '',
  sub_type: 'bg_media_composite',
  screen_edit: 1,
  screen_click: 1,
  sr_no: 0,
};

export const ICON_COMPOSITE_JSON: Master.CompositeJSON = {
  compositeType: Master.CompositeType.STICKER_IMAGE,
  layer_id: '',
  layer_name: '',
  layer_visible: true,
  id: '.7d51ba90-a1d9-4aa6-b2e6-e172e835bda7.ed7b558a-3c31-4df6-828b-9409100d48dc',
  duration: {
    absolute_duration: 5,
    delay: 0,
    end_time: 1,
    absolute_end: 4.9,
    priority: 25,

    absolute_start: -0.09999999999999999,
    split_self: 0,
    start_time: 0,
    split_others: 0,
  },
  name: 'icon_composite',
  input: {
    type: 'mandatory',
    required: 1,
    hidden: 1,
    allow_multiple: 1,
    placeholder: 'Icon Here',
  },
  layout: {
    layer_properties: {
      color_overlay: 'None',
      drop_shadow: 'None',
      color_correction: 'None',
      blend_effect: 'None',
      opacity: 1,
    },
    fit_type: 'self_position',
    overlay: 'hidden',
  },
  animation: {
    animation: 'None',
    animation_in: 'fadeIn',
    animation_out: 'fadeOut',
    animation_in_parameters: {
      effect_time: 0.2,
    },
    animation_out_parameters: {
      effect_time: 0.2,
    },
  },
  position: {
    bottom_y: 0.6,
    bottom_x: 0.6,
    pivot_y: 0.5,
    top_x: 0.4,
    top_y: 0.4,
    pivot_x: 0.5,
    // vertical_text_alignment: 'center',
    orientation: 'horizontal',
    // horizontal_text_alignment: 'center',
  },
  // screen_edit: 1,
  components: [
    {
      id: '.7d51ba90-a1d9-4aa6-b2e6-e172e835bda7.ed7b558a-3c31-4df6-828b-9409100d48dc.bce33596-765b-4785-a9fc-a445e4b2e03c',
      audio: {},
      duration: {
        absolute_end: 4.9,
        delay: 0,
        start_time: 0,
        element_duration: 5,
        end_time: 1,
        split_others: 0,
        priority: 3,
        absolute_start: -0.09999999999999999,
        split_self: 0,
        absolute_duration: 5,
        minimum_duration: 5,
      },
      name: 'icon_image',
      sub_type: '',
      input: {
        required: 1,
        type: 'icon_media_input',
        placeholder: 'None',
        allow_multiple: 1,
        key: 'url',
        hidden: 1,
        value: 'default_image.png',
      },
      orientation: '',
      layout: {
        layer_properties: {
          color_overlay: 'None',
          drop_shadow: 'None',
          color_correction: 'None',
          blend_effect: 'None',
          opacity: 1,
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
        },
        fit_type: 'fit_with_transparent',
        resize_logic: 'max_size',
      },
      animation: {
        animation: 'None',
        animation_in: 'None',
        animation_out: 'None',
      },
      position: {
        bottom_y: 1,
        bottom_x: 1,
        pivot_y: 0.5,
        top_x: 0,
        top_y: 0,
        pivot_x: 0.5,
      },
      screen_edit: 1,
      url: 'https://s3.ap-south-1.amazonaws.com/invideo-icons/FLAG_1535014338193.png',
      components: [],
      type: 'image',
      screen_click: 0,
      word: '',
    },
  ],
  type: 'composite',
  // screen_click: 1,
  sub_type: 'icon_composite',
};

export const inPlaceAnimations = [
  {
    family: 'None',
    label: 'None',
    hint: 'None',
    className: 'None',
    direction: 'none',
    key: 'None',
    display: 'None',
  },
  {
    family: 'Pan',
    label: 'Pan Left',
    hint: 'Left',
    className: 'pan_to_left',
    direction: 'left',
    key: 'pan_to_left',
    display: 'Pan Left',
  },
  {
    family: 'Pan',
    label: 'Pan Right',
    hint: 'Right',
    className: 'pan_to_right',
    direction: 'right',
    key: 'pan_to_right',
    display: 'Pan Right',
  },
  {
    family: 'Pan',
    label: 'Pan Up',
    hint: 'Up',
    className: 'pan_to_up',
    direction: 'up',
    key: 'pan_to_up',
    display: 'Pan Up',
  },
  {
    family: 'Pan',
    label: 'Pan Bottom',
    hint: 'Bottom',
    className: 'pan_to_bottom',
    direction: 'down',
    key: 'pan_to_bottom',
    display: 'Pan Bottom',
  },
  {
    family: 'Zoom',
    label: 'Zoom In',
    hint: 'In',
    className: 'zoom_in',
    direction: 'still',
    key: 'zoom_in',
    display: 'Zoom In',
  },
  {
    family: 'Zoom',
    label: 'Zoom Out',
    hint: 'Out',
    className: 'zoom_out',
    direction: 'still',
    key: 'zoom_out',
    display: 'Zoom Out',
  },
];

export const TRANSITION_COMPOSITE_JSON: Master.CompositeJSON = {
  name: 'video_transition',
  type: 'composite',
  sub_type: 'transition_composite',
  id: '',
  components: [],
  duration: { split_self: 0, split_others: 0, priority: 6, delay: -1 },
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  layout: {
    fit_type: 'self_position',
    overlay: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
    },
  },
  input: { type: 'mandatory', allow_multiple: 0, placeholder: 'Blah', hidden: 1, required: 0 },
  animation: { animation_in: 'None', animation_out: 'None', animation: 'None' },
  screen_edit: 0,
  screen_click: 0,
} as unknown as Master.CompositeJSON;

export const TRANSITION_IMAGE_COMPONENT_JSON = {
  name: 'image',
  components: [],
  duration: {
    absolute_duration: 5,
    element_duration: 5,
    minimum_duration: 5,
    split_self: 0,
    split_others: 0,
    delay: 0,
    priority: 5,
  },
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  layout: {
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: 'None',
    },
    resize_logic: 'max_size',
    fit_type: 'stretch_to_screen',
  },
  input: { type: 'mandatory', allow_multiple: 0, placeholder: 'Blah', hidden: 1, required: 1 },
  animation: { animation_in: 'None', animation_out: 'None', animation: 'None' },
} as unknown as Master.ImageJSON;

export const TRANSITION_VIDEO_COMPONENT_JSON = {
  name: 'transition_asset',
  type: 'video',
  id: '',
  sub_type: 'video_transition',
  screen_edit: 0,
  screen_click: 0,
  components: [],
  duration: {
    split_self: 0,
    split_others: 0,
    priority: 5,
    delay: 0,
    absolute_duration: 2,
    element_duration: 2,
    precalculated_duration: 2,
  },
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  layout: {
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: { color: 'secondary', type: 'tritone' },
      color_overlay: 'None',
      drop_shadow: 'None',
    },
    playback_type: 'speed_factor',
    blur: 0,
    playback_rate: 1,
    fit_type: 'stretch_to_screen',
    loop: 0,
  },
  animation: { animation_in: 'None', animation: 'None', animation_out: 'None' },
  input: { type: 'mandatory', allow_multiple: 0, placeholder: 'Blah', hidden: 1, required: 1 },
  audio: {},
} as unknown as Master.VideoJSON;

export const TRANSITION_DEFAULT_CONFIG: DefaultTransitionConfig = {
  transition_asset: {
    possible_types: 'video',
    url: '',
    layout: {
      layer_properties: {
        opacity: 1,
        blend_effect: 'None',
        color_correction: {
          color: 'secondary',
          type: 'duotone',
        },
        color_overlay: 'None',
        drop_shadow: 'None',
      },
      playback_type: 'speed_factor',
      blur: 0,
      playback_rate: 1,
      fit_type: 'stretch_to_screen',
      loop: 0,
    },
  },
} as unknown as DefaultTransitionConfig;

export const BACKGROUD_COMPOSITE = {
  compositeType: 'CanvasBackground',
  name: 'Clean 1',
  type: 'composite',
  components: [
    {
      name: 'gif_video',
      type: 'image',
      components: [],
      layout: {
        layer_properties: {
          opacity: 1,
          blend_effect: 'None',
          color_correction: {
            type: 'duotone',
            color: ['0', '0', '0', 1],
            colors_ref: {
              color: {
                user_flag: 0,
                reference: 'quaternary',
              },
            },
          },
          color_overlay: 'None',
          drop_shadow: 'None',
          opacity_ref: {
            user_flag: 0,
            reference: 1,
          },
          filter: 'None',
        },
        fit_type: 'crop_to_screen',
        loop: 1,
        blur: 0,
        playback_rate: 1,
        playback_type: 'speed_factor',
        mute: 0,
      },
      duration: {
        split_self: 0,
        split_others: 0,
        delay: 0,
        priority: 5,
        absolute_duration: 5,
        element_duration: 5,
        start_time: 0,
        end_time: 1,
        absolute_start: 0,
        absolute_end: 5,
        precalculated_duration: 4.04,
        minimum_duration: 5,
      },
      animation: {
        animation_in: 'None',
        animation_out: 'None',
        animation: 'None',
        animation_in_parameters: {
          type: 'whole',
        },
        animation_out_parameters: {
          type: 'whole',
        },
      },
      audio: {},
      position: {
        top_x: 0,
        bottom_x: 1,
        top_y: 0,
        bottom_y: 1,
        pivot_x: 0.5,
        pivot_y: 0.5,
        orientation: 'horizontal',
      },
      input: {
        type: 'mandatory',
        placeholder: 'Blah',
        allow_multiple: 0,
        required: 0,
        hidden: 1,
      },
      id: '.8c79eb11-8a2f-489d-a8e2-04c62195ee34.713abf28-c89e-60d7-ff7f-126c6f3fb321.f85cae6e-9cea-4b91-dffe-c597b7c1e397',
      url: 'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Background/bguniquecanvas.png',
      word: '',
      sub_type: 'image_asset',
      orientation: '',
      screen_edit: 1,
      screen_click: 0,
      thumbnail_url:
        'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Background/bguniquecanvas.png',
      media_properties: {
        audio_present: false,
      },
    },
  ],
  layout: {
    fit_type: 'self_position',
    overflow: 'hidden',
    layer_properties: {
      opacity: 1,
      blend_effect: 'None',
      color_correction: 'None',
      color_overlay: 'None',
      drop_shadow: {
        enabled: true,
        name: 'None',
        distance: 0,
        blur: 0,
        opacity: 0,
        angle: 0,
        drop_shadow_color: [0, 0, 0, 255],
        colors_ref: {
          drop_shadow_color: {
            user_flag: 0,
            reference: [0, 0, 0, 255],
          },
        },
        shadow_factor: 662.4999999999999,
      },
      opacity_ref: {
        user_flag: 0,
        reference: 1,
      },
    },
  },
  duration: {
    split_self: 0,
    split_others: 0,
    priority: 1.5,
    delay: 0,
    absolute_duration: 5,
    start_time: 0,
    end_time: 1,
    absolute_start: 0,
    absolute_end: 5,
    fixed_start_time: 0,
    fixed_end_time: 1,
  },
  animation: {
    animation_in: 'None',
    animation_out: 'None',
    animation: 'None',
    animation_in_parameters: {
      type: 'whole',
    },
    animation_out_parameters: {
      type: 'whole',
    },
  },
  position: {
    top_x: 0,
    bottom_x: 1,
    top_y: 0,
    bottom_y: 1,
    pivot_x: 0.5,
    pivot_y: 0.5,
    orientation: 'horizontal',
  },
  input: {
    type: 'mandatory',
    allow_multiple: 0,
    placeholder: 'Blah',
    hidden: 1,
  },
  id: '.8c79eb11-8a2f-489d-a8e2-04c62195ee34.713abf28-c89e-60d7-ff7f-126c6f3fb321',
  sub_type: 'pre_video_asset_composite',
  is_visible: true,
  is_lock: false,
  layer_name: 'Background',
  timeline_config: {
    mode: 'smart',
  },
};

import {
  Node,
  EditorState,
  NodeItem,
  NodeKey,
  ParagraphNode,
  NodeMap,
  TextNode,
  ParsedNode,
  TextStyles,
  ParagraphStyle,
  TextAlign,
  NodeType,
  ParsedTextNode,
  ParsedHighlightNode,
  HighlightNode,
  ParsedParagraphNode,
  ParsedNodes,
  LinebreakNode,
  TextContentType,
  ParsedLinebreakNode,
  ParsedNodeMap,
  RootNode,
} from './lexicalTypes';
import { StyledTextChunk, StyledTextChunks } from '../skia/skiaTypes';
import { makeStyledTextChunks } from '../skia';

const paragraphAligns: Array<TextAlign> = ['left', 'left', 'center', 'right', 'justify'];
const textStyles = ['bold', 'italic', 'strike', 'underline'];

export function lexicalToStyledTextChunks(lexical: EditorState): StyledTextChunks | undefined {
  const nodeMap = getNodeMap(lexical);
  const parsedLexical = parseNodeMap(nodeMap);

  if (!parsedLexical) return undefined;

  const styledTextChunks = reduceTextChunks(parsedLexical.nodes);
  return styledTextChunks;
}

function makeParagraphNodeMap(styledTextChunks: StyledTextChunks): NodeMap {
  const nodeMap = createDefaultNodeMap();

  const currParaIndex = 1;
  let nextInsertIndex = 2;
  const [_, node] = nodeMap[1];
  const currentPara = node as ParagraphNode;
  styledTextChunks.forEach((chunk) => {
    const key = `${nextInsertIndex}`;
    const paraKey = `${currParaIndex}`;
    if (chunk.text === '\n') {
      const newNode = createLinebreakNode(key, paraKey);
      currentPara.__children.push(key);
      nodeMap.push(newNode);
      nextInsertIndex++;
    } else if (chunk.type === 'default') {
      const newNode = createTextNode(key, paraKey, chunk.text);
      currentPara.__children.push(key);
      nodeMap.push(newNode);
      nextInsertIndex++;
    } else if (chunk.type === 'highlight') {
      const newNodes = createHighlightNode(nextInsertIndex, paraKey, chunk.text);
      currentPara.__children.push(key);
      newNodes.forEach((n) => nodeMap.push(n));
      nextInsertIndex += 2;
    }
  });
  return nodeMap;
}

function makeListNodeMap(styledTextChunks: StyledTextChunks): NodeMap {
  const nodeMap = createDefaultNodeMap();

  // TODO: implement
  return nodeMap;
}

export function makeNodeMapFromHtml(html: string, textContentType: TextContentType): NodeMap {
  const styledTextChunks = makeStyledTextChunks(html);
  if (textContentType === 'paragraph') {
    return makeParagraphNodeMap(styledTextChunks);
  } else if (textContentType === 'list') {
    return makeListNodeMap(styledTextChunks);
  }

  // fallback to default nodeMap
  return createDefaultNodeMap();
}

export function lexicalToHtml(nodeMap: NodeMap): { html: string; highlightedStrings: string[] } {
  const styledTextChunks = lexicalToStyledTextChunks({ _nodeMap: nodeMap });

  if (!styledTextChunks) return { html: '', highlightedStrings: [] };

  const highlightedStrings = styledTextChunks
    .filter((c) => c.type === 'highlight')
    .map((c) => c.text);

  const html = styledTextChunks.reduce((acc, curr) => {
    const currentHtml =
      curr.text === '\n'
        ? '<br/>'
        : curr.type === 'highlight'
        ? `<span class="highlighted_string">${curr.text}</span>`
        : curr.text;
    return acc + currentHtml;
  }, '');
  return { html, highlightedStrings };
}

function isValidNodeMap(nodeMap: NodeMap): boolean {
  const rootNode: RootNode | undefined = findNodeByKey(nodeMap, 'root') as RootNode | undefined;
  if (!rootNode) return false;

  if (rootNode.__children.length === 0) {
    return false;
  }

  const [firstNodeKey, ...__] = rootNode.__children;
  const firstNode = findNodeByKey(nodeMap, firstNodeKey)!;

  if (firstNode.__type === 'paragraph') {
    // Get all nodes.
    // Check each node is paragraph
    const hasOnlyParagraphNodes = !rootNode.__children
      .map((k) => findNodeByKey(nodeMap, k))
      .find((n) => !n || n.__type !== 'paragraph');
    return hasOnlyParagraphNodes;
  } else if (firstNode.__type === 'list') {
    // must contain single list node
    return rootNode.__children.length === 1;
  }
  return true;
}

function getContentType(nodeMap: NodeMap): TextContentType {
  const node = getFirstContentNode(nodeMap);
  return node!.__type as TextContentType;
}

function parseNodeMap(nodeMap: NodeMap): ParsedNodeMap | undefined {
  if (!isValidNodeMap(nodeMap)) return undefined;

  const textContentType: TextContentType = getContentType(nodeMap);
  if (textContentType === 'paragraph') {
    const paras = getAllParagraphs(nodeMap);
    const numParas = paras.length;
    const nodes: ParsedParagraphNode[] = [];
    paras.forEach((para, i) => {
      nodes.push(...para.__children.map((key) => parseNode(nodeMap, key)));
      if (i < numParas - 1) {
        nodes.push({ type: 'linebreak' });
      }
    });
    const style = getParagraphStyle(paras[0]);
    const parsed = {
      nodes,
      style,
      type: textContentType,
    };
    return parsed;
  } else if (textContentType === 'list') {
    //TODO: implement
  }
}

function getNodeMap(lexical: EditorState): NodeMap {
  if (!lexical._nodeMap) throw Error('invalid lexical editor state');
  const nodeMap = lexical._nodeMap;
  return nodeMap;
}

const getAllParagraphs = (nodeMap: NodeMap): ParagraphNode[] => {
  const rootNode: RootNode = findNodeByKey(nodeMap, 'root') as RootNode;
  const paras = rootNode.__children.map((k) => findNodeByKey(nodeMap, k) as ParagraphNode);
  return paras;
};

const getFirstContentNode = (nodeMap: NodeMap): ParagraphNode => {
  const rootNode: RootNode = findNodeByKey(nodeMap, 'root') as RootNode;

  const [firstNodeKey, ...__] = rootNode.__children;
  const node = findNodeByKey(nodeMap, firstNodeKey);
  return node as ParagraphNode;
};

const findNodeByKey = (nodeMap: NodeMap, key: NodeKey): Node | undefined => {
  const nodeItem = nodeMap.find(([nodeKey, _]) => nodeKey === key);
  if (!nodeItem) return undefined;
  const [_, node] = nodeItem;
  return node;
};

const getParagraphStyle = (para: ParagraphNode): ParagraphStyle => {
  const align = paragraphAligns[para.__format ?? 0];
  return { align, dir: para.__dir ?? 'ltr' };
};

const parseNode = (nodeMap: NodeMap, key: string): ParsedNode => {
  const node = findNodeByKey(nodeMap, key);
  if (!node) throw Error('node not found');
  switch (node.__type) {
    case 'text':
      return parseTextNode(node as TextNode);
    case 'linebreak':
      return parseLinebreakNode(node as LinebreakNode);
    case 'highlight':
      return parseHighlightNode(nodeMap, node as HighlightNode);
    default:
      throw Error('invalid node key');
  }
};

const parseTextNode = (node: TextNode): ParsedTextNode => {
  const text = node.__text;
  const style = parseTextFormat(node.__format, node.__style);
  return { text, style, type: 'text' };
};

const parseTextFormat = (format: number, style: string): TextStyles => {
  const bisu = textStyles.filter((_, i) => Math.pow(2, i) & format);
  const styles = Object.assign({}, ...Array.from(bisu, (k) => ({ [k]: true })));
  style
    .split(';')
    .filter((s) => s !== '')
    .map((cssStyle) => {
      const [k, v] = cssStyle.split(':');
      // const css = CSSStyleValue.parse(k,v);
      // styles[k] = css.value;
      styles[k] = v;
    });
  return styles;
};

const parseLinebreakNode = (_: LinebreakNode): ParsedLinebreakNode => {
  return { type: 'linebreak' };
};

const parseHighlightNode = (nodeMap: NodeMap, node: HighlightNode): ParsedHighlightNode => {
  const nodes = node.__children.map((key) => parseNode(nodeMap, key));
  return { nodes, type: 'highlight' } as ParsedHighlightNode;
};

const reduceTextChunks = (nodes: ParsedNodes): StyledTextChunks => {
  if (nodes.length === 0) return [];
  const firstNodeType: StyledTextChunk['type'] = nodes[0].type === 'text' ? 'default' : 'highlight';
  const styledTextChunks: StyledTextChunks = nodes.reduce(
    (chunks: StyledTextChunks, currNode: ParsedParagraphNode) => {
      if (currNode.type === 'text') {
        const node = currNode as ParsedTextNode;
        const lastNode = chunks[chunks.length - 1];
        if (lastNode.type !== 'default' || lastNode.text === '\n') {
          chunks.push({ text: node.text, type: 'default' });
        } else {
          lastNode.text += node.text;
        }
      } else if (currNode.type === 'highlight') {
        const node = currNode as ParsedHighlightNode;
        const lastNode = chunks[chunks.length - 1];
        if (lastNode.type !== 'highlight') {
          chunks.push({ text: '', type: 'highlight' });
        }
        node.nodes.reduce((chunks: StyledTextChunks, currNode: ParsedParagraphNode) => {
          const lastNode = chunks[chunks.length - 1];
          if (currNode.type === 'text') {
            const textNode = currNode as ParsedTextNode;
            lastNode.text += textNode.text;
          } else if (currNode.type === 'linebreak') {
            chunks.push({ text: '\n', type: 'default' });
            chunks.push({ text: '', type: 'highlight' });
          }
          return chunks;
        }, chunks);
      } else if (currNode.type === 'linebreak') {
        chunks.push({ text: '\n', type: 'default' });
      }
      return chunks;
    },
    [{ text: '', type: firstNodeType }],
  );

  return styledTextChunks;
};

// created a dummy editor state
// Start adding nodes (text/link) from index [2]
const createDefaultNodeMap = (): NodeMap => {
  return [
    [
      'root',
      {
        __children: ['1'],
        __dir: 'ltr',
        __format: 0,
        __indent: 0,
        __key: 'root',
        __parent: null,
        __type: 'root',
      },
    ],
    [
      '1',
      {
        __type: 'paragraph',
        __parent: 'root',
        __key: '1',
        __children: [],
        __format: 0,
        __indent: 0,
        __dir: 'ltr',
      },
    ],
  ];
};

const createLinebreakNode = (key: string, parentKey: string): NodeItem => {
  return [
    key,
    {
      __type: 'linebreak',
      __parent: parentKey,
      __key: key,
    },
  ];
};

const createTextNode = (key: string, parentKey: string, text: string): NodeItem => {
  return [
    key,
    {
      __type: 'text',
      __parent: parentKey,
      __key: key,
      __text: text,
      __format: 0,
      __style: '',
      __mode: 0,
      __detail: 0,
    },
  ];
};

const createHighlightNode = (nextIndex: number, parentKey: string, text: string): NodeItem[] => {
  const key = `${nextIndex}`;
  const textNodeKey = `${nextIndex + 1}`;
  const textNode = createTextNode(textNodeKey, key, text);
  const highlightNode: NodeItem = [
    key,
    {
      __type: 'highlight',
      __parent: parentKey,
      __key: key,
      __children: [textNodeKey],
      __format: 0,
      __indent: 0,
      __dir: 'ltr',
    },
  ];
  return [highlightNode, textNode];
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DeviceInfo,
  DropMetaData,
  NetworkInfo,
  StorageInfo,
  AppConfig,
  TemplateInfo,
  AccountData,
  PurchasedMedia,
  Experiments,
  YoutubeCopyrightsInfo,
  UserData,
  GoogleConnectedInfo,
  PremiumMediaEvent,
  CardDetails,
  ColorPalette,
  FacebookConnectedInfo,
  TwitterConnectedInfo,
  FeatureConfig,
} from './types';

export type State = {
  device: DeviceInfo | null;
  network: NetworkInfo | null;
  storage: StorageInfo | null;
  sessionUrl: string | null;
  appConfig: AppConfig | null;
  deviceId: string | null;
  sessionId: string;
  templateInfo: TemplateInfo | null;
  templateSearchTerm: string;
  templateAnalyticsProperties: {
    startedWithRoute: string;
    startedWithKeyword: string;
  };
  fixedAnalyticsProperties: {
    [name: string]: string;
  };
  dragEventProperties: DropMetaData | null;
  guestUserFlow: boolean;
  userData: null | UserData;
  purchasedMedia: PurchasedMedia;
  experiments: Experiments;
  teamMembersCount: null | number;
  youtubeCopyrightsInfo: YoutubeCopyrightsInfo;
  cardDetails: CardDetails | null;
  googleConnectedInfo: GoogleConnectedInfo;
  countryCode: string;
  colorPalette?: Array<ColorPalette>;
  premiumMediaEvent: PremiumMediaEvent | null;
  facebookConnectedInfo: FacebookConnectedInfo;
  twitterConnectedInfo: TwitterConnectedInfo;
  featureConfig: FeatureConfig | null;
};

export const INITIAL_STATE: State = {
  device: null,
  network: null,
  storage: null,
  sessionUrl: null,
  sessionId: '-1',
  appConfig: null,
  deviceId: null,
  templateInfo: null,
  templateSearchTerm: '',
  templateAnalyticsProperties: {
    startedWithRoute: '',
    startedWithKeyword: '',
  },
  fixedAnalyticsProperties: {},
  dragEventProperties: null,
  guestUserFlow: false,
  userData: null,
  purchasedMedia: [],
  experiments: {
    advTimelineExperiment: 'A',
  },
  teamMembersCount: null,
  youtubeCopyrightsInfo: {
    isYoutubeWhitelisted: true,
    isCopyrightBannerDismissed: true,
  },
  cardDetails: null,
  googleConnectedInfo: {
    isGoogleConnected: false,
  },
  countryCode: '',
  premiumMediaEvent: null,
  facebookConnectedInfo: {
    isFacebookConnected: false,
  },
  twitterConnectedInfo: {
    isTwitterConnected: false,
  },
  featureConfig: null,
};

export const slice = createSlice({
  name: 'userState',
  initialState: INITIAL_STATE,
  reducers: {
    updateAppConfig: (state, action: PayloadAction<AppConfig>) => {
      state.appConfig = action.payload;
    },

    updateBgRemoveCount: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0) {
        if (state.userData && state.userData.account_details) {
          state.userData.account_details.bg_remove_count = action.payload;
          localStorage.setItem(window.btoa('user'), JSON.stringify(state.userData));
        }
      }
    },

    updateDevice: (state, action: PayloadAction<DeviceInfo | null>) => {
      state.device = action.payload;
    },
    updateNetwork: (state, action: PayloadAction<NetworkInfo | null>) => {
      state.network = action.payload;
    },
    updateStorage: (state, action: PayloadAction<StorageInfo | null>) => {
      state.storage = action.payload;
    },
    updateDeviceId: (state, action: PayloadAction<string | null>) => {
      state.deviceId = action.payload;
    },
    updateTemplateInfo: (state, action: PayloadAction<TemplateInfo | null>) => {
      state.templateInfo = action.payload;
    },
    updateTemplateSearchTerm: (state, action: PayloadAction<string>) => {
      state.templateSearchTerm = action.payload;
    },
    updateTemplateAnalyticsProperties: (
      state,
      action: PayloadAction<{ startedWithRoute: string; startedWithKeyword: string }>,
    ) => {
      state.templateAnalyticsProperties.startedWithKeyword = action.payload.startedWithKeyword;
      state.templateAnalyticsProperties.startedWithRoute = action.payload.startedWithRoute;
    },
    updateFixedAnalyticsProperties: (state, action: PayloadAction<{ [name: string]: string }>) => {
      state.fixedAnalyticsProperties = action.payload;
    },
    updateDragEventProperties: (state, action: PayloadAction<DropMetaData | null>) => {
      state.dragEventProperties = action.payload;
    },
    updateGuestUserFlow: (state, action: PayloadAction<boolean>) => {
      state.guestUserFlow = action.payload;
    },
    updatedAccountData: (state, action: PayloadAction<AccountData>) => {
      if (state.userData) {
        state.userData.account_details = action.payload;
      }
    },
    updatedUserData: (state, action: PayloadAction<UserData | null>) => {
      state.userData = action.payload;
    },
    updatePurchasedMedia: (state, action: PayloadAction<PurchasedMedia>) => {
      state.purchasedMedia = action.payload;
    },
    updateAudioAdvTimelineExperiment: (
      state,
      action: PayloadAction<Experiments['advTimelineExperiment']>,
    ) => {
      state.experiments.advTimelineExperiment = action.payload;
    },
    updateTeamMembersCount: (state, action: PayloadAction<number | null>) => {
      state.teamMembersCount = action.payload;
    },
    updateIsYoutubeWhitelisted: (
      state,
      action: PayloadAction<YoutubeCopyrightsInfo['isYoutubeWhitelisted']>,
    ) => {
      state.youtubeCopyrightsInfo.isYoutubeWhitelisted = action.payload;
    },
    updateIsCopyrightBannerDismissed: (
      state,
      action: PayloadAction<YoutubeCopyrightsInfo['isCopyrightBannerDismissed']>,
    ) => {
      state.youtubeCopyrightsInfo.isCopyrightBannerDismissed = action.payload;
    },
    updateSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload || '-1';
    },
    updateCardDetails: (state, action: PayloadAction<CardDetails | null>) => {
      state.cardDetails = action.payload;
    },
    updateIsGoogleConnected: (
      state,
      action: PayloadAction<GoogleConnectedInfo['isGoogleConnected']>,
    ) => {
      state.googleConnectedInfo.isGoogleConnected = action.payload;
    },
    updateCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
    updateColorPalette: (state, action: PayloadAction<Array<ColorPalette> | undefined>) => {
      state.colorPalette = action.payload;
    },
    updatePremiumMediaEvent: (state, action: PayloadAction<PremiumMediaEvent | null>) => {
      state.premiumMediaEvent = action.payload;
    },
    updateIsFacebookConnected: (
      state,
      action: PayloadAction<FacebookConnectedInfo['isFacebookConnected']>,
    ) => {
      state.facebookConnectedInfo.isFacebookConnected = action.payload;
    },
    updateIsTwitterConnected: (
      state,
      action: PayloadAction<TwitterConnectedInfo['isTwitterConnected']>,
    ) => {
      state.twitterConnectedInfo.isTwitterConnected = action.payload;
    },
    updateFeatureConfig: (state, action: PayloadAction<FeatureConfig>) => {
      state.featureConfig = action.payload;
    },
  },
});

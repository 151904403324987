import { SlateStore } from '../slateStore';
export type { SlateEntities } from '../slateStore';

const store = new SlateStore('singleton');
type FunctionKeys<T> = { [K in keyof T]: T[K] extends Function ? K : never }[keyof T];

const proxyToStore = <T extends FunctionKeys<SlateStore>>(funcName: T): SlateStore[T] => {
  return ((...args: any[]) => {
    return (store[funcName] as any)(...args);
  }) as SlateStore[T];
};

export const getState = () => store.state;
export const getErrors = () => store.errors;

export const update = proxyToStore('syncWithShadow');
export const reset = proxyToStore('reset');

export const getByType = proxyToStore('getByType');
export const getById = proxyToStore('getById');
export const getSlateRoot = proxyToStore('getSlateRoot');
export const getSlateBlock = proxyToStore('getSlateBlock');
export const getSlateTransition = proxyToStore('getSlateTransition');
export const getSlateLayer = proxyToStore('getSlateLayer');
export const getSlateAudio = proxyToStore('getSlateAudio');
export const getSlateComposite = proxyToStore('getSlateComposite');
export const getSlateComponent = proxyToStore('getSlateComponent');
export const getSlateWatermark = proxyToStore('getSlateWatermark');
export const getSlateGap = proxyToStore('getSlateGap');

export const addSlatelayer = proxyToStore('addSlateLayer');
export const patchSelected = proxyToStore('patchSelected');
export const patchSlateComposite = proxyToStore('patchSlateComposite');
export const patchSlateTransitionTime = proxyToStore('patchSlateTransitionTime');
export const patchSlatePositionComposite = proxyToStore('patchSlatePositionComposite');
export const patchSlateParagraphVisibility = proxyToStore('patchSlateParagraphVisibility');
export const patchSlateCompositeForceVisibility = proxyToStore(
  'patchSlateCompositeForceVisibility',
);
export const patchSlateCompositeForceInvisibility = proxyToStore(
  'patchSlateCompositeForceInvisibility',
);
export const patchSlateComponentFitAlignment = proxyToStore('patchSlateComponentFitAlignment');
export const patchSlateComponentAdjustmentFilters = proxyToStore(
  'patchSlateComponentAdjustmentFilters',
);
export const patchSlateComponentEffects = proxyToStore('patchSlateComponentEffects');
export const patchSlateComponentColorAtIndex = proxyToStore('patchSlateComponentColorAtIndex');
export const patchSlateComponentColorCorrection = proxyToStore(
  'patchSlateComponentColorCorrection',
);
export const patchSlateCompositeTime = proxyToStore('patchSlateCompositeTime');
export const patchSlateVideoTrim = proxyToStore('patchSlateVideoTrim');
export const patchSlateVideoPlaybackRate = proxyToStore('patchSlateVideoPlaybackRate');
export const patchSlateBlockTime = proxyToStore('patchSlateBlockTime');
export const patchSlateCompositeLayerId = proxyToStore('patchSlateCompositeLayerId');
export const patchSlateLayers = proxyToStore('patchSlateLayers');
export const patchSlateWatermarkCtaUrl = proxyToStore('patchSlateWatermarkCtaUrl');
export const patchSlateImageUrl = proxyToStore('patchSlateImageUrl');
export const putSlateParagraph = proxyToStore('putSlateParagraph');
export const patchSlateAudioTime = proxyToStore('patchSlateAudioTime');
export const patchSlateAudioTrim = proxyToStore('patchSlateAudioTrim');
export const patchSlateAudioConfig = proxyToStore('patchSlateAudioConfig');
export const patchSlateAudioVolume = proxyToStore('patchSlateAudioVolume');
export const patchToNearestFrame = proxyToStore('patchToNearestFrame');

export const addPlaceholderBlockAtIndex = proxyToStore('addPlaceholderBlockAtIndex');
export const patchDropShadowToComposite = proxyToStore('patchDropShadowToComposite');
export const patchSlateLayerLock = proxyToStore('patchSlateLayerLock');
export const patchSlateComponentOpacity = proxyToStore('patchSlateComponentOpacity');
export const patchSlateParagraphBlendMode = proxyToStore('patchSlateParagraphBlendMode');
export const patchSlateCompositeBlendMode = proxyToStore('patchSlateCompositeBlendMode');
export const patchSlateCompositeFxFilters = proxyToStore('patchSlateCompositeFxFilters');
export const patchSlateParagraphOutline = proxyToStore('patchSlateParagraphOutline');
export const patchSlateParagraphFontOpacity = proxyToStore('patchSlateParagraphFontOpacity');
export const patchSlateParagraphBandOpacity = proxyToStore('patchSlateParagraphBandOpacity');
export const patchSlateParagraphFontMainColor = proxyToStore('patchSlateParagraphFontMainColor');
export const patchSlateParagraphFontHighlightColor = proxyToStore(
  'patchSlateParagraphFontHighlightColor',
);
export const patchSlateParagraphBackgroundColor = proxyToStore(
  'patchSlateParagraphBackgroundColor',
);
export const patchSlateParagraphLineHeight = proxyToStore('patchSlateParagraphLineHeight');
export const patchSlateParagraphCharacterSpacing = proxyToStore(
  'patchSlateParagraphCharacterSpacing',
);
export const patchDropShadowToParagraph = proxyToStore('patchDropShadowToParagraph');
export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}
export const forceTouch = proxyToStore('forceTouch');

// LET US MAKE SURE THESE FUNCTIONS DO NOT HAVE ANY SIDE EFFECTS. (eg: updating shell, shadow etc)
// WE CAN MAKE THESE FUNCTIONS PURE. BUT THEY REQUIRE SHADOW. SO LET US MAKE SURE THESE DO NOT HAVE SIDE EFFECT

import { Master } from '../../jsonTypes';
import { getShadowById } from '../shadowRegistry';
import { isInvisibleBackgroundComponent } from './getCompositeType';

const butLast = (array: Array<unknown>) => array.slice(0, -1);
const tail = ([, ...tail]: Array<unknown>) => tail;

const getParentId = (componentId: string) => {
  const parentId = butLast(tail(componentId.split('.')).map((id: any) => `.${id}`)).join('');
  return parentId || '.';
};

function componentHasFill(componentJSON: any): boolean {
  const cc = componentJSON.layout.layer_properties.color_correction;
  return cc && cc !== 'None';
}

const getMaskComponentFromComposite = (componentJSON: Master.ComponentJSON) => {
  if (!componentJSON || !componentJSON['components']) {
    return null;
  }
  for (let i = 0; i < componentJSON['components'].length; i++) {
    if (
      componentJSON['components'][i]['type'].includes('svg') &&
      'mask' in componentJSON['components'][i]['layout']['layer_properties'] &&
      componentJSON['components'][i]['layout']['layer_properties']['mask']
    ) {
      return componentJSON['components'][i];
    }
  }
  return null;
};

const getTopPanelComponentType = (
  componentJSON: Master.ComponentJSON | Master.IgnoredMissingText,
) => {
  if (!componentJSON) {
    return null;
  }

  if (isInvisibleBackgroundComponent(componentJSON)) {
    return 'InvisibleBackground';
  }

  /* ***** parent json checks ***** */

  const parentID = getParentId(componentJSON.id);
  const parentJSON = getShadowById(parentID) as any;

  /* **** Text Related Types **** */

  if (componentJSON?.type === 'text') {
    return 'Text';
  }

  if (componentJSON?.type === 'composite' && componentJSON?.sub_type === 'text_composite') {
    return 'Textbox';
  }

  /* **** Image Related Types **** */

  if (componentJSON?.type === 'image') {
    switch (parentJSON?.sub_type) {
      case 'pre_video_asset_composite':
        return 'Background';
      case 'video_composite':
        if (componentJSON?.sub_type === 'gif_video') {
          return 'Sticker';
        }
        return componentHasFill(componentJSON) ? 'Shape' : 'ShapeWithoutFill';
      case 'bg_media_composite':
        if (getMaskComponentFromComposite(parentJSON)) {
          return 'ImageMask';
        }
        return 'Image';
      case 'icon_composite':
        return 'StickerImage';
      case 'left_overlay_asset_composite':
      case 'video_asset_composite':
        return 'Overlay';
      case 'widget_composite':
        return 'Image';
      case 'image_composite':
        return 'ImageComposite';
      default:
        return 'Image';
    }
  }

  /* **** Video Related Types **** */
  if (componentJSON?.type === 'video') {
    switch (parentJSON?.sub_type) {
      case 'pre_video_asset_composite':
        return 'Background';
      case 'bg_media_composite':
        if (getMaskComponentFromComposite(parentJSON)) {
          return 'VideoMask';
        }
        return 'Video';
      case 'video_composite':
        if (componentJSON?.sub_type === 'gif_video') {
          return 'Sticker';
        }
        return 'Shape';
      case 'left_overlay_asset_composite':
      case 'video_asset_composite':
      case 'overlay_asset_composite':
        return 'Overlay';
      case 'widget_composite':
        return 'Video';
      default:
        return 'Video';
    }
  }

  /* **** Group Related Types **** */
  if (componentJSON?.type === 'composite' && componentJSON?.sub_type === 'position_composite') {
    return 'Group';
  }

  /* **** Audio Related Types **** */
  if (componentJSON.id?.includes('voice_over')) {
    return 'VOTTS';
  }

  if (componentJSON?.type === 'audio' && componentJSON?.sub_type === 'bg_music') {
    return 'Audio';
  }

  if (componentJSON?.type === 'audio' && componentJSON?.sub_type === 'voice_over') {
    return 'VO';
  }

  return null;
};

export default getTopPanelComponentType;

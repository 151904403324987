import { Master, Shadow } from '../../jsonTypes';
import * as componentType from './getCompositeType';
import { DEFAULT_FIXED_BACKGROUND_URL } from '../constants';
import uuid from './uuid';

const butLast = <T>(array: T[]): T[] => array.slice(0, -1);
const tail = <T>([, ...tail]: T[]) => tail;

export const getBaseComponentFromParentJSON = (componentJSON: Master.CompositeJSON) => {
  // All the composites which are themselves to be selected
  if (componentType.isGroupComposite(componentJSON) || componentJSON.sub_type === 'voice_over') {
    return componentJSON;
  }

  // Text
  const components = componentJSON.components;
  if (componentType.isTextComposite(componentJSON)) {
    for (let i = 0; i < components.length; i++) {
      const baseComponent = components[i];
      if (componentType.isTextComponent(baseComponent)) {
        return baseComponent;
      }
    }
  }

  // Others
  if (!componentType.isTextComposite(componentJSON)) {
    for (let i = 0; i < components.length; i++) {
      const baseComponent = components[i];
      if (
        componentType.isImageComponent(baseComponent) ||
        componentType.isVideoComponent(baseComponent)
      ) {
        return baseComponent;
      }
    }
  }

  // for blocks
  return componentJSON;
};

export const getSubComponents = (component: Shadow.AnyJSON | Shadow.DimensionJSON) => {
  if ('components' in component) return component.components;
  if ('blocks' in component) return component.blocks;
  return [];
};

export const getCompositeIdFromChildId = (componentId: string) => {
  // in the case of audio block there is not scene or composite id
  if (!componentId.match(/[.]/)) return componentId;
  const [, sceneId, compositeId] = componentId.split('.');
  return `.${sceneId}.${compositeId}`;
};

export const updateId = (component: Shadow.AnyJSON, parentId: string) => {
  const id = `${parentId}.${uuid()}`;
  component.id = id;

  getSubComponents(component).forEach((c) => {
    updateId(c, id);
  });
};

export const getCompositeThumbnail = (component: Shadow.CompositeJSON) => {
  const baseComponent = getBaseComponentFromParentJSON(component);
  if ('thumbnail_url' in baseComponent && baseComponent.thumbnail_url) {
    return baseComponent.thumbnail_url;
  }

  if (baseComponent.type === 'image' && 'url' in baseComponent && baseComponent.url) {
    return baseComponent.url;
  }

  return null;
};

export const getParentId = (componentId: string) => {
  const parentId = butLast(tail(componentId.split('.')).map((id) => `.${id}`)).join('');
  return parentId || '.';
};

export const getSubsequentBlockIds = (
  blockId: string,
  dimension: Shadow.DimensionJSON,
): string[] => {
  const index = dimension.blocks.findIndex((it) => it.id === blockId);
  return dimension.blocks.slice(index + 1).map((it) => it.id);
};

export const getDefaultTransition = (dimension: Shadow.DimensionJSON) => {
  if (dimension.default_transition) {
    return dimension.default_transition;
  }
  return 0;
};

/*
  Remove any extra background composite and sanitize the properties,
*/
export function sanitizeBackgroundComposite(masterJSON: Master.JSON): Master.JSON {
  // if (Store.user.isDesignRole) return masterJSON;

  const reset = (
    component: Master.ComponentJSON,
    absolute_start: number,
    absolute_end: number,
    absolute_duration: number,
  ) => {
    component.duration = {
      ...component.duration,
      absolute_start: absolute_start,
      absolute_end: absolute_end,
      absolute_duration: absolute_duration,
      start_time: 0,
      end_time: 1,
    };

    component.position = {
      ...component.position,
      top_x: 0,
      top_y: 0,
      bottom_x: 1,
      bottom_y: 1,
    };

    component.animation = {
      ...component.animation,
      animation: 'None',
      animation_in: 'None',
      animation_out: 'None',
    };
  };

  const dimensionJSON = masterJSON['16:9'] ?? masterJSON['1:1'] ?? masterJSON['9:16'];
  if (!dimensionJSON) return masterJSON;

  dimensionJSON.blocks
    .filter((block) => block.type !== 'audio')
    .forEach((block) => {
      const backgroundComposites = block.components.filter(
        componentType.isInvisibleBackgroundComposite,
      );
      if (backgroundComposites.length > 1) {
        // remove all extra pre_video_asset_composite
        backgroundComposites.splice(0, backgroundComposites.length - 1);

        block.components = [
          ...backgroundComposites,
          ...block.components.filter((it) => !componentType.isInvisibleBackgroundComposite(it)),
        ];
      }

      const backgroundComposite = block.components.find(
        componentType.isInvisibleBackgroundComposite,
      );

      if (!backgroundComposite) return;

      backgroundComposite.compositeType = Master.CompositeType.CANVAS_BACKGROUND;
      backgroundComposite.name = 'Clean 1';
      backgroundComposite.layer_name = 'Background 1';
      const { absolute_start, absolute_end, absolute_duration } = block.duration;
      reset(backgroundComposite, absolute_start, absolute_end, absolute_duration);
      backgroundComposite.components.forEach((c) => {
        reset(
          c as Master.ImageJSON | Master.VideoJSON,
          absolute_start,
          absolute_end,
          absolute_duration,
        );
        c.type = 'image';
        c.sub_type = 'image_asset';
        (c as Master.ImageJSON).url = DEFAULT_FIXED_BACKGROUND_URL;
        (c as Master.ImageJSON).thumbnail_url = DEFAULT_FIXED_BACKGROUND_URL;
        (c as Master.ImageJSON).layout.layer_properties.opacity = 1;
      });
    });
  return masterJSON;
}

import * as ShadowRegistry from '../shadowRegistry';
import { Master, Shadow, Slate } from '../../jsonTypes';
// Check out 'audio-block-manager.ts' for the original code this was copied from

const DEFAULT_FADE_TIME = 0.5;

const getAllAudioIntervals = (
  audioBlocks: Array<Shadow.AudioBlockJSON | Master.AudioBlockJSON>,
): Array<Array<number>> => {
  /**
   * returns an Array of Array where each array represents [absolute_start, absolute_end]
   */
  const audioIntervals = [];
  for (let i = 0; i < audioBlocks.length; i++) {
    const audioComp = audioBlocks[i];
    let duration = audioComp.duration;
    if (audioComp.sub_type === Slate.AUDIO_TYPE.BG_VIDEO) {
      const videoComponent = ShadowRegistry.getShadowComponentById(
        audioComp.component_ref_id || '',
      );
      if (videoComponent) duration = videoComponent.duration;
    }
    if (duration) {
      const interval = [duration.absolute_start, duration.absolute_end];
      audioIntervals.push(interval);
    }
  }
  return audioIntervals;
};

const getAudioIntervalsFlattened = (audioIntervals: Array<Array<number>>): Array<any> => {
  /**
   * @param audioInterval list of durations of all audioBlocks
   * @return a 1-d sorted array of start and end times marked with 'S' and 'E denotes Start, End
   */

  const flattenedList = [];
  for (let i = 0; i < audioIntervals.length; i++) {
    const interval = audioIntervals[i];
    const startTime = { value: interval[0], type: 'start' };
    const endTime = { value: interval[1], type: 'end' };
    flattenedList.push(startTime, endTime);
  }

  const sortedFlattenedList = flattenedList.sort((first, second) => {
    if (first.value <= second.value) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortedFlattenedList;
};

const getOverlappedIntervals = (flattenedList: Array<any>): Array<Array<number>> => {
  /**
   * iterate through flattened list
   * keep a track of processed_start and processed_end
   * at each checkpoint check if it's a Start, if yes check diff of processed_start and processed_end
   * if diff > 1, the overlap starts from this point
   * at each checkpoint if it's an end, check if the diff of processed_start and processed_end = 1
   * if diff == 1 then overlap has finished store the overlapDuration and reset overlapStart, overlapEnd
   * continue this until the end of the array
   */
  const overlappedIntervals = [];
  let overlapStart = null,
    overlapEnd = null,
    processedStart = 0,
    processedEnd = 0;

  for (let i = 0; i < flattenedList.length; i++) {
    const interval = flattenedList[i];
    // const checkPoint = element[element.length - 1];

    const checkPoint = interval.type;

    if (checkPoint === 'start') {
      processedStart++;
      if (processedStart - processedEnd > 1 && overlapStart === null) {
        overlapStart = interval.value;
      }
    } else {
      processedEnd++;
      if (processedStart - processedEnd === 1) {
        overlapEnd = interval.value;
        const newOverlapInterval = [overlapStart, overlapEnd];
        overlappedIntervals.push(newOverlapInterval);
        overlapStart = null;
        overlapEnd = null;
      }
    }
  }
  return overlappedIntervals;
};

const splitAudioIntervals = (
  audioListIntervals: Array<Array<number>>,
  overlappedIntervals: Array<Array<number>>,
  highVolumeState: number,
  lowVolumeState: number,
): Array<Array<number>> => {
  /**
   * Iterate through overlappedIntervals which is always in ascending order
   * splice the last index of audioListIntervals (We do this because since the overlappedIntervals is in ascending order
   *                                               the split will always happen for the last audioListInterval)
   * continue if no overlap happens also break if overlapStart has passed the audio duration end
   * if overlap is present
   * overlapping section is basically [max(both the starts), min(both the ends)]
   * adding to this logic if audioStart is less than maxStart then that becomes one section where overlap doesn't happen
   * also if audioEnd > minEnd, even in this section overlap doesn't happen
   * so basically the list looks something like this
   * [non overlapping section if present, overlapping section always present, non overlapping section if present]
   * if another overlapping section appears, the last non overlapping section is spliced and the same process is repeated
   */

  for (let i = 0; i < overlappedIntervals.length; i++) {
    /**
     * overlapping start and end time
     */
    const [overlapStart, overlapEnd] = overlappedIntervals[i];

    /**
     * last audioInterval start and end time
     */
    const lastAudioInterval = audioListIntervals.splice(-1)[0];
    const [audioStart, audioEnd] = lastAudioInterval;

    if (overlapEnd < audioStart) {
      /**
       * no overlap
       */
      audioListIntervals.push(lastAudioInterval);
      continue;
    } else if (overlapStart > audioEnd) {
      /**
       * overlap has passed the audio duration
       */
      audioListIntervals.push(lastAudioInterval);
      break;
    } else {
      /**
       * get max(both starts), min(both ends) since that is the overlapping section
       */
      const maxStart = Math.max(overlapStart, audioStart);
      const minEnd = Math.min(overlapEnd, audioEnd);

      /**
       * check if a section is present before the overlapping starts
       * if yes, add a section with highVolumeState until maxStart
       */
      if (audioStart < maxStart) {
        audioListIntervals.push([audioStart, maxStart, highVolumeState]);
      }

      /**
       * overlapping section
       * add a section with lowVolumeState
       */
      audioListIntervals.push([maxStart, minEnd, lowVolumeState]);

      /**
       * check if a section is present after the overlapping is finished
       * add Section with highVolumeState
       */
      if (audioEnd > minEnd) {
        audioListIntervals.push([minEnd, audioEnd, highVolumeState]);
      }
    }
  }
  return audioListIntervals;
};

export const recalculateAudioList = (
  audioBlocks: Array<Shadow.AudioBlockJSON | Master.AudioBlockJSON>,
) => {
  const audioIntervals = getAllAudioIntervals(audioBlocks);

  const audioIntervalFlattened = getAudioIntervalsFlattened(audioIntervals);

  const overlappedIntervals = getOverlappedIntervals(audioIntervalFlattened);

  /**
   * At this point we have all the overlapping durations, now we just need to split audio_list of each audioBlocks based on this overlapping intervals
   */
  for (let i = 0; i < audioBlocks.length; i++) {
    const audioCmp = audioBlocks[i];
    let duration = audioCmp.duration;
    let remainingFadeInTime = audioCmp['fade_in_time'];
    const remainingFadeOutTime = audioCmp['fade_out_time'];
    let finalFadeOutStart: number;
    if (audioCmp.sub_type === Slate.AUDIO_TYPE.BG_VIDEO) {
      if (!audioCmp.component_ref_id) {
        continue;
      }
      const bgVideoComponent = ShadowRegistry.getShadowComponentById(audioCmp.component_ref_id);
      if (!bgVideoComponent) continue;
      duration = bgVideoComponent.duration;
      finalFadeOutStart = duration['absolute_end'] - audioCmp['fade_out_time'];
    } else {
      finalFadeOutStart = audioCmp['duration']['absolute_end'] - audioCmp['fade_out_time'];
    }
    /**
     * initialAudioInterval takes the entire duration, and high state volume
     */
    let audioListIntervals = [];

    if (duration) {
      audioListIntervals.push([
        duration.absolute_start,
        duration.absolute_end,
        audioCmp.audio_volume,
      ]);
    }

    /**
     * now splitting the audioListIntervals if an overlapping interval is present within this duration
     */
    audioListIntervals = splitAudioIntervals(
      audioListIntervals,
      overlappedIntervals,
      audioCmp.audio_volume,
      audioCmp.bg_volume,
    );

    /**
     * creating audio_list object
     */
    const newAudioList: Array<Master.AudioListItemJSON> = [];
    for (let j = 0; j < audioListIntervals.length; j++) {
      const newInterval = audioListIntervals[j];
      const absoluteStart = newInterval[0];
      const absoluteEnd = newInterval[1];
      const absoluteDuration = absoluteEnd - absoluteStart;
      let fadeTime: number;
      let fadeStartTime: number;
      if (remainingFadeInTime > 0) {
        fadeStartTime = absoluteStart;
        fadeTime = Math.min(remainingFadeInTime, absoluteDuration);
        remainingFadeInTime -= fadeTime;
      } else if (finalFadeOutStart < absoluteStart) {
        // final fade has started before the last audio list
        fadeTime = absoluteStart - finalFadeOutStart;
        fadeStartTime = finalFadeOutStart;
        finalFadeOutStart = absoluteStart;
      } else {
        fadeTime = DEFAULT_FADE_TIME;
        fadeStartTime = absoluteStart - DEFAULT_FADE_TIME / 2;
        if (j === 0) {
          fadeTime = 0;
          fadeStartTime = absoluteStart;
        } else if (fadeStartTime < 0) {
          fadeTime = fadeTime + fadeStartTime;
          fadeStartTime = 0;
        }
      }
      newAudioList.push({
        absolute_start: absoluteStart,
        absolute_end: absoluteEnd,
        absolute_duration: absoluteDuration,
        audio_volume: newInterval[2],
        fade_time: fadeTime,
        fade_start: fadeStartTime,
      });
    }
    audioCmp.audio_list = newAudioList;
  }
};

export const publishAudioMutations = () => {
  recalculateAudioList(ShadowRegistry.getShadowDimensionJson().audio_blocks);
};

import { Skia, SkiaMetrics, TextBoxStyle } from '.';
import { Master, Shadow } from '../../jsonTypes';
import { Store } from '../../store';
import { cloneJSON } from '../../utils';
import {
  getAnimationGranularity,
  ParentData,
  parseParagraphStyle,
  relativeToAbsolutePosition,
} from '../masterCleaner';
import { LineMetrics, Paragraph } from './canvaskitTypes';
import { FontAsset, Padding, Size, StyledTextChunk, StyledTextChunks } from './skiaTypes';

type ParentSize = ParentData['size'];

const fontPromises: { [fontName: string]: Promise<void> } = {};
export const NEWLINE_FOR_WRAP = '\n_FOR_WRAP';
export const MAX_FONT_SIZE = 999;

export function drawText(
  size: Size,
  padding: Padding,
  canvas: HTMLCanvasElement,
  paragraph: Paragraph,
): HTMLCanvasElement {
  const { width, height } = size;
  const { w, h } = {
    w: width + padding.h,
    h: height + padding.v,
  };

  const CK = window.CanvasKit;
  canvas.height = h;
  canvas.width = w;
  const surface = CK.MakeSWCanvasSurface(canvas);
  surface?.getCanvas().drawParagraph(paragraph, padding.h / 2, padding.v / 2);
  surface?.flush();
  surface?.dispose();

  if ((window as any).SKIA_DEBUG === 1) {
    const ctx = canvas.getContext('2d');
    ctx?.beginPath();
    ctx!.lineWidth = 2;
    ctx!.strokeStyle = 'red';
    ctx?.rect(padding.h / 2, 0, width, height);
    ctx?.rect(0, 0, width / 2, height / 2);
    ctx?.rect(width / 2, height / 2, width, height);
    ctx?.stroke();
    console.log('SKIA_DEBUG', canvas.toDataURL());
  }

  return canvas;
}

export function makeStyledTextChunks(htmlString: string): StyledTextChunks {
  const richText = htmlString.replaceAll('\n', '<br>').replaceAll('\r', '<br>');
  const richTextChunks: StyledTextChunks = [
    {
      text: richText,
      type: 'default',
    },
  ];

  const SPLIT_STR = '21c9434ce78c03be0bd1ed913ea4b13140a9c26b8e73a74b02478f3c68f9e765';
  const HIGHLIGHT_SPLIT_STR = 'b57236246779f72cc87f1d4210606694e12a4e0aa97046415e2b43ff7942c34e';
  const domParser = new DOMParser();

  const parseHtml = (s: string) => {
    const doc = domParser.parseFromString(s, 'text/html');
    let leadingSpaces = '';

    if (doc.body.innerText.length !== 0) {
      leadingSpaces = s.length > 0 ? ' '.repeat(s.search(/\S/)) : '';
    } else {
      // only blank spaces
      leadingSpaces = s.trim().length === 0 ? s : '';
    }
    return {
      text: leadingSpaces + doc.body.innerText,
      html: doc.body.innerHTML,
      htmlWithLeadingSpace: leadingSpaces + doc.body.innerHTML,
    };
  };

  const parseHTMLFontTag = (richTextChunks: StyledTextChunks): StyledTextChunks => {
    const parsedText: string = richTextChunks[0].text
      .replace(/<font[^>]*>/g, '')
      .replace(/<\/font>/g, '');
    richTextChunks[0].text = parsedText;
    return richTextChunks;
  };
  const parseHighlights = (richTextChunks: StyledTextChunks): StyledTextChunks => {
    const parsed: StyledTextChunks = [];
    richTextChunks.forEach((chunk) => {
      const parts = chunk.text
        .replace(/<span[^>]*>/g, SPLIT_STR + HIGHLIGHT_SPLIT_STR)
        .replace(/<\/span>/g, SPLIT_STR)
        .split(SPLIT_STR)
        .filter((str) => str !== '');

      parts.forEach((part) => {
        if (part.startsWith(HIGHLIGHT_SPLIT_STR)) {
          parsed.push({
            text: parseHtml(part.split(HIGHLIGHT_SPLIT_STR)[1]).htmlWithLeadingSpace,
            type: 'highlight',
          });
        } else {
          parsed.push({
            text: parseHtml(part).htmlWithLeadingSpace,
            type: 'default',
          });
        }
      });
    });
    return parsed;
  };

  const parseNewLines = (richTextChunks: StyledTextChunks): StyledTextChunks => {
    const parsed: StyledTextChunks = [];
    richTextChunks.forEach((chunk) => {
      const { text, html, htmlWithLeadingSpace } = parseHtml(chunk.text);
      if (
        html.indexOf('<br') === -1 && // change to regex check
        html.indexOf('\n') === -1 // change to regex check
      ) {
        chunk.text = text;
        parsed.push(chunk);
        return;
      }

      const parts = htmlWithLeadingSpace
        .replace(/[\n\r]/g, SPLIT_STR)
        .replace(/<br[^>]*>/g, SPLIT_STR + '\n' + SPLIT_STR)
        .split(SPLIT_STR)
        .filter((part) => part === '\n' || part.trim().length > 0);
      parts.map((part) => {
        parsed.push({
          text: parseHtml(part).text,
          type: chunk.type,
        });
      });
    });
    return parsed;
  };

  const mergeSimilarChunkTypes = (richTextChunks: StyledTextChunks) => {
    return richTextChunks.reduce((acc: StyledTextChunks, curr: StyledTextChunk) => {
      if (
        acc.length > 0 &&
        curr.text !== '\n' &&
        acc[acc.length - 1].text !== '\n' &&
        acc[acc.length - 1].type === curr.type
      ) {
        acc[acc.length - 1].text += curr.text;
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
  };

  let parsedTextChunks = parseHTMLFontTag(richTextChunks);
  parsedTextChunks = parseHighlights(parsedTextChunks);
  parsedTextChunks = parseNewLines(parsedTextChunks);
  parsedTextChunks = mergeSimilarChunkTypes(parsedTextChunks);
  parsedTextChunks = parsedTextChunks.filter((chunk) => chunk.text !== '');
  return parsedTextChunks;
}

export function isSurrogateChar(str: string): boolean {
  /*
    👍 : thumbs up
      Unicode: U+1F44D,
      UTF-8: F0 9F 91 8D
    👍🏻 : thumbs up - variant
      Unicode: U+1F44D U+1F3FB, (the 2nd unicode is modifier/surrogate)
      UTF-8: F0 9F 91 8D F0 9F 8F BB
  */
  return /[\u{1F3FB}\u{1F3FC}\u{1F3FD}\u{1F3FE}\u{1F3FF}]/u.test(str);
}

export function fixSoftBreaks(styledTextChunks: StyledTextChunks): StyledTextChunks {
  return styledTextChunks.map((chunk) => {
    if (chunk.text === NEWLINE_FOR_WRAP) chunk.text = '\n';
    return chunk;
  });
}

export function sanitizeLineMetrics(plainText: string, lineMetrics: LineMetrics[]): LineMetrics[] {
  const chars = Array.from(plainText);
  const utf16Lengths = chars.map((c) => {
    const charCode = c.charCodeAt(0);
    if (charCode < 128) return 1;
    if (charCode < 256) return 2;
    return 3;
  });
  let actualStartIndexInLine = 0;
  let excessLength = 0;
  const sanitizedLineMetrics: LineMetrics[] = lineMetrics.map((lineMetric) => {
    const totalutf16LengthOfLine = lineMetric.endIncludingNewline - lineMetric.startIndex;
    let totalLengthCounted = 0;
    let newLineMetric = { ...lineMetric };
    for (let index = actualStartIndexInLine; index < utf16Lengths.length; index++) {
      const currentCharLength = utf16Lengths[index];
      totalLengthCounted += currentCharLength;
      if (totalLengthCounted === totalutf16LengthOfLine) {
        const expectedLength = index - actualStartIndexInLine + 1;
        excessLength += totalLengthCounted - expectedLength;
        newLineMetric = {
          ...newLineMetric,
          startIndex: actualStartIndexInLine,
          endExcludingWhitespaces: lineMetric.endExcludingWhitespaces - excessLength,
          endIncludingNewline: lineMetric.endIncludingNewline - excessLength,
          endIndex: lineMetric.endIndex - excessLength,
        };
        actualStartIndexInLine = newLineMetric.endIndex;
        totalLengthCounted = 0;
        break;
      }
    }
    return newLineMetric;
  });
  return sanitizedLineMetrics;
}

export function addSoftBreaksForWrap(
  styledTextChunks: StyledTextChunks,
  lineMetrics: LineMetrics[],
): StyledTextChunks {
  const wrappedChunks: StyledTextChunks = [];
  let currentChunkIndex = 0;
  const numChunks = styledTextChunks.length;
  const numLines = lineMetrics.length;
  let startIndexInChunk = 0;
  const plainText = styledTextChunks.reduce((prev, curr) => prev + curr.text, '');
  const sanitizedLineMetrics = sanitizeLineMetrics(plainText, lineMetrics);
  sanitizedLineMetrics.forEach((lineMetric, lineNumber) => {
    const { endIndex, startIndex, isHardBreak } = lineMetric;
    const hardBreak = isHardBreak ? 1 : 0;
    const isLastLine = () => lineNumber === numLines - 1;
    const lineLength = endIndex - startIndex - hardBreak + (isLastLine() ? 1 : 0);
    let numCharsParsedForLine = 0;
    while (currentChunkIndex < numChunks) {
      const currentChunk = styledTextChunks[currentChunkIndex];
      const isLastChunkInLine =
        currentChunkIndex === styledTextChunks.length - 1 ||
        styledTextChunks[currentChunkIndex + 1].text === '\n';
      const text = currentChunk.text;
      if (text === '\n') {
        wrappedChunks.push({
          text: text,
          type: currentChunk.type,
        });
        currentChunkIndex++;
        numCharsParsedForLine = 0;
        break;
      }
      const chunkLength = text.length;
      const numCharsRemainingInChunk = chunkLength - startIndexInChunk;

      if (numCharsRemainingInChunk + numCharsParsedForLine <= lineLength - hardBreak) {
        wrappedChunks.push({
          text: text.substr(startIndexInChunk, numCharsRemainingInChunk),
          type: currentChunk.type,
        });
        numCharsParsedForLine += numCharsRemainingInChunk;
        startIndexInChunk = 0;
        currentChunkIndex++;
      } else {
        const numCharsToSplit = lineLength - numCharsParsedForLine;
        wrappedChunks.push({
          text: text.substr(startIndexInChunk, numCharsToSplit),
          type: currentChunk.type,
        });
        wrappedChunks.push({
          text: isHardBreak ? '\n' : NEWLINE_FOR_WRAP,
          type: currentChunk.type,
        });
        if (startIndexInChunk + numCharsToSplit === chunkLength) {
          startIndexInChunk = 0;
          currentChunkIndex += 1 + (isLastChunkInLine ? hardBreak : 0);
        } else {
          startIndexInChunk += numCharsToSplit;
        }
        break;
      }
    }
  });
  let fonundNewLine = false;
  const filteredWrappedChunks = wrappedChunks.filter((chunk) => {
    if (chunk.text === '') return false;
    if (chunk.text === NEWLINE_FOR_WRAP) {
      if (fonundNewLine) return false;
      else {
        fonundNewLine = true;
        return true;
      }
    }
    fonundNewLine = false;
    return true;
  });
  return filteredWrappedChunks;
}

export function fontPromiseFor(
  fontPromises: { [fontName: string]: Promise<void> },
  fontName: string,
): Promise<void> {
  if (fontPromises[fontName] === null || fontPromises[fontName] === undefined) {
    fontPromises[fontName] = fetch(
      `https://s3.ap-south-1.amazonaws.com/invideo-block-assets/fonts${fontName}`,
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }

        return res;
      })
      .then((res) => res.arrayBuffer())
      .then((data): FontAsset => ({ src: fontName, data: new Uint8Array(data) }))
      .then((fontAsset) => Skia.singleton.registerFonts([fontAsset]));
  }
  return fontPromises[fontName];
}

declare const CanvasKitInit: any;
export function loadCanvasKit() {
  if (!(window as any).CanvasKitPromise) {
    // eslint-disable-next-line no-undef
    (window as any).CanvasKitPromise = CanvasKitInit({
      locateFile: () =>
        'https://d1nc6vzg2bevln.cloudfront.net/canvaskit-wasm/production/v3-stage/br/canvaskit.br',
    }).then((CK: any) => {
      (window as any).CanvasKit = CK;
      return CK;
    });
  }
  return (window as any).CanvasKitPromise;
}

export async function calcTextMetricsForComposite(
  composite: Master.ComponentJSON,
  parentData: ParentSize,
  forceCalculate = true,
): Promise<Master.TextJSON[]> {
  let textComponents: Master.TextJSON[] =
    (composite.components?.filter(
      (comp) => (comp as any as Master.ComponentJSON).type === 'text',
    ) as Master.TextJSON[]) ?? [];
  textComponents = textComponents.filter(
    (comp) => (!comp.metrics || forceCalculate) && comp.highlighted_text.trim() !== '',
  );
  const measuredTextComponents = await Promise.all(
    textComponents.map((comp) => calcTextMetricsForComponent(comp as Master.TextJSON, parentData)),
  );
  if (textComponents.length === 1) {
    return measuredTextComponents;
  }
  // measuredTextComponents are result of first Skia pass
  // We then check the maximum font size needed for all sibling text components
  // and recalculate grains for all sibling components
  const maxFontSize = measuredTextComponents
    .map((comp) => comp.metrics.style.font_size)
    .reduce((min, curr) => Math.min(min, curr), MAX_FONT_SIZE);
  return await Promise.all(
    measuredTextComponents
      .filter((comp) => comp.metrics.style.font_size !== maxFontSize)
      .map(async (comp) => await calcTextMetricsForComponent(comp, parentData, maxFontSize)),
  );
}

export function calcTextMetricsForComponent(
  textComponent: Master.TextJSON,
  parentData: ParentSize,
  maxFontSize: number = MAX_FONT_SIZE,
): Promise<Master.TextJSON> {
  const paragraph = textComponent;
  const originalFontSize = paragraph.layout.font_size * 0.98;
  const style = parseParagraphStyle(paragraph as Master.TextJSON);
  const { top_x, top_y, bottom_x, bottom_y } = relativeToAbsolutePosition(
    parentData,
    paragraph as Shadow.ComponentJSON,
  );
  const width = bottom_x - top_x;
  const height = bottom_y - top_y;

  const hasBandColor = style.defaultTextStyle.backgroundColor.a !== 0;
  const textBoxStyle: TextBoxStyle = {
    height: height,
    width: width,
    paddingX: ((paragraph.layout.xpad ?? 0) / 2) * width,
    paddingY: ((paragraph.layout.ypad ?? 0) / 2) * height,
    bandPaddingX: hasBandColor ? paragraph.layout.horizontal_band_factor ?? 0.1 : 0,
    bandPaddingY: hasBandColor ? paragraph.layout.vertical_band_factor ?? 0.1 : 0,
  };

  const parse = () => {
    return Promise.all(
      [style.defaultTextStyle.font, style.highlightTextStyle.font, ...style.fallbackFonts].map(
        (font) => fontPromiseFor(fontPromises, font),
      ),
    ).then(() => {
      const skiaMetrics = new SkiaMetrics(
        paragraph.highlighted_text,
        style,
        textBoxStyle,
        getAnimationGranularity(paragraph),
        maxFontSize,
      );
      try {
        const { size, grains, style } = skiaMetrics.measure();
        const fontSize = style.defaultTextStyle.fontSize;

        if (
          !paragraph.layout.fixed &&
          originalFontSize - (originalFontSize % 3) !== fontSize / 0.98
        ) {
          paragraph.layout.font_size = Math.round(fontSize / 0.98);
        }

        paragraph.metrics = { size, grains, style: { font_size: fontSize } };
        return paragraph;
      } catch (error) {
        if ((window as any).SKIA_DEBUG) {
          const data = { paragraph };
          console.log(data);
          debugger;
        }
        throw new Error('Skia metrics error');
      }
    });
  };
  return (window as any).CanvasKit ? parse() : loadCanvasKit().then(parse);
}

const getDimJSON = (json: Master.JSON) => json['16:9'] ?? json['1:1'] ?? json['9:16'];
export type TextRef = { comp: Master.ComponentJSON; size: ParentSize };
export type TextRefs = TextRef[];

export function extractTextCompositeRefs(
  dimensionJSON: Master.DimensionJSON,
  screenSize?: [number, number],
): TextRefs {
  const textRefs: TextRefs = [];
  const shouldExtractTextComposite = (comp: Master.TextJSON) => {
    return !comp.metrics;
  };
  const [w, h] = screenSize ?? [0, 0];
  const sceneSize: ParentSize = { w, h };

  dimensionJSON.blocks.forEach((block) => {
    block.components.forEach((composite) => {
      if (
        composite.sub_type === 'text_composite' &&
        shouldExtractTextComposite(composite as unknown as Master.TextJSON)
      ) {
        const parentData = calcSize(sceneSize, composite as Master.CompositeJSON);
        textRefs.push({ comp: composite as Master.ComponentJSON, size: parentData });
      } else if (composite.sub_type === 'position_composite') {
        const parentData = calcSize(sceneSize, composite as Master.CompositeJSON);
        const positionComposite = composite;
        positionComposite.components?.forEach((textComp) => {
          if (
            (textComp as Master.CompositeJSON).sub_type === 'text_composite' &&
            shouldExtractTextComposite(textComp as Master.TextJSON)
          ) {
            const posParentData = calcSize(parentData, textComp as Master.CompositeJSON);
            textRefs.push({
              comp: textComp as Master.ComponentJSON,
              size: posParentData,
            });
          }
        });
      }
    });
  });
  return textRefs;
}

/* Calculates grains and modifies composite size, if needed.
 */
export async function recalculateGrains({
  json,
  parent,
  maxFontSize = MAX_FONT_SIZE,
}: {
  json: Master.TextJSON;
  parent?: Master.CompositeJSON;
  maxFontSize?: number;
}): Promise<Master.CompositeJSON> {
  if (json.type !== 'text') {
    throw Error('recalculateGrains: Expected `Master.TextJSON`');
  }
  const originalFontSize = json.layout.font_size * 0.98;
  const textId = json.id;
  const parentId = parent ? parent.id : textId.substr(0, textId.lastIndexOf('.'));
  const parentComposite = cloneJSON(
    parent ?? Store.project.getComponentById(parentId),
  ) as Master.CompositeJSON;

  const paragraphIndex = parentComposite.components.findIndex((c) => c.id === textId);
  const paragraph = cloneJSON(json) as Master.TextJSON;
  parentComposite.components.splice(paragraphIndex, 1, paragraph);

  const sceneSize = getSceneSize();

  // if text is in position composite, get position composite size
  const parentOfParentId = parentComposite.id.substr(0, parentId.lastIndexOf('.'));
  const parentOfParentComponent = Store.project.getComponentById(parentOfParentId);
  const isPositionComposite = parentOfParentComponent?.sub_type === 'position_composite';

  const parentComponentSize = isPositionComposite
    ? getAbsoluteData(sceneSize, parentOfParentComponent as Master.ComponentJSON)
    : sceneSize;

  const parentData = getAbsoluteData(parentComponentSize, parentComposite);
  const parentSize = { w: parentData.w, h: parentData.h };

  const style = parseParagraphStyle(paragraph as Master.TextJSON);
  const textBoxData = getAbsoluteData(parentSize, paragraph);

  const hasBandColor = style.defaultTextStyle.backgroundColor.a !== 0;
  const textBoxStyle: TextBoxStyle = {
    height: textBoxData.h,
    width: textBoxData.w,
    paddingX: ((paragraph.layout.xpad ?? 0) / 2) * textBoxData.w,
    paddingY: ((paragraph.layout.ypad ?? 0) / 2) * textBoxData.h,
    bandPaddingX: hasBandColor ? paragraph.layout.horizontal_band_factor ?? 0.1 : 0,
    bandPaddingY: hasBandColor ? paragraph.layout.vertical_band_factor ?? 0.1 : 0,
  };

  const adjustCompositeHeight = (expectedSize: { width: number; height: number }) => {
    const avaiableHeightInTextBox = textBoxData.h - textBoxStyle.paddingY;
    if (expectedSize.height <= avaiableHeightInTextBox) {
      // text fits within textbox
      return;
    }
    // (tBy - tTy) * newParentHeight = expectedHeight + (newParentHeight * paddingY)
    // Therefore, newParentHeight = expectedHeight / (tBy - tTy - paddingY)
    const textRelative = paragraph.position;
    const expectedParentHeight =
      expectedSize.height / (textRelative.bottom_y - textRelative.top_y - paragraph.layout.ypad);
    const expectedParentBottom = parentData.y + expectedParentHeight;
    if (expectedParentHeight >= parentComponentSize.h) {
      if (!isPositionComposite) parentComposite.position.top_y = 0;
      parentComposite.position.bottom_y = 1;
      return;
    }
    if (expectedParentBottom <= parentComponentSize.h) {
      parentComposite.position.bottom_y = expectedParentBottom / parentComponentSize.h;
    } else {
      if (!isPositionComposite)
        parentComposite.position.top_y = 1 - expectedParentHeight / parentComponentSize.h;
      parentComposite.position.bottom_y = 1;
    }
  };

  const parse = (): Promise<Master.CompositeJSON> => {
    return Promise.all(
      [style.defaultTextStyle.font, style.highlightTextStyle.font, ...style.fallbackFonts].map(
        (font) => fontPromiseFor(fontPromises, font),
      ),
    ).then(() => {
      const skiaMetrics = new SkiaMetrics(
        paragraph.highlighted_text,
        style,
        textBoxStyle,
        getAnimationGranularity(paragraph),
        maxFontSize,
      );
      try {
        const { size: expectedSize, grains, style } = skiaMetrics.measure();
        const fontSize = style.defaultTextStyle.fontSize;

        paragraph.metrics = { size: expectedSize, grains, style: { font_size: fontSize } };

        if (paragraph.layout.fixed) {
          adjustCompositeHeight(expectedSize);
        } else {
          if (originalFontSize - (originalFontSize % 3) !== fontSize / 0.98) {
            paragraph.layout.font_size = Math.round(fontSize / 0.98);
          }
        }

        return parentComposite;
      } catch (error) {
        if ((window as any).SKIA_DEBUG) {
          const data = { paragraph };
          console.log(data);
          debugger;
        }
        throw new Error(
          'Skia metrics error:\n' +
          `id:${paragraph.id}\n` +
          `text:${paragraph.highlighted_text}\n` +
          `plain text:${paragraph.text}`,
        );
      }
    });
  };
  return (window as any).CanvasKit ? parse() : loadCanvasKit().then(parse);
}

export async function reCalcGrainsForComponent(
  dimJson: Shadow.DimensionJSON,
  targetComponent: Master.ComponentJSON,
) {
  const sceneSize: ParentSize = getSceneSize();
  const promisesArrays: Promise<any>[] = [];
  for (const i in dimJson.blocks) {
    const block = dimJson.blocks[i];
    const blockComponents = block.components ?? [];
    for (const j in blockComponents) {
      const component = blockComponents[j];
      const textComponent = component as Master.ComponentJSON;
      const parentData = calcSize(sceneSize, textComponent);
      if (component.sub_type === 'text_composite' && targetComponent.id === component.id) {
        promisesArrays.push(calcTextMetricsForComposite(targetComponent, parentData, true));
      } else if (component.sub_type === 'position_composite') {
        const positionCompositie =
          targetComponent.sub_type === 'position_composite' && component.id === targetComponent.id
            ? targetComponent
            : component;
        const childComponents = positionCompositie.components ?? [];
        for (const k in childComponents) {
          const childComponent = childComponents[k] as Master.ComponentJSON;
          if (
            childComponent.sub_type === 'text_composite' &&
            childComponent.id.startsWith(targetComponent.id)
          ) {
            const positionCompositeSize = calcSize(parentData, childComponent);
            promisesArrays.push(
              calcTextMetricsForComposite(
                targetComponent.sub_type === 'text_composite' ? targetComponent : childComponent,
                positionCompositeSize,
              ),
            );
          }
        }
      }
    }
  }

  const promises = Array.prototype.concat.apply([], promisesArrays);
  await Promise.all(promises);
  return targetComponent;
}

export async function calcGrainsForComponents(
  components: Master.ComponentJSON[],
  screenSize?: [number, number],
) {
  const [w, h] = screenSize ?? [0, 0];
  const sceneSize: ParentSize = screenSize ? { w, h } : getSceneSize();
  const textComposites = components.filter(
    (component) =>
      component.sub_type === 'text_composite' || component.sub_type === 'position_composite',
  );
  const promisesArrays: Promise<any>[] = [];
  for (const i in textComposites) {
    const component = textComposites[i];
    const parentData = calcSize(sceneSize, component);
    if (component.sub_type === 'text_composite') {
      promisesArrays.push(calcTextMetricsForComposite(component, parentData));
    } else if (component.sub_type === 'position_composite') {
      const childComponents =
        component.components?.filter(
          (childComponent) =>
            (childComponent as Master.ComponentJSON).sub_type === 'text_composite',
        ) ?? [];
      childComponents.map((childComponent) => {
        const comp = childComponent as Master.ComponentJSON;
        const positionCompositeSize = calcSize(parentData, comp);
        promisesArrays.push(calcTextMetricsForComposite(comp, positionCompositeSize));
      });
    }
  }
  const promises = Array.prototype.concat.apply([], promisesArrays);
  await Promise.all(promises);
  return components;
}

export async function calcGrainsForNewBlock(
  block: Master.BlockJSON,
  screenSize?: [number, number],
) {
  const sceneSize: ParentSize = screenSize
    ? { w: screenSize[0], h: screenSize[1] }
    : getSceneSize();
  const promises: Promise<Master.TextJSON[]>[] = [];
  block.components.forEach((composite) => {
    const parentData = calcSize(sceneSize, composite as Master.ComponentJSON);
    if (composite.sub_type === 'text_composite') {
      promises.push(calcTextMetricsForComposite(composite as Master.ComponentJSON, parentData));
    } else if (composite.sub_type === 'position_composite') {
      const positionComposite = composite;
      positionComposite.components?.forEach((textComp) => {
        if ((textComp as Master.CompositeJSON).sub_type === 'text_composite') {
          const posParentData = calcSize(parentData, textComp as Master.CompositeJSON);
          promises.push(
            calcTextMetricsForComposite(textComp as Master.ComponentJSON, posParentData),
          );
        }
      });
    }
  });
  await Promise.all(promises);
  return block;
}

const calcSize = (parentData: ParentSize, composite: Master.ComponentJSON): ParentSize => {
  const { top_x, top_y, bottom_x, bottom_y } = relativeToAbsolutePosition(
    parentData,
    composite as Shadow.ComponentJSON,
  );
  const w = bottom_x - top_x;
  const h = bottom_y - top_y;
  return { w, h };
};

const getAbsoluteData = (
  parentData: ParentSize,
  composite: Master.ComponentJSON,
): { x: number; y: number; w: number; h: number; bottom: number; right: number } => {
  const { top_x, top_y, bottom_x, bottom_y } = relativeToAbsolutePosition(
    parentData,
    composite as Shadow.ComponentJSON,
  );
  const w = bottom_x - top_x;
  const h = bottom_y - top_y;
  return { x: top_x, y: top_y, w, h, bottom: bottom_y, right: bottom_x };
};

const getSceneSize = (): ParentSize => {
  const [w, h] = Store.project.dimensionJson.screen_size;
  return { w, h };
};

import { ActionSource, ClipDragState } from './types';
import { Master } from '../../../jsonTypes';

const initialDragState: ClipDragState = {
  type: 'video',
  source: ActionSource.NONE,
  compositeType: Master.CompositeType.DEFAULT,
  initialLayerId: 'none',
  initialScrollX: 0,
  tDeltaFromStart: 0,
  action: 'none',
  clipId: 'none',
  sceneId: 'none',
  overLayerId: null,
  overAddNewLayer: false,
  behavior: 'overwrite',
};

export class TimelineDrag {
  private static instance: TimelineDrag;

  private _dragState!: ClipDragState | null;

  public static get shared(): TimelineDrag {
    if (!TimelineDrag.instance) {
      TimelineDrag.instance = new TimelineDrag();
    }
    return TimelineDrag.instance;
  }

  public setdragState(state: Partial<ClipDragState>) {
    this._dragState = {
      ...this.getdragState(),
      ...state,
    };
  }

  public getdragState(): ClipDragState {
    return this._dragState || initialDragState;
  }

  public resetdragState() {
    this._dragState = initialDragState;
  }
}

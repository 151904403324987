import { Plan } from './types';

export const PAID_PLANS = ['BUSINESS', 'UNLIMITED', 'PREMIUM'];

export const FREE_PASS_LIMIT = 0;

export const INITIAL_PLANS: Plan[] = [
  {
    name: 'FREE',
    description: 'For anyone who wishes to try InVideo',
    planPrice: {
      monthly: {
        currency: '$',
        price: 0,
        subText: '',
      },
      annually: {
        currency: '$',
        price: 0,
        subText: '',
      },
    },
    CTA: {
      monthly: {
        text: 'Start now',
        url: `/workflow/marketing-templates`,
        backgroundColorClass: 'iv-bg-grey-100',
        textColorClass: 'iv-text-10',
      },
      annually: {
        text: 'Start now',
        url: `/workflow/marketing-templates`,
        backgroundColorClass: 'iv-bg-grey-100',
        textColorClass: 'iv-text-10',
      },
    },
    feature: [],
    featuresCheckImage: ``,
    redirection: {
      url: `/workflow/marketing-templates`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    features: [],
    id: 1,
    key_features: [],
    label: 'Free',
    rank: 0,
    tagline: '',
    durations: [],
  },
  {
    name: 'BUSINESS',
    description: 'For small business, educators and influencers',
    bannerText: 'Popular',
    planPrice: {
      monthly: {
        currency: '$',
        price: 30,
        subText: '/ mo*',
        starText: '*Billed monthly until cancelled',
      },
      annually: {
        currency: '$',
        price: 15,
        subText: '/ mo*',
        starText: '*Billed $180 annually',
      },
    },
    CTA: {
      monthly: {
        text: 'Get Business',
        url: `/studio-pricing`,
        backgroundColorClass: 'iv-bg-blue',
        textColorClass: 'iv-text-blue',
      },
      annually: {
        text: 'Get Business',
        url: `/studio-pricing`,
        backgroundColorClass: 'iv-bg-blue',
        textColorClass: 'iv-text-blue',
      },
    },
    feature: [],
    appPromo: {
      firstLine: 'Get Filmr Pro ',
      secondLine: 'Mobile app',
      CTAText: 'Read more',
      backgroundGradientClass: 'iv-gradient-raspberry-deep-blue-blue',
      appImage: ``,
    },
    featuresCheckImage: ``,
    redirection: {
      url: `/studio-pricing`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    features: [],
    id: 1,
    key_features: [],
    label: 'Business',
    rank: 0,
    tagline: '',
    durations: [],
  },
  {
    name: 'UNLIMITED',
    description: 'For agencies and multiple teams',
    planPrice: {
      monthly: {
        currency: '$',
        price: 60,
        subText: '/ mo*',
        starText: '*Billed monthly until cancelled',
      },
      annually: {
        currency: '$',
        price: 30,
        subText: '/ mo*',
        starText: '*Billed $360 annually',
      },
    },
    CTA: {
      monthly: {
        text: 'Get Unlimited',
        url: `/studio-pricing`,
        backgroundColorClass: 'iv-bg-violet',
        textColorClass: 'iv-text-violet',
      },
      annually: {
        text: 'Get Unlimited',
        url: `/studio-pricing`,
        backgroundColorClass: 'iv-bg-violet',
        textColorClass: 'iv-text-violet',
      },
    },
    feature: [],
    appPromo: {
      firstLine: 'Get Filmr Pro ',
      secondLine: 'Mobile app',
      CTAText: 'Read more',
      backgroundGradientClass: 'iv-gradient-raspberry-violet',
      appImage: ``,
    },
    featuresCheckImage: ``,
    redirection: {
      url: `/studio-pricing`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    features: [],
    id: 1,
    key_features: [],
    label: 'Unlimited',
    rank: 0,
    tagline: '',
    durations: [],
  },
];

import { seconds } from '../../jsonTypes/SlateJSON';
import { TimeRange, TimeCode } from '../types';

export const last = <T>(array: T[]): T => array[array.length - 1];
export const head = <T>(array: T[]): T => array[0];

export const clamp = (val: number, min: number, max = Infinity) =>
  val <= min ? min : val > max ? max : val;

export const toFixed = (n: number, precision = 3) => (n ? Number(n.toFixed(precision)) : n);

const fmod = function (a: number, b: number) {
  return Number((a - Math.floor(a / b) * b).toPrecision(8));
};

const zeroPad = (nr: number, base: number): string => {
  const len = String(base).length - String(nr).length + 1;
  return len > 0 ? new Array(len).join('0') + nr : `${nr}`;
};

export const clampTimeRange = (t1: TimeRange, t2: TimeRange): TimeRange => {
  const start = Math.max(t1.start, t2.start);
  const end = Math.min(t1.end, t2.end);
  return { start, end };
};

export const clampTime = (t: seconds, t1: TimeRange): seconds => {
  return clamp(t, t1.start, t1.end);
};

/**
 *                   [ other ]
 *              [      this      ]
 */
export const containsTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(other.start) > toFixed(t.start) && toFixed(other.end) < toFixed(t.end);
};

/**
 *                    time
 *                      ↓
 *                      *
 *              [      this      ]
 */
export const containsTime = (t1: TimeRange, t: seconds) => {
  return toFixed(t1.start) <= toFixed(t) && toFixed(t) < toFixed(t1.end);
};

/**
 *              [ this ]
 *                  [ other ]
 */
export const overlapsTimeRange = (t: TimeRange, other: TimeRange) => {
  return (
    toFixed(t.start) < toFixed(other.start) &&
    toFixed(t.end) > toFixed(other.start) &&
    toFixed(t.end) < toFixed(other.end)
  );
};

/**
 *                    time
 *                      ↓
 *                      *
 *              [      this      ]
 */
export const overlapsTime = (t1: TimeRange, t: seconds) => {
  return containsTime(t1, t);
};

/**
 *              [ this ] [ other ]
 */
export const beforeTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(other.start) > toFixed(t.end);
};

/**
 *                        other
 *                          ↓
 *              [ this ]    *
 */
export const beforeTime = (t1: TimeRange, other: seconds) => {
  return toFixed(t1.end) < toFixed(other);
};

/**
 *              [ other ] [ this ]
 */
export const afterTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(t.start) > toFixed(other.end);
};

/**
 *                other
 *                  ↓
 *                  *  [ this ]
 */
export const afterTime = (t1: TimeRange, other: seconds) => {
  return toFixed(t1.start) > toFixed(other);
};

/**
 *              [ this ][ other ]
 */
export const meetsTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(other.start) === toFixed(t.end) && toFixed(other.start) - toFixed(t.end) >= 0;
};

/**
 *              [ this ]
 *              [    other    ]
 */
export const beginsTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(t.start) === toFixed(other.start) && toFixed(t.end) < toFixed(other.end);
};

/**
 *            other
 *              ↓
 *              *
 *              [ this ]
 */
export const beginsTime = (t1: TimeRange, other: seconds) => {
  return toFixed(t1.start) === toFixed(other);
};

/**
 *                      [ this ]
 *              [     other    ]
 */
export const finishesTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(t.end) === toFixed(other.end) && toFixed(t.start) > toFixed(other.start);
};

/**
 *                   other
 *                     ↓
 *                     *
 *              [ this ]
 */
export const finishesTime = (t1: TimeRange, other: seconds) => {
  return toFixed(t1.end) === toFixed(other);
};

/**
 *         [    this    ]           OR      [    other    ]           OR  [     this     ]
 *              [     other    ]                    [     this    ]           [ other ]
 */
export const intersectTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(t.start) < toFixed(other.end) && toFixed(t.end) > toFixed(other.start);
};

/**
 *              [  this ]
 *              [ other ]
 */
export const equalTimeRange = (t: TimeRange, other: TimeRange) => {
  return toFixed(t.start) === toFixed(other.start) && toFixed(t.end) === toFixed(other.end);
};

/**
 *         [ this ]         AND [ other ]
 *                [ other ]             [ this ]
 */
export const emptyTimeRange = (t: TimeRange, other: TimeRange) => {
  return !intersectTimeRange(t, other) && !containsTimeRange(t, other);
};

export const toFrame = (seconds: seconds, rate: number) => {
  return Math.round(seconds * rate);
};

export const toSeconds = (frame: number, rate: number) => {
  return frame / rate;
};

export const toNearestFrame = (seconds: number, rate: number) => {
  const frame = Math.round(seconds * rate);
  return toSeconds(frame, rate);
};

export const flattenTimeRange = (timeRanges: TimeRange[]) => {
  if (!timeRanges.length) return timeRanges;
  const [head, ...tail] = timeRanges.sort((a, b) => a.start - b.start);
  const flatTimeRanges = [head];

  tail.forEach((tr) => {
    const lastTR = last(flatTimeRanges);
    if (containsTime(lastTR, tr.start) || finishesTime(lastTR, tr.start)) {
      const end = Math.max(lastTR.end, tr.end);
      lastTR.end = end;
    } else {
      flatTimeRanges.push(tr);
    }
  });
  return flatTimeRanges;
};

export const gapTimeRanges = (timeRanges: TimeRange[], offset = 0): TimeRange[] => {
  if (!timeRanges.length) return [];
  const trs = [...timeRanges].sort((a, b) => a.start - b.start);
  const gapTimeRanges: TimeRange[] = [];
  const first = head(trs);
  if (first && first.start > offset) {
    const gapTimeRange = { start: offset, end: first.start };
    gapTimeRanges.push(gapTimeRange);
  }

  trs.forEach((tr, index) => {
    const nextTR = trs[index + 1];
    if (!nextTR) return;
    if (nextTR.start > tr.end) {
      const gapTimeRange = { start: tr.end, end: nextTR.start };
      gapTimeRanges.push(gapTimeRange);
    }
  });
  return gapTimeRanges;
};

export const toTimecode = (totolSeconds: number, rate: number): TimeCode => {
  const r = rate;
  const framesInTargetRate = toFrame(totolSeconds, rate);

  // Number of frames in an hour
  const framesPerHour = Math.round(r * 60 * 60);
  // Number of frames in a day - timecode rolls over after 24 hours
  const framesPer24Hours = framesPerHour * 24;

  // If the number of frames is more than 24 hours, roll over clock
  const value = fmod(framesInTargetRate, framesPer24Hours);

  const nominalFps = Math.ceil(r);

  // compute the fields
  const frames = fmod(value, nominalFps);
  const secondsTotal = Math.floor(value / nominalFps);
  const seconds = fmod(secondsTotal, 60);
  const minutes = fmod(Math.floor(secondsTotal / 60), 60);
  const hours = Math.floor(Math.floor(secondsTotal / 60) / 60);

  return {
    hours: zeroPad(hours, 10),
    minutes: zeroPad(minutes, 10),
    seconds: zeroPad(seconds, 10),
    frames: zeroPad(frames, 10),
  };
};

export const fromTimeCode = (timecode: TimeCode, rate: number) => {
  const hours = parseInt(timecode.hours);
  const minutes = parseInt(timecode.minutes);
  const seconds = parseInt(timecode.seconds);
  const frames = parseInt(timecode.frames);
  const totalMinutes = hours * 60 + minutes;

  const value = totalMinutes * 60 + seconds + toSeconds(frames, rate);
  return value;
};

export const toTimecodeString = (totalSeconds: number, rate: number) => {
  const timecode = toTimecode(totalSeconds, rate);

  return `${timecode.minutes}:${timecode.seconds}:${timecode.frames}`;
};

export const toSecondsFromTimecodeString = (timecodeString: string, rate: number) => {
  // takes a "mm:ss:ff" timecode string and returns total seconds
  const [mmInString, ssInString, framesInString] = timecodeString.split(':');
  return Number(mmInString) * 60 + Number(ssInString) + toSeconds(Number(framesInString), rate);
};

export type Mode = 'editor' | 'loading' | 'playing' | 'paused';

export enum SlateType {
  FRAME = 'FRAME',
  CLASSIC = 'CLASSIC',
  NONE = 'NONE',
}

export enum PreviewType {
  BASIC_TIMELINE_SCENE_PREVIEW = 'BASIC_TIMELINE_SCENE_PREVIEW',
  BASIC_TIMELINE_FULL_PREVIEW = 'BASIC_TIMELINE_FULL_PREVIEW',
  ADVANCED_TIMELINE_SCENE_PREVIEW = 'ADVANCED_TIMELINE_SCENE_PREVIEW',
  ADVANCED_TIMELINE_PREVIEW_FROM_SEEKER = 'ADVANCED_TIMELINE_PREVIEW_FROM_SEEKER',
  STORYBOARD_SCENE_PREVIEW = 'STORYBOARD_SCENE_PREVIEW',
  STORYBOARD_FULL_PREVIEW = 'STORYBOARD_FULL_PREVIEW',
}

export enum UserActionSource {
  KEYBOARD = 'Keyboard',
  MOUSE = 'Mouse',
  AUTO = 'Auto',
}

export enum UserActionSourceForSeekTimeAnalytics {
  MOUSE = 'Mouse',
  KEYBOARD = 'Keyboard',
  NEXT = 'Next Button',
  PREVIOUS = 'Previous Button',
  AUTO = 'Auto',
  RIGHT_CLICK = 'Right Click',
  SCENE_CARD = 'Scene Card',
}

import { cloneJSON } from '../utils';
import { DraggedElementJSON, DraggedMedia } from './types';

interface Registry {
  draggedElement: { type: 'media'; media: DraggedMedia } | null;
  lastDroppedElement: { type: 'media'; media: DraggedMedia } | null;
}

const _registry: Registry = {
  draggedElement: null,
  lastDroppedElement: null,
};

export const startDrag = (media: DraggedMedia) => {
  _registry.draggedElement = { type: 'media', media };
};

export const cancelDrag = () => {
  _registry.draggedElement = null;
};

export const completeDrag = (media: DraggedMedia) => {
  _registry.lastDroppedElement = { type: 'media', media };
};

export const getDraggedElement = () => {
  return _registry.draggedElement;
};

export const getDraggedElementJSON = (): DraggedElementJSON | null => {
  if (!_registry.draggedElement) return _registry.draggedElement;

  const {
    type,
    media: { draggedElement, onDragCompleted, droppedTo, ...jsonMedia },
  } = _registry.draggedElement;
  return cloneJSON({ type, media: jsonMedia });
};

export const getLastDroppedElement = () => {
  return _registry.lastDroppedElement;
};

export const getLastDroppedElementJSON = (): DraggedElementJSON | null => {
  if (!_registry.lastDroppedElement) return _registry.lastDroppedElement;

  const {
    type,
    media: { draggedElement, onDragCompleted, droppedTo, ...jsonMedia },
  } = _registry.lastDroppedElement;
  return cloneJSON({ type, media: jsonMedia });
};

export function resetLastDroppedElement() {
  _registry.lastDroppedElement = null;
}

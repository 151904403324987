import { Master } from '../jsonTypes';

type Path = (string | number)[];

export interface Registry {
  isRegistered: boolean;
  ids: {
    [id: string]: Path;
  };
  allIds: {
    [id: string]: Path;
  };
  audioBlocks: {
    [id: string]: Path;
  };

  blocks: {
    [id: string]: Path;
  };
  components: {
    [id: string]: Path;
  };
  blockIdsFor: {
    [id: string]: string;
  };
  parentIdsFor: {
    [id: string]: string;
  };
}

const _registry: Registry = {
  isRegistered: false,
  ids: {},
  allIds: {},
  blocks: {},
  audioBlocks: {},
  components: {},
  blockIdsFor: {},
  parentIdsFor: {},
};

export const reset = () => {
  _registry.isRegistered = false;
  _registry.ids = {};
  _registry.allIds = {};
  _registry.blocks = {};
  _registry.audioBlocks = {};
  _registry.components = {};
  _registry.blockIdsFor = {};
  _registry.parentIdsFor = {};
  //TODO propagate to shadow
};

export const register = (json: Master.DimensionJSON) => {
  reset();
  const dimJson = JSON.parse(JSON.stringify(json)) as Master.DimensionJSON;
  dimJson.blocks.forEach(_registerBlock);
  dimJson.audio_blocks.forEach(_registerAudioBlock);
  _registry.isRegistered = true;
  // TODO propagate to shadow
};

export const updateBlockOrComponent = (
  dim: Master.DimensionJSON,
  anyJson: Master.AnyJSON,
): Master.DimensionJSON => {
  const isBlock = anyJson.id.split('.').length === 2;
  const isAudioBlock = 'audio_list' in anyJson;
  if (isBlock && !isAudioBlock) {
    return updateBlock(dim, anyJson as Master.BlockJSON);
  } else if (isAudioBlock) {
    return updateAudioBlock(dim, anyJson as Master.AudioBlockJSON);
  } else {
    return updateComponent(dim, anyJson as Master.AnyComponentJSON);
  }
};

export const updateBlock = (
  dim: Master.DimensionJSON,
  blockJson: Master.BlockJSON,
): Master.DimensionJSON => {
  if (!_registry.isRegistered) {
    throw new Error(`Cannot update before registering a video.`);
  }
  const cDim = JSON.parse(JSON.stringify(dim)) as Master.DimensionJSON;
  const bJson = JSON.parse(JSON.stringify(blockJson)) as Master.BlockJSON;
  const oldJson = getBlockById(cDim, bJson.id);
  if (!oldJson) {
    throw new Error(
      `Failed to update video: could not find block with id (${bJson.id}) to update.`,
    );
  }
  _unRegisterBlock(oldJson);

  const idx = cDim.blocks.findIndex((c) => c.id === bJson.id);
  if (idx < 0)
    throw new Error(`Failed to update video: could not find block (${bJson.id}) in it's parent.`);
  cDim.blocks.splice(idx, 1, bJson as Master.BlockJSON);
  _registerBlock(bJson, idx);
  return cDim;
};

export const updateAudioBlock = (
  dim: Master.DimensionJSON,
  audioBlockJson: Master.AudioBlockJSON,
): Master.DimensionJSON => {
  if (!_registry.isRegistered) {
    throw new Error(`Cannot update before registering a video.`);
  }
  const cDim = JSON.parse(JSON.stringify(dim)) as Master.DimensionJSON;
  const aJson = JSON.parse(JSON.stringify(audioBlockJson)) as Master.AudioBlockJSON;
  const oldJson = getAudioBlockById(cDim, aJson.id);
  if (!oldJson || !('audio_list' in oldJson)) {
    throw new Error(
      `Failed to update video: could not find audio block with the id (${aJson.id}) to update.`,
    );
  }

  _unRegisterAudioBlock(oldJson);
  const idx = cDim.audio_blocks.findIndex((c) => c.id === aJson.id);
  if (idx < 0)
    throw new Error(
      `Failed to update video: could not find audio block (${aJson.id}) in it's parent.`,
    );
  cDim.audio_blocks.splice(idx, 1, aJson as Master.AudioBlockJSON);
  _registerAudioBlock(aJson, idx);
  return cDim;
};

export const updateComponent = (
  dim: Master.DimensionJSON,
  componentJson: Master.AnyComponentJSON,
): Master.DimensionJSON => {
  if (!_registry.isRegistered) {
    throw new Error(`Cannot update before registering a video.`);
  }
  const cDim = JSON.parse(JSON.stringify(dim)) as Master.DimensionJSON;
  const cJson = JSON.parse(JSON.stringify(componentJson)) as Master.AnyComponentJSON;
  const oldJson = getComponentById(cDim, cJson.id);
  if (!oldJson) {
    throw new Error(
      `Failed to update video: could not find component with the id (${cJson.id}) to update.`,
    );
  }
  const parentId = parentIdFor(cJson.id)!;
  const blockId = blockIdFor(cJson.id)!;
  const parentJson = parentId ? getAnyById(cDim, parentId) : null;
  if (!parentJson || !('components' in parentJson)) {
    throw new Error(`Failed to update video: failed to find the parent of ${cJson.id}`);
  }
  _unRegisterComponent(oldJson);
  const components = parentJson.components as Master.AnyComponentJSON[] | undefined; // some ts versions are not handling never[] correctly
  const idx = components?.findIndex((c) => {
    return c.id === cJson.id;
  });
  if (idx === undefined || idx < 0) {
    throw new Error(
      `Failed to update video: failed to find ${cJson.id} in parent ${parentJson.id}`,
    );
  }
  parentJson.components?.splice(idx, 1, cJson);
  _registerComponent(cJson, idx, parentId, blockId);

  return cDim;
};

export const getById = (dim: Master.DimensionJSON, id: string): Master.SelectableJSON | null => {
  return dig<Master.SelectableJSON>(dim, _registry.ids[id]);
};

export const getAnyById = (dim: Master.DimensionJSON, id: string): Master.AnyJSON | null => {
  return dig<Master.AnyJSON>(dim, _registry.allIds[id]);
};

export const getBlockById = (dim: Master.DimensionJSON, id: string): Master.BlockJSON | null => {
  return dig<Master.BlockJSON>(dim, _registry.blocks[id]);
};

export const getAudioBlockById = (
  dim: Master.DimensionJSON,
  id: string,
): Master.AudioBlockJSON | null => {
  return dig<Master.AudioBlockJSON>(dim, _registry.audioBlocks[id]);
};

export const getComponentById = (
  dim: Master.DimensionJSON,
  id: string,
): Master.AnyComponentJSON | null => {
  return dig<Master.AnyComponentJSON>(dim, _registry.components[id]);
};

export const parentIdFor = (id: string): string | null => {
  return _registry.parentIdsFor[id] ?? null;
};
export const blockIdFor = (id: string): string | null => {
  return _registry.blockIdsFor[id] ?? null;
};

/// private helpers

type Obj = { [key in string | number]: Obj | undefined | null };
const dig = <T>(object: unknown, path?: Path): T | null => {
  if (
    object === undefined ||
    object === null ||
    !path ||
    path.length === 0 ||
    typeof object !== 'object'
  ) {
    return (object ?? null) as T | null;
  }

  return dig((object as Obj)[path[0]], path.slice(1));
};

const _registerBlock = (block: Master.BlockJSON, idx: number) => {
  _registry.allIds[block.id] = ['blocks', idx];
  _registry.blocks[block.id] = ['blocks', idx];
  _registry.blockIdsFor[block.id] = block.id;
  // _registry.parentIdsFor[block.id] = block.id;
  block.components.forEach((c, idx) => _registerComponent(c, idx, block.id, block.id));
};

const _registerAudioBlock = (audioBlock: Master.AudioBlockJSON, idx: number) => {
  _registry.ids[audioBlock.id] = ['audio_blocks', idx];
  _registry.allIds[audioBlock.id] = ['audio_blocks', idx];
  _registry.audioBlocks[audioBlock.id] = ['audio_blocks', idx];
};

const _registerComponent = (
  component: Master.ComponentJSON | Master.IgnoredComponentJSON,
  idx: number,
  parentId: string,
  blockId: string,
) => {
  const path = [..._registry.allIds[parentId], 'components', idx];
  _registry.parentIdsFor[component.id] = parentId;
  _registry.blockIdsFor[component.id] = blockId;
  _registry.ids[component.id] = path;
  _registry.components[component.id] = path;
  _registry.allIds[component.id] = path;
  if ('components' in component) {
    component.components?.forEach((c, idx) => _registerComponent(c, idx, component.id, blockId));
  }
};

const _unRegisterBlock = (block: Master.BlockJSON) => {
  delete _registry.allIds[block.id];
  delete _registry.blocks[block.id];
  delete _registry.blockIdsFor[block.id];
  block.components.forEach((c) => _unRegisterComponent(c));
};

const _unRegisterAudioBlock = (audioBlock: Master.AudioBlockJSON) => {
  delete _registry.ids[audioBlock.id];
  delete _registry.allIds[audioBlock.id];
  delete _registry.audioBlocks[audioBlock.id];
};

const _unRegisterComponent = (component: Master.ComponentJSON | Master.IgnoredComponentJSON) => {
  delete _registry.parentIdsFor[component.id];
  delete _registry.blockIdsFor[component.id];
  delete _registry.ids[component.id];
  delete _registry.components[component.id];
  delete _registry.allIds[component.id];
  if ('components' in component) {
    component.components?.forEach((c) => _unRegisterComponent(c));
  }
};

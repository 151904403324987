import { Master } from '../../jsonTypes';
type FilterProperties = Master.FilterProperties;

type Ease =
  | 'linear'
  | 'expo.in'
  | 'expo.out'
  | 'expo.inOut'
  | 'power1.out'
  | 'power1.in'
  | 'power1.inOut'
  | 'power2.out'
  | 'power2.in'
  | 'power2.inOut'
  | 'power3.out'
  | 'power3.in'
  | 'power3.inOut'
  | 'power4.out'
  | 'power4.in'
  | 'power4.inOut'
  | 'circ.out'
  | 'circ.in'
  | 'circ.inOut'
  | 'sine.out'
  | 'sine.in'
  | 'sine.inOut'
  | 'elastic.out'
  | 'elastic.in'
  | 'elastic.inOut';

// We should not define these values here,
// otherwise these will be embedded to MasterJSON and bloat up the file size.
// The actual data is defined in getFilterProperties.ts in iv-frame.
const allEasing: Ease[] = [];
const allEasingMenu: string[] = [];

export const fxKeys = [
  'AuroraFX',
  'BarrelFX',
  'BasReliefFX',
  'BeatRockFX',
  'BlurFastFX',
  'BokehBlurFX',
  'CameraFX',
  'CartoonFX',
  'ColorCorrectionFX',
  'CrossHatchFX',
  'DotsFX',
  'EightBitFX',
  'FashionFX',
  'FilmFX',
  'FlashbackFX',
  'FocusBlurFX',
  'GhostRgbFX',
  'GradientOverlayFX',
  'GranularityFX',
  'HalfToneFX',
  'KaleidoscopeFX',
  'LensFlareFX',
  'MirrorFX',
  'PaintFX',
  'PencilFX',
  'PixelGlitchFX',
  'RadialFX',
  'RgbGlitchFX',
  'ScreenToneFX',
  'ScifiFX',
  'SixteenBitFX',
  'SobelFX',
  'SpecialColorFX',
  'StippleFX',
  'StippleRGBFX',
  'StreaksFX',
  'VoronoiFX',
  'KenBurnsFX',
] as const;

type FxKey = typeof fxKeys[number];

const fxProps: Record<FxKey, FilterProperties> = {
  AuroraFX: {
    amount: {
      value: 1,
      range: [0.0, 1.0],
      type: 'Number',
      name: 'Amount',
    },
    scale: {
      value: 1,
      range: [0.5, 2.0],
      type: 'Number',
      name: 'Scale',
    },
  },

  BarrelFX: {
    amount: {
      value: 0.75,
      range: [0.0, 5.0],
      type: 'Number',
      name: 'Amount',
    },
  },

  BasReliefFX: {
    amount: {
      value: 1.5,
      range: [0.0, 5.0],
      type: 'Number',
      name: 'Amount',
    },
    lightX: {
      value: 1.0,
      range: [0.0, 1.0],
      type: 'Number',
      name: 'Light X',
    },
    lightY: {
      value: 0.0,
      range: [0.0, 1.0],
      type: 'Number',
      name: 'Light Y',
    },
    lightZ: {
      value: 1.0,
      range: [0.0, 5.0],
      type: 'Number',
      name: 'Light Z',
    },
    intensity: {
      value: 2.0,
      range: [0.0, 10.0],
      type: 'Number',
      name: 'Intensity',
    },
    roughness: {
      value: 0.2,
      range: [0.0, 1.0],
      type: 'Number',
      name: 'Roughness',
    },
  },

  BeatRockFX: {
    intensity: { type: 'Number', name: 'Intensity', range: [0, 1], value: 1 },
    interval: { type: 'Number', name: 'Interval', range: [0, 10], value: 2 },
    duration: { type: 'Number', name: 'Duration', range: [0, 2], value: 0.5 },
  },

  BlurFastFX: {
    amount: {
      value: 1.0,
      range: [0.0, 1.0],
      type: 'Number',
      name: 'Amount',
    },
    sizeX: {
      value: 1.0,
      range: [0.0, 10.0],
      type: 'Number',
      name: 'Size X',
    },
    sizeY: {
      value: 1.0,
      range: [0.0, 10.0],
      type: 'Number',
      name: 'Size Y',
    },
  },

  BokehBlurFX: {
    angle: {
      value: 0,
      range: [0, 360],
      type: 'Number',
      name: 'Angle',
    },
    size: { value: 0.2, range: [0.0, 1.0], type: 'Number', name: 'Size', step: 0.01 },
    radius: { value: 30.0, range: [0.0, 100.0], type: 'Number', name: 'Amount' },
  },

  CameraFX: {
    zoomInterval: {
      value: 2.0,
      range: [0.0, 100.0],
      type: 'Number',
      name: 'Zoom Interval',
    },
    maxZoom: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Max Zoom' },
  },

  CartoonFX: {
    amount: { value: 0.75, range: [0.0, 2.0], type: 'Number', name: 'Amount' },
  },

  ColorCorrectionFX: {
    brightness: { type: 'Number', name: 'Brightness', range: [0, 1], value: 0.5 },
    exposure: { type: 'Number', name: 'Exposure', range: [0, 1], value: 0.5 },
    contrast: { type: 'Number', name: 'Contrast', range: [0, 1], value: 0.5 },
    highlight: { type: 'Number', name: 'Highlight', range: [0, 1], value: 0.5 },
    shadow: { type: 'Number', name: 'Shadow', range: [0, 1], value: 0.5 },
    hue: { type: 'Number', name: 'Hue', range: [0, 1], value: 0.5 },
    offset: { type: 'Number', name: 'Offset', range: [0, 1], value: 0.5 },
    saturation: { type: 'Number', name: 'Saturation', range: [0, 1], value: 0.5 },
    vibration: { type: 'Number', name: 'Vibration', range: [0, 1], value: 0.5 },
    temperature: { type: 'Number', name: 'Temperature', range: [0, 1], value: 0.5 },
    tint: { type: 'Number', name: 'Tint', range: [0, 1], value: 0.5 },
    sharpness: { type: 'Number', name: 'Sharpness', range: [0, 1], value: 0 },
  },

  CrossHatchFX: {
    rotation: { value: 45.0, range: [0.0, 90.0], type: 'Number', name: 'Rotation' },
    scale: { value: 0.2, range: [0.0, 2.0], type: 'Number', name: 'Scale' },
    backgroundColor: { value: 0xffffff, type: 'Color', name: 'Background Color' },
  },

  DotsFX: {
    dotSize: { type: 'Number', name: 'Dot Size', range: [0, 100], value: 17 },
    shape: { type: 'Number', name: 'Shape', range: [0, 1], value: 0.5 },
  },

  EightBitFX: {
    pixelSize: { type: 'Number', name: 'Pixel Size', range: [1, 30], value: 10, step: 1 },
  },

  FashionFX: {
    zoomMax: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Max Zoom' },
    offset: { value: 1.0, range: [0.0, 1.0], type: 'Number', name: 'Max Offset' },
    stopInterval: {
      value: 2,
      range: [0.0, 10],
      type: 'Number',
      name: 'Stop Interval',
    },
    photoInterval: {
      value: 1,
      range: [0.0, 10],
      type: 'Number',
      name: 'Photo Interval',
    },
    photoSaturation: {
      value: 0.0,
      range: [-2.0, 2.0],
      type: 'Number',
      name: 'Photo Saturation',
    },
  },

  FilmFX: {
    amount: { value: 1.0, range: [0.0, 2.0], type: 'Number', name: 'Amount' },
    vingette: { value: 0.2, range: [-1.0, 1.0], type: 'Number', name: 'Vignette' },
    chromatic: {
      value: 1.0,
      range: [0.0, 10.0],
      type: 'Number',
      name: 'Chromatic',
    },
  },

  FlashbackFX: {
    speed: { type: 'Number', name: 'Speed', range: [0, 3], value: 1 },
    interval: { type: 'Number', name: 'Interval', range: [0, 10], value: 3 },
    duration: { type: 'Number', name: 'Duration', range: [0, 2], value: 1 },
    color: { type: 'Color', name: 'Color', value: 0xffffff },
  },

  FocusBlurFX: {
    intensity: { type: 'Number', name: 'Intensity', range: [0, 10], value: 1 },
    size: { type: 'Number', name: 'Size', range: [0, 1], value: 0.1 },
  },

  GhostRgbFX: {
    amount: { value: 1.0, range: [0.0, 2.0], type: 'Number', name: 'Amount' },
    interval: { value: 40.0, range: [0, 100], type: 'Number', name: 'Interval' },
    color1: { value: 0xff0000, type: 'Color', name: 'Color 1' },
    color2: { value: 0x0000ff, type: 'Color', name: 'Color 2' },
  },

  GradientOverlayFX: {
    blendMode: {
      type: 'BlendMode',
      name: 'Blend Mode',
      value: 0,
    },
    opacity: {
      type: 'Number',
      name: 'Opacity',
      range: [0, 1],
      value: 0.5,
    },
    blendFactor: {
      type: 'Number',
      name: 'Blend Factor',
      range: [0, 100],
      value: 30,
    },
    point1: {
      type: 'Number2',
      name: 'Point 1',
      range: [
        [0, 0],
        [1, 1],
      ],
      value: [0, 0],
      isAdvanced: true,
    },
    color1: {
      type: 'Color',
      name: 'Color 1',
      value: 0xffff00,
    },
    color1alpha: {
      type: 'Number',
      name: 'Alpha 1',
      range: [0, 1],
      value: 1,
      isAdvanced: true,
    },
    point2: {
      type: 'Number2',
      name: 'Point 2',
      range: [
        [0, 0],
        [1, 1],
      ],
      value: [1, 0],
      isAdvanced: true,
    },
    color2: {
      type: 'Color',
      name: 'Color 2',
      value: 0xff00ff,
    },
    color2alpha: {
      type: 'Number',
      name: 'Alpha 2',
      range: [0, 1],
      value: 1,
      isAdvanced: true,
    },
    point3: {
      type: 'Number2',
      name: 'Point 3',
      range: [
        [0, 0],
        [1, 1],
      ],
      value: [0, 1],
      isAdvanced: true,
    },
    color3: {
      type: 'Color',
      name: 'Color 3',
      value: 0x00ffff,
    },
    color3alpha: {
      type: 'Number',
      name: 'Alpha 3',
      range: [0, 1],
      value: 1,
      isAdvanced: true,
    },
    point4: {
      type: 'Number2',
      name: 'Point 4',
      range: [
        [0, 0],
        [1, 1],
      ],
      value: [1, 1],
      isAdvanced: true,
    },
    color4: {
      type: 'Color',
      name: 'Color 4',
      value: 0x0000ff,
    },
    color4alpha: {
      type: 'Number',
      name: 'Alpha 4',
      range: [0, 1],
      value: 1,
      isAdvanced: true,
    },
  },

  GranularityFX: {
    amount: { value: 16.0, range: [0.0, 100.0], type: 'Number', name: 'Amount' },
  },

  HalfToneFX: {
    spacing: { value: 5.0, range: [0.0, 20.0], type: 'Number', name: 'Spacing' },
    softness: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Softness' },
    rotation: { value: 45.0, range: [0.0, 90.0], type: 'Number', name: 'Rotation' },
    bands: { value: 4.0, range: [1.0, 8.0], type: 'Number', name: 'Bands' },
    backgroundColor: { value: 0xffffff, type: 'Color', name: 'Background Color' },
  },

  KaleidoscopeFX: {
    speed: { value: 0.3, range: [0, 1], type: 'Number', name: 'Speed' },
    rotation: { value: 0, range: [-180, 180], type: 'Number', name: 'Rotation' },
    scroll: { value: 0.1, range: [0, 1], type: 'Number', name: 'Scroll' },
  },

  LensFlareFX: {
    intensity: { type: 'Number', name: 'Intensity', range: [0, 2], value: 1 },
    angle: { value: 0, range: [0, 360], type: 'Number', name: 'Angle' },
    distance: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Distance' },
  },

  MirrorFX: {
    mode: {
      value: 0,
      range: [0, 6],
      type: 'List',
      steps: 1,
      name: 'Mode',
      menu: ['Left', 'Right', 'Bottom', 'Top', 'Cross', 'Quad', 'Automatic'],
    },
    interval: {
      value: 1.0,
      range: [0.1, 10.0],
      type: 'Number',
      name: 'Interval',
      displayConditions: [{ type: 'List', name: 'mode', value: 6 }],
    },
  },

  PaintFX: {
    amount: { type: 'Number', name: 'amount', range: [0, 2], value: 1.0 },
  },

  PencilFX: {
    rotation: { value: 45.0, range: [0.0, 90.0], type: 'Number', name: 'Rotation' },
    scale: { value: 1.0, range: [0.0, 2.0], type: 'Number', name: 'Scale' },
    backgroundColor: { value: 0xffffff, type: 'Color', name: 'Background Color' },
  },

  PixelGlitchFX: {
    intensity: { type: 'Number', name: 'Intensity', range: [0, 1], value: 1 },
    speed: { type: 'Number', name: 'Speed', range: [0, 2], value: 1 },
    noise: { type: 'Number', name: 'Noise', range: [0, 1], value: 1 },
  },

  RadialFX: {
    amount: { value: 0.2, range: [0.0, 1.0], type: 'Number', name: 'Amount' },
  },

  RgbGlitchFX: {
    glitchColor: { value: 0x00ffff, type: 'Color', name: 'Glitch Color' },
    glitchSize: { value: 64, range: [0.0, 256], type: 'Number', name: 'Glitch Size' },
  },

  ScreenToneFX: {
    spacing: { value: 5.0, range: [0.0, 20.0], type: 'Number', name: 'Spacing' },
    softness: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Softness' },
    rotation: { value: 45.0, range: [0.0, 90.0], type: 'Number', name: 'Rotation' },
    bands: { value: 4.0, range: [1.0, 8.0], type: 'Number', name: 'Bands' },
  },

  ScifiFX: {
    strength: { value: 5.0, range: [0.0, 10.0], type: 'Number', name: 'Strength' },
    threshold: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Threshold' },
    stretchX: { value: 1.0, range: [0.0, 10.0], type: 'Number', name: 'Stretch X' },
    stretchY: { value: 4.0, range: [0.0, 10.0], type: 'Number', name: 'Stretch Y' },
    color: { value: 0x00ffee, type: 'Color', name: 'Color' },
  },

  SixteenBitFX: {
    pixelSize: { type: 'Number', name: 'Pixel Size', range: [1, 30], value: 10, step: 1 },
  },

  SobelFX: {},

  SpecialColorFX: {
    smhShadows: { value: 0xffffff, type: 'Color', name: 'Shadow color' },
    smhMidtones: { value: 0xffffff, type: 'Color', name: 'Midtone color' },
    smhHighlights: {
      value: 0xffffff,
      type: 'Color',
      name: 'Highlight color',
    },

    redSlope: { type: 'Number', name: 'Red Slope', range: [0, 2], value: 1.0 },
    redOffset: { type: 'Number', name: 'Red Offset', range: [0, 1], value: 0.0 },
    redPower: { type: 'Number', name: 'Red Power', range: [0, 2], value: 1.0 },

    greenSlope: { type: 'Number', name: 'Green Slope', range: [0, 2], value: 1.0 },
    greenOffset: { type: 'Number', name: 'Green Offset', range: [0, 1], value: 0.0 },
    greenPower: { type: 'Number', name: 'Green Power', range: [0, 2], value: 1.0 },

    blueSlope: { type: 'Number', name: 'Blue Slope', range: [0, 2], value: 1.0 },
    blueOffset: { type: 'Number', name: 'Blue Offset', range: [0, 1], value: 0.0 },
    bluePower: { type: 'Number', name: 'Blue Power', range: [0, 2], value: 1.0 },

    grey: { type: 'Number', name: 'Grey', range: [-1, 2], value: 0 },
  },

  StippleFX: {
    rotation: { value: 45.0, range: [0.0, 90.0], type: 'Number', name: 'Rotation' },
    scale: { value: 1.0, range: [0.0, 2.0], type: 'Number', name: 'Scale' },
    backgroundColor: { value: 0xffffff, type: 'Color', name: 'Background Color' },
  },

  StippleRGBFX: {
    rotation: { value: 45.0, range: [0.0, 90.0], type: 'Number', name: 'Rotation' },
    scale: { value: 1.0, range: [0.0, 2.0], type: 'Number', name: 'Scale' },
    backgroundColor: { value: 0xffffff, type: 'Color', name: 'Background Color' },
  },

  StreaksFX: {
    strength: { value: 10.0, range: [0.0, 100.0], type: 'Number', name: 'Strength' },
    threshold: { value: 0.65, range: [0.0, 1.0], type: 'Number', name: 'Threshold' },
    stretchX: { value: 3.0, range: [0.0, 10.0], type: 'Number', name: 'Stretch X' },
    stretchY: { value: 0.0, range: [0.0, 10.0], type: 'Number', name: 'Stretch Y' },
    color: { value: 0x0000ff, type: 'Color', name: 'Color' },
  },

  VoronoiFX: {
    amount: { type: 'Number', name: 'Amount', range: [0, 1], value: 0.5 },
    scale: { type: 'Number', name: 'Scale', range: [0, 100], value: 8.0 },
  },

  KenBurnsFX: {
    duration: { type: 'Number', name: 'Duration', range: [0, 100], value: 10 },
    numTargets: { type: 'Number', name: 'Num Targets', range: [2, 4], value: 2, step: 1 },

    target0X: { type: 'Number', name: 'Target 0 X', range: [0, 1], value: 0.5 },
    target0Y: { type: 'Number', name: 'Target 0 Y', range: [0, 1], value: 0.1 },
    target0Z: { type: 'Number', name: 'Target 0 Zoom', range: [1, 5], value: 1.5 },

    target1X: { type: 'Number', name: 'Target 1 X', range: [0, 1], value: 0.5 },
    target1Y: { type: 'Number', name: 'Target 1 Y', range: [0, 1], value: 0.9 },
    target1Z: { type: 'Number', name: 'Target 1 Zoom', range: [1, 5], value: 1.5 },

    target2X: { type: 'Number', name: 'Target 2 X', range: [0, 1], value: 0.1 },
    target2Y: { type: 'Number', name: 'Target 2 Y', range: [0, 1], value: 0.5 },
    target2Z: { type: 'Number', name: 'Target 2 Zoom', range: [1, 5], value: 1.5 },

    target3X: { type: 'Number', name: 'Target 3 X', range: [0, 1], value: 0.9 },
    target3Y: { type: 'Number', name: 'Target 3 Y', range: [0, 1], value: 0.5 },
    target3Z: { type: 'Number', name: 'Target 3 Zoom', range: [1, 5], value: 1.5 },
  },
};

const transitionKeys = [
  'BarrelTransition',
  'BlockDisplacementTransition',
  'BlurFastTransition',
  'CutTransition',
  'DistordWaveTransition',
  'DotGlitchTransition',
  'DotsTransition',
  'FadeTransition',
  'FlashTransition',
  'Glitch1Transition',
  'GlitchSlideTransition',
  'GranularityTransition',
  'HoleTransition',
  'LensDistortionTransition',
  'LensFlareTransition',
  'MirrorZoomTransition',
  'PixelateZoomTransition',
  'ProjectorFailTransition',
  'RadialTransition',
  'RollTransition',
  'Roll3DTransition',
  'RotationTransition',
  'RotationZoomTransition',
  'Rotation3DTransition',
  'SlideTransition',
  'SlideBlurTransition',
  'SlideBulgeTransition',
  'SlideWarpTransition',
  'ScanlineTransition',
  'StretchTransition',
  'StretchBlurTransition',
  'ThresholdTransition',
  'TwirlTransition',
  'VaselineTransition',
  'VoronoiTransition',
  'WobbleSlideTransition',
  'ZoomTransition',
  'ZoomStretchTransition',
  'ZoomTwirlRGBTransition',
] as const;

type TransitionKey = typeof transitionKeys[number];

const transitionProps: Record<TransitionKey, FilterProperties> = {
  BarrelTransition: {
    mode: {
      value: 2,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Mode',
      menu: ['Zoom In', 'Zoom Out', 'Mix In/Out', 'Mix Out/In'],
    },
    amount: { type: 'Number', name: 'amount', range: [1, 10], value: 4.0 },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  BlockDisplacementTransition: {
    displacement: {
      value: 0.5,
      range: [0, 2],
      type: 'Number',
      name: 'Displacement',
    },
    scale: {
      value: 1,
      range: [0, 2],
      type: 'Number',
      name: 'Scale',
    },
    rgbDelay: {
      value: 0.1,
      range: [0, 1],
      type: 'Number',
      name: 'RGB Delay',
    },
    direction: {
      value: 0,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Right', 'Up', 'Left', 'Down'], // we can't change the order...
    },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  BlurFastTransition: {
    stretchX: { value: 2.0, range: [0.0, 10.0], type: 'Number', name: 'Stretch X' },
    stretchY: { value: 2.0, range: [0.0, 10.0], type: 'Number', name: 'Stretch Y' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  CutTransition: {
    mode: {
      value: 0,
      range: [0, 1, 2],
      steps: 1,
      type: 'List',
      name: 'Mode',
      menu: ['Vertical', 'Horizontal', 'Both'],
    },
    scale: { type: 'Number', name: 'scale', range: [0, 2], value: 0.2 },
    scaleSpeed: { type: 'Number', name: 'scale speed', range: [0, 1], value: 0.5 },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  DistordWaveTransition: {
    amount: { value: 1.0, range: [0.0, 10.0], type: 'Number', name: 'Amount' },
    waveSize: { value: 0.5, range: [0.0, 2.0], type: 'Number', name: 'Wave Size' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  DotGlitchTransition: {
    scaleX: {
      value: 1,
      range: [0.1, 10],
      type: 'Number',
      name: 'Scale X',
    },
    scaleY: {
      value: 1,
      range: [0.1, 10],
      type: 'Number',
      name: 'Scale Y',
    },
    dotGrid: {
      value: 8,
      range: [1, 32],
      type: 'Number',
      name: 'Dot Grid',
    },
    rgbShift: {
      value: 1,
      range: [0, 10],
      type: 'Number',
      name: 'RGB Shift',
    },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  DotsTransition: {
    dotSize: { type: 'Number', name: 'dot size', range: [0, 50], value: 17.0 },
    shape: { type: 'Number', name: 'shape', range: [0, 1], value: 0.45 },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  FadeTransition: {
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  FlashTransition: {
    flashColor: { value: 0xf58d42, type: 'Color', name: 'Flash Color' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  Glitch1Transition: {
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  GlitchSlideTransition: {
    scale: {
      value: 1,
      range: [0, 2],
      type: 'Number',
      name: 'Scale',
    },
    rgbShift: {
      value: 1,
      range: [0, 10],
      type: 'Number',
      name: 'RGB Shift',
    },
    direction: {
      value: 0,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  GranularityTransition: {
    amount: { value: 100.0, range: [0.0, 200.0], type: 'Number', name: 'Amount' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  HoleTransition: {
    mode: {
      value: 'in',
      range: ['in', 'out'],
      type: 'Zoom',
      name: 'Zoom',
      menu: ['Zoom In', 'Zoom Out'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  LensDistortionTransition: {
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  LensFlareTransition: {
    intensity: { value: 5.0, range: [0.0, 10.0], type: 'Number', name: 'Intensity' },
    positionX: { value: 0.9, range: [0.0, 1.0], type: 'Number', name: 'Position X' },
    positionY: { value: 0.4, range: [0.0, 1.0], type: 'Number', name: 'Position Y' },
    orbit: { value: 0.1, range: [-1.0, 1.0], type: 'Number', name: 'Orbit' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  MirrorZoomTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  PixelateZoomTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.1 },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  ProjectorFailTransition: {
    lightBleed: { value: 3.0, range: [0.0, 5.0], type: 'Number', name: 'Light Bleed' },
    stretchX: { value: 1, range: [0.0, 3.0], type: 'Number', name: 'Stretch X' },
    stretchY: { value: 1, range: [0.0, 3.0], type: 'Number', name: 'Stretch Y' },
    tint: { value: 0xfffe80, type: 'Color', name: 'Tint' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  RadialTransition: {
    amount: { value: 1.0, range: [0.0, 10.0], type: 'Number', name: 'Amount' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  RollTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    stretch: { type: 'Number', name: 'stretch', range: [0, 100], value: 20 },
    wrap: {
      value: 0,
      range: [0, 1],
      steps: 1,
      type: 'List',
      name: 'Wrap',
      menu: ['Repeat', 'Mirror'],
    },
    direction: {
      value: 0,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  Roll3DTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    curve: { type: 'Number', name: 'barrel', range: [0, 2], value: 1 },
    roll: { type: 'Number', name: 'roll', range: [-1, 1], value: 0 },
    wrap: {
      value: 0,
      range: [0, 1],
      steps: 1,
      type: 'List',
      name: 'Wrap',
      menu: ['Repeat', 'Mirror'],
    },
    direction: {
      value: 0,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  RotationTransition: {
    rotation: { type: 'Number', name: 'rotation', range: [0, 360], value: 180 },
    blur: { type: 'Number', name: 'blur', range: [0, 1], value: 0.5 },
    direction: {
      value: 0,
      range: [0, 1],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Clockwise', 'Counter Clockwise'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  RotationZoomTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    direction: {
      value: 0,
      range: [0, 1],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Clockwise', 'Counter Clockwise'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  Rotation3DTransition: {
    direction: {
      value: 'up',
      range: ['up', 'down', 'left', 'right'],
      type: 'Direction',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  SlideTransition: {
    direction: {
      value: 'up',
      range: ['up', 'down', 'left', 'right'],
      type: 'Direction',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  SlideBlurTransition: {
    direction: {
      value: 0,
      range: [0, 1, 2, 3, 4, 5, 6, 7],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right', 'Up Left', 'Up Right', 'Down Left', 'Down Right'],
    },
    stretch: {
      value: 0.5,
      range: [0, 1],
      type: 'Number',
      name: 'Stretch',
    },
    curve: {
      value: 0.2,
      range: [0, 1],
      type: 'Number',
      name: 'Curve',
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  SlideBulgeTransition: {
    direction: {
      value: 0,
      range: [0, 1, 2, 3, 4, 5, 6, 7],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right', 'Up Left', 'Up Right', 'Down Left', 'Down Right'],
    },
    stretch: {
      value: 0.5,
      range: [0, 1],
      type: 'Number',
      name: 'Stretch',
    },
    bulge: {
      value: 0.5,
      range: [0, 1],
      type: 'Number',
      name: 'Bulge',
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  SlideWarpTransition: {
    direction: {
      value: 0,
      range: [0, 1, 2, 3, 4, 5, 6, 7],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right', 'Up Left', 'Up Right', 'Down Left', 'Down Right'],
    },
    stretch: {
      value: 0.5,
      range: [0, 1],
      type: 'Number',
      name: 'Stretch',
    },
    warp: {
      value: 0.5,
      range: [0, 1],
      type: 'Number',
      name: 'Warp',
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  ScanlineTransition: {
    direction: {
      value: 'up',
      range: ['up', 'down', 'left', 'right'],
      type: 'Direction',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  StretchTransition: {
    direction: {
      value: 0,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  StretchBlurTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    lightFlash: { value: 0.5, range: [0.0, 2.0], type: 'Number', name: 'Light Flash' },
    direction: {
      value: 'horizontal',
      range: ['horizontal', 'vertical'],
      type: 'Orientation',
      name: 'Orientation',
      menu: ['Horizontal', 'Vertical'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  ThresholdTransition: {
    mode: {
      value: 0,
      range: [0, 1],
      type: 'List',
      steps: 1,
      name: 'Mode',
      menu: ['From Light', 'From Dark'],
    },
    alpha: { value: 0.0, range: [0.0, 1.0], type: 'Number', name: 'Alpha' },
    color: { value: 0x0000ff, type: 'Color', name: 'Color' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  TwirlTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    direction: {
      value: 0,
      range: [0, 1],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Clockwise', 'Counter Clockwise'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  VaselineTransition: {
    lightBleed: { value: 0.5, range: [0.0, 1.0], type: 'Number', name: 'Light Bleed' },
    tint: { value: 0xfffe80, type: 'Color', name: 'Tint' },
    flash: { value: 1, range: [0.0, 1.0], type: 'Number', name: 'Flash' },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  VoronoiTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 100], value: 16.0 },
    easeIn: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'linear',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  WobbleSlideTransition: {
    scale: {
      value: 1,
      range: [0, 2],
      type: 'Number',
      name: 'Scale',
    },
    direction: {
      value: 0,
      range: [0, 1, 2, 3],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Up', 'Down', 'Left', 'Right'],
    },
    easeIn: {
      value: 'power4.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'power4.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  ZoomTransition: {
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  ZoomStretchTransition: {
    focusSize: { type: 'Number', name: 'focusSize', range: [0, 1], value: 0.1 },
    blur: { type: 'Number', name: 'blur', range: [0, 1], value: 0.5 },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },

  ZoomTwirlRGBTransition: {
    amount: { type: 'Number', name: 'amount', range: [0, 1], value: 0.5 },
    dispersion: { type: 'Number', name: 'dispersion', range: [0, 1], value: 0.5 },
    direction: {
      value: 0,
      range: [0, 1],
      steps: 1,
      type: 'List',
      name: 'Direction',
      menu: ['Clockwise', 'Counter Clockwise'],
    },
    easeIn: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeIn',
      menu: allEasingMenu,
    },
    easeOut: {
      value: 'expo.inOut',
      range: allEasing,
      type: 'Easing',
      name: 'easeOut',
      menu: allEasingMenu,
    },
  },
};

function isFxKey(key: string): key is FxKey {
  return fxKeys.includes(key as any);
}
function isTransitionKey(key: string): key is TransitionKey {
  return transitionKeys.includes(key as any);
}

export function getFilterProperties(key: string): FilterProperties {
  if (isFxKey(key)) {
    return fxProps[key];
  }
  if (isTransitionKey(key)) {
    return transitionProps[key];
  }

  console.warn(`Property definition not found for key: ${key}`);
  return {};
}

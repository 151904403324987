export class LeftPanelRender {
  private static instance: LeftPanelRender;

  private _shouldRender = true;

  public static get shared(): LeftPanelRender {
    if (!LeftPanelRender.instance) {
      LeftPanelRender.instance = new LeftPanelRender();
    }
    return LeftPanelRender.instance;
  }

  public get shouldRender(): boolean {
    return this._shouldRender;
  }

  public set shouldRender(value: boolean) {
    this._shouldRender = value;
  }
}

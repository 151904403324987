export const availableBuckets = [
  {
    name: 'republic-invideo',
    alias: 'd1efk3962q5p8g',
  },
  {
    name: 'invideo-images',
    alias: 'dspewdm6uepxg',
  },
  {
    name: 'invideo-uploads',
    alias: 'd3jrdn1nm7thr1',
  },
  {
    name: 'invideo-overlays',
    alias: 'd31jnkkjbfjpqx',
  },
  {
    name: 'asset-snapshot',
    alias: 'd24ab89t81mcub',
  },
  {
    name: 'invideo-uploads-image',
    alias: 'd3lpxzh90xab0r',
  },
  {
    name: 'invideo-block-assets',
    alias: 'd360zdw5tkn3j9',
  },
  {
    name: 'converted-webm-videos',
    alias: 'd1qlugglvc8yr3',
  },
  {
    name: 'invideo-uploads-videos',
    alias: 'd1kr40z4o5df3w',
  },
  {
    name: 'invideo-uploads-audio',
    alias: 'd1ahsq9yki2fk4',
  },
  {
    name: 'invideo-music',
    alias: 'd3aqxhqn7od95x',
  },
  {
    name: 'invideo-uploads-ap-south-1',
    alias: 'd1c7ic18uy0m4b',
  },
  {
    name: 'invideo-uploads-us-east-1',
    alias: 'd1kri5mif3adym',
  },
  {
    name: 'invideo-uploads-ap-southeast-1',
    alias: 'de298hc1e0fzm',
  },
  {
    name: 'invideo-uploads-eu-west-2',
    alias: 'd2uvx3ocukou0h',
  },
  {
    name: 'previews-480',
    alias: 'dulpz3imcxnof',
  },
  {
    name: 'asset-snapshot-webp-test',
    alias: 'db13gshimyyed',
  },
  {
    name: 's3-invideo-demo-bucket',
    alias: 'd23p9tb8gwzw00',
  },
  {
    name: 'storyblock-audios',
    alias: 'd4avkergo7ay2',
  },
];

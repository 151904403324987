import { distinctUntilChanged, map } from 'rxjs/operators';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { slice } from './paymentSlice';
import * as MasterStore from '../store/masterStore';

import {
  Gateway,
  PaymentModalRouteConfig,
  PaymentModalState,
  PaymentModalType,
  PaymentState,
  Plan,
  PricingModalText,
  PricingPlans,
  UserGateWay,
  UserGeoInfo,
} from './types';

export class Api {
  constructor(private stateFetcher: () => PaymentState, private dispatch: Dispatch<AnyAction>) {}

  get asDebugView() {
    return this.state;
  }

  private get state(): PaymentState {
    return this.stateFetcher();
  }

  private get actions() {
    return slice.actions;
  }

  observables = {
    pricingPlans$: this.sliceObservable((state) => state.pricingPlans),
    geoInfo$: this.sliceObservable((state) => state.geoInfo),
    countryCode$: this.sliceObservable((state) => state.countryCode),
    gateway$: this.sliceObservable((state) => state.gateway),
    currency$: this.sliceObservable((state) => state.currency),
    durationSwitch$: this.sliceObservable((state) => state.durationSwitch),
    coupon$: this.sliceObservable((state) => state.coupon),
    pricingModal$: this.sliceObservable((state) => state.pricingModal),
    paymentModalRoute$: this.sliceObservable((state) => state.paymentModalRoute),
    paymentModalState$: this.sliceObservable((state) => state.paymentModalState),
  };

  private sliceObservable<SliceT>(selector: (state: PaymentState) => SliceT) {
    return MasterStore.stores.payment$.pipe(
      map((st) => selector(st)),
      distinctUntilChanged(),
    );
  }

  openPricingModal(modalType: PaymentModalType, props?: any) {
    this.dispatch(this.actions.updatePaymentModalState({ modalType, props, isOpen: true }));
  }

  closePricingModal() {
    this.dispatch(this.actions.updatePaymentModalState({ modalType: 'NONE', isOpen: false }));
  }

  removeCoupon() {
    this.dispatch(
      this.actions.updateCouponState({
        couponCode: '',
        couponResponse: null,
        couponType: '',
        discountCouponApplied: false,
      }),
    );
  }

  get PricingPlans(): PricingPlans {
    return this.state.pricingPlans;
  }

  set PricingPlans(pricingPlans: PricingPlans) {
    this.dispatch(this.actions.updatePricingPlans(pricingPlans));
  }

  get seletedPlan(): Plan | null {
    return this.state.selectedPlan;
  }

  set seletedPlan(seletedPlan: Plan | null) {
    this.dispatch(this.actions.updateSeletedPlan(seletedPlan));
  }

  get geoInfo(): UserGeoInfo {
    return this.state.geoInfo;
  }

  set geoInfo(geoInfo: UserGeoInfo) {
    this.dispatch(this.actions.updateGeoInfo(geoInfo));
  }

  get countryCode(): string {
    return this.state.countryCode;
  }

  set countryCode(value: string) {
    this.dispatch(this.actions.updateCountryCode(value));
  }

  get gateway(): Gateway {
    return this.state.gateway;
  }

  set gateway(value: Gateway) {
    this.dispatch(this.actions.updateGateway(value));
  }

  get currency(): string {
    return this.state.currency;
  }

  set currency(value: string) {
    this.dispatch(this.actions.updateCurrency(value));
  }

  get durationSwitch(): boolean {
    return this.state.durationSwitch;
  }

  set durationSwitch(value: boolean) {
    this.dispatch(this.actions.updateDurationSwitch(value));
  }

  get couponResponse(): Record<string, any> {
    return this.state.coupon.couponResponse || {};
  }

  set couponResponse(value: Record<string, any>) {
    this.dispatch(this.actions.updateCouponResponse(value));
  }

  get couponCode(): string {
    return this.state.coupon.couponCode;
  }

  set couponCode(value: string) {
    this.dispatch(this.actions.updateCouponCode(value));
  }

  get couponType(): string {
    return this.state.coupon.couponType;
  }

  set couponType(value: string) {
    this.dispatch(this.actions.updateCouponType(value));
  }

  get discountCouponApplied(): boolean {
    return this.state.coupon.discountCouponApplied;
  }

  set discountCouponApplied(value: boolean) {
    this.dispatch(this.actions.updateDiscountCouponApplied(value));
  }

  get receiptData(): Record<string, any> | null {
    return this.state.receiptData;
  }

  set receiptData(value: Record<string, any> | null) {
    this.dispatch(this.actions.updateReceiptData(value));
  }

  get checkoutError(): Record<string, any> | null {
    return this.state.checkoutError;
  }

  set checkoutError(value: Record<string, any> | null) {
    this.dispatch(this.actions.updateCheckoutError(value));
  }

  get pricingModal(): PricingModalText | null {
    return this.state.pricingModal;
  }

  set pricingModal(value: PricingModalText | null) {
    this.dispatch(this.actions.updatePricingModal(value));
  }

  get paymentModalRoute(): PaymentModalRouteConfig {
    return this.state.paymentModalRoute;
  }

  set paymentModalRoute(value: PaymentModalRouteConfig) {
    this.dispatch(this.actions.updatePaymentModalRoute(value));
  }

  get paymentModalstate(): PaymentModalState {
    return this.state.paymentModalState;
  }

  set paymentModalstate(value: PaymentModalState) {
    this.dispatch(this.actions.updatePaymentModalState(value));
  }

  get userGateway(): UserGateWay {
    return this.state.userGateway;
  }

  set userGateway(value: UserGateWay) {
    this.dispatch(this.actions.updateUserGateway(value));
  }

  get paymentSuccessProps(): Record<string, any> | null {
    return this.state.paymentSuccessProps;
  }

  set paymentSuccessProps(value: Record<string, any> | null) {
    this.dispatch(this.actions.updatePaymentSuccessProps(value));
  }
}

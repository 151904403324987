import {
  isInvisibleBackgroundComposite,
  isLogoPlaceholderComposite,
  isTransitionComposite,
} from './getCompositeTypes';
import { Master } from '../../../jsonTypes';

export const isInvisibleComposite = (composite: Master.CompositeJSON) => {
  const isInvisibleCompositeRules = [
    isInvisibleBackgroundComposite,
    isLogoPlaceholderComposite,
    isTransitionComposite,
  ];
  for (const rule of isInvisibleCompositeRules) {
    if (rule(composite)) {
      return true;
    }
  }
  return false;
};

export const isVisibleComposite = (composite: Master.CompositeJSON) => {
  return !isInvisibleComposite(composite);
};

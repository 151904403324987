import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _TimeRegistry from './timeRegistry';
import {
  Mode,
  PreviewType,
  SlateType,
  UserActionSource,
  UserActionSourceForSeekTimeAnalytics,
} from './types';
export const TimeRegistry = _TimeRegistry;

export interface State {
  slateType: SlateType; // frame or classic
  // mode represents user intent
  mode: Mode; // Treat "loading" as paused. ("editor" | "loading" | "playing" | "paused")
  isScrubbing: boolean;
  isSlateSeeking: boolean;

  volume: number;
  speed: number;
  isMuted: boolean;
  loop: boolean;
  previewType: PreviewType;

  seekAction?: UserActionSourceForSeekTimeAnalytics;
  previewAction?: UserActionSource;
  isSnapped: boolean;
  tPreviewStart: number;
  slateBounds: { tSlateStart: number; tSlateEnd: number };
}

export const INITIAL_STATE: State = {
  slateType: SlateType.NONE,
  mode: 'editor',
  isScrubbing: false,
  isSlateSeeking: false,
  volume: 1,
  speed: 1,
  isMuted: false,
  loop: false,
  previewType: PreviewType.BASIC_TIMELINE_FULL_PREVIEW,
  isSnapped: false,
  tPreviewStart: 0,
  slateBounds: { tSlateStart: 0, tSlateEnd: 0 },
};
export const slice = createSlice({
  name: 'video',
  initialState: INITIAL_STATE,
  reducers: {
    playPauseToggled: (
      state,
      action: PayloadAction<
        _TimeRegistry.TimeCheck & {
          previewType: PreviewType;
          actionSource: UserActionSource;
        }
      >
    ) => {
      const { previewType, actionSource } = action.payload;

      if (state.mode === 'editor') {
        state.mode = 'loading';
        state.tPreviewStart = TimeRegistry.t();
      } else if (state.mode === 'playing') {
        state.mode = 'paused';
      } else if (state.mode === 'paused') {
        state.mode = 'playing';
      }

      state.previewType = previewType;
      state.previewAction = actionSource;
    },
    previewTypeUpdated: (
      state,
      action: PayloadAction<_TimeRegistry.TimeCheck & { previewType: PreviewType }>
    ) => {
      state.previewType = action.payload.previewType;
    },
    playRequested: (state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {
      state.mode = 'playing';
    },
    pauseRequested: (state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {
      state.mode = 'paused';
    },
    editRequested: (state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {
      state.mode = 'editor';
    },

    modeUpdated: (state, action: PayloadAction<_TimeRegistry.TimeCheck & { mode: Mode }>) => {
      state.mode = action.payload.mode;
    },
    slateSeekStarted: (state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {
      state.isSlateSeeking = true;
    },
    slateSeekCompleted: (state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {
      state.isSlateSeeking = false;
    },
    scrubStarted: (
      state,
      action: PayloadAction<
        _TimeRegistry.TimeCheck & { seekAction?: UserActionSourceForSeekTimeAnalytics }
      >
    ) => {
      state.isScrubbing = true;
      if (action.payload.seekAction) {
        state.seekAction = action.payload.seekAction;
      }
    },
    scrubActionChanged: (
      state,
      action: PayloadAction<
        _TimeRegistry.TimeCheck & {
          seekAction: UserActionSourceForSeekTimeAnalytics;
        }
      >
    ) => {
      const { seekAction } = action.payload;
      if (!state.isScrubbing) {
        state.isScrubbing = true;
      }
      state.seekAction = seekAction;
    },
    scrubCompleted: (state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {
      state.isScrubbing = false;
      state.seekAction = undefined;
    },
    slateTypeUpdated: (
      state,
      action: PayloadAction<_TimeRegistry.TimeCheck & { slateType: SlateType }>
    ) => {
      state.slateType = action.payload.slateType;
    },

    fpsUpdated: (_state, _action: PayloadAction<_TimeRegistry.TimeCheck>) => {},
    debugTUpdated: (
      _state,
      _action: PayloadAction<_TimeRegistry.TimeCheck & { command: string }>
    ) => {},
    volumeUpdated: (state, action: PayloadAction<_TimeRegistry.TimeCheck & { volume: number }>) => {
      state.volume = Math.max(Math.min(action.payload.volume, 1), 0);
    },
    speedUpdated: (state, action: PayloadAction<_TimeRegistry.TimeCheck & { speed: number }>) => {
      state.speed = Math.min(Math.max(action.payload.speed, 50), 1);
    },
    loopUpdated: (state, action: PayloadAction<_TimeRegistry.TimeCheck & { loop: boolean }>) => {
      state.loop = action.payload.loop;
    },
    reset: (state) => {
      Object.keys(INITIAL_STATE).forEach((key) => {
        (state as any)[key] = INITIAL_STATE[key as keyof State];
      });
    },
    snapUpdated: (
      state,
      action: PayloadAction<_TimeRegistry.TimeCheck & { isSnapped: boolean }>
    ) => {
      state.isSnapped = action.payload.isSnapped;
    },
    updatedSlateBounds: (
      state,
      action: PayloadAction<{ tSlateStart: number; tSlateEnd: number }>
    ) => {
      state.slateBounds = action.payload;
    },
  },
});

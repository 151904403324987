import {
  SelectableProperty,
  State,
  slice,
  ProjectMode,
  ExportSettings,
  AudioBlockPreviousValues,
  Clipboard,
  INITIAL_STATE,
} from './projectSlice';
import { Master, Shadow, Slate, Template } from '../jsonTypes';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as ShadowRegistry from './shadowRegistry';
import * as Registry from './masterRegistry';
import * as SlateRegistry from './slateRegistry';
import { TimeBounds } from './types';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import * as MasterStore from '../store/masterStore';
import { cloneJSON } from '../utils';
import { analyticsEvent, BLANK_TEMPLATE_ID, LOGO_PLACEHOLDER_URLS } from '.';
import debug from 'debug';
import {
  reCalcGrainsForComponent,
  calcGrainsForComponents,
  calcGrainsForNewBlock,
  recalculateGrains,
} from '../slateStore/skia';
import {
  AdjustmentFilterProperties,
  ColorCorrection,
  ColorJSON,
  Effect,
  VideoJSON,
} from '../jsonTypes/SlateJSON';
import { publishTimeMutation } from './utils/publishTimeMutations';
import { getTemplateType } from '../utilities';
import { publishAudioMutations, recalculateAudioList } from './utils/publishAudioMutations';
import { Store } from '../store';

const log = debug('shell-projectapi');

export type SlateEntities = SlateRegistry.SlateEntities;

export class Api {
  constructor(private stateFetcher: () => State, private dispatch: Dispatch<AnyAction>) {}

  private get state(): State {
    return this.stateFetcher();
  }
  private get actions() {
    return slice.actions;
  }

  private sliceObservable = <SliceT>(selector: (state: State) => SliceT) => {
    return MasterStore.stores.project$.pipe(
      map((st) => selector(st)),
      distinctUntilChanged(),
    );
  };

  get asDebugView() {
    return this.state;
  }

  // PUBLIC API

  observables = {
    videoId$: this.sliceObservable((st) => st.masterJson.master_video_id),
    duration$: this.sliceObservable((st) => st.duration),
    dimension$: this.sliceObservable((st) => st.dimension),
    masterJson$: this.sliceObservable((st) => st.masterJson),
    dimensionJson$: this.sliceObservable((st) => st.dimensionJson),
    selectedBlockIndex$: this.sliceObservable((st) => st.selectedBlockIndex),
    selectedBlockId$: this.sliceObservable((st) =>
      st.dimensionJson.blocks[st.selectedBlockIndex]
        ? st.dimensionJson.blocks[st.selectedBlockIndex].id
        : '.00000',
    ),
    selectedComponentId$: this.sliceObservable((st) => st.selectedComponentId),
    selectedCompositeId$: this.sliceObservable((st) => st.selectedCompositeId),
    selectedProperty$: this.sliceObservable((st) => st.selectedProperty),
    selectedGapId$: this.sliceObservable((st) => st.selectedGapId),
    selectedBlock$: this.sliceObservable(
      (state) => state.dimensionJson.blocks[state.selectedBlockIndex]!,
    ),
    changedFonts$: this.sliceObservable((st) => st.fonts),
    ...ShadowRegistry.observables,
    slateErrors$: ShadowRegistry.observables.dimension.changed$.pipe(map(() => this.slateErrors)),
    slateBlocks$: ShadowRegistry.observables.dimension.changed$.pipe(map(() => this.slateBlocks)),
    slateLayers$: ShadowRegistry.observables.dimension.changed$.pipe(map(() => this.slateLayers)),
    slateRoot$: ShadowRegistry.observables.dimension.changed$.pipe(map(() => this.slateRoot)),
    updateBlockline$: this.sliceObservable((st) => st.updateBlockline),
    templateJson$: this.sliceObservable((st) => st.templateJson),
    analyticsEventToBeSent$: this.sliceObservable((st) => st.analyticsEventToBeSent),
    selectedReviewBoxId$: this.sliceObservable((st) => st.selectedReviewBoxId),
    templateType$: this.sliceObservable((st) => st.templateType),
    blocksPreviousValues$: this.sliceObservable((st) => st.audio.blocksPreviousValues),
  };

  get fps(): number {
    return this.state.fps;
  }

  set fps(fps: number) {
    this.dispatch(this.actions.fpsUpdated({ fps }));
  }

  //// duration and dimension

  get videoId(): number {
    return this.state.masterJson.master_video_id;
  }

  get duration(): number {
    return this.state.duration;
  }

  get dimension(): Master.Dimension {
    return this.state.dimension;
  }

  get dpWidth(): number {
    return this.state.dimensionJson.screen_size[0] ?? 1920;
  }

  get dpHeight(): number {
    return this.state.dimensionJson.screen_size[1] ?? 1080;
  }

  get timeBounds(): TimeBounds {
    return {
      tStart: 0,
      tEnd: this.state.duration,
    };
  }

  /**@description  time bounds including the block transitions.*/
  get absoluteBlockTimeBounds(): TimeBounds {
    const block = this.selectedBlock;
    return {
      tStart: block.duration.absolute_start,
      tEnd: block.duration.absolute_end,
    };
  }

  /**@description  time bounds without the block transitions.*/
  get blockTimeBounds(): TimeBounds {
    const block = SlateRegistry.getSlateBlock(this.selectedBlock.id)!;
    return {
      tStart: block.tSceneStart,
      tEnd: block.tSceneEnd,
    };
  }

  //// slate
  get slateState() {
    return SlateRegistry.getState();
  }

  get slateErrors() {
    return SlateRegistry.getErrors();
  }

  get slateRoot() {
    return SlateRegistry.getSlateRoot();
  }

  get slateBlocks(): Slate.BlockJSON[] {
    return SlateRegistry.getSlateRoot().blocks.map(this.getSlateByIdentifier).filter(isPresent);
  }

  get slateLayers() {
    return SlateRegistry.getSlateRoot().layers.map(this.getSlateByIdentifier).filter(isPresent);
  }

  get appliedColorId() {
    return this.state.masterJson.applied_colors_id;
  }

  isSlateLayerEmpty(layerId: string, sceneId: string) {
    const clips = this.getSlateCompositesOfLayer(layerId, sceneId).filter((c) => c.visible);
    return !clips.length;
  }

  getSlateAudioByLayer(layerId: string) {
    return this.getSlateAudioBlocks()
      .map((a) => this.getSlateAudio(a.id))
      .filter(isPresent)
      .filter((a) => a.layerId === layerId);
  }

  isSlateLayerLocked(layerId: string) {
    const layer = this.getSlateLayer(layerId);
    const isVideo = layer?.layerType === 'video';
    const activeSceneId = this.selectedBlockId;

    const videoClips = this.getSlateCompositesOfLayer(layerId, activeSceneId).filter(
      (c) => c.visible,
    );

    const audioClips = this.getSlateAudioByLayer(layerId);
    return isVideo
      ? videoClips.length
        ? videoClips.every((c) => c.locked)
        : false
      : audioClips.length
      ? audioClips.every((c) => c.locked)
      : false;
  }

  updateLayerLock(id: string, isLocked: boolean) {
    SlateRegistry.patchSlateLayerLock(id, isLocked);
  }

  getSlateBlockComposites(id: string) {
    const block = this.getSlateBlock(id);
    return this.getSlateCompositeChildren(block);
  }

  getSlateCompositesOfLayer(layerId: string, sceneId: string) {
    const composites = this.getSlateBlockComposites(sceneId);
    return composites.filter((comp) => comp.layerId === layerId);
  }

  getSlateComposite(id: string): Slate.CompositeJSON | null {
    return SlateRegistry.getSlateComposite(id);
  }
  getSlateComponent(id: string): Slate.CompositeJSON | Slate.AnyComponentJSON | null {
    return SlateRegistry.getSlateComponent(id);
  }
  getSlateWatermark(id: string): Slate.WatermarkJSON | null {
    return SlateRegistry.getSlateWatermark(id);
  }

  getSlateCompositeChildren(json: Slate.AnyJSON | null): Slate.CompositeJSON[] {
    if (!json) return [];
    const identifiers = 'componentIds' in json ? json.componentIds : [];
    return identifiers
      .filter(({ type }) => type === 'composite')
      .map(({ id }) => SlateRegistry.getSlateComposite(id))
      .filter(SlateRegistry.isPresent);
  }

  getSlateChildren(json: Slate.AnyJSON | null): (Slate.CompositeJSON | Slate.AnyComponentJSON)[] {
    if (!json) return [];
    const identifiers = 'componentIds' in json ? json.componentIds : [];
    return identifiers
      .map(({ id }) => SlateRegistry.getSlateComponent(id))
      .filter(SlateRegistry.isPresent);
  }

  getSlateChildrenById(compositeId: string): (Slate.CompositeJSON | Slate.AnyComponentJSON)[] {
    const json = SlateRegistry.getSlateComposite(compositeId);
    return this.getSlateChildren(json);
  }

  getSlateBlock(id: string): Slate.BlockJSON | null {
    return SlateRegistry.getSlateBlock(id);
  }

  getSlateTransition(id: string): Slate.TransitionJSON | null {
    return SlateRegistry.getSlateTransition(id);
  }

  getSlateBlocks() {
    return SlateRegistry.getSlateRoot().blocks;
  }

  getSlateTransitions() {
    return SlateRegistry.getSlateRoot().transitions;
  }

  getSlateLayers() {
    return SlateRegistry.getSlateRoot().layers;
  }

  getSlateGaps() {
    return SlateRegistry.getSlateRoot().gaps;
  }

  getSlateGapById(id: string) {
    return SlateRegistry.getSlateGap(id);
  }

  getSlateAudioBlocks() {
    return SlateRegistry.getSlateRoot().audioBlocks;
  }

  getSlateLayer(id: string): Slate.LayerJSON | null {
    return SlateRegistry.getSlateLayer(id);
  }

  getSlateAudio(id: string): Slate.AudioJSON | null {
    return SlateRegistry.getSlateAudio(id);
  }

  getSlateByType<EntityType extends SlateRegistry.SlateEntities['type']>(
    type: EntityType,
    id: string,
  ) {
    return SlateRegistry.getByType(type, id);
  }
  getSlateByIdentifier<EntityType extends SlateRegistry.SlateEntities['type']>(
    identifier: Slate.ComponentIdentifier<EntityType>,
  ) {
    return SlateRegistry.getByType<EntityType>(identifier.type, identifier.id);
  }

  getSlateById(id: string) {
    return SlateRegistry.getById(id);
  }

  addSlateLayer(layer: Slate.LayerJSON) {
    return SlateRegistry.addSlatelayer(layer);
  }

  publishAudioMutations() {
    publishAudioMutations();
  }

  publishTimeMutations() {
    publishTimeMutation();
  }

  patchSelected(id: string, selected: boolean) {
    return SlateRegistry.patchSelected(id, selected);
  }

  patchSlateComponentColorAtIndex(id: string, color: Slate.ColorJSON, ref: Slate.ColorRef) {
    return SlateRegistry.patchSlateComponentColorAtIndex(id, color, ref);
  }
  patchSlateComponentColorCorrection(id: string, colorCorrection: ColorCorrection) {
    return SlateRegistry.patchSlateComponentColorCorrection(id, colorCorrection);
  }

  patchSlateVideoPlaybackRate(
    id: string,
    properties: { speed?: number; playbackRateStrategy?: VideoJSON['playbackRateStrategy'] },
  ) {
    return SlateRegistry.patchSlateVideoPlaybackRate(id, properties);
  }

  patchSlateVideoTrim(
    id: string,
    properties: {
      trim: {
        startTime: number;
        endTime: number;
      }[];
      loop: boolean;
    },
  ) {
    return SlateRegistry.patchSlateVideoTrim(id, properties);
  }

  patchSlateAudioTrim(
    id: string,
    properties: {
      trimSection: {
        startTime: number;
        endTime: number;
      };
      loop: boolean;
    },
  ) {
    return SlateRegistry.patchSlateAudioTrim(id, properties);
  }

  patchSlateAudioVolume(
    id: string,
    properties: {
      volume?: number;
      duckedVolume?: number;
      isDucked?: boolean;
    },
  ) {
    return SlateRegistry.patchSlateAudioVolume(id, properties);
  }

  patchSlateCompositeTime(
    id: string,
    properties: {
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
      tIn?: Slate.seconds;
      tOut?: Slate.seconds;
    },
  ) {
    return SlateRegistry.patchSlateCompositeTime(id, properties);
  }

  patchSlateBlockTime(
    id: string,
    properties: {
      tSceneStart?: Slate.seconds;
      tSceneEnd?: Slate.seconds;
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
      tIn?: Slate.seconds;
      tOut?: Slate.seconds;
    },
  ) {
    return SlateRegistry.patchSlateBlockTime(id, properties);
  }

  patchSlateAudioConfig(
    id: string,
    properties: {
      audioType: Slate.AUDIO_TYPE;
    },
  ) {
    return SlateRegistry.patchSlateAudioConfig(id, properties);
  }

  patchSlateAudioTime(
    id: string,
    properties: {
      tStart: Slate.seconds;
      tEnd: Slate.seconds;
    },
  ) {
    return SlateRegistry.patchSlateAudioTime(id, properties);
  }

  patchCompositeLayerId(id: string, layer_id: string) {
    return SlateRegistry.patchSlateCompositeLayerId(id, layer_id);
  }

  patchLayers(layers: Shadow.LayerJSON[]) {
    return SlateRegistry.patchSlateLayers(layers);
  }

  patchSlateComposite(
    id: string,
    properties: {
      offsetX?: number;
      offsetY?: number;
      width: number;
      height: number;
      angle?: number;
      opacity?: number;
    },
  ) {
    return SlateRegistry.patchSlateComposite(id, properties);
  }

  patchSlateTransitionTime(
    id: string,
    properties: {
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
    },
  ) {
    return SlateRegistry.patchSlateTransitionTime(id, properties);
  }

  patchSlatePositionComposite(
    id: string,
    properties: {
      offsetX?: number;
      offsetY?: number;
      width: number;
      height: number;
      angle?: number;
      opacity?: number;
    },
    childProperties: {
      offsetX?: number;
      offsetY?: number;
      width: number;
      height: number;
      angle?: number;
      opacity?: number;
    }[],
  ) {
    return SlateRegistry.patchSlatePositionComposite(id, properties, childProperties);
  }

  patchSlateParagraphVisibility(id: string, hidden: boolean) {
    return SlateRegistry.patchSlateParagraphVisibility(id, hidden);
  }

  patchSlateComponentFitAlignment(id: string, fit: { x: number; y: number }) {
    return SlateRegistry.patchSlateComponentFitAlignment(id, fit);
  }

  patchSlateComponentAdjustmentFilters(
    id: string,
    property: AdjustmentFilterProperties,
    value: number,
  ) {
    return SlateRegistry.patchSlateComponentAdjustmentFilters(id, property, value);
  }

  patchSlateComponentEffects(id: string, effects: Array<Effect>) {
    return SlateRegistry.patchSlateComponentEffects(id, effects);
  }

  patchSlateCompositeForceVisibility(id: string, visibility: boolean) {
    return SlateRegistry.patchSlateCompositeForceVisibility(id, visibility);
  }
  patchSlateCompositeForceInvisibility(id: string, isInvisible: boolean) {
    return SlateRegistry.patchSlateCompositeForceInvisibility(id, isInvisible);
  }
  patchSlateWatermarkCtaUrl(id: string, url: string) {
    return SlateRegistry.patchSlateWatermarkCtaUrl(id, url);
  }
  patchSlateImageUrl(id: string, url: string) {
    return SlateRegistry.patchSlateImageUrl(id, url);
  }

  updateSlate() {
    SlateRegistry.update(this.shadowJson);
  }

  addSlateDummyBlockAtIndex(index: number, duration: number) {
    SlateRegistry.addPlaceholderBlockAtIndex(index, duration);
  }

  patchToNearestFrame(rate: number) {
    SlateRegistry.patchToNearestFrame(rate);
  }

  //// shadow

  get shadowJson(): Shadow.JSON {
    return ShadowRegistry.getShadowJson();
  }

  get shadowStoryJson(): Shadow.StoryJSON {
    return ShadowRegistry.getShadowStoryJson();
  }

  get shadowDimensionJson(): Shadow.DimensionJSON {
    return ShadowRegistry.getShadowDimensionJson();
  }

  markShadowAsMutated() {
    ShadowRegistry.markShadowAsMutated(performance.now());
    this.dispatch(this.actions.shadowMutated());
    ShadowRegistry.fireObservables();
  }

  getShadowById(
    id: string,
  ): Shadow.BlockJSON | Shadow.LayerJSON | Shadow.AudioBlockJSON | Shadow.ComponentJSON | null {
    return ShadowRegistry.getShadowById(id);
  }

  getShadowBlockById(id: string): Shadow.BlockJSON | null {
    return ShadowRegistry.getShadowBlockById(id);
  }

  getShadowBlockByIndex(index: number): Shadow.BlockJSON | null {
    const dim = ShadowRegistry.getShadowDimensionJson();
    return dim.blocks[index];
  }

  getShadowBlocks(): Shadow.BlockJSON[] {
    return ShadowRegistry.getShadowDimensionJson().blocks;
  }

  getShadowBlockComposites(sceneId: string) {
    return this.getShadowBlockById(sceneId)?.components ?? [];
  }

  getShadowCompositesOfLayer(layerId: string, sceneId: string) {
    const composites = this.getShadowBlockComposites(sceneId);
    return composites.filter((comp) => comp.layer_id === layerId);
  }

  getShadowAudioBlocks(): Shadow.AudioBlockJSON[] {
    return ShadowRegistry.getShadowDimensionJson().audio_blocks;
  }

  getShadowLayerById(id: string): Shadow.LayerJSON | null {
    return ShadowRegistry.getShadowLayerById(id);
  }

  getShadowAudioBlockById(id: string): Shadow.AudioBlockJSON | null {
    return ShadowRegistry.getShadowAudioBlockById(id);
  }

  getShadowComponentById(id: string): Shadow.ComponentJSON | null {
    return ShadowRegistry.getShadowComponentById(id);
  }

  publishShadow() {
    ShadowRegistry.markShadowAsMutated(performance.now());
    const json = ShadowRegistry.getMasterJson();
    this.dispatch(this.actions.shadowPublished({ json }));
  }

  fireShadowRegistryObservables() {
    ShadowRegistry.fireObservables();
  }

  publishMasterToStory() {
    const masterJson = cloneJSON(this.masterJson);
    const dim = cloneJSON(this.dimensionJson);
    const shadow = this.shadowStoryJson;

    const storyDim: Master.StoryDimensionJSON = {
      ...dim,
      headline: dim.headline ?? '',
      text: dim.text ?? '',
      step: dim.step ?? 0,
      spans: dim.spans ?? [],
      video_properties: dim.video_properties as Master.VideoProperties,
      listicle_flag: dim.listicle_flag ?? 0,
      transitions: dim.transitions ?? [],
      default_transition: dim.default_transition ?? 0,
    };

    const storyJson: Master.StoryJSON = {
      master_video_id: masterJson.master_video_id,
      dimensions: masterJson.dimensions,
      headline: masterJson.headline,
      text: masterJson.text,
      uploads: masterJson.uploads ?? [],
      [this.dimension]: storyDim,
    };

    shadow[this.dimension] = storyDim;
    this.storyJson = storyJson;
  }

  //// story json

  get mode(): ProjectMode {
    return this.state.mode;
  }

  set mode(mode: ProjectMode) {
    this.dispatch(this.actions.modeUpdated({ mode }));
  }

  /**
   * @depricated
   */
  get storyJson(): Master.StoryJSON | null {
    return this.state.storyJson;
  }

  /**
   * @depricated
   */
  set storyJson(json: Master.StoryJSON | null) {
    this.dispatch(this.actions.storyJsonUpdated({ json }));
    ShadowRegistry.fireObservables();
  }

  //// master json

  get masterJson(): Master.JSON {
    return this.state.masterJson;
  }

  set masterJson(json: Master.JSON | null) {
    if (json && !ShadowRegistry.hasMasterJsonChanged(json)) {
      log('optimization: masterJsonUpdated is noop');
      return;
    }
    this.dispatch(this.actions.masterJsonUpdated({ json }));
    ShadowRegistry.fireObservables();
  }

  get dimensionJson(): Master.DimensionJSON {
    return this.state.dimensionJson;
  }
  set dimensionJson(json: Master.DimensionJSON | null) {
    if (!json) {
      this.masterJson = null;
      return;
    }
    this.dispatch(this.actions.dimensionJsonUpdated({ json }));
    ShadowRegistry.fireObservables();
  }

  setMasterJson(json: Master.JSON | null, selectedBlockIndex: number) {
    if (json && !ShadowRegistry.hasMasterJsonChanged(json)) {
      log('optimization: masterJsonUpdated is noop');
      return;
    }
    this.dispatch(this.actions.masterJsonUpdated({ json, selectedBlockIndex }));
    ShadowRegistry.fireObservables();
  }

  setComponent(json: Master.ComponentJSON) {
    this.dispatch(this.actions.componentUpdated({ json }));
    ShadowRegistry.fireObservables();
  }

  async setTextComponent(
    textJson: Master.TextJSON,
    needsGrainRecalculation = false,
    parentComposite?: Master.CompositeJSON,
  ): Promise<any> {
    const MAX_FONT_SIZE = 999;
    const exec = (json: Master.ComponentJSON) => {
      this.dispatch(this.actions.componentUpdated({ json }));
      ShadowRegistry.fireObservables();
      try {
        textJson = json as Master.TextJSON;
      } catch (e) {
        console.warn('setting readonly failed');
      }
    };

    if (!needsGrainRecalculation) {
      exec(textJson);
      return Promise.resolve();
    }
    const parent = (
      parentComposite !== undefined
        ? parentComposite
        : this.getComponentById(textJson.id.substr(0, textJson.id.lastIndexOf('.')))
    ) as Master.CompositeJSON;
    const textComponents = parent.components?.filter(
      (c) => c.sub_type === 'text' || c.type === 'text',
    );
    const numTextComponent = textComponents?.length;
    if (numTextComponent === 1) {
      const parentComposite = await recalculateGrains({ json: textJson, parent });
      parentComposite.layer_name = textJson.text;
      exec(parentComposite);
      return Promise.resolve();
    }
    let newComposite = await recalculateGrains({ json: textJson, parent });
    let texts = newComposite.components
      ?.filter((c) => (c.sub_type === 'text' || c.type === 'text') && c.id !== textJson.id)
      .map((c) => c as Master.TextJSON);
    for (let i = 0; i < texts.length; i++) {
      const iTextJson = texts[i];
      newComposite = await recalculateGrains({
        json: iTextJson,
        maxFontSize: MAX_FONT_SIZE,
        parent: newComposite,
      });
    }

    const maxFontSize = newComposite.components
      .filter(
        (c) =>
          (c.sub_type === 'text' || c.type === 'text') &&
          (c as Master.TextJSON).highlighted_text.trim() !== '',
      )
      .map((c) => c as Master.TextJSON)
      .map((comp) => comp.metrics.style.font_size)
      .reduce((min, curr) => Math.min(min, curr), MAX_FONT_SIZE);

    let composite = newComposite;
    let fullText = '';
    texts = newComposite.components
      ?.filter((c) => c.sub_type === 'text' || c.type === 'text')
      .map((c) => c as Master.TextJSON);
    for (let i = 0; i < texts.length; i++) {
      const iTextJson = texts[i];
      fullText += ` ${iTextJson.text}`;
      if (iTextJson.metrics.style.font_size !== maxFontSize) {
        composite = await recalculateGrains({
          json: iTextJson,
          maxFontSize,
          parent: composite,
        });
      }
    }
    composite.layer_name = fullText;
    exec(composite);
  }

  setBlock(json: Master.BlockJSON) {
    this.dispatch(this.actions.blockUpdated({ json }));
    ShadowRegistry.fireObservables();
  }

  getMasterById(id: string): Master.AnyJSON | null {
    return Registry.getAnyById(this.state.dimensionJson, id);
  }

  getComponentById(id: string): Master.AnyComponentJSON | null {
    return Registry.getComponentById(this.state.dimensionJson, id);
  }

  getMasterBlockIdFor(id: string): string | null {
    return Registry.blockIdFor(id);
  }

  get projectScenes() {
    return this.state.projectScenes;
  }

  set projectScenes(scenes: Master.BlockJSON[]) {
    this.dispatch(this.actions.projectScenesUpdated({ scenes }));
  }

  addProjectScene(scene: Master.BlockJSON) {
    this.dispatch(this.actions.projectSceneAdded({ scene }));
  }

  //// select/delete block, component or property

  get selectedBlockIndex(): number {
    return this.state.selectedBlockIndex;
  }

  get selectedShadowBlock(): Shadow.BlockJSON {
    return ShadowRegistry.getShadowBlockById(this.selectedBlockId)!;
  }

  get selectedBlock(): Master.BlockJSON {
    return this.state.dimensionJson.blocks[this.state.selectedBlockIndex]!;
  }

  get selectedBlockId(): string {
    return this.selectedBlock?.id ?? '';
  }

  get selectedComponentId(): string {
    return this.state.selectedComponentId;
  }

  get selectedCompositeId(): string {
    return this.state.selectedCompositeId;
  }

  get selectedGapId(): string {
    return this.state.selectedGapId;
  }

  get selectedProperty(): SelectableProperty {
    return this.state.selectedProperty;
  }

  selectBlockIndex(index: number) {
    if (this.state.selectedBlockIndex === index) {
      log('optimization: blockIndexSelected is noop');
      return;
    }

    this.dispatch(this.actions.blockIndexSelected({ index }));
  }

  selectBlock(id: string) {
    const index = this.state.dimensionJson.blocks.findIndex((b) => b.id === id);
    if (index < 0) {
      throw new Error(`could not find block with id: ${id}`);
    }
    this.dispatch(this.actions.blockIndexSelected({ index }));
  }

  selectComponent(id: string, selectedProperty: SelectableProperty = SelectableProperty.NONE) {
    if (
      this.state.selectedComponentId === id &&
      this.state.selectedProperty === (selectedProperty ?? SelectableProperty.NONE)
    ) {
      log('optimization: componentSelected is noop');
      return;
    }

    this.dispatch(this.actions.componentSelected({ id, selectedProperty }));
  }

  selectGap(id: string) {
    if (this.state.selectedGapId === id) {
      log('optimization: gapSelected is noop');
      return;
    }

    this.dispatch(this.actions.gapSelected({ id }));
  }

  deleteComponent(id: string) {
    const clonedMasterJson: Master.JSON | null = JSON.parse(JSON.stringify(this.masterJson));
    const blockIndex = this.selectedBlockIndex;
    const aspectRatio = this.dimension;
    if (clonedMasterJson) {
      const blockJson = clonedMasterJson?.[aspectRatio]?.blocks[blockIndex];
      const index = blockJson?.components?.findIndex((it) => it.id === id) ?? -1;
      if (index > -1) {
        const compositeJson = blockJson?.components?.[index] as Master.CompositeJSON;
        if (compositeJson?.type === 'composite' && compositeJson?.sub_type === 'logo_composite') {
          clonedMasterJson[aspectRatio]!.blocks[blockIndex].components[index] =
            getHiddenLogoPlaceholderComposite(compositeJson);
        } else {
          clonedMasterJson?.[aspectRatio]?.blocks[blockIndex]?.components?.splice(index, 1);
        }
        this.masterJson = clonedMasterJson;
      }
    }
  }

  deleteComponentFromAllScene(
    findComponentFn: (component: Master.ComponentJSON | Master.IgnoredComponentJSON) => boolean,
  ) {
    const clonedMasterJson: Master.JSON | null = JSON.parse(JSON.stringify(this.masterJson));
    const aspectRatio = this.dimension;
    if (clonedMasterJson) {
      clonedMasterJson?.[aspectRatio]?.blocks.forEach((blockJson, blockIndex) => {
        const index = blockJson.components?.findIndex((it) => findComponentFn(it)) ?? -1;
        if (index > -1) {
          const compositeJson = blockJson?.components?.[index] as Master.CompositeJSON;
          if (compositeJson?.type === 'composite' && compositeJson?.sub_type === 'logo_composite') {
            clonedMasterJson[aspectRatio]!.blocks[blockIndex].components[index] =
              getHiddenLogoPlaceholderComposite(compositeJson);
            clonedMasterJson.logo_placeholder_hidden = true;
          } else {
            clonedMasterJson?.[aspectRatio]?.blocks[blockIndex]?.components?.splice(index, 1);
          }
        }
      });
      this.masterJson = clonedMasterJson;
    }
  }

  selectProperty(selectableProperty: SelectableProperty) {
    this.dispatch(this.actions.propertySelected({ selectableProperty }));
  }

  get fonts() {
    return this.state.fonts;
  }

  setFonts(fonts: any) {
    this.dispatch(this.actions.fontsChanged({ fonts }));
  }

  set updateBlockline(data: { option: 'addBlock'; index: number }) {
    this.dispatch(this.actions.setUpdateBlockline(data));
  }

  reset() {
    this.dispatch(this.actions.reset());
  }

  get templateJson() {
    return this.state.templateJson;
  }

  set templateJson(templateJson: Template.JSON) {
    this.dispatch(this.actions.setTemplateJson(templateJson));
    this.dispatch(this.actions.setTemplateType(getTemplateType(templateJson)));
  }

  get templateType() {
    return this.state.templateType;
  }

  get projectType() {
    return this.masterJson.type;
  }

  reCalcGrainsForComponent(component: Master.ComponentJSON): Promise<Master.ComponentJSON> {
    return reCalcGrainsForComponent(this.shadowDimensionJson, component);
  }

  calcGrainsForComponents(
    components: Master.ComponentJSON[],
    sceneSize?: [number, number],
  ): Promise<Master.ComponentJSON[]> {
    return calcGrainsForComponents(components, sceneSize);
  }

  calcGrainsForNewBlock(
    block: Master.BlockJSON,
    sceneSize?: [number, number],
  ): Promise<Master.BlockJSON> {
    return calcGrainsForNewBlock(block, sceneSize);
  }

  markWatermarkForUpdate() {
    this.dispatch(this.actions.masterJsonUpdated({ json: this.shadowJson }));
    ShadowRegistry.fireObservables();
  }

  sendAnalyticsEvent(analyticsEvent: analyticsEvent) {
    this.dispatch(this.actions.sendAnalyticsEvent(analyticsEvent));
  }

  analyticsEventSent() {
    this.dispatch(this.actions.analyticsEventSent());
  }

  setUrlOptimizationEnabled(enabled: boolean) {
    this.dispatch(this.actions.setUrlOptimizationEnabled(enabled));
  }

  getAudioBlockById(id: string): Master.AudioBlockJSON | null {
    return Registry.getAudioBlockById(this.state.dimensionJson, id);
  }

  get selectedReviewBoxId() {
    return this.state.selectedReviewBoxId;
  }

  set selectedReviewBoxId(id: string | null) {
    this.dispatch(this.actions.selectedReviewBoxIdUpdated(id));
  }
  get exportSettings() {
    return this.state.exportSettings;
  }
  updateExportSettings(exportSettings: ExportSettings) {
    this.dispatch(this.actions.updateExportSettings(exportSettings));
  }

  setAudioBlockValuesOnMute(audioBlockPreviousValues: AudioBlockPreviousValues) {
    return this.dispatch(this.actions.saveAudioBlockPreviousValuesOnMute(audioBlockPreviousValues));
  }

  get blocksPreviousValues() {
    return this.state.audio.blocksPreviousValues;
  }

  getAudioBlockValuesWhenMuted(id: string) {
    return (
      this.state.audio.blocksPreviousValues?.[id] ||
      ({
        audio_volume: 1,
        bg_volume: 1,
        is_ducking: false,
        panner_pan: 0,
        fade_in_time: 0,
        fade_out_time: 0,
      } as AudioBlockPreviousValues)
    );
  }

  deleteAudioBlockPreviousValuesOnUnmute(id: string) {
    return this.dispatch(this.actions.removeAudioBlockPreviousValueOnUnmute(id));
  }

  get isStoryboardV2() {
    return this.masterJson.type === 'storyboard-v2';
  }

  get storyboardScenes(): Array<Master.Scene> {
    return this.dimensionJson.scenes ?? [];
  }

  get storyboardScenesGenerationConfig(): Master.SceneGenerationConfig | undefined {
    return this.dimensionJson.scene_generation_config;
  }
  patchDropShadowToComposite(
    id: string,
    dropShadow: {
      blur: number;
      opacity: number;
      distance: number;
      angle: number;
      color: ColorJSON;
    },
  ) {
    return SlateRegistry.patchDropShadowToComposite(id, dropShadow);
  }

  patchDropShadowToParagraph(
    id: string,
    dropShadow: {
      blur: number;
      opacity: number;
      distance: number;
      angle: number;
      color: ColorJSON;
    },
  ) {
    return SlateRegistry.patchDropShadowToParagraph(id, dropShadow);
  }

  patchSlateComponentOpacity(id: string, opacity: number) {
    return SlateRegistry.patchSlateComponentOpacity(id, opacity);
  }
  patchSlateParagraphBlendMode(id: string, blendMode: Slate.BlendMode) {
    return SlateRegistry.patchSlateParagraphBlendMode(id, blendMode);
  }

  patchSlateCompositeBlendMode(id: string, blendMode: Slate.BlendMode) {
    return SlateRegistry.patchSlateCompositeBlendMode(id, blendMode);
  }

  patchSlateCompositeFxFilters(id: string, fxFilters: Slate.FxFilterJSON[]) {
    return SlateRegistry.patchSlateCompositeFxFilters(id, fxFilters);
  }

  recalculateAudioList(audioBlocks: Array<Shadow.AudioBlockJSON | Master.AudioBlockJSON>) {
    recalculateAudioList(audioBlocks);
  }

  /**@description Update thumbnail if preset, else adds the thumbnail */
  updateThumbnailUrlandTUpdated(thumbnail: {
    id: string;
    url: string;
    tUpdated: number;
    hash: number;
  }) {
    const dimensionJSON = this.state.dimensionJson;
    this.dimensionJson = {
      ...dimensionJSON,
      blocks: dimensionJSON.blocks.map((block) => {
        if (block.id === thumbnail.id) {
          return {
            ...block,
            thumbnail_url: thumbnail.url,
            thumbnail_hash: thumbnail.hash,
            thumbnail_updated_at: thumbnail.tUpdated,
            isAccurateThumbnail: true,
          };
        } else {
          return block;
        }
      }),
    };
  }

  patchSlateParagraphOutline(id: string, outline: Slate.Outline) {
    return SlateRegistry.patchSlateParagraphOutline(id, outline);
  }

  patchSlateParagraphFontOpacity(id: string, opacity: number) {
    return SlateRegistry.patchSlateParagraphFontOpacity(id, opacity);
  }

  patchSlateParagraphBandOpacity(id: string, opacity: number) {
    return SlateRegistry.patchSlateParagraphBandOpacity(id, opacity);
  }

  patchSlateParagraphFontMainColor(id: string, color: Slate.ColorJSON) {
    return SlateRegistry.patchSlateParagraphFontMainColor(id, color);
  }

  patchSlateParagraphFontHighlightColor(id: string, color: Slate.ColorJSON) {
    return SlateRegistry.patchSlateParagraphFontHighlightColor(id, color);
  }

  patchSlateParagraphBackgroundColor(id: string, color: Slate.ColorJSON) {
    return SlateRegistry.patchSlateParagraphBackgroundColor(id, color);
  }

  patchSlateParagraphLineHeight(id: string, lineHeight: number) {
    return SlateRegistry.patchSlateParagraphLineHeight(id, lineHeight);
  }

  patchSlateParagraphCharacterSpacing(id: string, spacing: number) {
    return SlateRegistry.patchSlateParagraphCharacterSpacing(id, spacing);
  }

  forceTouch(id: string) {
    return SlateRegistry.forceTouch(id);
  }

  get clipboard(): Clipboard {
    return JSON.parse(JSON.stringify(this.state.clipboard)) as Clipboard;
  }

  setClipboard(data: Master.CompositeJSON | Master.BlockJSON | Master.AudioBlockJSON) {
    this.dispatch(this.actions.setClipboardData(JSON.parse(JSON.stringify(data))));
  }

  clipboardDataPasted(sceneId: string) {
    this.dispatch(this.actions.clipboardDataPasted({ sceneId }));
  }

  get isBlankTemplate(): boolean {
    return this.state.templateJson.id === BLANK_TEMPLATE_ID;
  }

  deselectAllComponents() {
    this.dispatch(this.actions.componentSelected({ id: INITIAL_STATE.selectedComponentId }));
  }

  selectBackgroundComponent(blockId?: string): boolean {
    blockId = blockId ?? this.selectedBlockId;
    const blockJson = this.getShadowBlockById(blockId);
    if (!blockJson) throw new Error(`Block not found = ${blockId}`);

    const backgroundComposite = blockJson.components.find(
      (it) =>
        it.compositeType === Master.CompositeType.CANVAS_BACKGROUND ||
        it.sub_type === 'pre_video_asset_composite',
    );

    // if no canvas background found, clear all selection
    if (!backgroundComposite) {
      console.warn(
        `Background composite not found in block ${blockId}. As a fallback no component will be selected`,
      );
      this.deselectAllComponents();
      return false;
    }

    // if composite exist but no child component within it
    const [component] = backgroundComposite.components;
    if (!component) {
      console.warn(
        `Background component not found in composite ${backgroundComposite.id}. As a fallback no component will be selected`,
      );
      this.deselectAllComponents();
      return false;
    }
    // if composite exist and child component exist within it
    if (component.id === this.selectedComponentId) {
      log('optimization: selectBackgroundComponent is noop');
      return false;
    }

    this.selectComponent(component.id);
    return true;
  }

  get isValidMasterJSON(): boolean {
    return this.masterJson.master_video_id !== 0;
  }

  get isValidTemplateJSON(): boolean {
    return this.templateJson.id !== 0;
  }
}

function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

function getHiddenLogoPlaceholderComposite(logoComposite: Master.CompositeJSON) {
  const cloned: Master.CompositeJSON = JSON.parse(JSON.stringify(logoComposite));

  // hide the logo placeholder if it's component has image type and placeholder url
  const haveLogoPlaceholder = cloned.components?.some(
    (component) =>
      component?.type == 'image' && isLogoPlaceholder((component as Master.ImageJSON).url),
  );
  if (haveLogoPlaceholder) {
    cloned.is_hidden = true;
    cloned.logo_placeholder_hidden = true;
  }
  return cloned;
}

const isLogoPlaceholder = (url: string) => {
  const userData = Store.user.userData;
  const isCreator = userData?.['role'] === 'creator';
  const isDesigner = userData?.['role'] === 'design';
  const logoUrlsRegex = new RegExp(LOGO_PLACEHOLDER_URLS.join('|'), 'i').test(url);
  return !(isCreator || isDesigner) && logoUrlsRegex;
};

import { Master, Shadow, Slate } from '../jsonTypes';
import { AUDIO_TYPE, ImageJSON } from '../jsonTypes/SlateJSON';
import * as componentType from '../project/utils/getCompositeType';
import {
  isTransitionComponent,
  isTransitionComposite,
} from '../project/utils/getCompositeType/getCompositeTypes';
import { getCompositeThumbnail } from '../project/utils/master-json-utils';
import { availableBuckets } from './availableBuckets';
import { SkiaTextMetrics } from './skia/skiaTypes';

export const DEFAULT_FIXED_BACKGROUND_URL =
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Background/bguniquecanvas.png';
export const DEFAULT_FIXED_BACKGROUND_CLOUDFRONT_URL =
  'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/Background/bguniquecanvas.png';
export const DEFAULT_MASK_PLACEHOLDER_URL =
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/MASKS/mask-placeholder.png';

export const EPSILON = 0.0015;

export interface ParentData {
  derivesBlendModeFromChildren?: boolean;
  size: {
    w: number;
    h: number;
  };
  duration: {
    t0: number;
    t1: number;
  };
  absoluteAngle: number;
  isLogoPlaceHolderHidden?: boolean;
  isPositionComposite?: boolean;
}

const slateComponentTypeFor = (
  shadowJson: Shadow.ComponentJSON,
): Slate.ComponentJSON['type'] | null => {
  switch (shadowJson.type) {
    case 'image':
    case 'svg_image':
      return 'image';
    case 'text':
      return 'paragraph';
    case 'composite':
      return 'composite';
    case 'video':
      return shadowJson.type;
  }

  return null;
};

const slateComponentIdFor = (
  shadowJson: Shadow.ComponentJSON,
): Slate.ComponentIdentifier | null => {
  const componentType = slateComponentTypeFor(shadowJson);
  return componentType ? { type: componentType, id: shadowJson.id } : null;
};

/* Common box properties */
function parseBox(
  component: Shadow.BlockJSON | Shadow.ComponentJSON,
  parentData: ParentData,
): Slate.BoxJSON {
  const { top_x, top_y, bottom_x, bottom_y } = relativeToAbsolutePosition(
    parentData.size,
    component,
  );
  const { tStart, tEnd } = relativeToAbsoluteTime(parentData, component.duration);
  const { tIn, tOut } = absoluteAnimationTime(tStart, tEnd, component);
  const width = bottom_x - top_x;
  const height = bottom_y - top_y;

  const inAnimation = component.animation.animation_in || 'None';
  const outAnimation = component.animation.animation_out || 'None';

  const blendMode = parentData.derivesBlendModeFromChildren
    ? 'normal'
    : toBlendMode(component.layout.layer_properties.blend_effect);
  const blur = component.layout.layer_properties.blur;
  let zoom: Slate.ZoomJSON | null = null;
  let pan: Slate.PanJSON | null = null;
  const fitType = component.layout.fit_type ?? '';
  switch (component.animation.animation) {
    case 'zoom_in':
      zoom = {
        originX: width * 0.5,
        originY: height * 0.5,
        z0: 1,
        z1: 1.2,
      };
      break;
    case 'zoom_out':
      zoom = {
        originX: width * 0.5,
        originY: height * 0.5,
        z0: 1.2,
        z1: 1,
      };
      break;
    case 'pan_to_bottom':
      pan = getPanToBottomCalcs(fitType);
      break;
    case 'pan_to_left':
      pan = getPanToLeftCalcs(fitType);
      break;
    case 'pan_to_right':
      pan = getPanToRightCalcs(fitType);
      break;
    case 'pan_to_up':
      pan = getPanToUpCalcs(fitType);
      break;
  }

  const lastDotPosition = component.id.lastIndexOf('.');
  const parentId = component.id.substring(0, lastDotPosition);
  const blockId = `.${component.id.split('.')[1]}`;

  return {
    uid: component.uid,
    id: component.id,
    parentId,
    blockId,
    cacheKey: `${component.tUpdated}-${component.id}`,
    hidden: 'is_visible' in component && !component.is_visible,
    locked: ('is_lock' in component && component.is_lock) ?? false,
    inAnimation: inAnimation === 'None' ? null : inAnimation,
    outAnimation: outAnimation === 'None' ? null : outAnimation,
    tStart,
    tIn,
    tOut,
    tEnd,
    zoom,
    pan,
    blur: blur ?? 0,
    opacity:
      component.layout.layer_properties.opacity !== undefined
        ? component.layout.layer_properties.opacity
        : 1,
    filterType: toFilterType(component.layout.layer_properties.filter),
    blendMode,
    width,
    height,
    offsetX: top_x,
    offsetY: top_y,
    angle: rot(component.position.rotate ?? 0, 360),
    absoluteAngle: rot(parentData.absoluteAngle, 360),
    tEdit: (tIn + tOut) / 2,
    ui: {
      selected: false,
      forceVisible: false,
      forceInvisible: false,
    },
    name: component.name,
    flipHorizontally: component?.layout?.layer_properties?.flip?.horizontal || false,
    flipVertically: component?.layout?.layer_properties?.flip?.vertical || false,
  };
}

/* Watermark */
export function parseWatermark(
  component: Shadow.ImageJSON | Shadow.VideoJSON,
  parentData: ParentData,
  subType: 'overlay' | 'badge' | 'cta',
): Slate.WatermarkJSON {
  const sourceLabel =
    (component.media_properties?.partner_image_id ||
      component.media_properties?.partner_video_id) ??
    '';

  const source = (sourceLabel.split('_')[0] || '') as Slate.WatermarkSources;
  const isHidden = !['shutterstock', 'istock', 'storyblock'].includes(source);

  let url = '';
  switch (subType) {
    case 'overlay':
      url = `https://d1nc6vzg2bevln.cloudfront.net/images/watermarks/${source}_watermark.svg`;
      break;
    case 'badge': {
      url =
        source === 'istock'
          ? `https://web-assets.invideo.io/editor/prod/light/panels/view-panel/misc-icons/light-mode/istock-badge-new.svg`
          : `https://web-assets.invideo.io/editor/prod/light/panels/view-panel/misc-icons/light-mode/premium-badge-new.svg`;
      const { position } = component;
      position.top_x = 0.03;
      position.top_y = 0.05;
      break;
    }
    case 'cta': {
      url = `https://web-assets.invideo.io/editor/prod/light/slate/watermark/remove-watermark-badge.svg`;
      // The offset{x,y} and width,height are calculated in the widget.
      // Because these calculations require this.px since the size of the
      // cta watermark should always be a constant irrespective of this.px

      break;
    }
  }
  url += '?ivSource=iv-frame';
  const box = parseBox(component, parentData);

  return {
    ...box,
    type: 'watermark',
    source,
    subType,
    hidden: isHidden,
    url,
  };
}

function isInVideoUrl(url: string): boolean {
  if (url.includes('amazonaws.com')) {
    return true;
  }
  return (
    availableBuckets.map((bucket) => bucket['alias']).find((alias) => url.includes(alias)) !==
    undefined
  );
}

function toEffects(component: Shadow.ImageJSON | Shadow.VideoJSON): Array<Slate.Effect> {
  return (component.layout.layer_properties.effects ?? []) // force break
    .map((effect: Master.Effect) => {
      const { type } = effect;
      switch (type) {
        case 'lut': {
          const lutJSON = effect as Slate.LUTJSON;
          const { lutUrl, lutBlendRatio } = lutJSON;
          return {
            type: 'lut',
            lutUrl,
            lutBlendRatio,
            lut: 0,
          } as Slate.LUTJSON;
        }
        case 'imageOverlay': {
          const imageOverlayJSON = effect as Slate.ImageOverlayJSON;
          const { imageOverlayUrl, imageOverlayOpacity, imageOverlayBlendMode } = imageOverlayJSON;
          return {
            type: 'imageOverlay',
            imageOverlayUrl,
            imageOverlayOpacity,
            imageOverlayBlendMode,
          } as Slate.ImageOverlayJSON;
        }
        case 'gaussianBlur': {
          const blurJSON = effect as Slate.BlurJSON;
          const { blurRadius, blurBlendRatio } = blurJSON;
          return {
            type: 'gaussianBlur',
            blurRadius,
            blurBlendRatio,
          } as Slate.BlurJSON;
        }
        case 'whiteColorPicker': {
          const whiteColorPickerJSON = effect as Slate.WhiteColorPickerJSON;
          const { color } = whiteColorPickerJSON;
          return {
            type: 'whiteColorPicker',
            color,
          };
        }
        // case 'aiBgRemoval': {
        //   const json = effect as Slate.AiBgRemovalJSON;
        //   return {
        //     type: 'aiBgRemoval',
        //     convertedVideoUrl: json.convertedVideoUrl,
        //   } as Slate.AiBgRemovalJSON;
        // }
      }
    });
}

const DEFAULT_UPLOAD_LOGO_URL =
  'https://web-assets.invideo.io/editor/prod/light/slate/placeholder/logo-placeholder.png';

/* Image */
// TODO: handle orientation,
export function parseImage(
  component: Shadow.ImageJSON,
  parentData: ParentData,
  optimize: boolean,
  defaultUploadLogo?: boolean,
): Slate.ImageJSON {
  const box = parseBox(component, parentData);
  const { colorCorrection, isEnabled } = parseColorCorrection(
    component.layout.layer_properties.color_correction,
  );
  if (![undefined, true].includes(isEnabled)) box.opacity = 0;
  let componentUrl = defaultUploadLogo ? DEFAULT_UPLOAD_LOGO_URL : component.url;
  componentUrl = replaceOldLogoUrl(componentUrl) as string;
  let componentThumbnailUrl = defaultUploadLogo ? DEFAULT_UPLOAD_LOGO_URL : component.thumbnail_url;
  componentThumbnailUrl = replaceOldLogoUrl(componentThumbnailUrl);
  const url = appendIvFrameToSource(
    optimize
      ? isInVideoUrl(componentUrl)
        ? toOptimizedUrl(componentUrl)
        : componentThumbnailUrl ?? componentUrl
      : componentUrl,
  );
  const effects = toEffects(component);
  const adjustmentFilters = toAdjustmentFilters(
    component.layout.layer_properties.adjustment_filters,
  );
  return {
    type: 'image',
    ...box,
    colorCorrection,
    // if it is not an invideo url we assume it to be a vendor image and shows it low res thumbnail_url
    url,
    fit: parseBoxFit(component.layout.fit_type),

    clip: parseClip(component.layout.clip),

    backgroundBlur: component.layout.fit_type === 'fit_with_blur' ? 20 : 0,
    relativeForegroundOffset: component.layout.fit_alignment
      ? {
          x: component.layout.fit_alignment.x,
          y: component.layout.fit_alignment.y,
        }
      : { x: 0.5, y: 0.5 },
    isBackground: component.url === DEFAULT_FIXED_BACKGROUND_URL,
    effects,
    ...adjustmentFilters,
  };
}
function replaceOldLogoUrl(url?: string): string | undefined {
  if (
    url ===
    'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/addalogo.png'
  ) {
    return DEFAULT_UPLOAD_LOGO_URL;
  }
  return url;
}
/* Video */
// TODO: handle trim, handle orientation,
export function parseVideo(
  component: Shadow.VideoJSON,
  parentData: ParentData,
  optimize: boolean,
  audioBlock?: Shadow.AudioBlockJSON,
): Slate.VideoJSON {
  const box = parseBox(component, parentData);
  const { tStart, tEnd } = box;
  const muted = !audioBlock || audioBlock.audio_volume === 0;
  let fadeSections;
  if (audioBlock) {
    fadeSections = parseFadeSections(audioBlock, tStart, tEnd);
  }
  const { colorCorrection } = parseColorCorrection(
    component.layout.layer_properties.color_correction,
  );

  const effects = toEffects(component);
  const adjustmentFilters = toAdjustmentFilters(
    component.layout.layer_properties.adjustment_filters,
  );

  const sourceDuration = component.layout.trim?.length
    ? component.layout.trim[component.layout.trim.length - 1].ceil
    : component.duration.precalculated_duration ?? 0;

  return {
    type: 'video',
    ...box,
    colorCorrection,
    componentRefId: component.id,
    url: appendIvFrameToSource(optimize ? getMediaVideoOptimisedUrl(component.url) : component.url),
    backgroundUrl: appendIvFrameToSource(
      optimize ? toOptimizedUrl(component.thumbnail_url) : component.thumbnail_url,
    ),
    fit: parseBoxFit(component.layout.fit_type),
    clip: parseClip(component.layout.clip),
    backgroundBlur: component.layout.fit_type === 'fit_with_blur' ? 20 : 0,
    loop: component.layout.loop >= 1,
    muted,
    speed: component.layout.playback_rate !== undefined ? component.layout.playback_rate : 1,
    playbackRateStrategy: component.playback_rate_strategy ?? 'mute',
    trim: component.layout.trim
      ? [...component.layout.trim].sort((a, b) => {
          return a.startTime - b.startTime;
        })
      : null,
    fadeSections,
    durations: [{ t0: 0, t1: tEnd - tStart }],
    effects,
    ...adjustmentFilters,
    sourceDuration,
  };
}

export function getAnimationGranularity(
  component: Shadow.TextJSON | Master.TextJSON,
): Slate.Granularity {
  if (component.animation.animation_in_parameters) {
    if (component.animation.animation_in_parameters.type === 'chars') {
      if (fetchLanguage(component.text) !== 'english') {
        return 'whole';
      }
    }
  }
  return toGranularity(component.animation.animation_in_parameters?.type);
}

export function parseParagraph(
  component: Shadow.TextJSON,
  parentData: ParentData,
): Slate.ParagraphJSON {
  const box = parseBox(component, parentData);
  const granularity = getAnimationGranularity(component);
  const highlightedText = component.highlighted_text;
  const boxPadding = {
    h: component.layout.xpad * box.width,
    v: component.layout.ypad * box.height,
  };
  const style = parseParagraphStyle(component);
  let textMetrics: Master.TextMetricsJSON = {
    size: { width: 0, height: 0 },
    grains: [],
    style: { font_size: 12 },
  };
  if (component.metrics) {
    textMetrics = component.metrics;
    style.defaultTextStyle.fontSize = style.highlightTextStyle.fontSize =
      textMetrics.style.font_size;
  }
  const metrics: SkiaTextMetrics = { ...textMetrics, style };

  const grainDuarationAndDelayFactor = (): { eachDuration: number; delayFactor: number } => {
    const animationDuration = box.tIn - box.tStart;
    const grainsCount = metrics.grains.length;
    if (granularity === 'whole') {
      return { eachDuration: animationDuration, delayFactor: 0 };
    } else {
      const vars: { [key in Slate.Granularity]: [a: number, b: number, c: number, d: number] } = {
        lines: [3, 0.75, 0.125, 0.375],
        words: [6, 0.58, 0.084, 0.504],
        chars: [12, 0.462, 0.042, 0.504],
        whole: [0, 0, 0, 0],
      };
      const [a, b, c, d] = vars[granularity];
      if (grainsCount <= a) {
        return { eachDuration: b * animationDuration, delayFactor: c * animationDuration };
      } else {
        return {
          eachDuration: (1 + 1 / grainsCount - d) * animationDuration,
          delayFactor: (d / grainsCount) * animationDuration,
        };
      }
    }
  };
  const { eachDuration, delayFactor } = grainDuarationAndDelayFactor();

  const hasHorizontalPadding = style.defaultTextStyle.backgroundColor.a > 0;
  const paddingHorizontal =
    hasHorizontalPadding && (granularity === 'lines' || granularity === 'whole')
      ? (component.layout.horizontal_band_factor ?? 0.1) * textMetrics.style.font_size
      : 0;

  const grainJsons: Slate.ParagraphGrainJSON[] = metrics.grains.map((grain, idx) => {
    const tStart: number = box.tStart + idx * delayFactor;
    return {
      uid: `${box.uid}-${idx}`,
      id: `${box.uid}-${idx}`,
      cacheKey: `${box.cacheKey}-g${idx}`,
      type: granularity,
      hidden: box.hidden,
      height: grain.size.height,
      width: grain.size.width,
      grain: grain,
      inAnimation: box.inAnimation,
      outAnimation: null,
      tStart,
      tIn: tStart + eachDuration,
      tOut: 0,
      tEnd: box.tEnd,
      style: style,
      paddingHorizontal: paddingHorizontal,
    };
  });

  return {
    type: 'paragraph',
    highlightedText,
    granularity,
    boxPadding,
    ...metrics,
    grains: grainJsons,
    ...box,
    inAnimation: null,
    cacheKey: box.cacheKey,
  };
}

export function parseParagraphStyle(component: Master.TextJSON): Slate.RichTextStyle {
  const dropShadow = component.layout.layer_properties.drop_shadow as Master.DropShadowJSON;
  const mainFont = component.layout.main_font.font_style;
  const highlightFont = component.layout.highlight_font.font_style;
  const textAlignment = toTextAlignment(component.layout.horizontal_text_alignment);
  const verticalAlignment = toVerticalAlignment(component.layout.vertical_text_alignment);
  const bullets = toBullet(component.layout.bullets);
  const outline = toOutline(component.layout.layer_properties.outline);
  const fontSpec = component.layout.font_spec ?? [];
  const bold = fontSpec.includes('bold');
  const italic = fontSpec.includes('italic');
  const underline = fontSpec.includes('underline');

  const { fallbackFonts, rtl } = fallbackFontsFor(component.text);
  let fontSize = Math.max(toInt(component.layout.font_size, -1), 12);
  if (fontSize !== -1) {
    fontSize = (fontSize - (fontSize % 3)) * 0.98;
  }
  const isFontSizeFixed = component.layout.fixed;
  const sharedStyles = {
    fontSize: fontSize,
    lineHeight: toFloat(component.layout.lineHeight, 1.4),
    letterSpacing: component.layout.letterSpacing ?? 0,
    bold: bold,
    italic: italic,
    underline: underline,
  };
  const hasBandColor = getBandStatus(component);
  let bgColorArray: (string | number)[] = [0, 0, 0, 0];
  bgColorArray = [...component.layout.main_font.background_color];
  bgColorArray[3] = Math.round(component.layout.band_opacity * 255);
  const bandColor = hasBandColor ? toColor(bgColorArray) ?? transparent : transparent;
  const defaultTextStyle: Slate.TextStyle = {
    font: mainFont,
    ...sharedStyles,
    color: toColor(component.layout.main_font.foreground_color) ?? white,
    backgroundColor: bandColor,
  };
  const highlightTextStyle: Slate.TextStyle | null = highlightFont
    ? {
        font: highlightFont,
        ...sharedStyles,
        color: toColor(component.layout.highlight_font.foreground_color) ?? white,
        backgroundColor: bandColor,
      }
    : defaultTextStyle;
  let textShadow: Slate.ShadowJSON = {
    enabled: false,
    color: transparent,
    blur: 4,
    opacity: 0.75,
    angle: 135,
    distance: 10,
  };

  if (typeof dropShadow !== 'string' && typeof dropShadow?.enabled !== 'string') {
    const shadowColor =
      toColor(dropShadow?.drop_shadow_color) ??
      toColor(dropShadow?.colors_ref.drop_shadow_color.reference) ??
      white;
    shadowColor.a = dropShadow?.opacity ?? 0.75;
    textShadow = {
      enabled: dropShadow?.enabled,
      color: shadowColor,
      blur: dropShadow?.blur ?? 4,
      opacity: dropShadow?.opacity ?? 0.75,
      angle: dropShadow?.angle ?? 135,
      distance: dropShadow?.distance ?? 10,
    };
  }

  return {
    defaultTextStyle,
    highlightTextStyle,
    fallbackFonts: fallbackFonts,
    rtl: rtl,
    textAlignment,
    verticalAlignment,
    textShadow,
    isFontSizeFixed,
    bullets,
    outline,
  };
}

export function parseComposite(
  component: Shadow.CompositeJSON,
  parentData: ParentData,
  index: number,
): Slate.CompositeJSON {
  const slateCompositeJSON = parseBox(component, parentData);

  const componentData: ParentData = {
    ...parentData,
    size: {
      w: slateCompositeJSON.width,
      h: slateCompositeJSON.height,
    },
    duration: {
      t0: slateCompositeJSON.tStart,
      t1: slateCompositeJSON.tEnd,
    },
    absoluteAngle: parentData.absoluteAngle + slateCompositeJSON.angle,
  };

  const mask: Slate.ImageJSON | null = extractMaskFromChildComponent(
    component.components,
    componentData,
  );

  //blend mode is extracted from children and applied on the composite.
  //https://github.com/pixijs/picture/issues/22
  const blendMode: Slate.BlendMode = extractBlendModeFromCompositeOrChildJson(
    component,
    slateCompositeJSON,
  );
  const isPlaceholderLogo = isPlaceholderLogoComposite(component);

  const firstChild = component.components[0];
  const first_child_image_url =
    firstChild && firstChild.type === 'image' && 'url' in firstChild ? firstChild.url : null;
  const isBackground = first_child_image_url === DEFAULT_FIXED_BACKGROUND_URL;
  const subType: 'placeholder_logo' | 'position_composite' | 'transition_composite' | undefined =
    isPlaceholderLogo
      ? 'placeholder_logo'
      : 'position_composite' === component.sub_type || 'transition_composite' === component.sub_type
      ? component.sub_type
      : undefined;

  const isHiddenLogoProperty =
    component.sub_type === 'logo_composite'
      ? {
          isLogoPlaceholderHidden:
            !!component?.logo_placeholder_hidden || parentData.isLogoPlaceHolderHidden,
        }
      : {};
  const clipColor = toColor(component.clip_color);

  const thumbnailUrl = getCompositeThumbnail(component);

  return {
    ...slateCompositeJSON,
    thumbnailUrl,
    index,
    visible: componentType.isVisibleComposite(component),
    compositeType: component.compositeType,
    // componentIds: component.components.filter((c) => c.type !== 'svg_image').map((c) => c.id),
    componentIds: component.components
      .filter((c) => c.type !== 'svg_image')
      .map(slateComponentIdFor)
      .filter(isPresent),
    type: 'composite',
    subType,
    mask,
    blendMode,
    cacheKey: slateCompositeJSON.cacheKey,
    isBackground,
    selectionType: toSelectionType(
      isBackground,
      component.sub_type,
      firstChild?.type,
      first_child_image_url,
    ),
    layerId: component.layer_id,
    name: 'layer_name' in component ? component.layer_name : component.name,
    clipColor: clipColor ? clipColor : undefined,
    flipHorizontally: component?.layout?.layer_properties?.flip?.horizontal || false,
    flipVertically: component?.layout?.layer_properties?.flip?.vertical || false,
    ...isHiddenLogoProperty,
    isLogoHiddenForRender: component.sub_type === 'logo_composite' && component.is_hidden,
    dropShadow: toDropShadow(component.layout.layer_properties.drop_shadow),
    fxFilters: component.layout.layer_properties.fx_filters,
  };
}

export function parseLayer(component: Shadow.LayerJSON): Slate.LayerJSON {
  return {
    uid: component.uid,
    id: component.id,
    cacheKey: `${component.tUpdated}`,
    type: 'layer',
    name: component.name,
    hidden: false,
    visible: component.visible,
    index: component.index,
    layerType: component.layer_type,
    layerSubType: component.layer_sub_type,
  };
}

export function parseBlock(
  component: Shadow.BlockJSON,
  parentData: ParentData,
  index: number,
  startTransitionDelay: number,
  endTransitionDelay: number,
): Slate.BlockJSON {
  const slateBlockJSON = parseBox(component, parentData);
  // overriding tIn tOut incase of filter transitions
  const transitionIn = component.filter_transition?.in ? component.filter_transition.in.name : null;
  const transitionOut = component.filter_transition?.out
    ? component.filter_transition.out.name
    : null;
  const tIn = transitionIn
    ? slateBlockJSON.tStart + (component.filter_transition?.in?.duration ?? 0)
    : slateBlockJSON.tIn;
  const tOut = transitionOut
    ? slateBlockJSON.tEnd - (component.filter_transition?.out?.duration ?? 0)
    : slateBlockJSON.tOut;

  return {
    ...slateBlockJSON,
    type: 'block',
    index,
    tSceneStart: slateBlockJSON.tStart + startTransitionDelay,
    tSceneEnd: slateBlockJSON.tEnd - endTransitionDelay - EPSILON,
    componentIds: component.components.map(slateComponentIdFor).filter(isPresent),
    thumbnailUrl: component?.thumbnail_url || null,
    inTransition: component.filter_transition?.in ? component.filter_transition.in.name : null,
    inTransitionParameters: component.filter_transition?.in?.parameters || {},
    outTransition: component.filter_transition?.out ? component.filter_transition.out.name : null,
    outTransitionParameters: component.filter_transition?.out?.parameters || {},
    tIn,
    tOut,
  };
}

export function parseTransition(
  id: string,
  index: number,
  prevBlock: Shadow.BlockJSON,
  nextBlock: Shadow.BlockJSON,
  dim: Shadow.DimensionJSON,
): Slate.TransitionJSON {
  let tStart = 0;
  let tEnd = 0;
  let type: Slate.TransitionType = 'animation';

  const transitions = dim.transitions || [];
  const transitionId = transitions[index];

  if (transitionId === 0) {
    type = 'none';
  }
  const outAnimationTime =
    prevBlock.animation.animation_out !== 'None'
      ? prevBlock.animation.animation_out_parameters?.out_effect_factor || 0
      : prevBlock.filter_transition?.out?.duration || 0;
  const inAnimationTime =
    nextBlock.animation.animation_in !== 'None'
      ? nextBlock.animation.animation_in_parameters?.in_effect_factor || 0
      : nextBlock.filter_transition?.in?.duration || 0;

  // Some times, there is no out animation, but because of our delay value the
  // next scene has already started animating. This should be compared for tStart
  tStart = Math.min(
    prevBlock.duration.absolute_end - outAnimationTime,
    nextBlock.duration.absolute_start,
  );
  tEnd = nextBlock.duration.absolute_start + inAnimationTime;

  const overlayTransitionComposite = nextBlock.components.find(isTransitionComposite);

  if (overlayTransitionComposite) {
    const overlayTransitionComponent =
      overlayTransitionComposite.components.find(isTransitionComponent);
    if (overlayTransitionComponent) {
      tStart = overlayTransitionComposite.duration.absolute_start;
      const videoDuration = overlayTransitionComponent.duration.precalculated_duration
        ? overlayTransitionComponent.duration.precalculated_duration
        : overlayTransitionComponent.duration.element_duration ?? 0;
      tEnd = tStart + videoDuration;
      type = 'overlay';
    }
  }

  return {
    type: 'transition',
    transitionType: type,
    id,
    index: index,
    transitionId,
    cacheKey: `${prevBlock.tUpdated}-${nextBlock.tUpdated}-${prevBlock.id}-${nextBlock.id}`,
    tStart,
    tEnd,
    prevRefId: prevBlock.id,
    nextRefId: nextBlock.id,
  };
}

export function parseAudio(
  component: Shadow.AudioBlockJSON,
  video: Slate.VideoJSON | null,
  optimize: boolean,
): Slate.AudioJSON {
  const {
    tStart,
    tEnd,
    sourceDuration = 0,
  } = isPresent(component.duration)
    ? {
        tStart: component.duration.absolute_start ?? 0,
        tEnd: component.duration.absolute_end ?? 0,
        sourceDuration: component.duration.element_duration ?? 0,
      }
    : video
    ? { tStart: video.tStart, tEnd: video.tEnd }
    : { tStart: 0, tEnd: 0 };
  const fadeSections =
    video && video.fadeSections ? video.fadeSections : parseFadeSections(component, tStart, tEnd);

  const audioUrl =
    component.sub_type === 'bg_video'
      ? // If is bg_video, the video url has preference
        video?.url || component.url || ''
      : // If is not a bg_video, the component url has preference
        component.url || video?.url || '';
  const url = optimize ? getAudioOptimisedUrlCloudfront(audioUrl) : audioUrl;

  return {
    uid: component.uid,
    id: component.id,
    cacheKey: `${component.tUpdated}-${component.id}`,
    hidden: 'is_visible' in component && !component.is_visible ? true : false,
    locked: 'is_lock' in component && component.is_lock ? true : false,
    tStart,
    tEnd,
    sourceDuration,
    type: 'audio',
    loop: video?.loop ?? component.loop === 1,
    playbackRate: video?.speed ?? component.playback_rate,
    // This is a flag for the iv-audio to do time stretch if playbackRate !== 1
    // it's required to preserve backwards compatibility
    playbackRateStrategy: component.playback_rate_strategy ?? video?.playbackRateStrategy ?? 'mute',
    trimSection: isPresent(component.trim_section)
      ? { startTime: component.trim_section.start_time, endTime: component.trim_section.end_time }
      : { startTime: 0, endTime: 0 },
    videoTrimSections: video?.trim,
    fadeSections,
    url,
    component_ref_id: component.component_ref_id,
    layerId: component.layer_id,
    name: component.layer_name,
    audioType: component.sub_type as AUDIO_TYPE,

    // Volume and ducking
    volume: component.audio_volume ?? 1,
    isDucked: !!component.is_ducking,
    duckedVolume: component.bg_volume ?? 1,
    fadeInDuration: component.fade_in_time ?? 0,
    fadeOutDuration: component.fade_out_time ?? 0,

    // Panner FX
    pannerPan: component.panner_pan ?? 0,

    // Compressor FX
    compressorAttack: component.compressor_attack ?? 0.003,
    compressorBypass: component.compressor_bypass ?? true,
    compressorGain: component.compressor_gain ?? 1,
    compressorKnee: component.compressor_knee ?? 30,
    compressorRatio: component.compressor_ratio ?? 1,
    compressorRelease: component.compressor_release ?? 0.1,
    compressorThreshold: component.compressor_threshold ?? 0,
  };
}

/* utils */
function getPanToBottomCalcs(fitType: string): Slate.PanJSON | null {
  let pan: Slate.PanJSON | null;
  switch (fitType) {
    case 'fit_with_blur':
      pan = {
        x0: 0.5,
        x1: 0.5,
        y0: 0.3,
        y1: 0.6,
      };
      break;
    case 'fit_with_transparent':
    case 'crop_to_screen':
      pan = {
        x0: 0.5,
        x1: 0.5,
        y0: 0,
        y1: 1,
      };
      break;
    case 'stretch_to_screen':
      pan = {
        x0: -50,
        x1: 50,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    default:
      pan = null;
  }

  return pan;
}

function getPanToLeftCalcs(fitType: string): Slate.PanJSON | null {
  let pan: Slate.PanJSON | null;
  switch (fitType) {
    case 'fit_with_blur':
      pan = {
        x0: 0.6,
        x1: 0.3,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    case 'fit_with_transparent':
    case 'crop_to_screen':
      pan = {
        x0: 1,
        x1: 0,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    case 'stretch_to_screen':
      pan = {
        x0: -50,
        x1: 50,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    default:
      pan = null;
  }

  return pan;
}

function getPanToRightCalcs(fitType: string): Slate.PanJSON | null {
  let pan: Slate.PanJSON | null;
  switch (fitType) {
    case 'fit_with_blur':
      pan = {
        x0: 0.3,
        x1: 0.6,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    case 'fit_with_transparent':
    case 'crop_to_screen':
      pan = {
        x0: 0,
        x1: 1,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    case 'stretch_to_screen':
      pan = {
        x0: 50,
        x1: -50,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    default:
      pan = null;
  }

  return pan;
}

function getPanToUpCalcs(fitType: string): Slate.PanJSON | null {
  let pan: Slate.PanJSON | null;
  switch (fitType) {
    case 'fit_with_blur':
      pan = {
        x0: 0.5,
        x1: 0.5,
        y0: 0.6,
        y1: 0.3,
      };
      break;
    case 'fit_with_transparent':
    case 'crop_to_screen':
      pan = {
        x0: 0.5,
        x1: 0.5,
        y0: 1,
        y1: 0,
      };
      break;
    case 'stretch_to_screen':
      pan = {
        x0: 50,
        x1: -50,
        y0: 0.5,
        y1: 0.5,
      };
      break;
    default:
      pan = null;
  }

  return pan;
}

function getBandStatus(component: Master.TextJSON) {
  let hasBand = false;
  try {
    if (component.layout.main_font.colors_ref!.band_opacity.reference > 0) {
      hasBand = true;
    }
  } catch (_) {
    console.log('No band opacity');
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return hasBand;
  }
}

function extractMaskFromChildComponent(
  children: Shadow.ComponentJSON[],
  componentData: ParentData,
): Slate.ImageJSON | null {
  const shadowMaskComponent = children.find(
    (child) => child.type === 'svg_image',
  ) as Shadow.ImageJSON;
  let slateMaskJSON = null;
  if (shadowMaskComponent) {
    slateMaskJSON = parseImage(shadowMaskComponent, componentData, true);
    slateMaskJSON.url = slateMaskJSON.url.replace('.svg', '.png').replace('/SVG/', '/PNG/');
  }
  return slateMaskJSON;
}

export const LOGO_PLACEHOLDER_URLS = [
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/addalogo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo1598611463649.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo1596696031140.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/01Invideo/01_Standard_logo/Default_Black_logo.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/logos/Default_Logo_Black.png',
  'https://invideo-block-assets.s3.ap-south-1.amazonaws.com/Template_Block_Assets/New_Structure/Default_Logo.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/logos/yourlogo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/LogoPng1589889662615.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/New_Structure/Default_Logo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/WDefaultLogo1568638576851.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1557481105097.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1554565341718.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1555166772774.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1556954361939.png',
  'https://invideo-uploads-eu-west-2.s3.eu-west-2.amazonaws.com/DefaultLogo11567626013932.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo11591938577591.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/LogoPng1589950857138.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/New_Structure/Default_Logo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/WDefaultLogo1568638576851.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/logos/Default_Logo_Black.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/LogoPng1589889662615.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogo1568633440290.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogo11565246611814.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1555166772774.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo21593611015673.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogoBlack1585133858719.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo1597383607504.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1556954361939.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogoBlack1579243736242.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlackLogo',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogoBlack',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogo1',
];

function isLogoPlaceholderUrl(url: string) {
  return new RegExp(LOGO_PLACEHOLDER_URLS.join('|'), 'i').test(url);
}

export function isPlaceholderLogoComposite(component: Shadow.CompositeJSON): boolean {
  if (component.sub_type !== 'logo_composite') return false;
  const image = component.components[0];
  if (!image || !('url' in image)) return false;

  return isLogoPlaceholderUrl(image.url);
}

function extractBlendModeFromCompositeOrChildJson(
  component: Shadow.CompositeJSON,
  _slateCompositeJSON: Slate.BoxJSON,
) {
  // In case of `text_composite`, if a blendMode is applied to the box asset inside it
  // we need to apply the blend to the whole text composite
  // As per classic code, we never consider whats inside composite json blend mode.
  const compositeBlendMode = component.layout.layer_properties.blend_effect;
  const compositeBlendModeEnable = !!component.layout.layer_properties.is_blend_enabled;
  if (compositeBlendMode && compositeBlendMode !== 'None' && compositeBlendModeEnable) {
    return toBlendMode(compositeBlendMode);
  }
  let childBlendEffect = 'normal';
  const childImageWithBlend = component.components.find(
    (child) =>
      (child.type === 'image' || child.type === 'video' || child.type === 'text') &&
      [true, undefined].includes(child.layout.layer_properties.is_blend_enabled) &&
      !['normal', 'None', undefined].includes(child.layout.layer_properties.blend_effect),
  );
  if (childImageWithBlend !== undefined) {
    childBlendEffect = childImageWithBlend.layout.layer_properties.blend_effect ?? 'normal';
  }
  return toBlendMode(childBlendEffect);
}

function parseFadeSections(
  audioBlock: Pick<Shadow.AudioBlockJSON, 'fade_out_time' | 'audio_list' | 'audio_volume'>,
  tStart: number,
  tEnd: number,
): Array<Slate.FadeSection> {
  const fadeOut = audioBlock.fade_out_time;

  const audioList = audioBlock.audio_list.filter((audioItem) => audioItem.absolute_duration >= 0.5);
  if (audioList.length > 0) {
    const lastAudioItem = audioList[audioList.length - 1];
    const newLastAudioItem: Master.AudioListItemJSON = {
      ...lastAudioItem,
      absolute_start: tEnd - fadeOut,
      absolute_end: tEnd,
      audio_volume: 0,
      fade_time: fadeOut,
      fade_start: tEnd - fadeOut,
    };
    newLastAudioItem.absolute_duration =
      newLastAudioItem.absolute_end - newLastAudioItem.absolute_start;
    audioList.push(newLastAudioItem);
  } else {
    const firstAudioListItem: Master.AudioListItemJSON = {
      absolute_start: tStart,
      absolute_end: tEnd,
      absolute_duration: tEnd - tStart,
      audio_volume: audioBlock.audio_volume,
      fade_time: 0,
      fade_start: tStart,
    };
    audioList.push(firstAudioListItem);

    const lastAudioListItem: Master.AudioListItemJSON = {
      absolute_start: tStart,
      absolute_end: tEnd,
      absolute_duration: tEnd - tStart,
      audio_volume: audioBlock.audio_volume,
      fade_time: 0,
      fade_start: tEnd,
    };
    audioList.push(lastAudioListItem);
  }

  const fadeSections: Slate.FadeSection[] = [];
  for (let i = 0; i < audioList.length; i++) {
    const nowAudioListItem = audioList[i];
    const tStart = nowAudioListItem.fade_start;
    const tEnd = nowAudioListItem.fade_start + nowAudioListItem.fade_time;

    const previousAudioListItem = audioList[i - 1];
    const vStart = i === 0 ? 0 : previousAudioListItem.audio_volume;
    const vEnd = audioList[i].audio_volume;
    const fadeSection: Slate.FadeSection = {
      tStart,
      tEnd,
      vStart,
      vEnd,
    };

    fadeSections.push(fadeSection);
  }
  return fadeSections;
}

/* parsers */
const white = { r: 255, g: 255, b: 255, a: 255 };
const transparent = { r: 0, g: 0, b: 0, a: 0 };

function parseColorCorrection(masterCc: string | Master.ColorCorrectionJSON | undefined | null): {
  isEnabled?: boolean;
  colorCorrection: ImageJSON['colorCorrection'];
} {
  if (!masterCc) {
    return {
      colorCorrection: null,
    };
  }

  const isEnabled =
    (typeof masterCc !== 'string' &&
      [undefined, true].includes((masterCc as Master.ColorCorrectionJSON).isEnabled)) ||
    masterCc === 'None';

  if (typeof masterCc === 'string') {
    const colorCorrectionType = toColorCorrection(masterCc);
    switch (colorCorrectionType) {
      case null: {
        return { isEnabled, colorCorrection: null };
      }
      case 'duotone1': {
        return { isEnabled, colorCorrection: { type: 'duotone1', color: white } };
      }
      case 'duotone2': {
        return { isEnabled, colorCorrection: { type: 'duotone2', color_1: white, color_2: white } };
      }
      case 'tritone1': {
        return { isEnabled, colorCorrection: { type: 'tritone1', color: white } };
      }
      case 'tritone3': {
        return {
          isEnabled,
          colorCorrection: { type: 'tritone3', color_1: white, color_2: white, color_3: white },
        };
      }
    }
  } else {
    const colorCorrectionType = masterCc?.type ? toColorCorrection(masterCc.type) : null;
    switch (colorCorrectionType) {
      case 'duotone1': {
        return {
          isEnabled,
          colorCorrection: { type: 'duotone1', color: toColor(masterCc.color) ?? white },
        };
      }
      case 'duotone2': {
        return {
          isEnabled,
          colorCorrection: {
            type: 'duotone2',
            color_1: toColor(masterCc.color_1) ?? white,
            color_2: toColor(masterCc.color_2) ?? white,
          },
        };
      }
      case 'tritone1': {
        return {
          isEnabled,
          colorCorrection: {
            type: 'tritone1',
            color: toColor(masterCc.color) ?? white,
          },
        };
      }
      case 'tritone3': {
        return {
          isEnabled,
          colorCorrection: {
            type: 'tritone3',
            color_1: toColor(masterCc.color_1) ?? white,
            color_2: toColor(masterCc.color_2) ?? white,
            color_3: toColor(masterCc.color_3) ?? white,
          },
        };
      }
      case null: {
        return { isEnabled, colorCorrection: null };
      }
    }
  }
}

function parseBoxFit(fitType: string): Slate.BoxFit {
  switch (fitType) {
    case 'free_flow':
    case 'stretch_to_screen':
      return 'fill';
    case 'fit_with_blur':
    case 'fit_with_transparent':
      return 'contain';
    default:
      return 'cover';
  }
}

/* time and position helpers */
function relativeToAbsoluteTime(
  parentData: ParentData,
  duration: Master.DurationJSON,
): { tStart: number; tEnd: number } {
  const { t0, t1 } = parentData.duration;
  const t = t1 - t0;
  const tStart = Math.round((t0 + t * duration.start_time) * 10000) / 10000;
  const tEnd = Math.round((t0 + t * duration.end_time) * 10000) / 10000;
  return {
    tStart,
    tEnd,
  };
}

function absoluteAnimationTime(
  tStart: number,
  tEnd: number,
  json: Shadow.BlockJSON | Shadow.ComponentJSON,
): { tIn: number; tOut: number } {
  const duration = tEnd - tStart;
  const animationIn = json.animation.animation_in ?? 'None';
  const animationOut = json.animation.animation_out ?? 'None';
  const inEffectTime = json.animation.animation_in_parameters?.effect_time;
  const inAnimationDuration = inEffectTime ? inEffectTime * duration : 1;

  const tIn = tStart + (animationIn === 'None' ? 0 : inAnimationDuration);

  const outEffectTime = json.animation.animation_out_parameters?.effect_time;
  const outAnimationDuration = outEffectTime ? outEffectTime * duration : 1;
  const tOut = tEnd - (animationOut === 'None' ? 0 : outAnimationDuration);
  return { tIn, tOut };
}

export function relativeToAbsolutePosition(
  parentData: ParentData['size'],
  json: Shadow.BlockJSON | Shadow.ComponentJSON,
): {
  top_x: number;
  top_y: number;
  bottom_x: number;
  bottom_y: number;
} {
  const top_x = parentData.w * json.position.top_x!;
  const top_y = parentData.h * json.position.top_y!;
  const bottom_x = parentData.w * json.position.bottom_x!;
  const bottom_y = parentData.h * json.position.bottom_y!;
  return { top_x, top_y, bottom_x, bottom_y };
}

function getUploadsBucketAndRegionFromUrl(url: string) {
  const urlArr = url.split('/');
  let bucket = '';
  let region = '';
  for (const key in urlArr) {
    if (key) {
      if (urlArr[key].includes('invideo-uploads')) {
        bucket = urlArr[key].split('.')[0];
        region = bucket.substring(16);
        break;
      }
    }
  }
  return { bucket: bucket, region: region };
}

function checkIfSpecialCharactersPresent(fileName: string) {
  const specialCharacters = '+%&?$!@#,:;=^*';
  const folderList = fileName.split('/');
  for (let i = 0; i < folderList.length; i++) {
    const name = folderList[i];
    for (let j = 0; j < specialCharacters.length; j++) {
      if (name.indexOf(specialCharacters[j]) > -1) {
        return true;
      }
    }
  }
  return false;
}

function getBucketAlias(bucket: string) {
  let alias = null;
  const bucket_data = availableBuckets.filter((buck) => {
    if (buck.name === bucket) {
      return true;
    }
    return false;
  });
  if (bucket_data) {
    alias = bucket_data[0]['alias'];
  }
  return alias;
}

function getUploadsImageOptimisedUrl(url: string, dimension: number) {
  let cloudfront_url = url;
  const buckets = ['invideo-uploads'];
  for (const key in buckets) {
    if (key) {
      if (url.includes(buckets[key])) {
        const urlArr = url.split('/');
        const filename = urlArr[urlArr.length - 1];
        // const compressed_url = this.getMediaAudioOptimisedUrl(url);
        const data = getUploadsBucketAndRegionFromUrl(url);
        const bucket = data['bucket'];
        const alias = getBucketAlias(bucket);
        if (alias) {
          cloudfront_url =
            'https://' + alias + '.cloudfront.net/' + dimension + 'x' + dimension + '/' + filename;
        }
      }
    }
  }
  return cloudfront_url;
}

function getMediaVideoOptimisedUrl(url: string) {
  const avaiableBuckets = [
    'invideo-uploads',
    'invideo-images',
    'invideo-overlays',
    'invideo-block-assets',
    'invideo-uploads-videos',
    'invideo-uploads-gif',
  ];
  const urlArr = url.split('/');
  let bucket = urlArr[3];
  if (url.includes('invideo-uploads')) {
    const regionBucketDetails = getUploadsBucketAndRegionFromUrl(url);
    bucket = regionBucketDetails['bucket'];
  }

  if (avaiableBuckets.includes(bucket)) {
    const bucketIndex = url.indexOf(bucket);
    const bucketLength = bucket.length;

    const fileNameWithExtension = url.substring(bucketIndex + bucketLength, url.length);
    const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
    const extension = fileNameWithExtension.split('.').pop();
    let newUrl = url;

    const isSpecialCharacter = checkIfSpecialCharactersPresent(fileName);

    let domainUrl = 'https://dulpz3imcxnof.cloudfront.net/';
    if (isSpecialCharacter) {
      domainUrl = 'https://s3.ap-south-1.amazonaws.com/previews-480/';
    }

    if (extension === 'webm') {
      newUrl = domainUrl + bucket + fileNameWithExtension;
    } else {
      if (bucket === 'invideo-uploads' || bucket === 'invideo-uploads-videos') {
        newUrl = domainUrl + bucket + fileName + '.mp4';
      } else {
        newUrl = domainUrl + bucket + fileNameWithExtension;
      }
    }
    return newUrl;
  } else if (url.includes('invideo-uploads')) {
    let newUrl = url;
    const filename = urlArr[urlArr.length - 1];
    // newUrl = 'https://s3.' + region + '.amazonaws.com/' + bucket + '/previews-480/' + filename;
    const alias = getBucketAlias(bucket);
    const index_of_dot = filename.lastIndexOf('.');
    const filename_without_extension = filename.substring(0, index_of_dot);
    let extension = filename.substring(filename.lastIndexOf('.'));
    if (extension !== '.webm') {
      extension = '.mp4';
    }
    newUrl =
      'https://' + alias + '.cloudfront.net/previews-480/' + filename_without_extension + extension;
    return newUrl;
  }

  return url;
}

function appendIvFrameToSource(url: string) {
  if (
    url &&
    !url.startsWith('blob:') &&
    !url.includes('vimeo.com') &&
    url.includes('cloudfront.net') &&
    !url.includes('ivSource=iv-frame')
  ) {
    url += url.includes('?') ? '&ivSource=iv-frame' : '?ivSource=iv-frame';
  }
  return url;
}

function getAudioOptimisedUrlCloudfront(url: string) {
  const bucket = 'storyblock-audios';
  let cloudfront_url = url;

  if (url.includes('invideo-uploads-audio')) {
    return url;
  }
  const urlArr = url.split('/');
  const filename = urlArr[urlArr.length - 1];
  if (url.includes('invideo-uploads')) {
    const data = getUploadsBucketAndRegionFromUrl(url);
    const bucket = data['bucket'];
    const alias = getBucketAlias(bucket);

    if (alias) {
      cloudfront_url = `https://${alias}.cloudfront.net/compressed-audio/${filename}`;
    }
  }
  if (url.includes(bucket)) {
    const alias = getBucketAlias(bucket);
    cloudfront_url = `https://${alias}.cloudfront.net/compressed-audio/${filename}`;
  }

  if (url.includes('invideo-music')) {
    const filepath = urlArr.slice(urlArr.indexOf('invideo-music') + 1).join('/');
    const alias = getBucketAlias('invideo-music');
    if (alias) {
      cloudfront_url = `https://${alias}.cloudfront.net/invideo-music/${filepath}`;
    }
  }

  return cloudfront_url;
}

function toOptimizedUrl(url: string, notVideoCheck?: boolean) {
  let newUrl = url;
  const dimension = 480;

  if (url && url.includes('amazonaws.com')) {
    const urlArr = url.split('/');
    let imageBucket = urlArr[3];
    if (url.includes('invideo-uploads')) {
      imageBucket = getUploadsBucketAndRegionFromUrl(url)['bucket'];
    }
    const fileArr = urlArr.slice(4);
    const filename = fileArr.join('/');
    availableBuckets.forEach((bucket) => {
      if (imageBucket === bucket.name) {
        if (
          imageBucket === 'converted-webm-videos' ||
          imageBucket === 'invideo-uploads-videos' ||
          imageBucket === 'invideo-block-assets' ||
          imageBucket === 'invideo-uploads-audio'
        ) {
          const isSpecialCharacter = checkIfSpecialCharactersPresent(filename);
          if (isSpecialCharacter) {
            newUrl = 'https://s3.ap-south-1.amazonaws.com/' + imageBucket + '/' + filename;
          } else if (notVideoCheck || url.includes('/MASKS/SVG/')) {
            newUrl =
              'https://' +
              bucket.alias +
              '.cloudfront.net/' +
              dimension +
              'x' +
              dimension +
              '/' +
              filename;
          } else {
            newUrl = 'https://' + bucket.alias + '.cloudfront.net/' + filename;
          }
        } else if (imageBucket.includes('invideo-uploads')) {
          newUrl = getUploadsImageOptimisedUrl(url, dimension);
        } else {
          newUrl =
            'https://' +
            bucket.alias +
            '.cloudfront.net/' +
            dimension +
            'x' +
            dimension +
            '/' +
            filename;
        }
      }
    });
  }
  if (
    newUrl &&
    !url.includes('vimeo.com') &&
    !newUrl.startsWith('blob:') &&
    !newUrl.includes('ivSource=iv-frame')
  ) {
    newUrl += newUrl.includes('?') ? '&ivSource=iv-frame' : '?ivSource=iv-frame';
  }
  return newUrl;
}

function parseClip(clip: (number | string)[] | undefined): Slate.ClipJSON | null {
  return clip
    ? {
        top: ((clip[0] as number) || 0) / 100,
        right: ((clip[1] as number) || 0) / 100,
        bottom: ((clip[2] as number) || 0) / 100,
        left: ((clip[3] as number) || 0) / 100,
      }
    : null;
}

/* sanitizers */

// There is  a bug in masterJSON where a 255 alpha is sometimes noted down as 1.
function toAlpha(a: number): number {
  return a === 1 ? 255 : a;
}

function toInt(num: string | number | null | undefined, defaultValue: number): number {
  try {
    if (num === undefined || num === null) return defaultValue;

    const a = typeof num === 'string' ? parseInt(num, 10) : num;
    return a;
  } catch (e) {
    console.log(`Failed to parse ${num} to a number`, e);
  }

  return defaultValue;
}

function toFloat(num: string | number | null | undefined, defaultValue: number): number {
  try {
    if (num === undefined || num === null) return defaultValue;

    const a = typeof num === 'string' ? parseFloat(num) : num;
    return a;
  } catch (e) {
    console.log(`Failed to parse ${num} to a number`, e);
  }

  return defaultValue;
}

function rot(num: number, by = 360): number {
  num = num % by;
  return num < 0 ? num + by : num;
}

const toColor = (c: undefined | null | (string | null | number)[]): Slate.ColorJSON | null => {
  if (!c || c.length === 0 || c.length > 4) return null;
  return {
    r: rot(toInt(c[0], 255), 256),
    g: rot(toInt(c[1], 255), 256),
    b: rot(toInt(c[2], 255), 256),
    a: toAlpha(rot(toInt(c[3], 255), 256)),
  };
};

function toEnum<T extends string | null | undefined>(
  values: T[],
): (val: string | null | undefined) => T {
  return (value: string | null | undefined) => values.find((v) => v === value) || values[0];
}

const toGranularity = toEnum<Slate.Granularity>(['whole', 'lines', 'words', 'chars']);
type ColorCorrectionType = 'duotone1' | 'tritone1' | 'duotone2' | 'tritone3';
const toColorCorrection = (value: string): ColorCorrectionType | null => {
  switch (value) {
    case 'duotone':
    case 'duotone1': {
      return 'duotone1';
    }
    case 'duotone_2': {
      return 'duotone2';
    }
    case 'tritone':
    case 'tritone1': {
      return 'tritone1';
    }
    case 'tritone3':
    case 'tritone_3': {
      return 'tritone3';
    }
    default: {
      return null;
    }
  }
};
const toTextAlignment = toEnum<Slate.TextAlignment>(['left', 'right', 'center']);
const toBullet = (bullet?: { type: Slate.Bullet; color?: number[] }): Slate.Bullets =>
  bullet
    ? {
        type: bullet.type,
        color: toColor(bullet.color) ?? toColor([0, 0, 0, 1])!,
      }
    : ({ type: 'round', color: toColor([0, 0, 0, 1]) } as Slate.Bullets);
const toOutline = (outline?: Master.OutlineJSON): Slate.Outline => {
  if (!outline)
    return { opacity: 0, thickness: 0, color: { r: 0, g: 0, b: 0, a: 0 }, enabled: false };

  let color = toColor(outline.color) ?? { r: 0, g: 0, b: 0, a: 0 };
  color = {
    ...color,
    a: outline.opacity,
  };
  return {
    enabled: outline.enabled,
    opacity: outline.opacity,
    thickness: outline.size,
    color,
  };
};
const toVerticalAlignment = (value: string): Slate.VerticalAlignment =>
  value === 'center' ? 'middle' : toEnum<Slate.VerticalAlignment>(['top', 'bottom'])(value);
// const toBoxFit = toEnum<Slate.BoxFit>(['contain', 'cover', 'fill']);

const fetchLanguage = (text: string) => {
  let lang = 'english';
  if (text) {
    const chars = text.split('');
    chars.forEach((element) => {
      const charCode = element.charCodeAt(0);
      if (charCode >= 2309 && charCode <= 2361) {
        // hindi
        lang = 'hindi';
      } else if (charCode >= 2688 && charCode <= 2815) {
        // gujarati
        lang = 'gujarati';
      } else if (charCode >= 3200 && charCode <= 3327) {
        lang = 'kannada';
      } else if (charCode >= 2432 && charCode <= 2559) {
        // bengali
        lang = 'bengali';
      } else if (charCode >= 2560 && charCode <= 2687) {
        // gurmukhi or punjabi
        lang = 'punjabi';
      } else if (charCode >= 3328 && charCode <= 3455) {
        // malayalam
        lang = 'malayalam';
      } else if (charCode >= 1536 && charCode <= 1791) {
        // arabic
        lang = 'arabic';
      } else if (charCode >= 3072 && charCode <= 3199) {
        lang = 'telugu';
      } else if (charCode >= 2944 && charCode <= 3071) {
        // Tamil
        lang = 'tamil';
      } else if (charCode >= 6016 && charCode <= 6143) {
        // Khmer
        lang = 'khmer';
      } else if (charCode >= 1424 && charCode <= 1535) {
        // hebrew
        lang = 'hebrew';
      } else if (charCode >= 1024 && charCode <= 1279) {
        // cyrillic
        lang = 'cyrillic';
      } else if (charCode >= 4096 && charCode <= 4255) {
        // myanmar
        lang = 'myanmar';
      } else if (charCode >= 19968 && charCode <= 40959) {
        // myanmar
        lang = 'cjk';
      } else if (charCode >= 3584 && charCode <= 3711) {
        lang = 'thai';
      } else if (charCode >= 880 && charCode <= 1023) {
        lang = 'greek';
      } else if (
        (charCode >= 12592 && charCode <= 12687) ||
        (charCode >= 44032 && charCode <= 55203)
      ) {
        lang = 'hangul';
      } else if (charCode >= 192 && charCode <= 255) {
        lang = 'latin';
      } else if (charCode >= 256 && charCode <= 591) {
        lang = 'latinextended';
      } else if (charCode >= 4608 && charCode <= 4991) {
        lang = 'ethiopic';
      }
    });
  }
  return lang;
};

const fallbackFontsFor = (text: string): { fallbackFonts: string[]; rtl: boolean } => {
  const fallbackFonts: string[] = [];
  let rtl = false;
  const langs = [
    {
      name: 'Latin',
      regex: /\p{Script_Extensions=Latin}/u,
      font: '/NotoSans-Regular.ttf',
      rtl: false,
    },
    {
      name: 'Greek',
      regex: /\p{Script_Extensions=Greek}/u,
      font: '/NotoSans-Regular.ttf',
      rtl: false,
    },
    {
      name: 'Tamil',
      regex: /\p{Script_Extensions=Tamil}/u,
      font: '/MuktaMalarRegular.ttf',
      rtl: false,
    },
    {
      name: 'Arabic',
      regex: /\p{Script_Extensions=Arabic}/u,
      font: '/NotoSansArabicRegular1624869346401JROAYNFU.ttf',
      rtl: true,
    }, //TODO: update to global font
    {
      name: 'Devnagari',
      regex: /\p{Script_Extensions=Devanagari}/u,
      font: '/Mukta-Regular.ttf',
      rtl: false,
    },
    {
      name: 'Gujarati',
      regex: /\p{Script_Extensions=Gujarati}/u,
      font: '/HindVadodara-Regular.ttf',
      rtl: false,
    },
    {
      name: 'Kannada',
      regex: /\p{Script_Extensions=Kannada}/u,
      font: '/BalooTammaRegular.ttf',
      rtl: false,
    },
    {
      name: 'Bengali',
      regex: /\p{Script_Extensions=Bengali}/u,
      font: '/HindSiliguriRegular.ttf',
      rtl: false,
    },
    {
      name: 'Malayalam',
      regex: /\p{Script_Extensions=Malayalam}/u,
      font: '/ChilankaRegular.ttf',
      rtl: false,
    },
    {
      name: 'Telugu',
      regex: /\p{Script_Extensions=Telugu}/u,
      font: '/NotoSansTeluguRegular.ttf',
      rtl: false,
    },
    {
      name: 'Khmer',
      regex: /\p{Script_Extensions=Khmer}/u,
      font: '/Khmer OS bokor.ttf',
      rtl: false,
    },
    {
      name: 'Hebrew',
      regex: /\p{Script_Extensions=Hebrew}/u,
      font: '/Heebo-Regular.ttf',
      rtl: true,
    },
    {
      name: 'Cyrillic',
      regex: /\p{Script_Extensions=Cyrillic}/u,
      font: '/Roboto-Regular.ttf',
      rtl: false,
    },
    { name: 'Thai', regex: /\p{Script_Extensions=Thai}/u, font: '/Prompt-Regular.ttf', rtl: false },
    {
      name: 'Hangul',
      regex: /\p{Script_Extensions=Hangul}/u,
      font: '/NotoSansKRRegular.otf',
      rtl: false,
    },
    {
      name: 'Myanmar',
      regex: /\p{Script_Extensions=Myanmar}/u,
      font: '/PadaukRegular1620212001289IDWQFKHB.ttf',
      rtl: false,
    },
    {
      name: 'Gurmukhi',
      regex: /\p{Script_Extensions=Gurmukhi}/u,
      font: '/MuktaMaheeRegular1620211613374DIVSQKRC.ttf',
      rtl: false,
    },
    {
      name: 'Ethiopic',
      regex: /\p{Script_Extensions=Ethiopic}/u,
      font: '/NotoSansEthiopicRegular1620212312454NFOIOWUF.ttf',
      rtl: false,
    },
    {
      name: 'Sinhala',
      regex: /\p{Script_Extensions=Sinhala}/u,
      font: '/AbhayaLibre-Regular.ttf',
      rtl: false,
    },
    {
      name: 'Oriya',
      regex: /\p{Script_Extensions=Oriya}/u,
      font: '/BalooBhaina2Regular.ttf',
      rtl: false,
    },
    {
      name: 'Lao',
      regex: /\p{Script_Extensions=Lao}/u,
      font: '/LaoSansProRegular1621409338889VHMKYRHG.ttf',
      rtl: false,
    },
    {
      name: 'Georgian',
      regex: /\p{Script_Extensions=Georgian}/u,
      font: '/DejaVuSansCondensed1621409832733PRSJIXQE.ttf',
      rtl: false,
    },
    {
      name: 'Armenian',
      regex: /\p{Script_Extensions=Armenian}/u,
      font: '/ArialAMURegular88911621412237319EUGUULHA.ttf',
      rtl: false,
    },
    {
      name: 'Japanese',
      regex: /\p{Script_Extensions=Han}/u,
      font: '/NotoSansJPRegular1621409560029KCPRHJEG.otf',
      rtl: false,
    },
    {
      name: 'Chinese Traditional',
      regex: /\p{Script_Extensions=Han}/u,
      font: '/NotoSansTCRegular1621411130509BQLBDLSA.otf',
      rtl: false,
    },
    {
      name: 'Chinese Simplified',
      regex: /\p{Script_Extensions=Han}/u,
      font: '/NotoSansSCRegular1621411960235NFSYZYGC.otf',
      rtl: false,
    },
    { name: 'Emoji', regex: /\p{Emoji}/u, font: '/NotoColorEmoji.ttf', rtl: false },
    { name: 'Special', regex: /[‑]/u, font: '/Roboto-Regular.ttf', rtl: false },
  ];
  langs.forEach((lang) => {
    if (lang.regex.test(text) && !fallbackFonts.includes(lang.font)) {
      fallbackFonts.push(lang.font);
      if (lang.rtl) rtl = true;
    }
  });
  return {
    fallbackFonts,
    rtl,
  };
};

const toFilterType = toEnum<Slate.FilterType | null>([
  null,
  'filter-1977',
  'filter-aden',
  'filter-amaro',
  'filter-ashby',
  'filter-brannan',
  'filter-brooklyn',
  'filter-charmes',
  'filter-clarendon',
  'filter-crema',
  'filter-dogpatch',
  'filter-earlybird',
  'filter-gingham',
  'filter-ginza',
  'filter-hefe',
  'filter-helena',
  'filter-hudson',
  'filter-inkwell',
  'filter-juno',
  'filter-kelvin',
  'filter-lark',
  'filter-lofi',
  'filter-ludwig',
  'filter-maven',
  'filter-mayfair',
  'filter-moon',
  'filter-nashville',
  'filter-perpetua',
  'filter-poprocket',
  'filter-reyes',
  'filter-rise',
  'filter-sierra',
  'filter-skyline',
  'filter-slumber',
  'filter-stinson',
  'filter-sutro',
  'filter-toaster',
  'filter-valencia',
  'filter-vesper',
  'filter-walden',
  'filter-willow',
  'filter-xpro-ii',
]);
const toBlendMode = toEnum<Slate.BlendMode>([
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity',
  'soft-light',
  'hard-light',
]);

const toSelectionType = (
  isBackground: boolean,
  sub_type: string,
  first_child_type: string,
  first_child_image_url?: string | null,
) => {
  if (isBackground) {
    return 'BACKGROUND';
  } else {
    switch (sub_type) {
      case 'text_composite':
        return 'TEXT';
      case 'logo_composite': {
        const isLogoPlaceholder = isLogoPlaceholderUrl(first_child_image_url!);
        return isLogoPlaceholder ? 'DELETABLE_LOGO' : 'LOGO';
      }
      case 'icon_composite':
        return 'MAINTAIN_ASPECT_RATIO';
      case 'bg_media_composite': {
        return first_child_type === 'svg_image' ? 'MAINTAIN_ASPECT_RATIO' : 'DEFAULT';
      }
      case 'position_composite':
        return 'GROUP';
      default:
        return 'DEFAULT';
    }
  }
};

function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

// for testing
export const internals = {
  toColor,
  parseBox,
  parseColorCorrection,
  getPanToBottomCalcs,
  getPanToLeftCalcs,
  getPanToRightCalcs,
  getPanToUpCalcs,
  parseAudio,
  toSelectionType,
  toOptimizedUrl,
  getAnimationGranularity,
  parseFadeSections,
};

function toAdjustmentFilters(filters?: Master.AdjustmentFilterJSON) {
  return {
    brightness: filters?.brightness ?? 0.5,
    contrast: filters?.contrast ?? 0.5,
    saturation: filters?.saturation ?? 0.5,
    sharpness: filters?.sharpness ?? 0.5,
    vibrance: filters?.vibrance ?? 0.0,
    exposure: filters?.exposure ?? 0.5,
    shadow: filters?.shadow ?? 0.0,
    highlight: filters?.highlight ?? 0.0,
    offset: filters?.offset ?? 0.25,
    temperature: filters?.temperature ?? 0.0,
    tint: filters?.tint ?? 0.0,
    vignette: filters?.vignette ?? 0.0,
    hue: filters?.hue ?? 0.5,
  };
}

function toDropShadow(shadow?: string | Master.DropShadowJSON): Slate.ShadowJSON {
  if (!shadow || typeof shadow == 'string') {
    return {
      opacity: 0,
      distance: 0,
      blur: 0,
      angle: 0,
      color: { r: 0, g: 0, b: 0, a: 0 },
      enabled: false,
    };
  }
  const color = (shadow as Master.DropShadowJSON).drop_shadow_color;
  const enabled = !!(shadow as Master.DropShadowJSON).enabled;
  const [r, g, b, a] = Array.isArray(color)
    ? color.map((it) => parseInt(it.toString()))
    : [0, 0, 0, 1];
  return {
    opacity: shadow.opacity,
    distance: shadow.distance,
    blur: shadow.blur,
    angle: shadow.angle,
    color: { r, g, b, a },
    enabled: enabled,
  };
}

import { cloneJSON } from '../utils';
import { Master } from '../jsonTypes';
import { calcTextMetricsForComposite, extractTextCompositeRefs, TextRefs } from './skia';
import { layerManager } from '../project';
import { removeCompositesWithBlobUrl } from './purgeBlobUrls';

const TEXT_ENGINE_VERSION = 0.7;

export async function checkAndUpgrade(
  masterJson: Master.JSON,
  forceCalculate = false,
): Promise<Master.JSON> {
  const cloneJson = cloneJSON(masterJson);
  const dimension = cloneJson.dimensions[0] as Master.Dimension;
  const dimensionJSON = cloneJson[dimension];
  if (!dimensionJSON) {
    throw new Error('Dimension JSON is not initialised');
  }
  const screenSize: [number, number] = [dimensionJSON.screen_size[0], dimensionJSON.screen_size[1]];

  // Purge blob urls
  removeCompositesWithBlobUrl(dimensionJSON);

  // Sync timeline layers
  layerManager.syncLayers(dimensionJSON, 1, true);

  // Fix end clip
  fixEndclipBlock(dimensionJSON);

  const version = dimensionJSON.versions?.textEngine ?? TEXT_ENGINE_VERSION - 1;
  const upgradedForDimension = dimensionJSON.versions?.forDimension;
  const currentForDimension = `${screenSize[0]}x${screenSize[1]}`;
  if (
    !forceCalculate &&
    version >= TEXT_ENGINE_VERSION &&
    upgradedForDimension === currentForDimension
  ) {
    return Promise.resolve(cloneJson);
  }

  const textComposites: TextRefs = extractTextCompositeRefs(dimensionJSON, screenSize);
  const promisesArrays = textComposites.map((textComposite) =>
    calcTextMetricsForComposite(textComposite.comp, textComposite.size, true),
  );
  const promises: Promise<Master.TextJSON>[] = Array.prototype.concat.apply([], promisesArrays);
  await Promise.all(promises);

  if (promises.length > 0) {
    if (!dimensionJSON.versions)
      dimensionJSON.versions = {
        forDimension: currentForDimension,
        textEngine: TEXT_ENGINE_VERSION,
      };
    else {
      dimensionJSON.versions.textEngine = TEXT_ENGINE_VERSION;
      dimensionJSON.versions.forDimension = currentForDimension;
    }
  }
  return cloneJson;
}

function fixEndclipBlock(dimensionJSON: Master.DimensionJSON) {
  function fixID(c: Master.BlockJSON | Master.AnyComponentJSON) {
    if (c.id.charAt(0) !== '.') {
      console.log(`fixed\n${c.id}`);
      c.id = `.${c.id}`;
      c.components?.forEach((c) => fixID(c));
    }
  }
  dimensionJSON.blocks.forEach((b) => {
    if (b.type === 'endclip_block') fixID(b);
  });
}

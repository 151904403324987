import { Master } from '../jsonTypes';

export const orderedDimensions: Master.Dimension[] = ['16:9', '9:16', '1:1'];

export const NULL_BLOCK_ID = '.00000000-0000-0000-0000-000000000000';
export const NULL_COMPOSITE_ID = `${NULL_BLOCK_ID}.00000000-0000-0000-0000-000000000000`;
export const NULL_COMPONENT_ID = `${NULL_COMPOSITE_ID}.00000000-0000-0000-00000000-0000-0000-0000-000000000000`;
export const NULL_CLIP_ID = `${NULL_COMPONENT_ID}.00000000-0000-0000-00000000-0000-0000-0000-000000000000`;
export const NULL_LAYER_ID = '.00000000-0000-0000-0000-000000000000';
export const NULL_GAP_ID = '.00000000-0000-0000-0000-000000000000';
export const NULL_DIMENSION = '16:9';
export const NULL_DURATION = 5;

const color: [string, string, string, number] = ['255', '255', '255', 1];
export const NULL_VIDEO: Master.JSON = {
  master_video_id: 0,
  is_colors_applied: false,
  '16:9': {
    master_video_id: 0,
    template_name: '',
    premium: [],
    template_id: 0,
    screen_size: [1280, 720],
    blocks: [
      {
        name: 'loading...',
        type: 'empty_block',
        components: [
          {
            name: 'loading...',
            type: 'composite',
            compositeType: Master.CompositeType.DEFAULT,
            components: [
              {
                name: 'empty_bg',
                type: 'image',
                components: [],
                layout: {
                  layer_properties: {
                    opacity: 1,
                    blend_effect: 'None',
                    color_correction: {
                      type: 'duotone',
                      color: ['5', '21', '39', '1'],
                    },
                    color_overlay: 'None',
                    drop_shadow: 'None',
                    opacity_ref: { user_flag: 0, reference: 1 },
                    filter: 'None',
                  },
                  fit_type: 'crop_to_screen',
                  loop: 1,
                  blur: 0,
                  playback_rate: 1,
                  playback_type: 'speed_factor',
                  mute: 0,
                },
                duration: {
                  split_self: 0,
                  split_others: 0,
                  delay: 0,
                  priority: 5,
                  absolute_duration: 5,
                  element_duration: 5,
                  start_time: 0,
                  end_time: 1,
                  absolute_start: 0,
                  absolute_end: 5,
                  precalculated_duration: 4.04,
                  minimum_duration: 5,
                },
                animation: {
                  animation_in: 'None',
                  animation_out: 'None',
                  animation: 'None',
                  animation_in_parameters: { type: 'whole' },
                  animation_out_parameters: { type: 'whole' },
                },
                audio: {},
                position: {
                  top_x: 0,
                  bottom_x: 1,
                  top_y: 0,
                  bottom_y: 1,
                  pivot_x: 0.5,
                  pivot_y: 0.5,
                  orientation: 'horizontal',
                },
                input: {
                  type: 'mandatory',
                  placeholder: 'Blah',
                  allow_multiple: 0,
                  required: 0,
                  hidden: 1,
                },
                id: NULL_COMPONENT_ID,
                url: 'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Background/bguniquecanvas.png',
                sub_type: 'image_asset',
                orientation: '',
                screen_edit: 1,
                screen_click: 0,
                thumbnail_url:
                  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Background/bguniquecanvas.png',
              },
            ],
            layout: {
              fit_type: 'self_position',
              overflow: 'hidden',
              layer_properties: {
                opacity: 1,
                blend_effect: 'None',
                color_correction: 'None',
                color_overlay: 'None',
                drop_shadow: {
                  enabled: true,
                  name: 'None',
                  distance: 0,
                  blur: 0,
                  opacity: 0,
                  angle: 0,
                  drop_shadow_color: [0, 0, 0, 255],
                  colors_ref: { drop_shadow_color: { user_flag: 0, reference: [0, 0, 0, 255] } },
                  shadow_factor: 662.4999999999999,
                },
                opacity_ref: { user_flag: 0, reference: 1 },
              },
            },
            duration: {
              split_self: 1,
              split_others: 0,
              priority: 1.5,
              delay: 0,
              absolute_duration: 5,
              start_time: 0,
              end_time: 1,
              absolute_start: 0,
              absolute_end: 5,
              fixed_start_time: 0,
              fixed_end_time: 1,
            },
            animation: {
              animation_in: 'None',
              animation_out: 'None',
              animation: 'None',
              animation_in_parameters: { type: 'whole' },
              animation_out_parameters: { type: 'whole' },
            },
            position: {
              top_x: 0,
              bottom_x: 1,
              top_y: 0,
              bottom_y: 1,
              pivot_x: 0.5,
              pivot_y: 0.5,
              orientation: 'horizontal',
            },
            input: { type: 'mandatory', allow_multiple: 0, placeholder: 'Blah', hidden: 1 },
            id: NULL_COMPOSITE_ID,
            layer_id: NULL_LAYER_ID,
            layer_name: '',
            layer_visible: true,
            sub_type: 'pre_video_asset_composite',
            is_visible: true,
            is_lock: false,
          },
        ],
        layout: {
          layer_properties: {
            opacity: 1,
            blend_effect: 'None',
            color_correction: 'None',
            color_overlay: 'None',
            drop_shadow: 'None',
            opacity_ref: { user_flag: 0, reference: 1 },
          },
          fit_type: 'self_position',
          overflow: 'hidden',
        },
        duration: {
          split_self: 1,
          split_others: 1,
          priority: 1,
          delay: 0,
          absolute_duration: 5,
          start_time: 0,
          end_time: 1,
          absolute_start: 0,
          absolute_end: 5,
          block_minimum_duration: 2.5,
          fixed_duration: 5,
        },
        animation: {
          animation_in: 'None',
          animation: 'None',
          animation_out: 'None',
          animation_in_parameters: { in_effect_factor: 1, type: 'whole' },
          animation_out_parameters: { out_effect_factor: 1, type: 'whole' },
        },
        position: {
          orientation: 'horizontal',
          top_x: 0,
          bottom_x: 1,
          top_y: 0,
          bottom_y: 1,
          pivot_x: 0.5,
          pivot_y: 0.5,
        },
        id: NULL_BLOCK_ID,
        block_id: 0,
        screen_size: [1920, 1080],
        block_type: 'empty_json',
      },
    ],
    layers: [],
    audio_blocks: [],
    duration: {
      absolute_duration: 5,
      start_time: 0,
      absolute_start: 0,
      end_time: 1,
      absolute_end: 5,
    },
    video_properties: {
      fonts: { primary: '', secondary: '', tertiary: '' },
      colors: { primary: color, secondary: color, tertiary: color, quaternary: color },
      template_properties: {
        colors: { primary: color, secondary: color, tertiary: color, quaternary: color },
      },
      applied_colors: 'template',
    },
    timeline_config: {
      mode: 'smart',
    },
  },
  dimensions: ['16:9'],
  headline: '',
  text: '',
  listicle_flag: 0,
};

export const MIN_COMPONENT_DURATION = 0.3;
export const BLANK_TEMPLATE_ID = 10456;
export const STOCK_TEMPLATE_ID = 14132;
export const DEFAULT_MASK_PLACEHOLDER_URL =
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/MASKS/mask-placeholder.png';
export const DEFAULT_FIXED_BACKGROUND_URL =
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Background/bguniquecanvas.png';
export const LOGO_PLACEHOLDER_URLS = [
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/addalogo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo1598611463649.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo1596696031140.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/01Invideo/01_Standard_logo/Default_Black_logo.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/logos/Default_Logo_Black.png',
  'https://invideo-block-assets.s3.ap-south-1.amazonaws.com/Template_Block_Assets/New_Structure/Default_Logo.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/logos/yourlogo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/LogoPng1589889662615.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/New_Structure/Default_Logo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/WDefaultLogo1568638576851.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1557481105097.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1554565341718.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1555166772774.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1556954361939.png',
  'https://invideo-uploads-eu-west-2.s3.eu-west-2.amazonaws.com/DefaultLogo11567626013932.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo11591938577591.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/LogoPng1589950857138.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/New_Structure/Default_Logo.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/WDefaultLogo1568638576851.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/logos/Default_Logo_Black.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/LogoPng1589889662615.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogo1568633440290.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogo11565246611814.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1555166772774.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo21593611015673.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogoBlack1585133858719.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlacklogo1597383607504.png',
  'https://s3.ap-south-1.amazonaws.com/invideo-uploads-image/upload_img_1556954361939.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogoBlack1579243736242.png',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultBlackLogo',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogoBlack',
  'https://invideo-uploads-ap-south-1.s3.ap-south-1.amazonaws.com/DefaultLogo1',
];

export const STORYTELLER_TO_STORYBOARD_V2: { [key: string]: number } = {
  '10923': 21013,
  '10925': 21014,
  '10926': 21015,
  '10927': 21016,
  '10928': 21017,
  '10929': 21018,
  '10932': 21019,
  '10934': 21020,
  '10938': 21037,
  '10941': 21003,
  '10948': 21004,
  '10950': 21005,
  '10951': 20996,
  '10955': 21000,
  '10956': 21006,
  '10959': 20997,
  '10972': 20998,
  '10975': 21001,
  '11501': 20990,
  '13144': 20991,
  '13149': 20992,
  '13150': 20993,
  '13167': 22557,
  '16571': 20995,
  '16642': 20986,
  '16658': 20987,
  '16683': 20989,
  '16686': 20988,
  '19354': 20985,
  '19464': 20984,
  '19465': 20982,
  '19612': 20981,
  // these storyteller templates are missing
  // their respective storyboard v2 template
  '10824': 25170,
  '10930': 25212,
  '10931': 25176,
  '10935': 25177,
  '10939': 25213,
  '10947': 25229,
  '10952': 25214,
  '10953': 25215,
  '10954': 25162,
  '10957': 25216,
  '10958': 25223,
  '11000': 25157,
  '13130': 25153,
  '19431': 25146,
  '19615': 25143,
};

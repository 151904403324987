import { slice, State, TimeRegistry } from './videoSlice';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  PreviewType,
  SlateType,
  UserActionSource,
  UserActionSourceForSeekTimeAnalytics,
  Mode,
} from './types';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import * as MasterStore from '../store/masterStore';
import { Store } from '..';
import debug from 'debug';
import { timeUtils } from '../project';

const log = debug('shell-videoapi');

export class Api {
  constructor(private stateFetcher: () => State, private dispatch: Dispatch<AnyAction>) {
    // (window as any).scrubTo = (time: number) =>
    //   this.scrubTo(time, UserActionSourceForSeekTimeAnalytics.AUTO);
  }

  private get state(): State {
    return this.stateFetcher();
  }
  private get actions() {
    return slice.actions;
  }

  private sliceObservable<SliceT>(selector: (state: State) => SliceT) {
    return MasterStore.stores.video$.pipe(
      map((st) => selector(st)),
      distinctUntilChanged(),
    );
  }

  get asDebugView() {
    return this.state;
  }

  observables = {
    slateType$: this.sliceObservable((st) => st.slateType),
    mode$: this.sliceObservable((st) => st.mode),
    t$: TimeRegistry.t$,
    tSlate$: TimeRegistry.tSlate$,
    isSlateSeeking$: this.sliceObservable((st) => st.isSlateSeeking),
    isScrubbing$: this.sliceObservable((st) => st.isScrubbing),
    fps$: TimeRegistry.fps$,
    volume$: this.sliceObservable((st) => st.volume),
    isMuted$: this.sliceObservable((st) => st.isMuted),
    previewType$: this.sliceObservable((st) => st.previewType),
    seekAction$: this.sliceObservable((st) => st.seekAction),
    previewAction$: this.sliceObservable((st) => st.previewAction),
    isLoading$: this.sliceObservable((st) => st.mode === 'loading'),
    isEditor$: this.sliceObservable((st) => st.mode === 'editor' || st.mode === 'loading'),
    isPreview$: this.sliceObservable((st) => st.mode !== 'editor' && st.mode !== 'loading'),
    isPlaying$: this.sliceObservable((st) => st.mode === 'playing'),
    isPaused$: this.sliceObservable((st) => st.mode === 'paused'),
    slateFrame$: TimeRegistry.slateFrame$,
    isFullPreview$: this.sliceObservable(this._isFullPreview),
    isBasic$: this.sliceObservable(this._isBasic),
    isAdvanced$: this.sliceObservable(this._isAdvanced),
    isStoryboard$: this.sliceObservable(this._isStoryboard),
  };

  // PUBLIC API
  get slateType() {
    return this.state.slateType;
  }

  set slateType(slateType: SlateType) {
    this.dispatch(this.actions.slateTypeUpdated({ ...TimeRegistry.timeCheck(), slateType }));
  }

  get mode() {
    return this.state.mode;
  }

  set mode(mode: Mode) {
    if (this.state.mode === mode) {
      log('optimization: set mode is noop');
      return;
    }

    /**@description update slate bounds */
    let slateBounds = this.state.slateBounds;
    const block =
      Store.project.slateBlocks.find((block) => block.tSceneEnd > TimeRegistry.t()) ??
      Store.project.slateBlocks[0];
    if (mode === 'playing' && this.state.mode === 'paused') {
      switch (this.state.previewType) {
        case PreviewType.BASIC_TIMELINE_SCENE_PREVIEW: {
          slateBounds = { tSlateStart: block.tSceneStart, tSlateEnd: block.tSceneEnd };
          break;
        }
      }
    }
    this.dispatch(this.actions.updatedSlateBounds(slateBounds));

    this.dispatch(
      this.actions.modeUpdated({
        ...TimeRegistry.timeCheck(),
        mode,
      }),
    );
    this.completeSeek();
  }
  get t() {
    return TimeRegistry.t();
  }
  set t(time: number) {
    TimeRegistry.seek(time);
    if (MasterStore.reduxIsTracing) {
      this.dispatch(this.actions.debugTUpdated({ command: 't=', ...TimeRegistry.timeCheck() }));
    }
    this.completeSeek();
  }

  get tFrame() {
    return timeUtils.toFrame(this.t, this.fps);
  }

  set tFrame(frame: number) {
    this.t = timeUtils.toSeconds(frame, this.fps);
  }

  get slateFrame() {
    return TimeRegistry.slateFrame();
  }

  get tSlate() {
    return TimeRegistry.tSlate();
  }
  set tSlate(time: number) {
    if (!this.state.isSlateSeeking) {
      this.dispatch(this.actions.slateSeekStarted(TimeRegistry.timeCheck()));
    }
    TimeRegistry.seekSlate(time);
    if (MasterStore.reduxIsTracing) {
      this.dispatch(
        this.actions.debugTUpdated({ command: 'tSlate=', ...TimeRegistry.timeCheck() }),
      );
    }
  }

  get isScrubbing() {
    return this.state.isScrubbing;
  }

  get isSlateSeeking() {
    return this.state.isSlateSeeking;
  }

  get isSeeking() {
    return this.state.isSlateSeeking || this.state.isScrubbing;
  }

  get slateBounds() {
    return this.state.slateBounds;
  }

  // this is like scrubTo but with no seekAction implications
  forceSeek(time: number) {
    if (!this.state.isSlateSeeking) {
      this.dispatch(this.actions.slateSeekStarted(TimeRegistry.timeCheck()));
    }
    TimeRegistry.seek(time);
    if (MasterStore.reduxIsTracing) {
      this.dispatch(
        this.actions.debugTUpdated({ command: 'forceSeek', ...TimeRegistry.timeCheck() }),
      );
    }
    this.dispatch(this.actions.slateSeekCompleted(TimeRegistry.timeCheck()));
  }

  scrubTo(time: number, seekAction: UserActionSourceForSeekTimeAnalytics) {
    TimeRegistry.seek(time);
    if (MasterStore.reduxIsTracing) {
      this.dispatch(
        this.actions.debugTUpdated({ command: 'scrubTo', ...TimeRegistry.timeCheck() }),
      );
    }

    if (this.state.seekAction !== seekAction) {
      this.state.isScrubbing
        ? this.dispatch(
            this.actions.scrubActionChanged({ ...TimeRegistry.timeCheck(), seekAction }),
          )
        : this.dispatch(this.actions.scrubStarted({ ...TimeRegistry.timeCheck(), seekAction }));
    }
  }

  play() {
    this.dispatch(this.actions.playRequested(TimeRegistry.timeCheck()));
    this.completeSeek();
  }
  refreshSlateBoundsForReviewMode() {
    const duration =
      Store.project.slateBlocks.length !== 0
        ? Store.project.slateBlocks[Store.project.slateBlocks.length - 1].tSceneEnd
        : 0;
    const slateBounds = { tSlateStart: 0, tSlateEnd: duration };
    this.dispatch(this.actions.updatedSlateBounds(slateBounds));
  }
  reviewModeMounted() {
    const duration =
      Store.project.slateBlocks.length !== 0
        ? Store.project.slateBlocks[Store.project.slateBlocks.length - 1].tSceneEnd
        : 0;
    const slateBounds = { tSlateStart: 0, tSlateEnd: duration };
    this.dispatch(this.actions.updatedSlateBounds(slateBounds));
    this.dispatch(
      this.actions.modeUpdated({
        ...TimeRegistry.timeCheck(),
        mode: 'paused',
      }),
    );
    this.t = 0;
  }

  autoPreviewTransition(tIn: number, tOut: number) {
    const slateBounds = { tSlateStart: tIn, tSlateEnd: tOut };
    this.t = tIn;
    this.dispatch(this.actions.updatedSlateBounds(slateBounds));
    this.dispatch(
      this.actions.modeUpdated({
        ...TimeRegistry.timeCheck(),
        mode: 'playing',
      }),
    );
  }

  pause() {
    this.dispatch(this.actions.pauseRequested(TimeRegistry.timeCheck()));
    this.completeSeek();
  }
  edit() {
    this.dispatch(this.actions.editRequested(TimeRegistry.timeCheck()));
    this.completeSeek();
  }

  togglePlayPause(previewType: PreviewType, actionSource: UserActionSource) {
    const visualBlock = Store.project.slateBlocks.find(
      (block) => block.tSceneEnd >= TimeRegistry.t(),
    );
    const selectedBlock = Store.project.slateBlocks.find(
      (block) => block.id === Store.project.selectedBlockId,
    );
    const duration =
      Store.project.slateBlocks.length !== 0
        ? Store.project.slateBlocks[Store.project.slateBlocks.length - 1].tSceneEnd
        : 0;
    const block =
      (Store.video.mode === 'editor' || Store.video.isAdvanced ? selectedBlock : visualBlock) ??
      Store.project.slateBlocks[Store.project.slateBlocks.length - 1];

    let slateBounds = this.state.slateBounds;
    if (this.state.mode === 'editor' || this.state.mode === 'paused') {
      switch (previewType) {
        case PreviewType.ADVANCED_TIMELINE_PREVIEW_FROM_SEEKER:
        case PreviewType.ADVANCED_TIMELINE_SCENE_PREVIEW: {
          slateBounds = { tSlateStart: block.tStart, tSlateEnd: block.tEnd };
          break;
        }
        case PreviewType.BASIC_TIMELINE_FULL_PREVIEW: {
          slateBounds = { tSlateStart: 0, tSlateEnd: duration };
          break;
        }
        case PreviewType.BASIC_TIMELINE_SCENE_PREVIEW: {
          slateBounds = { tSlateStart: block.tSceneStart, tSlateEnd: block.tSceneEnd };
          break;
        }
      }
    }
    this.dispatch(this.actions.updatedSlateBounds(slateBounds));

    this.dispatch(
      this.actions.playPauseToggled({
        ...TimeRegistry.timeCheck(),
        previewType,
        actionSource,
      }),
    );
    this.completeSeek();
  }

  playStoryboard() {
    const selectedBlock = Store.project.slateBlocks.find(
      (block) => block.id === Store.project.selectedBlockId,
    );
    const start = selectedBlock?.tStart ?? 0;
    const end = selectedBlock?.tEnd ?? 0;
    const currentTime = this.t;
    if (currentTime > end || currentTime < start) {
      this.t = start;
    }
    this.dispatch(this.actions.updatedSlateBounds({ tSlateStart: start, tSlateEnd: end }));
    this.play();
  }

  get volume() {
    return this.state.volume;
  }
  set volume(volume: number) {
    this.dispatch(this.actions.volumeUpdated({ ...TimeRegistry.timeCheck(), volume }));
  }
  get loop() {
    return this.state.loop;
  }
  set loop(loop: boolean) {
    this.dispatch(this.actions.loopUpdated({ ...TimeRegistry.timeCheck(), loop }));
  }

  get fps() {
    return TimeRegistry.fps();
  }
  set fps(fps: number) {
    this.dispatch(this.actions.fpsUpdated({ ...TimeRegistry.timeCheck(), fps }));
  }

  get speed() {
    return this.state.speed;
  }
  set speed(speed: number) {
    this.dispatch(this.actions.speedUpdated({ ...TimeRegistry.timeCheck(), speed }));
  }

  get isMuted() {
    return this.state.isMuted;
  }
  get previewType() {
    return this.state.previewType;
  }
  set previewType(previewType: PreviewType) {
    this.dispatch(this.actions.previewTypeUpdated({ ...TimeRegistry.timeCheck(), previewType }));
    this.completeSeek();
  }
  get seekAction() {
    return this.state.seekAction;
  }
  get previewAction() {
    return this.state.previewAction;
  }

  //// helpers
  get isLoading(): boolean {
    return this.state.mode === 'loading';
  }
  get isEditor(): boolean {
    return this.state.mode === 'editor';
  }
  get isPreview(): boolean {
    return !this.isEditor;
  }

  get isFullPreview(): boolean {
    return this._isFullPreview(this.state);
  }

  get isAdvanced(): boolean {
    return this._isAdvanced(this.state);
  }

  get isBasic(): boolean {
    return this._isBasic(this.state);
  }

  get isStoryboard(): boolean {
    return this._isStoryboard(this.state);
  }

  get isPlaying(): boolean {
    return this.state.mode === 'playing';
  }
  get isPaused(): boolean {
    return this.state.mode === 'paused';
  }

  get isExplicitPreviewAction() {
    const previewAction = this.previewAction;
    return previewAction != UserActionSource.AUTO;
  }

  /// private
  _isFullPreview(st: State) {
    return (
      st.mode !== 'editor' &&
      (st.previewType === PreviewType.BASIC_TIMELINE_FULL_PREVIEW ||
        st.previewType === PreviewType.STORYBOARD_FULL_PREVIEW)
    );
  }
  _isBasic(st: State) {
    return (
      st.previewType === PreviewType.BASIC_TIMELINE_FULL_PREVIEW ||
      st.previewType === PreviewType.BASIC_TIMELINE_SCENE_PREVIEW
    );
  }

  _isAdvanced(st: State) {
    return (
      st.previewType === PreviewType.ADVANCED_TIMELINE_PREVIEW_FROM_SEEKER ||
      st.previewType === PreviewType.ADVANCED_TIMELINE_SCENE_PREVIEW
    );
  }

  _isStoryboard(st: State) {
    return (
      st.previewType === PreviewType.STORYBOARD_SCENE_PREVIEW ||
      st.previewType === PreviewType.STORYBOARD_FULL_PREVIEW
    );
  }

  reset() {
    this.dispatch(this.actions.reset());
  }

  /*
   * @deprecated use isSnapped= instead
   */
  setSnap(isSnapped: boolean) {
    this.isSnapped = isSnapped;
  }

  set isSnapped(isSnapped: boolean) {
    this.dispatch(this.actions.snapUpdated({ ...TimeRegistry.timeCheck(), isSnapped }));
  }

  /*
   * @deprecated use isSnapped instead
   */
  get snap() {
    return this.isSnapped;
  }

  get isSnapped() {
    return this.state.isSnapped;
  }

  /*
   * @deprecated no one sets this. Don't use it anymore
   */
  get tPreviewStart() {
    return this.state.tPreviewStart;
  }

  get isFrame() {
    return this.state.slateType === SlateType.FRAME;
  }

  private completeSeek() {
    if (this.state.isSlateSeeking) {
      this.dispatch(this.actions.slateSeekCompleted(TimeRegistry.timeCheck()));
    }
    if (this.state.isScrubbing) {
      this.dispatch(this.actions.scrubCompleted(TimeRegistry.timeCheck()));
    }
  }

  seekToComponent(componentId: string) {
    const component = Store.project.getShadowComponentById(componentId);
    if (!component) {
      console.warn('Component to seek not found. Defaulting to Scene mid');
    }
    const start = component?.duration?.absolute_start ?? 0;
    const duration =
      component?.duration?.absolute_duration ??
      Store.project.selectedBlock.duration.absolute_duration;
    const time = start + duration / 2;
    this.t = time;
  }
}

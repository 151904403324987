import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SceneLimitModal,
  ScrollBehavior,
  TimelinePrompts,
  TimelineScrollIntent,
  TimelineTypeIntent,
  TransitionCompositesState,
  TimelineInteraction,
  EditorNavigationRoute,
  RouterEvent,
  LayeredTimelineVariant,
  TransitionColors,
  TemplatesVariant,
  StockVariant,
  MusicVariant,
  TextVariant,
  LogoVariant,
  ColorVariant,
  ElementsVariant,
  CollageVariant,
  SocialVariant,
  UploadedMedia,
  RearrangeModal,
  LihpPostProcessParams,
  PaymentModalParams,
  TransitionHistory,
  TransitionHistoryEntry,
  ConfirmationState,
  ActiveSection,
  PaymentConfirmModalMode,
  RemoveVideoBgPostProcessRequest,
  VideoTrimModalOpenLocation,
  VideoTrimModalCloseLocation,
  ModalType,
  InspectorSelectedProperty,
  ConfirmationModalOptions,
  AnimationData,
  StockVideoTemplateInfo,
  REMOVE_VIDEO_BG_STATUS,
} from './types';
import { NULL_COMPONENT_ID } from '../project/constants';
import {
  ProjectAutoSaveStatus,
  TransitionState,
  KeyboardContext,
  UploadPayload,
  RecentUpload,
  RecentUploadStatus,
  TrimModalData,
  RepaintComponentType,
  StoryboardSceneBGMediaPayload,
  ZoomLevelData,
  TimelineMediaAddType,
  TimelineType,
  RepaintType,
  Module,
  CopyPasteAction,
  CopyPaste,
  DraggedMediaJSON,
  DraggedElementJSON,
  Mode,
  StoryboardNavigationRoute,
  StoryboardNavigationData,
  ApplyAllJSON,
  ResizeType,
  UndoRedoSource,
  RepaintState,
  LogoPlaceholderModalPayload,
  FontLoadingStatus,
  FontModel,
  EditorLoadingStatus,
  TimelineVariant,
  TimelineInteractionVariant,
  ActiveTab,
  AnalyticsState,
  LeftPanelActionSource,
} from './types';
import {
  FilterProperties,
  VideoTrimSectionJSON,
  ImageMediaPropertiesJSON,
  ImageBgRemovalType,
} from '../jsonTypes/MasterJSON';
import { ProxyJobResult } from './types';
import {
  inPlaceAnimations,
  TRANSITION_COMPOSITE_JSON,
  TRANSITION_DEFAULT_CONFIG,
  TRANSITION_IMAGE_COMPONENT_JSON,
  TRANSITION_VIDEO_COMPONENT_JSON,
} from './constants';

const aiBgLocalStorageKey = 'aiBgRemovalOngoingJobs';

type TimelineVersion<T> = T extends 'timeline' ? 'iv-timeline' : never;
type SlateVersion<T> = T extends 'slate' ? 'frame' | 'classic' | null : never;

type ModuleState<T extends Module> = {
  version: TimelineVersion<T> | SlateVersion<T> | 'classic' | null;
  loading: 'loaded' | 'failed' | null;
};

export type upgradeModalSource = 'cta' | 'brandwatermark';

export type frameLoader = 'NORMAL' | 'BLUR' | 'NONE';
export type ClipTopPanelButtonClickStatus = null | {
  id: string;
  button: 'delete';
  status: 'handled' | 'tobehandled';
  uxFlow: string;
  uxSource: string;
};

export type RedirectRequest = null | {
  status: 'handled' | 'tobehandled';
  path: string;
};
export type AudioTopPanelButtonClickStatus = null | {
  id: string;
  button: 'trim' | 'duplicate' | 'delete' | 'unloop' | 'loop' | 'trim';
  status: 'handled' | 'tobehandled';
};
export type EditorMode = 'editor' | 'storyboard';

export type EditorModeSource = 'auto' | 'review';

export type StoryboardV2State =
  | StoryboardBlankState
  | StoryboardProcessTextState
  | StoryboardGeneratingSceneState
  | StoryboardReadyState;

export type StoryboardBlankState = {
  type: 'blank';
};
export type StoryboardProcessTextState = {
  type: 'processing_text';
};
export type StoryboardGeneratingSceneState = {
  type: 'generating_scenes';
  payload?: StoryboardGeneratingScenePayload;
};

export type StoryboardReadyState = {
  type: 'ready';
};

export type StoryboardGeneratingScenePayload = {
  isFreeMedia: boolean;
  isIStockMedia: boolean;
  isPremiumMedia: boolean;
};

export type VolumePanelCloseButtonClickStatus = null | 'tobehandled' | 'handled';

export interface BgRemovalJob {
  jobId: string;
  s3Key: string;
  status: REMOVE_VIDEO_BG_STATUS;
  componentId: string;
  proxyData: ProxyJobResult | null;
}
export interface ImageBgRemovalJob {
  jobId: string;
  status: string;
  componentId: string;
  type: ImageBgRemovalType;
}

//non-exhaustive fields
export type ModifiedCustomTextStyleItem = {
  id: number;
};

export type State = {
  backgroundRemoveLimitAlert: boolean;
  /**
   * @description will be set to true when the editor loads the initial masterjson
   */
  isInitialMasterJSONFetched: boolean;

  isMyMediaBackButtonHidden: boolean;

  clipTopPanelButtonClickStatus: ClipTopPanelButtonClickStatus;
  customTextStyles: Array<ModifiedCustomTextStyleItem>;
  audioTopPanelButtonClickStatus: AudioTopPanelButtonClickStatus;
  volumePanelCloseButtonClickStatus: VolumePanelCloseButtonClickStatus;
  mode: Mode;
  applyAll: ApplyAllJSON;
  postGuestSignupExport: boolean;
  draggedElement: { type: 'media'; media: DraggedMediaJSON } | null;
  lastDroppedElement: { type: 'media'; media: DraggedMediaJSON } | null;
  hoveredSelectionBox: {
    boxId: string;
  } | null;
  hoveredAddSceneButton: {
    sceneIndex: number;
  } | null;
  lastHoveredSelectionBox: {
    boxId: string;
  } | null;
  filesForUploads: UploadPayload;
  recentUploads: RecentUpload[];
  folderCreationRequests: null | { timestamp: number; activeTab: ActiveTab };
  uploadQueueLength: number;
  keyboardContext: KeyboardContext;
  editorLoadingStatus: EditorLoadingStatus;
  currentTab: string | null;
  selectedLogoPlaceHolder: { selected: boolean; id: string; source: string };
  repaintCompomentType: RepaintComponentType;
  timelineMediaAddType: TimelineMediaAddType;
  timelineResizeType: ResizeType;
  redirectRequest: RedirectRequest;
  projectAutoSave: {
    enabled: boolean;
    forceSave: boolean;
    status: ProjectAutoSaveStatus;
  };
  gridlines: {
    isVisible: boolean;
  };
  guestSignupModal: {
    isVisible: boolean;
    action?: string;
    authModalState?: string;
  };
  recordingCountdown: {
    isVisible: boolean;
  };
  saveCustomTextStyleValue: boolean;
  refetchCustomTextStyles: boolean;
  transitionsTray: {
    currentTransition: TransitionState;
    transitionHistory: TransitionHistory;
    confirmation: ConfirmationState;
    trayType: 'old' | 'new';
    autoPreview: boolean;
    isVisible: boolean;
  };
  videoTrimModal: {
    isVisible: boolean;
    data: TrimModalData | null;
    openLocation?: VideoTrimModalOpenLocation | null;
    closeLocation?: VideoTrimModalCloseLocation | null;
  };
  lastVideoTrimData: {
    trimSections: Array<VideoTrimSectionJSON>;
    hasTrimmed: boolean;
  } | null;
  mediaCropModal: {
    isVisible: boolean;
  };
  logoPlaceholderModal: LogoPlaceholderModalPayload;
  sceneLoadedForDuplicate: {
    isVisible: boolean;
    sceneNumber: number | string | null;
  };
  overlays: {
    confirm: ConfirmationModalOptions;
  };
  slateOverlayLoader: {
    isVisible: boolean;
    message: string | null;
  };
  storyboardSceneBGMedia: StoryboardSceneBGMediaPayload | null;
  timeline: {
    zoom: {
      level: number;
      data: ZoomLevelData;
    };
    shouldRender: boolean;
    interaction: TimelineInteraction;
    prompts: TimelinePrompts;
    snapTime: number | null;
    scrollIntent: TimelineScrollIntent;
    timelineTypeIntent: TimelineTypeIntent;
    type: TimelineType;
    variant: TimelineVariant;
    interactionVariant: TimelineInteractionVariant;
    layeredTimelineVariant: LayeredTimelineVariant;
    deleteSelectedClip: {
      isRequestPending: true;
      uxSource: string;
      uxFlow: string;
    } | null;
    height: number;
    width: number;
    position: { x: number; y: number };
    scroll: {
      scrollX: number;
      scrollY: number;
    };
  };
  undoRedo: {
    canUndo: boolean;
    canRedo: boolean;
    snapshotRequests: {
      counter: number;
      source: string | null;
    };
    undoRequests: {
      counter: number;
      source: UndoRedoSource | null;
    };
    redoRequests: {
      counter: number;
      source: UndoRedoSource | null;
    };
    undoCompleted: {
      counter: number;
    };
    redoCompleted: {
      counter: number;
    };
  };
  slate: {
    repaint: RepaintState;
    textPostProcessing: {
      status: boolean;
      componentId: string;
      isUnlockFontSize: boolean;
    };
  };
  animation: {
    animationData: any[];
    inAnimations: AnimationData[];
    outAnimations: AnimationData[];
    inPlaceAnimations: any[];
    inTransitions: AnimationData[];
    outTransitions: AnimationData[];
  };
  filmrModal: {
    isVisible: boolean;
    source: string | null;
  };
  copyPaste: CopyPaste;
  logo: {
    // todo -> refactor all logo related state to this
    deleted: boolean;
  };
  lastMoveUploadItemToLogo: {
    // used to track the image media which has to be moved to logo panel
    file: UploadedMedia | null;
    // should logo be applied to current scene after moving
    applyToCurrentScene: boolean;
  };
  leftPanel: {
    panels: {
      templatesVariant: TemplatesVariant;
      stockVariant: StockVariant;
      musicVariant: MusicVariant;
      textVariant: TextVariant;
      logoVariant: LogoVariant;
      colorVariant: ColorVariant;
      elementsVariant: ElementsVariant;
      collageVariant: CollageVariant;
      socialVariant: SocialVariant;
    };
    hasTransitionEnded: boolean;
    removeWatermarkBadge: {
      isVisible: boolean;
    };
    tab: ActiveTab | null;
    selectedTab: ActiveTab | null;
    selectedSection: ActiveSection | null;
    source: LeftPanelActionSource;
    addSceneIndex: number;
    textAnimationActiveTab?: 'Text' | 'Textbox';
    modalStatus: boolean;
    requestedUploadingClipDelete: {
      status: boolean;
      id: string;
    };
    templateState: any;
    sourceMonitorPlayState: 'play' | 'paused';
    logoTray: { files: FileList | null };
  };
  rightPanel: {
    isVisible: boolean;
  };
  modules: {
    [Key in Module]: ModuleState<Key>;
  };
  pricingModal: {
    isOpen: boolean;
    title: string;
    packPriorityFeature: string;
    showContinueSession: boolean;
    continueFlow: string;
  };
  paymentConfirmModal: {
    mode: PaymentConfirmModalMode | null;
  };
  creditCardModal: {
    isOpen: boolean;
  };
  ivCommonModal: {
    isOpen: boolean;
    modalType?: string;
    props?: any;
  };
  upgradeModal: {
    isVisible: boolean;
    source?: upgradeModalSource;
  };
  // handles storyboard navigation between advance editor & storyboard
  editorNavigationRoute: EditorNavigationRoute;
  storyboardNavigation: StoryboardNavigationData;
  microphonePermissionModal: {
    isVisible: boolean;
  };
  blankCanvasOverlay: {
    isVisible: boolean;
  };
  socialBlock: {
    isAdded: boolean;
  };
  fonts: {
    loadingStatus: FontLoadingStatus;
    data: FontModel[] | null;
  };
  confirmAudioTrim: {
    isVisible: boolean;
    consent: boolean | null;
  };
  analytics: AnalyticsState | null;
  frameLoader: frameLoader;
  sceneLimitModals: {
    warnModal: SceneLimitModal;
    maxLimitModal: SceneLimitModal;
  };
  rearrangeModal: RearrangeModal;
  sceneAddedFromLP: boolean;
  logOutRequestStatus: 'requested' | null;
  isTeamSettingsModalInReviewDisplayed: boolean;
  routerEvent: null | RouterEvent;
  reviewPageDownloadButtonStatus: null | 'clicked' | 'loading';
  isAudioLocked: boolean;
  editorMode: EditorMode | null;
  editorModeSource: EditorModeSource;
  isStoryboardVOClickHandled: boolean;
  fullscreenPreview: {
    active: boolean;
  };
  storyboardV2: {
    state: StoryboardV2State;
  };
  frame: {
    containerHeight: number;
    externalLoader: {
      visible: boolean;
      message: string;
    };
  };
  showAlertModal: {
    title: string;
    message: string;
  };
  audioLevelMeter: {
    visible: boolean;
    isEnabled: boolean;
    // Add modal and stuff showXXXModals
  };
  keyboardShortcutPanel: {
    isVisible: boolean;
  };
  lihpPostExportModal: {
    isVisible: boolean;
  };
  lihpAuthPostProcessParams: LihpPostProcessParams;
  paymentModalParams: PaymentModalParams;
  // Add modal and stuff showXXXModals
  bgRemovalJobs: Array<BgRemovalJob>;
  lastImageBgRemoveJob: {
    url: string;
    imageMediaProperties: ImageMediaPropertiesJSON;
  } | null;
  lastVideoBgRemoveJob: {
    url: string;
    videoMediaProperties: RemoveVideoBgPostProcessRequest;
  } | null;
  exportVideo: {
    directExport: boolean;
    openExportPanel: boolean;
  };
  editorExportModal: {
    isVisible: boolean;
  };
  inspector: {
    selectedProperty: InspectorSelectedProperty;
  };
  isOnline: boolean;
  // maintains stock media state when a user creates a stock project
  stockVideoTemplate: StockVideoTemplateInfo | null;
  uploadMediaModal: { visible: boolean; wasClosed: boolean };
  imageBgRemovalJobs: Array<ImageBgRemovalJob>;

  // once the upload is done, add bloburl and filedata to this state.
  // it will be used during export button click to replace blob urls
  blobURLMap: BlobURLMap;

  // {blobURL: key}
  failedUploadsBlobURLs: Record<string, string>;

  deletedBlobURLs: Array<string>;

  // if true then refreshes the logo panel
  refreshLogos: boolean;
  maintenanceBanner: {
    isOpen: boolean;
  };
  featurePromoBanner: {
    isOpen: boolean;
  };
  studioBanner: {
    height: number;
    isOpen: boolean;
  };
};

type VideoClipType = 'image' | 'video';
type AudioClipType = 'audio';
type SceneClipType = 'scene';

type LeftPanelMediaFileType = VideoClipType | AudioClipType | SceneClipType | 'folder' | 'none';
type FilePermissions = {
  rename: boolean;
  delete: boolean;
  move: boolean;
};
interface TrimSection {
  startTime: number;
  endTime: number;
  ceil?: number;
}

export type LeftPanelMediaFileMetadata = {
  id: number;
  name: string;
  url: string;
  mediaUrl: string;
  type: LeftPanelMediaFileType;
  permissions: FilePermissions;
  uploadedBy: string;
  userID: number;
  accountID: number;
  isFavourite: boolean;
  size: number;
  folderID: number;
  createdAt: string;
  updatedAt: string;

  // valid for video & image only
  thumbnailUrl: string | null;
  thumbnail_link: string | null;

  // valid for video only
  audioPresent: boolean | null;

  // valid for video & audio only
  duration: number | null;
  trimSections?: TrimSection[];
  loop?: boolean;

  // valid for video & image only
  width: number | null;
  height: number | null;

  processingPath: string;
  uploadKey?: string;
  sceneState?: 'loading' | 'ready';
  sceneProcessingPath?: string;
  sourcePanel: ActiveTab.MyMedia;
};

type BlobURLMap = Record<string, LeftPanelMediaFileMetadata>;

export const INITIAL_STATE: State = {
  backgroundRemoveLimitAlert: false,
  isInitialMasterJSONFetched: false,
  isMyMediaBackButtonHidden: false,
  redirectRequest: null,
  refetchCustomTextStyles: false,
  saveCustomTextStyleValue: false,
  logOutRequestStatus: null,
  customTextStyles: [],

  inspector: { selectedProperty: null },

  isAudioLocked: false,
  isStoryboardVOClickHandled: true,

  clipTopPanelButtonClickStatus: null,
  audioTopPanelButtonClickStatus: null,
  volumePanelCloseButtonClickStatus: null,

  draggedElement: null,
  lastDroppedElement: null,
  hoveredSelectionBox: null,
  hoveredAddSceneButton: null,
  lastHoveredSelectionBox: null,
  applyAll: {
    type: null,
    status: 'none',
  },
  mode: 'normal',
  filesForUploads: {
    files: null,
    source: null,
    folder: null,
    allowedFileTypes: null,
  },
  recentUploads: [],
  folderCreationRequests: null,
  uploadQueueLength: 0,
  keyboardContext: KeyboardContext.APP,
  editorLoadingStatus: EditorLoadingStatus.NOT_LOADED,
  currentTab: null,
  isTeamSettingsModalInReviewDisplayed: false,
  postGuestSignupExport: false,
  selectedLogoPlaceHolder: { selected: false, id: '', source: '' },
  repaintCompomentType: RepaintComponentType.FULL,
  timelineMediaAddType: TimelineMediaAddType.DEFAULT,
  timelineResizeType: ResizeType.SCENE,
  projectAutoSave: {
    enabled: false,
    forceSave: false,
    status: ProjectAutoSaveStatus.SAVED,
  },
  transitionsTray: {
    isVisible: false,
    transitionHistory: {},
    confirmation: 'invisible',
    trayType: 'old',
    autoPreview: false,
    currentTransition: {
      selectedTransitionIndex: null,
      transitions: [],
      transitionData: [],
      transitionComposites: {
        composite: TRANSITION_COMPOSITE_JSON,
        imageComponent: TRANSITION_IMAGE_COMPONENT_JSON,
        videoComponent: TRANSITION_VIDEO_COMPONENT_JSON,
        defaultConfig: TRANSITION_DEFAULT_CONFIG,
      },
      transitionNames: {
        '-1': 'None',
        '0': 'None',
        '997': 'Default',
        '998': 'Default',
        '999': 'Default',
      },
      addTransition: {
        status: false,
        transitionID: null,
        transitionIndex: null,
        addToAll: false,
      },
      defaultTransition: null,
      removeTransition: {
        status: false,
        transitionIndex: null,
      },
    },
  },
  gridlines: { isVisible: false },
  guestSignupModal: {
    isVisible: false,
    action: '',
    authModalState: '',
  },
  recordingCountdown: { isVisible: false },
  videoTrimModal: { isVisible: false, data: null, openLocation: null, closeLocation: null },
  lastVideoTrimData: null,
  mediaCropModal: { isVisible: false },
  logoPlaceholderModal: { isVisible: false, action: null, componentId: null },
  sceneLoadedForDuplicate: { isVisible: false, sceneNumber: null },
  overlays: {
    confirm: {
      title: null,
      body: null,
      primaryActionText: 'Confirm',
      secondaryActionText: 'Cancel',
      isSecondaryActionVisible: true,
    },
  },
  slateOverlayLoader: { isVisible: false, message: '' },
  storyboardSceneBGMedia: null,
  stockVideoTemplate: null,
  timeline: {
    zoom: {
      level: 0,
      data: {
        fitsDuration: 0,
        timeFrequency: 0,
        pixelsPerSecond: 0,
        bezierZoomIn: undefined,
        bezierZoomOut: undefined,
        percentage: undefined,
      },
    },
    shouldRender: false,
    interaction: {
      mode: 'normal',
      clipId: null,
    },
    prompts: {
      extendScene: false,
    },
    snapTime: null,
    scrollIntent: {
      type: 'none',
      time: null,
      behavior: ScrollBehavior.SMOOTH,
    },
    timelineTypeIntent: {
      type: TimelineType.REEL,
    },
    type: TimelineType.REEL,
    variant: TimelineVariant.IV_TIMELINE,
    interactionVariant: TimelineInteractionVariant.B,
    layeredTimelineVariant: LayeredTimelineVariant.A,
    deleteSelectedClip: null,
    height: 260,
    width: 0,
    position: { x: 0, y: 0 },
    scroll: {
      scrollX: 0,
      scrollY: 0,
    },
  },
  undoRedo: {
    canUndo: false,
    canRedo: false,
    snapshotRequests: {
      counter: 0,
      source: null,
    },
    undoRequests: {
      counter: 0,
      source: null,
    },
    redoRequests: {
      counter: 0,
      source: null,
    },
    undoCompleted: {
      counter: 0,
    },
    redoCompleted: {
      counter: 0,
    },
  },
  slate: {
    repaint: {
      needsRepaint: false,
      repaintType: RepaintType.BLOCK,
      showLoader: false,
      componentRepaintId: null,
    },
    textPostProcessing: {
      status: false,
      componentId: NULL_COMPONENT_ID,
      isUnlockFontSize: false,
    },
  },
  animation: {
    animationData: [],
    inAnimations: [],
    outAnimations: [],
    inPlaceAnimations: inPlaceAnimations,
    inTransitions: [],
    outTransitions: [],
  },
  logo: {
    deleted: false,
  },
  lastMoveUploadItemToLogo: {
    file: null,
    applyToCurrentScene: false,
  },
  leftPanel: {
    panels: {
      templatesVariant: TemplatesVariant.LEGACY,
      stockVariant: StockVariant.LEGACY,
      musicVariant: MusicVariant.LEGACY,
      textVariant: TextVariant.LEGACY,
      logoVariant: LogoVariant.LEGACY,
      colorVariant: ColorVariant.LEGACY,
      elementsVariant: ElementsVariant.LEGACY,
      collageVariant: CollageVariant.LEGACY,
      socialVariant: SocialVariant.LEGACY,
    },
    hasTransitionEnded: true,
    removeWatermarkBadge: {
      isVisible: true,
    },
    tab: null,
    selectedTab: null,
    selectedSection: null,
    templateState: null,
    source: LeftPanelActionSource.LeftPanel,
    addSceneIndex: -1,
    textAnimationActiveTab: 'Text',
    modalStatus: false,
    requestedUploadingClipDelete: { status: false, id: '' },
    sourceMonitorPlayState: 'play',
    logoTray: { files: null },
  },
  rightPanel: {
    isVisible: true,
  },
  modules: {
    header: { version: null, loading: null },
    slate: { version: null, loading: null },
    timeline: { version: null, loading: null },
    topPanel: { version: null, loading: null },
    leftPanel: { version: null, loading: null },
    rightPanel: { version: null, loading: null },
    slateOverlays: { version: null, loading: null },
    editor: { version: null, loading: null },
    storyboard: { version: null, loading: null },
    storyboardScaffold: { version: null, loading: null },
    storyboardEditor: { version: null, loading: null },
    ivstoryboard: { version: null, loading: null },
    ivpanels: { version: null, loading: null },
  },
  filmrModal: {
    isVisible: false,
    source: null,
  },
  pricingModal: {
    isOpen: false,
    title: '',
    packPriorityFeature: '',
    showContinueSession: false,
    continueFlow: '',
  },
  paymentConfirmModal: {
    mode: null,
  },
  creditCardModal: {
    isOpen: false,
  },
  upgradeModal: {
    isVisible: false,
  },
  copyPaste: {
    state: {
      paste: false,
    },
    action: {
      copy: false,
      paste: false,
    },
  },
  editorNavigationRoute: EditorNavigationRoute.NONE,
  storyboardNavigation: {
    route: StoryboardNavigationRoute.NONE,
    optionalQueryParams: {},
  },
  blankCanvasOverlay: {
    isVisible: false,
  },
  microphonePermissionModal: {
    isVisible: false,
  },
  socialBlock: {
    isAdded: false,
  },
  fonts: {
    loadingStatus: FontLoadingStatus.NOT_LOADED,
    data: null,
  },
  confirmAudioTrim: {
    isVisible: false,
    consent: null,
  },
  analytics: null,
  frameLoader: 'BLUR',
  rearrangeModal: {
    visible: false,
    alerted: false,
  },
  sceneLimitModals: {
    warnModal: {
      visible: false,
      limit: 30,
      alerted: false,
    },
    maxLimitModal: {
      visible: false,
      limit: 50,
    },
  },
  sceneAddedFromLP: false,
  routerEvent: null,
  reviewPageDownloadButtonStatus: null,
  editorMode: null,
  editorModeSource: 'auto',
  fullscreenPreview: {
    active: false,
  },
  storyboardV2: {
    state: { type: 'blank' },
  },
  frame: {
    containerHeight: 0,
    externalLoader: {
      visible: false,
      message: '',
    },
  },
  showAlertModal: {
    title: '',
    message: '',
  },
  audioLevelMeter: {
    visible: false,
    isEnabled: false,
  },
  keyboardShortcutPanel: {
    isVisible: false,
  },
  lihpPostExportModal: {
    isVisible: false,
  },
  lihpAuthPostProcessParams: {
    type: null,
    redirectUrl: null,
  },
  paymentModalParams: {
    page: null,
    heading: null,
  },
  ivCommonModal: {
    isOpen: false,
  },
  bgRemovalJobs: JSON.parse(localStorage.getItem(aiBgLocalStorageKey) ?? '[]'),
  lastImageBgRemoveJob: null,
  lastVideoBgRemoveJob: null,
  exportVideo: {
    directExport: false,
    openExportPanel: false,
  },
  editorExportModal: {
    isVisible: false,
  },
  isOnline: true,
  uploadMediaModal: { visible: false, wasClosed: false },
  imageBgRemovalJobs: [],
  blobURLMap: {},
  failedUploadsBlobURLs: {},
  deletedBlobURLs: [],
  refreshLogos: false,
  maintenanceBanner: {
    isOpen: false,
  },
  featurePromoBanner: {
    isOpen: false,
  },
  studioBanner: {
    height: 42,
    isOpen: true,
  },
};
export const slice = createSlice({
  name: 'uiState',
  initialState: INITIAL_STATE,
  reducers: {
    fetchedInitialMasterJSON: (state) => {
      state.isInitialMasterJSONFetched = true;
    },
    updateBackgroundRemoveLimitAlert: (state, action: PayloadAction<boolean>) => {
      state.backgroundRemoveLimitAlert = action.payload;
    },
    dragStarted: (state, action: PayloadAction<{ element: DraggedElementJSON }>) => {
      state.draggedElement = action.payload.element;
    },
    dragCancelled: (state) => {
      state.draggedElement = null;
    },
    dragCompleted: (state, action: PayloadAction<{ element: DraggedElementJSON }>) => {
      state.lastDroppedElement = action.payload.element;
    },
    selectionBoxHovered: (state, action: PayloadAction<{ boxId: string } | null>) => {
      state.hoveredSelectionBox = action.payload;
    },
    addSceneButtonHovered: (
      state,
      action: PayloadAction<{
        sceneIndex: number;
      } | null>,
    ) => {
      state.hoveredAddSceneButton = action.payload;
    },
    dropCompleted: (state) => {
      state.lastHoveredSelectionBox = state.hoveredSelectionBox;
    },
    filesForUploadsUpdated: (state, action: PayloadAction<UploadPayload>) => {
      state.filesForUploads = action.payload;
    },
    resetFilesForUpload: (state) => {
      state.filesForUploads.allowedFileTypes = null;
      state.filesForUploads.files = null;
      state.filesForUploads.folder = null;
      state.filesForUploads.source = null;
    },
    recentUploadAdded: (state, action: PayloadAction<{ upload: RecentUpload }>) => {
      state.recentUploads.push(action.payload.upload);
    },
    recentUploadUpdated: (
      state,
      action: PayloadAction<{ blobUrl: string; status: RecentUploadStatus }>,
    ) => {
      const upload = state.recentUploads.find(
        (upload) => upload.blobUrl === action.payload.blobUrl,
      );
      if (upload) upload.status = action.payload.status;
      else throw new Error('Cannot update upload status for an invalid blob url');
    },
    keyboardContextUpdated: (state, action: PayloadAction<{ context: KeyboardContext }>) => {
      state.keyboardContext = action.payload.context;
    },

    gridlinesToggled: (state) => {
      state.gridlines.isVisible = !state.gridlines.isVisible;
    },
    transitionsTrayToggled: (state) => {
      state.transitionsTray.isVisible = !state.transitionsTray.isVisible;
    },
    recoredingCountdownToggled: (state) => {
      state.recordingCountdown.isVisible = !state.recordingCountdown.isVisible;
    },
    videoTrimModalVisible: (state, action: PayloadAction<boolean>) => {
      state.videoTrimModal.isVisible = action.payload;
    },
    videoTrimModalToggled: (state, action: PayloadAction<TrimModalData | null>) => {
      state.videoTrimModal.isVisible = !state.videoTrimModal.isVisible;
      state.videoTrimModal.data = action.payload;
    },
    videoTrimModalOpened: (
      state,
      action: PayloadAction<{ trimModalData: TrimModalData; source?: VideoTrimModalOpenLocation }>,
    ) => {
      state.videoTrimModal.isVisible = true;
      state.videoTrimModal.data = action.payload.trimModalData;
      state.videoTrimModal.openLocation = action.payload.source;
    },
    videoTrimModalUpdated: (state, action: PayloadAction<TrimModalData>) => {
      state.videoTrimModal.data = {
        ...state.videoTrimModal.data,
        ...action.payload,
      };
    },
    videoTrimModalClosed: (
      state,
      action: PayloadAction<VideoTrimModalCloseLocation | undefined>,
    ) => {
      state.videoTrimModal.isVisible = false;
      state.videoTrimModal.closeLocation = action.payload;
    },
    lastVideoTrimData: (
      state,
      action: PayloadAction<{
        hasTrimmed: boolean;
        trimSections: Array<VideoTrimSectionJSON>;
      } | null>,
    ) => {
      state.lastVideoTrimData = action.payload;
    },
    mediaCropModalToggled: (state) => {
      state.mediaCropModal.isVisible = !state.mediaCropModal.isVisible;
    },
    transitionTrayTypeUpdated: (state, action: PayloadAction<'old' | 'new'>) => {
      state.transitionsTray.trayType = action.payload;
    },
    transitionConfirmationSet: (state, action: PayloadAction<ConfirmationState>) => {
      state.transitionsTray.confirmation = action.payload;
    },
    transitionsAutoPreviewSet: (state, action: PayloadAction<boolean>) => {
      state.transitionsTray.autoPreview = action.payload;
    },
    transitionHistoryPushed: (
      state,
      action: PayloadAction<{ id: string; entry: TransitionHistoryEntry }>,
    ) => {
      state.transitionsTray.transitionHistory[action.payload.id] = action.payload.entry;
    },
    transitionIndexUpdated: (state, action: PayloadAction<{ index: number | null }>) => {
      if (state.transitionsTray.trayType === 'old')
        state.transitionsTray.isVisible =
          action.payload.index === state.transitionsTray.currentTransition.selectedTransitionIndex
            ? !state.transitionsTray.isVisible
            : true;
      else state.transitionsTray.isVisible = true;
      state.transitionsTray.currentTransition.selectedTransitionIndex = action.payload.index;
    },
    transitionDataUpdated: (state, action: PayloadAction<any>) => {
      state.transitionsTray.currentTransition.transitionData = action.payload;
    },
    transitionNamesUpdated: (state, action: PayloadAction<any>) => {
      state.transitionsTray.currentTransition.transitionNames = action.payload;
    },
    transitionsUpdated: (state, action: PayloadAction<any>) => {
      state.transitionsTray.currentTransition.transitions = action.payload;
    },
    defaultTransitionUpdated: (state, action: PayloadAction<any>) => {
      state.transitionsTray.currentTransition.defaultTransition = action.payload;
    },
    addTransitionUpdated: (
      state,
      action: PayloadAction<{
        transitionId: number;
        transitionIndex: number | null;
        addToAll: boolean;
        source?: 'addTemplateTransition';
        transitionColors?: TransitionColors;
        time?: number;
        properties?: FilterProperties;
      }>,
    ) => {
      state.transitionsTray.currentTransition.addTransition.status = true;
      state.transitionsTray.currentTransition.addTransition.transitionID =
        action.payload.transitionId;
      state.transitionsTray.currentTransition.addTransition.transitionIndex =
        action.payload.transitionIndex;
      state.transitionsTray.currentTransition.addTransition.addToAll = action.payload.addToAll;
      state.transitionsTray.currentTransition.addTransition.source = action.payload.source;
      state.transitionsTray.currentTransition.addTransition.transitionColors =
        action.payload.transitionColors;
      state.transitionsTray.currentTransition.addTransition.time = action.payload.time;
      state.transitionsTray.currentTransition.addTransition.properties = action.payload.properties;
    },
    addTransitionWasReset: (state) => {
      state.transitionsTray.currentTransition.addTransition.status = false;
      state.transitionsTray.currentTransition.addTransition.transitionID = null;
      state.transitionsTray.currentTransition.addTransition.transitionIndex = null;
      state.transitionsTray.currentTransition.addTransition.addToAll = false;
    },
    removeTransitionUpdated: (state, action: PayloadAction<number>) => {
      state.transitionsTray.currentTransition.removeTransition.transitionIndex = action.payload;
      state.transitionsTray.currentTransition.removeTransition.status = true;
    },
    removeTransitionWasReset: (state) => {
      state.transitionsTray.currentTransition.removeTransition.status = false;
      state.transitionsTray.currentTransition.removeTransition.transitionIndex = null;
    },
    selectedTransitionIndexWasReset: (state) => {
      state.transitionsTray.currentTransition.selectedTransitionIndex = null;
      state.transitionsTray.isVisible = false;
    },
    transitionCompositesUpdated: (state, action: PayloadAction<TransitionCompositesState>) => {
      state.transitionsTray.currentTransition.transitionComposites = action.payload;
    },
    updateProjectAutoSave: (state, action: PayloadAction<boolean>) => {
      state.projectAutoSave.enabled = action.payload;
    },
    updateProjectForceSave: (state, action: PayloadAction<boolean>) => {
      state.projectAutoSave.forceSave = action.payload;
    },
    updateProjectAutoSaveStatus: (state, action: PayloadAction<ProjectAutoSaveStatus>) => {
      state.projectAutoSave.status = action.payload;
    },
    updateCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    editorLoadingStatusUpdated: (state, action: PayloadAction<EditorLoadingStatus>) => {
      state.editorLoadingStatus = action.payload;
    },
    repaintComponentTypeUpdated: (state, action: PayloadAction<RepaintComponentType>) => {
      state.repaintCompomentType = action.payload;
    },
    sceneLoadedForDuplicate: (state, action: PayloadAction<{ sceneNumber: number | string }>) => {
      state.sceneLoadedForDuplicate.isVisible = true;
      state.sceneLoadedForDuplicate.sceneNumber = action.payload.sceneNumber;
    },
    showSlateOverlayLoader: (state, action: PayloadAction<string | null>) => {
      state.slateOverlayLoader.isVisible = true;
      state.slateOverlayLoader.message = action.payload;
    },
    hideSlateOverlayLoader: (state) => {
      state.slateOverlayLoader.isVisible = false;
      state.slateOverlayLoader.message = '';
    },
    storyboardSceneBGMediaUpdated: (
      state,
      action: PayloadAction<StoryboardSceneBGMediaPayload>,
    ) => {
      state.storyboardSceneBGMedia = action.payload;
    },
    snapshotRequested: (state, action: PayloadAction<{ source?: string }>) => {
      state.undoRedo.snapshotRequests.counter++;
      state.undoRedo.snapshotRequests.source = action.payload.source ?? null;
    },

    undoRequested: (state, action: PayloadAction<{ source: UndoRedoSource }>) => {
      state.undoRedo.undoRequests.counter++;
      state.undoRedo.undoRequests.source = action.payload.source;
      state.projectAutoSave.status = ProjectAutoSaveStatus.PENDING;
    },
    redoRequested: (state, action: PayloadAction<{ source: UndoRedoSource }>) => {
      state.undoRedo.redoRequests.counter++;
      state.undoRedo.redoRequests.source = action.payload.source;
      state.projectAutoSave.status = ProjectAutoSaveStatus.PENDING;
    },
    canUndoRedoUpdated: (
      state,
      action: PayloadAction<{ canUndo: boolean; canRedo: boolean; debug?: unknown }>,
    ) => {
      state.undoRedo.canUndo = action.payload.canUndo;
      state.undoRedo.canRedo = action.payload.canRedo;
    },
    undoCompleted: (state) => {
      state.undoRedo.undoCompleted.counter++;
    },
    redoCompleted: (state) => {
      state.undoRedo.redoCompleted.counter++;
    },
    showTeamSettingsModalInReview: (state) => {
      state.isTeamSettingsModalInReviewDisplayed = true;
    },
    hideTeamSettingsModalInReview: (state) => {
      state.isTeamSettingsModalInReviewDisplayed = false;
    },
    repaintRequested: (
      state,
      action: PayloadAction<{
        repaintType: RepaintType;
        showLoader: boolean;
      }>,
    ) => {
      state.slate.repaint.needsRepaint = true;
      state.slate.repaint.repaintType = action.payload.repaintType;
      state.slate.repaint.showLoader = action.payload.showLoader;
    },
    repainted: (
      state,
      action: PayloadAction<{
        repaintType: RepaintType;
        showLoader: boolean;
      }>,
    ) => {
      state.slate.repaint.needsRepaint = false;
      state.slate.repaint.repaintType = action.payload.repaintType;
      state.slate.repaint.showLoader = action.payload.showLoader;
    },
    componentRepaintRequested: (
      state,
      action: PayloadAction<{
        repaintType: RepaintType;
        id: string | null;
      }>,
    ) => {
      state.slate.repaint.needsRepaint = true;
      state.slate.repaint.repaintType = action.payload.repaintType;
      state.slate.repaint.componentRepaintId = action.payload.id;
    },
    componentRepainted: (
      state,
      action: PayloadAction<{
        repaintType: RepaintType;
        id: string | null;
      }>,
    ) => {
      state.slate.repaint.needsRepaint = false;
      state.slate.repaint.repaintType = action.payload.repaintType;
      state.slate.repaint.componentRepaintId = action.payload.id;
    },
    updateAnimationData: (state, action: PayloadAction<any[]>) => {
      state.animation.animationData = action.payload;
    },
    updateInAnimations: (state, action: PayloadAction<AnimationData[]>) => {
      state.animation.inAnimations = action.payload;
    },
    updateOutAnimations: (state, action: PayloadAction<AnimationData[]>) => {
      state.animation.outAnimations = action.payload;
    },
    updateInPlaceAnimations: (state, action: PayloadAction<any[]>) => {
      state.animation.inPlaceAnimations = action.payload;
    },
    updateInTransitions: (state, action: PayloadAction<AnimationData[]>) => {
      state.animation.inTransitions = action.payload;
    },
    updateOutTransitions: (state, action: PayloadAction<AnimationData[]>) => {
      state.animation.outTransitions = action.payload;
    },
    markLeftPanelTransitionEnded: (
      state,
      action: PayloadAction<{ hasTransitionEnded: boolean }>,
    ) => {
      state.leftPanel.hasTransitionEnded = action.payload.hasTransitionEnded;
    },
    moduleLoaded: (
      state,
      actions: PayloadAction<{
        module: keyof State['modules'];
        version:
          | 'frame'
          | 'classic'
          | 'iv-timeline'
          | 'iv-storyboard'
          | 'iv-panels'
          | 'timeline'
          | 'iv-left-panels';
      }>,
    ) => {
      const { module, version } = actions.payload;
      if (version === 'frame') {
        if (module !== 'slate')
          throw new Error(`Version ${version} of Module ${module} is not supported`);
        state.modules[module] = { version: 'frame', loading: 'loaded' };
        return;
      }

      if (version === 'iv-timeline') {
        if (module !== 'timeline')
          throw new Error(`Version ${version} of Module ${module} is not supported`);
        state.modules[module] = { version: 'iv-timeline', loading: 'loaded' };
        return;
      }

      state.modules[module] = { version: 'classic', loading: 'loaded' };
    },
    moduleLoadFailed: (state, actions: PayloadAction<{ module: keyof State['modules'] }>) => {
      const { module } = actions.payload;
      state.modules[module] = { version: null, loading: 'failed' };
    },
    moduleUnloaded: (
      state,
      actions: PayloadAction<{
        module: keyof State['modules'];
      }>,
    ) => {
      const { module } = actions.payload;
      state.modules[module] = {
        version: null,
        loading: null,
      };
    },
    showFilmrModal: (state, action: PayloadAction<{ source: string }>) => {
      state.filmrModal.isVisible = true;
      state.filmrModal.source = action.payload.source;
    },
    hideFilmrModal: (state) => {
      state.filmrModal.isVisible = false;
      state.filmrModal.source = null;
    },
    pasteStateUpdate: (state, action: PayloadAction<boolean>) => {
      state.copyPaste.state.paste = action.payload;
    },
    copyPasteActionUpdate: (state, action: PayloadAction<CopyPasteAction>) => {
      state.copyPaste.action = action.payload;
    },
    logoDeleted: (state, action: PayloadAction<{ deleted: boolean }>) => {
      state.logo.deleted = action.payload.deleted;
      console.log('state updated');
    },
    logoPlaceHolderSelected: (state, action: PayloadAction<{ id: string; source: string }>) => {
      state.selectedLogoPlaceHolder.selected = true;
      state.selectedLogoPlaceHolder.id = action.payload.id;
      state.selectedLogoPlaceHolder.source = action.payload.source;
    },
    logoPlaceHolderUnselected: (state) => {
      state.selectedLogoPlaceHolder.selected = false;
      state.selectedLogoPlaceHolder.source = '';
      state.selectedLogoPlaceHolder.source = '';
    },
    updateLogoPlaceholderModal: (state, action: PayloadAction<LogoPlaceholderModalPayload>) => {
      state.logoPlaceholderModal.isVisible = action.payload.isVisible;
      state.logoPlaceholderModal.action = action.payload.action;
      state.logoPlaceholderModal.componentId = action.payload.componentId;
    },
    reset: (state) => {
      Object.keys(INITIAL_STATE).forEach((key) => {
        (state as any)[key] = INITIAL_STATE[key as keyof State];
      });
      state.bgRemovalJobs = JSON.parse(localStorage.getItem(aiBgLocalStorageKey) ?? '[]');
    },
    showGuestSignupModal(state, action: PayloadAction<{ action: string; authModalState: string }>) {
      state.guestSignupModal.isVisible = true;
      state.guestSignupModal.action = action.payload.action;
      state.guestSignupModal.authModalState = action.payload.authModalState;
    },
    hideGuestSignupModal(state) {
      state.guestSignupModal.isVisible = false;
      state.guestSignupModal.action = '';
      state.guestSignupModal.authModalState = '';
    },
    setLogoTrayFilesForUpload: (state, action: PayloadAction<FileList | null>) => {
      state.leftPanel.logoTray.files = action.payload;
    },
    updatePaymentConfirmModalMode: (
      state,
      action: PayloadAction<PaymentConfirmModalMode | null>,
    ) => {
      state.paymentConfirmModal.mode = action.payload;
    },
    updatePricingModalTitle: (state, action: PayloadAction<{ title: string }>) => {
      state.pricingModal.title = action.payload.title;
    },
    updatePricingModalPackPriorityFeature: (state, action: PayloadAction<{ feature: string }>) => {
      state.pricingModal.packPriorityFeature = action.payload.feature;
    },
    updatePricingModalShowContinueSession: (state, action: PayloadAction<boolean>) => {
      state.pricingModal.showContinueSession = action.payload;
    },
    updatePricingModalContinueFlow: (state, action: PayloadAction<string>) => {
      state.pricingModal.continueFlow = action.payload;
    },
    updatePricingModalOpenState: (state, action: PayloadAction<boolean>) => {
      state.pricingModal.isOpen = action.payload;
    },
    updateCreditCardModalOpenState: (state, action: PayloadAction<boolean>) => {
      state.creditCardModal.isOpen = action.payload;
    },
    expensiveModeTriggered: (state) => {
      state.mode = 'expensive';
    },
    normalModeRestored: (state) => {
      state.mode = 'normal';
    },
    storyboardNavigationRouteUpdate: (state, action: PayloadAction<StoryboardNavigationData>) => {
      state.storyboardNavigation = action.payload;
    },
    editorNavigationRouteUpdate: (state, action: PayloadAction<EditorNavigationRoute>) => {
      state.editorNavigationRoute = action.payload;
    },
    setBlankCanvasOverlay: (state, action: PayloadAction<boolean>) => {
      state.blankCanvasOverlay.isVisible = action.payload;
    },
    toggleMicPermissionModal: (state, action: PayloadAction<boolean>) => {
      state.microphonePermissionModal.isVisible = action.payload;
    },
    postGuestSignupExport: (state) => {
      state.postGuestSignupExport = true;
    },
    applyAllUpdated: (state, action: PayloadAction<ApplyAllJSON>) => {
      state.applyAll = action.payload;
    },
    showRemoveWatermarkBadge: (state) => {
      state.leftPanel.removeWatermarkBadge.isVisible = true;
    },
    hideRemoveWatermarkBadge: (state) => {
      state.leftPanel.removeWatermarkBadge.isVisible = false;
    },
    showUpgradeModal: (state, action: PayloadAction<upgradeModalSource>) => {
      state.upgradeModal = { isVisible: true, source: action.payload };
    },
    hideUpgradeModal: (state) => {
      state.upgradeModal = { isVisible: false };
    },
    updateTextPostProcessing: (
      state,
      action: PayloadAction<{ status: boolean; componentId: string; isUnlockFontSize: boolean }>,
    ) => {
      state.slate.textPostProcessing = action.payload;
    },
    updateUploadQueueLength: (state, action: PayloadAction<number>) => {
      state.uploadQueueLength = action.payload;
    },
    toggleSocialBlockAdded: (state) => {
      state.socialBlock.isAdded = !state.socialBlock.isAdded;
    },
    updatedIsRightPanelVisible: (state, action: PayloadAction<boolean>) => {
      state.rightPanel.isVisible = action.payload;
    },
    updateFonts: (
      state,
      action: PayloadAction<{ loadingStatus: FontLoadingStatus; data: FontModel[] | null }>,
    ) => {
      state.fonts.loadingStatus = action.payload.loadingStatus;
      state.fonts.data = action.payload.data;
    },
    updateFrameLoader: (state, action: PayloadAction<frameLoader>) => {
      state.frameLoader = action.payload;
    },
    setLeftPanelActiveTab: (
      state,
      action: PayloadAction<{ tab: ActiveTab | null; fromPanel: LeftPanelActionSource }>,
    ) => {
      state.leftPanel.selectedTab = action.payload.tab;
      state.leftPanel.source = action.payload.fromPanel;
    },
    setAddSceneIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.leftPanel.addSceneIndex = action.payload.index;
    },
    setLeftPanelSelectedTab: (
      state,
      action: PayloadAction<{ selectedTab: ActiveTab | null; source?: LeftPanelActionSource }>,
    ) => {
      state.leftPanel.selectedTab = action.payload.selectedTab;
      state.leftPanel.source = action.payload.source || LeftPanelActionSource.LeftPanel;
    },
    setLeftPanelSelectedSection: (
      state,
      action: PayloadAction<{ selectedSection: ActiveSection | null }>,
    ) => {
      state.leftPanel.selectedSection = action.payload.selectedSection;
    },
    setLeftPanelTemplateState: (state, action: PayloadAction<{ state: any }>) => {
      state.leftPanel.templateState = action.payload.state;
    },
    setTextAnimationActiveTab: (state, action: PayloadAction<'Text' | 'Textbox'>) => {
      state.leftPanel.textAnimationActiveTab = action.payload;
    },
    analyticsUpdated: (state, action: PayloadAction<AnalyticsState | null>) => {
      state.analytics = action.payload;
    },
    updateSceneWarnLimitModal: (state, action: PayloadAction<{ visible: boolean }>) => {
      if (action.payload.visible) state.sceneLimitModals.warnModal.alerted = true;
      state.sceneLimitModals.warnModal.visible = action.payload.visible;
    },
    updateSceneMaxLimitModal: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.sceneLimitModals.maxLimitModal.visible = action.payload.visible;
    },
    updateRearrangeModal: (
      state,
      action: PayloadAction<{ visible: boolean; alerted?: boolean; rearrange?: boolean }>,
    ) => {
      state.rearrangeModal.visible = action.payload.visible;
      state.rearrangeModal.alerted = action.payload.alerted;
      state.rearrangeModal.rearrange = action.payload.rearrange;
    },
    toggleAudioTrimConfirmModal: (state, action: PayloadAction<boolean>) => {
      state.confirmAudioTrim.isVisible = action.payload;
    },
    updateAudioTrimConfirmConsent: (state, action: PayloadAction<boolean | null>) => {
      state.confirmAudioTrim.consent = action.payload;
    },
    updateSceneAddedFromLP: (state, action: PayloadAction<boolean>) => {
      state.sceneAddedFromLP = action.payload;
    },
    // Timeline
    timelineMediaAddTypeUpdated: (state, action: PayloadAction<TimelineMediaAddType>) => {
      state.timelineMediaAddType = action.payload;
    },
    timelineResizeTypeUpdated: (state, action: PayloadAction<{ resizeType: ResizeType }>) => {
      state.timelineResizeType = action.payload.resizeType;
    },
    timelineVariantUpdated: (state, action: PayloadAction<{ variant: TimelineVariant }>) => {
      state.timeline.variant = action.payload.variant;
    },
    updateTimelineType: (state, action: PayloadAction<TimelineType>) => {
      state.timeline.type = action.payload;
    },
    timelineInteractionUpdated: (state, action: PayloadAction<TimelineInteraction>) => {
      state.timeline.interaction = action.payload;
    },
    timelineInteractionVariantUpdated: (
      state,
      action: PayloadAction<TimelineInteractionVariant>,
    ) => {
      state.timeline.interactionVariant = action.payload;
    },
    layeredTimelineVariantUpdate: (state, action: PayloadAction<LayeredTimelineVariant>) => {
      state.timeline.layeredTimelineVariant = action.payload;
    },
    updateTimelineZoomLevel: (state, action: PayloadAction<number>) => {
      state.timeline.zoom.level = action.payload;
    },
    updateTimelineZoomLevelData: (state, action: PayloadAction<ZoomLevelData>) => {
      state.timeline.zoom.data = action.payload;
    },
    updateTimelineSceneExtendPrompt: (state, action: PayloadAction<{ isVisible: boolean }>) => {
      state.timeline.prompts.extendScene = action.payload.isVisible;
    },
    updateTimelineSnapTime: (state, action: PayloadAction<{ time: number | null }>) => {
      state.timeline.snapTime = action.payload.time;
    },
    updateTimelineScrollIntent: (state, action: PayloadAction<TimelineScrollIntent>) => {
      state.timeline.scrollIntent = action.payload;
    },
    updateTimelineTypeIntent: (state, action: PayloadAction<TimelineTypeIntent>) => {
      state.timeline.timelineTypeIntent = action.payload;
    },
    requestedLogOut: (state) => {
      state.logOutRequestStatus = 'requested';
    },
    handledLogOutRequest: (state) => {
      state.logOutRequestStatus = null;
    },
    updateRouterEvent: (state, action: PayloadAction<RouterEvent>) => {
      state.routerEvent = action.payload;
    },
    clickedDownloadButtonInReviewPage: (state) => {
      state.reviewPageDownloadButtonStatus = 'clicked';
    },
    loadingDownloadButtonInfoInReviewPage: (state) => {
      state.reviewPageDownloadButtonStatus = 'loading';
    },
    handledDownloadButtonClickInReviewPage: (state) => {
      state.reviewPageDownloadButtonStatus = null;
    },
    clipTopPanelButtonClickStatusUpdated: (
      state,
      action: PayloadAction<ClipTopPanelButtonClickStatus>,
    ) => {
      state.clipTopPanelButtonClickStatus = action.payload;
    },
    audioTopPanelButtonClickStatusUpdated: (
      state,
      action: PayloadAction<AudioTopPanelButtonClickStatus>,
    ) => {
      state.audioTopPanelButtonClickStatus = action.payload;
    },
    volumePanelCloseButtonClickStatusUpdated: (
      state,
      action: PayloadAction<VolumePanelCloseButtonClickStatus>,
    ) => {
      state.volumePanelCloseButtonClickStatus = action.payload;
    },
    isAudioLockedUpdated: (state, action: PayloadAction<boolean>) => {
      state.isAudioLocked = action.payload;
    },
    updateEditorMode: (state, action: PayloadAction<EditorMode>) => {
      state.editorMode = action.payload;
    },
    updateEditorModeSource: (state, action: PayloadAction<EditorModeSource>) => {
      state.editorModeSource = action.payload;
    },
    updateIsStoryboardVOClickHandled: (state, action: PayloadAction<boolean>) => {
      state.isStoryboardVOClickHandled = action.payload;
    },
    showFullscreenPreview: (state) => {
      state.fullscreenPreview.active = true;
    },
    hideFullscreenPreview: (state) => {
      state.fullscreenPreview.active = false;
    },
    updateTimelineSelectedClipDelete: (
      state,
      action: PayloadAction<{
        isRequestPending: true;
        uxSource: string;
        uxFlow: string;
      } | null>,
    ) => {
      state.timeline.deleteSelectedClip = action.payload;
    },
    updateTimelineHeight: (state, action: PayloadAction<number>) => {
      state.timeline.height = action.payload;
    },
    updateTimelineWidth: (state, action: PayloadAction<number>) => {
      state.timeline.width = action.payload;
    },
    updateTimelinePosition: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.timeline.position = action.payload;
    },
    updateTimelineScroll: (state, action: PayloadAction<{ scrollX: number; scrollY: number }>) => {
      state.timeline.scroll = action.payload;
    },
    templatesVariantUpdated: (state, action: PayloadAction<{ variant: TemplatesVariant }>) => {
      state.leftPanel.panels.templatesVariant = action.payload.variant;
    },
    stockVariantUpdated: (state, action: PayloadAction<{ variant: StockVariant }>) => {
      state.leftPanel.panels.stockVariant = action.payload.variant;
    },
    musicVariantUpdated: (state, action: PayloadAction<{ variant: MusicVariant }>) => {
      state.leftPanel.panels.musicVariant = action.payload.variant;
    },
    textVariantUpdated: (state, action: PayloadAction<{ variant: TextVariant }>) => {
      state.leftPanel.panels.textVariant = action.payload.variant;
    },
    logoVariantUpdated: (state, action: PayloadAction<{ variant: LogoVariant }>) => {
      state.leftPanel.panels.logoVariant = action.payload.variant;
    },
    colorVariantUpdated: (state, action: PayloadAction<{ variant: ColorVariant }>) => {
      state.leftPanel.panels.colorVariant = action.payload.variant;
    },
    elementsVariantUpdated: (state, action: PayloadAction<{ variant: ElementsVariant }>) => {
      state.leftPanel.panels.elementsVariant = action.payload.variant;
    },
    collageVariantUpdated: (state, action: PayloadAction<{ variant: CollageVariant }>) => {
      state.leftPanel.panels.collageVariant = action.payload.variant;
    },
    socialVariantUpdated: (state, action: PayloadAction<{ variant: SocialVariant }>) => {
      state.leftPanel.panels.socialVariant = action.payload.variant;
    },
    updateStoryboardV2State: (state, action: PayloadAction<StoryboardV2State>) => {
      state.storyboardV2.state = action.payload;
    },
    updateFrameContainerHeight: (state, action: PayloadAction<number>) => {
      state.frame.containerHeight = action.payload;
    },
    updateFrameExternalLoader: (
      state,
      action: PayloadAction<{ visible: boolean; message: string }>,
    ) => {
      state.frame.externalLoader = action.payload;
    },
    showAlertModal: (state, action: PayloadAction<{ title: string; message: string }>) => {
      state.showAlertModal = {
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    timelineShouldRenderUpdated: (state, action: PayloadAction<boolean>) => {
      state.timeline.shouldRender = action.payload;
    },
    updateAudioLevelMeterVisibility: (state, action: PayloadAction<boolean>) => {
      state.audioLevelMeter.visible = action.payload;
    },
    updateAudioLevelMeterEnabled: (state, action: PayloadAction<boolean>) => {
      state.audioLevelMeter.isEnabled = action.payload;
    },
    keyboardShortcutPanelToggled: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.keyboardShortcutPanel.isVisible = action.payload.visible;
    },
    lastMoveUploadItemToLogo: (state, action: PayloadAction<State['lastMoveUploadItemToLogo']>) => {
      state.lastMoveUploadItemToLogo = action.payload;
    },
    updateCustomTextStyles: (state, action: PayloadAction<State['customTextStyles']>) => {
      state.customTextStyles = action.payload;
    },
    lihpPostExportModalVisibilityToggled: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.lihpPostExportModal.isVisible = action.payload.visible;
    },
    lihpAuthPostProcessParamsUpdated: (state, action: PayloadAction<LihpPostProcessParams>) => {
      state.lihpAuthPostProcessParams = action.payload;
    },
    updatePaymentModalParams: (state, action: PayloadAction<PaymentModalParams>) => {
      state.paymentModalParams = action.payload;
    },
    openIvCommonModal: (
      state,
      action: PayloadAction<{ isOpen: boolean; modalType?: ModalType; props?: any }>,
    ) => {
      state.ivCommonModal = action.payload;
    },

    setLeftPanelModalStatus: (state, action: PayloadAction<{ status: boolean }>) => {
      state.leftPanel.modalStatus = action.payload.status;
    },

    updateRequestedUploadingClipDelete: (
      state,
      action: PayloadAction<{ status: boolean; id: string }>,
    ) => {
      state.leftPanel.requestedUploadingClipDelete = {
        status: action.payload.status,
        id: action.payload.id,
      };
    },
    setSourceMonitorPlayState: (state, action: PayloadAction<'play' | 'paused'>) => {
      state.leftPanel.sourceMonitorPlayState = action.payload;
    },
    addBgRemovalJob: (state, action: PayloadAction<BgRemovalJob>) => {
      const existingAiBgRemovalJobs = state.bgRemovalJobs;
      const newAiBgRemovalJobs = [...existingAiBgRemovalJobs, action.payload];
      localStorage.setItem(aiBgLocalStorageKey, JSON.stringify(newAiBgRemovalJobs));
      state.bgRemovalJobs = newAiBgRemovalJobs;
    },
    removeBgRemovalJob: (state, action: PayloadAction<string>) => {
      const newAiBgRemovalJobs = state.bgRemovalJobs.filter(({ jobId }) => {
        return jobId !== action.payload;
      });
      localStorage.setItem(aiBgLocalStorageKey, JSON.stringify(newAiBgRemovalJobs));
      state.bgRemovalJobs = newAiBgRemovalJobs;
    },
    removeBgRemovalJobByComponentId: (state, action: PayloadAction<string>) => {
      const newAiBgRemovalJobs = state.bgRemovalJobs.filter(({ componentId }) => {
        return componentId !== action.payload;
      });
      localStorage.setItem(aiBgLocalStorageKey, JSON.stringify(newAiBgRemovalJobs));
      state.bgRemovalJobs = newAiBgRemovalJobs;
    },
    updateBgRemovalJobStatus: (
      state,
      action: PayloadAction<{ jobId: string; status: REMOVE_VIDEO_BG_STATUS }>,
    ) => {
      const newAiBgRemovalJobs = state.bgRemovalJobs.map((job) => {
        if (job.jobId === action.payload.jobId) return { ...job, status: action.payload.status };
        return job;
      });
      localStorage.setItem(aiBgLocalStorageKey, JSON.stringify(newAiBgRemovalJobs));
      state.bgRemovalJobs = newAiBgRemovalJobs;
    },
    updateBgRemovalJobProxyData: (
      state,
      action: PayloadAction<{ jobId: string; proxyData: ProxyJobResult }>,
    ) => {
      const newAiBgRemovalJobs = state.bgRemovalJobs.map((job) => {
        if (job.jobId === action.payload.jobId)
          return { ...job, proxyData: action.payload.proxyData };
        return job;
      });
      localStorage.setItem(aiBgLocalStorageKey, JSON.stringify(newAiBgRemovalJobs));
      state.bgRemovalJobs = newAiBgRemovalJobs;
    },
    clickExportVideo: (
      state,
      action: PayloadAction<{ directExport: boolean; openExportPanel: boolean }>,
    ) => {
      state.exportVideo = action.payload;
    },
    updateExportModalVisible: (state, action: PayloadAction<boolean>) => {
      state.editorExportModal.isVisible = action.payload;
    },
    clearBgRemovalJobs: (state) => {
      const newAiBgRemovalJobs: BgRemovalJob[] = [];
      localStorage.setItem(aiBgLocalStorageKey, JSON.stringify(newAiBgRemovalJobs));
      state.bgRemovalJobs = newAiBgRemovalJobs;
    },

    setRedirectionRequest(state, action: PayloadAction<{ path: string }>) {
      state.redirectRequest = { status: 'tobehandled', path: action.payload.path };
    },
    clearRedirection(state) {
      state.redirectRequest = null;
    },
    setLastImageBgRemoveJob: (
      state,
      action: PayloadAction<{ url: string; imageMediaProperties: ImageMediaPropertiesJSON } | null>,
    ) => {
      state.lastImageBgRemoveJob = action.payload;
    },
    setLastVideoBgRemoveJob: (
      state,
      action: PayloadAction<{
        url: string;
        videoMediaProperties: RemoveVideoBgPostProcessRequest;
      } | null>,
    ) => {
      state.lastVideoBgRemoveJob = action.payload;
    },
    folderCreationRequested: (state, action: PayloadAction<{ activeTab: ActiveTab }>) => {
      state.folderCreationRequests = { timestamp: Date.now(), activeTab: action.payload.activeTab };
    },
    savedCustomTextStyle: (state) => {
      state.saveCustomTextStyleValue = false;
      state.refetchCustomTextStyles = true;
    },
    refetchedCustomTextStyles: (state) => {
      state.refetchCustomTextStyles = false;
    },
    saveCustomTextStyle: (state) => {
      state.saveCustomTextStyleValue = true;
    },
    updateInspectorSelectedProperty: (
      state,
      action: PayloadAction<State['inspector']['selectedProperty']>,
    ) => {
      state.inspector.selectedProperty = action.payload;
    },
    confirmationOverlayUpdated: (state, action: PayloadAction<ConfirmationModalOptions>) => {
      state.overlays.confirm = {
        title: action.payload.title,
        body: action.payload.body,
        primaryActionText: action.payload.primaryActionText,
        secondaryActionText: action.payload.secondaryActionText,
        isSecondaryActionVisible: action.payload.isSecondaryActionVisible,
      };
    },

    updateSceneWarnLimitStateAlerted: (state, action: PayloadAction<boolean>) => {
      state.sceneLimitModals.warnModal.alerted = action.payload;
    },
    setOnlineStatus: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    updateStockVideoTemplateData: (state, action: PayloadAction<StockVideoTemplateInfo | null>) => {
      state.stockVideoTemplate = action.payload;
    },
    updateIsMyMediaBackButtonHidden: (state, action: PayloadAction<boolean>) => {
      state.isMyMediaBackButtonHidden = action.payload;
    },
    showUploadMediaModal: (state) => {
      state.uploadMediaModal.visible = true;
    },
    closeUploadMediaModal: (state) => {
      state.uploadMediaModal = { visible: false, wasClosed: true };
    },
    addImageBgRemovalJob: (state, action: PayloadAction<ImageBgRemovalJob>) => {
      state.imageBgRemovalJobs.push(action.payload);
    },
    updateImageBgRemovalJobForComponent: (state, action: PayloadAction<ImageBgRemovalJob>) => {
      const job = state.imageBgRemovalJobs.find(
        (j) => j.componentId === action.payload.componentId,
      );
      if (job) {
        job.jobId = action.payload.jobId;
        job.status = action.payload.status;
      }
    },
    removeImageBgRemovalJobByComponentId: (state, action: PayloadAction<string>) => {
      state.imageBgRemovalJobs = state.imageBgRemovalJobs.filter(
        (j) => j.componentId !== action.payload,
      );
    },
    addBlobURL: (
      state,
      action: PayloadAction<{ blobURL: string; fileMetadata: LeftPanelMediaFileMetadata }>,
    ) => {
      state.blobURLMap[action.payload.blobURL] = action.payload.fileMetadata;
      delete state.failedUploadsBlobURLs[action.payload.blobURL];
    },

    uploadFailed: (state, action: PayloadAction<{ blobURL: string; key: string }>) => {
      state.failedUploadsBlobURLs[action.payload.blobURL] = action.payload.key;
    },

    deletedUploadingAsset: (state, action: PayloadAction<string>) => {
      state.deletedBlobURLs.push(action.payload);
    },

    logoBgRemoved: (state) => {
      state.refreshLogos = true;
    },

    refetchedLogos: (state) => {
      state.refreshLogos = false;
    },
    updateMaintenaceBanner: (state, action: PayloadAction<boolean>) => {
      state.maintenanceBanner.isOpen = action.payload;
    },
    updateFeaturePromoBanner: (state, action: PayloadAction<boolean>) => {
      state.featurePromoBanner.isOpen = action.payload;
    },
    updateStudioBanner: (state, action: PayloadAction<boolean>) => {
      state.studioBanner.isOpen = action.payload;
    },
  },
});

import debug from 'debug';
const log = debug('media-cache');

interface MediaCacheOption {
  blob: boolean;
}

export class MediaCache {
  private static instance?: MediaCache;
  private cache: Map<string, string>;
  private runningPromises: Map<string, Promise<string>>;

  private constructor() {
    this.cache = new Map();
    this.runningPromises = new Map();
  }

  public static get shared(): MediaCache {
    if (!MediaCache.instance) {
      MediaCache.instance = new MediaCache();
    }
    return MediaCache.instance;
  }

  private isBlobUrl(url: string) {
    return url?.includes('blob:');
  }

  public get(url: string) {
    if (this.isBlobUrl(url)) return url;

    if (this.cache.has(url)) {
      return this.cache.get(url)!;
    }

    return url;
  }

  async fetch(url: string, options?: MediaCacheOption) {
    if (this.runningPromises.has(url)) {
      log('request already running', { url: url });
      return this.runningPromises.get(url)!;
    }

    const promise = this._fetch(url, options).finally(() => {
      this.runningPromises.delete(url);
    });

    this.runningPromises.set(url, promise);
    return promise;
  }

  async load(url: string) {
    return this.fetch(url);
  }

  private async _fetch(url: string, options?: MediaCacheOption): Promise<string> {
    log('cache requested', { url: url });

    if (this.isBlobUrl(url)) {
      return url;
    }

    if (this.cache.has(url)) {
      const link =  this.cache.get(url)!;

      if (!options?.blob) return link;

      if (options?.blob && this.isBlobUrl(link))  {
        return link
      }
    }
    try {
      const response = await fetch(url);
      let link = url;
      if (response.ok) {
        if (options?.blob) {
          const blob = await response.blob();
          link = URL.createObjectURL(blob);
        }
        this.cache.set(url, link);
      } else {
        log('Error while converting already existing response to blob', response.url);
      }
      return link;
    } catch (error) {
      log('Some error with fetch in media-cache', { url, error });
      return url;
    }
  }
}

/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Shadow, Slate } from '../jsonTypes';
import {
  isPlaceholderLogoComposite,
  ParentData,
  parseAudio,
  parseBlock,
  parseComposite,
  parseImage,
  parseParagraph,
  parseLayer,
  parseVideo,
  parseWatermark,
  parseTransition,
} from './masterCleaner';
import {
  ComponentIdentifier,
  GapType,
  WatermarkSubTypes,
  Effect,
  AdjustmentFilterProperties,
  AUDIO_TYPE,
  ColorJSON,
  ColorCorrection,
  VideoJSON,
} from '../jsonTypes/SlateJSON';
import { cloneJSON, getEmptySlateBlock, isPresent } from '../utils';
import { TIMELINE_DROP_PLACEHOLDER_ID } from '../ui/constants';
import { TimeRange } from '../project/types';
import { uuid } from '../project/utils/uuid';
import { clamp, toFixed, toNearestFrame } from '../project/utils/time-utils';
import { Store } from '../index';
import { ImageJSON } from '../jsonTypes/MasterJSON';

export type SlateEntities =
  | Slate.ComponentJSON
  | Slate.AudioJSON
  | Slate.LayerJSON
  | Slate.TransitionJSON
  | Slate.GapJSON;
type SlateRef<T> = {
  t: number;
  // can be mutated in place
  // but should not be reassigned as the object ref could be held by someone else
  readonly v: T;
  readonly parentKey?: string;
};
export type SlateRefRegistry = {
  [SlateEntity in SlateEntities as SlateEntity['type']]: {
    [id: string]: SlateRef<SlateEntity>;
  };
} & {
  root: {
    blocks: ComponentIdentifier<'block'>[];
    audioBlocks: ComponentIdentifier<'audio'>[];
    layers: ComponentIdentifier<'layer'>[];
    transitions: ComponentIdentifier<'transition'>[];
    gaps: ComponentIdentifier<'gap'>[];
  };
};

type FindByType<Union, Type> = Union extends { type: Type } ? Union : never;

export type SlateStoreState = 'READY' | 'BUFFERING' | 'FAILED';

export type SlateStoreError = {
  id: number;
  type: 'RECOVERABLE' | 'UNRECOVERABLE';
  message: string;
};
export class SlateStore {
  private _shadowDimensionJSON: Shadow.DimensionJSON | null = null;
  private _refRegistry: SlateRefRegistry;
  private _ongoingAsyncParsers: Set<string> = new Set();
  private _errors: Array<SlateStoreError> = [];

  constructor(public readonly rootId: string) {
    this._refRegistry = {
      root: { blocks: [], audioBlocks: [], layers: [], transitions: [], gaps: [] },
      image: {},
      video: {},
      paragraph: {},
      audio: {},
      composite: {},
      block: {},
      layer: {},
      watermark: {},
      transition: {},
      gap: {},
    };
  }

  get state(): SlateStoreState {
    if (this._errors.length > 0) return 'FAILED';
    if (this._ongoingAsyncParsers.size > 0) return 'BUFFERING';

    return 'READY';
  }

  get errors(): SlateStoreError[] {
    return this._errors;
  }

  // TODO: handle race condition, skip sync if asyncOps from previous sync are still ongoing
  // TODO: make a copy of shadowJSON before starting parsing, since it's mutable
  syncWithShadow(json: Shadow.JSON, optimize: boolean = true) {
    this._errors = [];
    const cloneJson = cloneJSON(json);
    const clonedDim: Shadow.DimensionJSON | undefined =
      cloneJson?.['16:9'] ?? cloneJson?.['1:1'] ?? cloneJson?.['9:16'];
    if (!clonedDim) throw new Error('Dimension Json not found in SlateStore -> syncWithShadow');

    this._shadowDimensionJSON = clonedDim;
    const audioBlocks = clonedDim.audio_blocks;
    const videoAudioBlocks = audioBlocks.filter((b) => b.sub_type === 'bg_video');
    // const nonVideoAudioBlocks = audioBlocks.filter((b) => b.sub_type !== 'bg_video');

    const parentData: ParentData = {
      size: {
        w: clonedDim.screen_size[0],
        h: clonedDim.screen_size[1],
      },
      duration: {
        t0: 0,
        t1: clonedDim.duration.absolute_duration,
      },
      absoluteAngle: 0,
      isLogoPlaceHolderHidden: cloneJson.logo_placeholder_hidden,
    };

    const layers = clonedDim.layers;

    layers.map((layer) => this.parseShadowToSlateLayer(layer));
    this._refRegistry.root.layers = layers.map((l) => ({ type: 'layer', id: l.id }));

    const blocks = clonedDim.blocks.filter((b) => b.type !== 'audio');

    this._refRegistry.root.gaps = [];
    this._refRegistry.gap = {};
    blocks.map((block, index) => {
      this.parseShadowToSlateBlock(
        block,
        parentData,
        videoAudioBlocks,
        (-1 * (block.duration.delay ?? 0)) / 2,
        (-1 * (blocks[index + 1]?.duration?.delay ?? 0)) / 2,
        optimize,
      );
    });
    blocks.map((block) => {
      this.parseSlateGap(block, layers);
    });
    this._refRegistry.root.blocks = blocks.map((b) => ({ type: 'block', id: b.id }));

    const transitionsIds = blocks
      .map((block, index) => {
        const nextBlock = blocks[index + 1];
        if (!nextBlock) return null;
        const id = `${block.id}${nextBlock.id}`;
        this.parseShadowToSlateTransition(id, index, block, nextBlock, clonedDim);
        return id;
      })
      .filter(isPresent);

    this._refRegistry.root.transitions = transitionsIds.map((id) => ({
      type: 'transition',
      id: id,
    }));

    // parse audio blocks is the last step since it require video blocks to be processed beforehand
    audioBlocks.map((audio) => this.parseShadowToSlateAudio(audio, optimize));
    this._refRegistry.root.audioBlocks = audioBlocks.map((b) => ({
      type: 'audio',
      id: b.id,
    }));
    this.parseSlateAudioGaps(
      layers.filter((l) => l.layer_type === 'audio'),
      { start: 0, end: clonedDim.duration.absolute_end },
    );
  }

  // getSlateImage(id: string): Slate.ImageJSON | null {
  //   return this._cache.image[id]?.v ?? null;
  // }

  // getSlateParagraph(id: string): Slate.ParagraphJSON | null {
  //   return this._cache.text[id]?.v ?? null;
  // }

  // https://github.com/Microsoft/TypeScript/issues/17915#issuecomment-413347828
  // have to use `FindByType` conditional type for auto type inference
  getByType<EntityType extends SlateEntities['type']>(
    type: EntityType,
    id: string,
  ): FindByType<SlateEntities, EntityType> | null {
    return (this._refRegistry[type][id]?.v as FindByType<SlateEntities, EntityType>) ?? null;
  }

  getById(id: string): SlateEntities | null {
    return this.getRefById(id)?.v ?? null;
  }

  getRefById(id: string): {
    t: number;
    readonly v: SlateEntities;
  } {
    return (
      this._refRegistry.block[id] ??
      this._refRegistry.composite[id] ??
      this._refRegistry.image[id] ??
      this._refRegistry.video[id] ??
      this._refRegistry.paragraph[id] ??
      this._refRegistry.audio[id] ??
      this._refRegistry.watermark[id] ??
      this._refRegistry.transition[id] ??
      null
    );
  }

  getSlateRoot() {
    return this._refRegistry.root;
  }

  getSlateComposite(id: string): Slate.CompositeJSON | null {
    return this._refRegistry.composite[id]?.v ?? null;
  }

  getSlateWatermark(id: string): Slate.WatermarkJSON | null {
    return this._refRegistry.watermark[id]?.v ?? null;
  }

  getSlateComponent(
    id: string,
  ): Slate.CompositeJSON | Slate.ImageJSON | Slate.VideoJSON | Slate.ParagraphJSON | null {
    return (
      this._refRegistry.composite[id]?.v ??
      this._refRegistry.image[id]?.v ??
      this._refRegistry.video[id]?.v ??
      this._refRegistry.paragraph[id]?.v ??
      null
    );
  }

  getSlateBlock(id: string): Slate.BlockJSON | null {
    return this._refRegistry.block[id]?.v ?? null;
  }

  getSlateGap(id: string): Slate.GapJSON | null {
    return this._refRegistry.gap[id]?.v ?? null;
  }

  getSlateTransition(id: string): Slate.TransitionJSON | null {
    return this._refRegistry.transition[id]?.v ?? null;
  }

  getSlateLayer(id: string): Slate.LayerJSON | null {
    return this._refRegistry.layer[id]?.v ?? null;
  }

  getSlateAudio(id: string): Slate.AudioJSON | null {
    return this._refRegistry.audio[id]?.v ?? null;
  }

  getSlateVideo(id: string): Slate.VideoJSON | null {
    return this._refRegistry.video[id]?.v ?? null;
  }

  addSlateLayer(layer: Slate.LayerJSON) {
    const tNow = Date.now();
    this._refRegistry['layer'][layer.id] = {
      t: tNow,
      v: layer,
    };
    this._refRegistry.root.layers.push({ id: layer.id, type: 'layer' });
  }

  patchSelected(id: string, selected: boolean) {
    const ref =
      this._refRegistry.paragraph[id] ?? this._refRegistry.image[id] ?? this._refRegistry.video[id];
    const ui = { selected: selected };
    const time = performance.now();
    if (ref) {
      Object.assign(ref.v, { ui: ui });
      this.touch(id, time);
    }
  }

  patchSlatePositionComposite(
    id: string,
    properties: {
      offsetX?: number;
      offsetY?: number;
      width: number;
      height: number;
      angle?: number;
      opacity?: number;
    },
    childProperties: {
      offsetX?: number;
      offsetY?: number;
      width: number;
      height: number;
      angle?: number;
      opacity?: number;
    }[],
  ) {
    const composite = this._refRegistry.composite[id];
    if (composite) {
      const time = Date.now();
      composite.t = time;
      Object.assign(composite.v, { ...properties, cacheKey: `${time}-${id}` });

      composite.v.componentIds.forEach((it, index: number) => {
        const currentPosition = childProperties[index];
        const { id, type } = it;
        const { opacity } = properties;
        if (type === 'composite') {
          this.patchSlateComposite(id, {
            offsetX: currentPosition.offsetX ?? 0,
            offsetY: currentPosition.offsetY ?? 0,
            angle: currentPosition.angle ?? 0,
            width: currentPosition.width,
            height: currentPosition.height,
            opacity,
          });
        }
      });
      const [blockId] = id
        .split('.')
        .filter((id) => id.trim().length > 0)
        .map((id) => `.${id}`);
      const block = this._refRegistry.block[blockId];
      if (block) {
        block.t = time;
        block.v.cacheKey = `${time}-${blockId}`;
      }
    }
  }

  patchSlateComposite(
    id: string,
    properties: {
      offsetX?: number;
      offsetY?: number;
      width: number;
      height: number;
      angle?: number;
      opacity?: number;
    },
  ) {
    const composite = this._refRegistry.composite[id];
    if (composite) {
      const time = Date.now();
      composite.t = time;
      //Calculate the relative change of height and width.
      //Apply the same change to the masked media w,h,x,y.
      const kWidth = properties.width / composite.v.width;
      const kHeight = properties.height / composite.v.height;
      Object.assign(composite.v, { ...properties, cacheKey: `${time}-${id}` });

      composite.v.componentIds.forEach((it) => {
        const { id, type } = it;
        const { width, height, opacity } = properties;
        if (type === 'composite') {
          this.patchSlateComposite(id, {
            offsetX: 0,
            offsetY: 0,
            angle: 0,
            width,
            height,
            opacity,
          });
        } else {
          const childSlateJson = this._refRegistry[type][id];
          childSlateJson.t = time;
          if (composite.v.mask !== null) {
            Object.assign(childSlateJson.v, {
              cacheKey: `${time}-${id}`,
              width: childSlateJson.v.width * kWidth,
              height: childSlateJson.v.height * kHeight,
              offsetX: childSlateJson.v.offsetX * kWidth,
              offsetY: childSlateJson.v.offsetY * kHeight,
            });
          } else {
            Object.assign(childSlateJson.v, { cacheKey: `${time}-${id}`, width, height });
          }

          const watermarkSubTypes: WatermarkSubTypes[] = ['overlay', 'badge', 'cta'];
          watermarkSubTypes.forEach((subType) => {
            const slateWatermarkJson = this._refRegistry.watermark[`${id}.${subType}`];
            if (slateWatermarkJson) {
              slateWatermarkJson.t = time;
              const cacheKey = `${time}-${id}`;
              switch (slateWatermarkJson.v.subType) {
                case 'cta': {
                  Object.assign(slateWatermarkJson.v, {
                    cacheKey,
                    offsetY: height * (1 - 0.06) - slateWatermarkJson.v.height,
                    offsetX: width * (1 - 0.04) - slateWatermarkJson.v.width,
                  });
                  break;
                }
                case 'overlay': {
                  Object.assign(slateWatermarkJson.v, {
                    cacheKey,
                    width: slateWatermarkJson.v.width * kWidth,
                    height: slateWatermarkJson.v.height * kHeight,
                  });
                  break;
                }
                case 'badge': {
                  Object.assign(slateWatermarkJson.v, {
                    cacheKey,
                    width: slateWatermarkJson.v.width * kWidth,
                    height: slateWatermarkJson.v.height * kHeight,
                  });
                  break;
                }
              }
            }
          });
        }
      });
      //assumption: the width and height of the mask would be same of the box
      if (composite.v.mask) {
        composite.v.mask.width = composite.v.width;
        composite.v.mask.height = composite.v.height;
      }

      const [blockId] = id
        .split('.')
        .filter((id) => id.trim().length > 0)
        .map((id) => `.${id}`);
      const block = this._refRegistry.block[blockId];
      if (block) {
        block.t = time;
        block.v.cacheKey = `${time}-${blockId}`;
      }
    }
  }

  patchSlateComponentOpacity(id: string, opacity: number) {
    const component: SlateRef<Slate.ImageJSON | Slate.VideoJSON> =
      this._refRegistry.image[id] || this._refRegistry.video[id];
    if (component) {
      const time = Date.now();
      component.t = time;
      Object.assign(component.v, { opacity });
      this.touch(id, time);
    }
  }

  patchSlateComponentFitAlignment(id: string, relativeForegroundOffset: { x: number; y: number }) {
    const component: SlateRef<Slate.ImageJSON | Slate.VideoJSON> =
      this._refRegistry.image[id] || this._refRegistry.video[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { relativeForegroundOffset: relativeForegroundOffset });
      this.touch(id, time);
    }
  }

  patchSlateComponentAdjustmentFilters(
    id: string,
    property: AdjustmentFilterProperties,
    value: number,
  ) {
    const component: SlateRef<Slate.ImageJSON | Slate.VideoJSON> =
      this._refRegistry.image[id] || this._refRegistry.video[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { [property]: value });
      this.touch(id, time);
    }
  }

  patchSlateComponentEffects(id: string, effects: Array<Effect>) {
    const component: SlateRef<Slate.ImageJSON | Slate.VideoJSON> =
      this._refRegistry.image[id] || this._refRegistry.video[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { effects: effects });
      this.touch(id, time);
    }
  }

  patchSlateComponentColorAtIndex(id: string, color: Slate.ColorJSON, colorRef: Slate.ColorRef) {
    const tNow = Date.now();
    const ref = this._refRegistry.image[id] ?? this._refRegistry.video[id] ?? null;

    // calculate the color_$[index] which has to be updated based on the ref.
    const component = cloneJSON(Store.project.getComponentById(id));
    let indexToUpdate: null | 0 | 1 | 2 | 3 = null;
    if (component) {
      if (component.type === 'image' || component.type === 'video') {
        const imageComponent = component as ImageJSON;
        const imageColorCorrection = imageComponent.layout.layer_properties.color_correction;
        if (imageColorCorrection && typeof imageColorCorrection != 'string') {
          const colors_ref = imageColorCorrection.colors_ref;
          if (colors_ref) {
            if (colors_ref.color?.reference === colorRef) {
              indexToUpdate = 0;
            } else if (colors_ref.color_1?.reference === colorRef) {
              indexToUpdate = 1;
            } else if (colors_ref.color_2?.reference === colorRef) {
              indexToUpdate = 2;
            } else if (colors_ref.color_3?.reference === colorRef) {
              indexToUpdate = 3;
            }
          }
        }
      }
    }

    if (ref) {
      const colorCorrection = ref.v.colorCorrection;
      if (colorCorrection) {
        switch (colorCorrection.type) {
          case 'duotone1':
          case 'tritone1': {
            if (indexToUpdate === 0) {
              colorCorrection.color = color;
            }
            break;
          }
          case 'duotone2': {
            if (indexToUpdate == 1) {
              colorCorrection.color_1 = color;
            } else if (indexToUpdate === 2) {
              colorCorrection.color_2 = color;
            }
            break;
          }
          case 'tritone3': {
            if (indexToUpdate == 1) {
              colorCorrection.color_1 = color;
            } else if (indexToUpdate === 2) {
              colorCorrection.color_2 = color;
            } else if (indexToUpdate === 3) {
              colorCorrection.color_3 = color;
            }
            break;
          }
        }
      }
      this.touch(id, tNow);
    }
  }

  patchSlateComponentColorCorrection(id: string, colorCorrection: ColorCorrection) {
    const tNow = Date.now();
    const ref = this._refRegistry.image[id] ?? this._refRegistry.video[id] ?? null;
    if (ref) {
      ref.v.colorCorrection = colorCorrection;
      this.touch(id, tNow);
    }
  }

  patchSlateBlockTime(
    id: string,
    properties: {
      tSceneStart?: Slate.seconds;
      tSceneEnd?: Slate.seconds;
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
      tIn?: Slate.seconds;
      tOut?: Slate.seconds;
    },
  ) {
    const tNow = Date.now();
    const block = this._refRegistry.block[id];
    if (block) {
      Object.assign(block.v, properties);
      this.touch(id, tNow);
    }
  }

  shiftSlateBlock(
    id: string,
    properties: {
      index: number;
      tSceneStart?: Slate.seconds;
      tSceneEnd?: Slate.seconds;
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
      tIn?: Slate.seconds;
      tOut?: Slate.seconds;
    },
  ) {
    const tNow = Date.now();
    const block = this._refRegistry.block[id];
    if (block) {
      Object.assign(block.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateVideoTrim(
    id: string,
    properties: {
      trim: {
        startTime: number;
        endTime: number;
      }[];
      loop: boolean;
    },
  ) {
    const tNow = Date.now();
    const video = this._refRegistry.video[id];
    if (video) {
      Object.assign(video.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateVideoPlaybackRate(
    id: string,
    properties: { speed?: number; playbackRateStrategy?: VideoJSON['playbackRateStrategy'] },
  ) {
    const tNow = Date.now();
    const video = this._refRegistry.video[id];
    if (video) {
      Object.assign(video.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateAudioTime(
    id: string,
    properties: {
      tStart: number;
      tEnd: number;
    },
  ) {
    const tNow = Date.now();
    const audio = this.getRefById(id);
    if (audio) {
      Object.assign(audio.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateAudioConfig(
    id: string,
    properties: {
      audioType: Slate.AUDIO_TYPE;
    },
  ) {
    const tNow = Date.now();
    const audio = this._refRegistry.audio[id];
    if (audio) {
      Object.assign(audio.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateAudioVolume(
    id: string,
    properties: {
      volume?: number;
      duckedVolume?: number;
      isDucked?: boolean;
    },
  ) {
    const tNow = Date.now();
    const audio = this._refRegistry.audio[id];
    if (audio) {
      Object.assign(audio.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateAudioTrim(
    id: string,
    properties: {
      trimSection: {
        startTime: number;
        endTime: number;
      };
      loop: boolean;
    },
  ) {
    const tNow = Date.now();
    const audio = this._refRegistry.audio[id];
    if (audio) {
      Object.assign(audio.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateCompositeTime(
    id: string,
    properties: {
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
      tIn?: Slate.seconds;
      tOut?: Slate.seconds;
    },
  ) {
    const tNow = Date.now();
    const composite = this.getRefById(id);
    if (composite) {
      Object.assign(composite.v, properties);
      this.touch(id, tNow);
    }
  }

  patchSlateCompositeLayerId(id: string, layer_id: string) {
    const tNow = Date.now();
    const composite = this._refRegistry.composite[id] || this._refRegistry.audio[id];
    if (composite) {
      composite.v.layerId = layer_id;
      this.touch(id, tNow);
    }
  }

  patchSlateLayers(layers: Shadow.LayerJSON[]) {
    console.log('Layers: ', layers);
  }

  patchSlateLayerLock(layerId: string, locked: boolean) {
    const tNow = Date.now();
    const layer = this._refRegistry.layer[layerId];

    if (layer) {
      Object.assign(layer.v, { locked });
      layer.t = tNow;
      layer.v.cacheKey = `${tNow}-${layer.v.id}`;
    }
  }

  patchSlateVideoComponentColorAtIndex(
    id: string,
    color: Slate.ColorJSON,
    colorRef: Slate.ColorRef,
  ) {
    const tNow = Date.now();
    const ref = this._refRegistry.image[id] ?? this._refRegistry.video[id] ?? null;

    // calculate the color_$[index] which has to be updated based on the ref.
    const component = cloneJSON(Store.project.getComponentById(id));
    let indexToUpdate: null | 0 | 1 | 2 | 3 = null;
    if (component) {
      if (component.type === 'image' || component.type === 'video') {
        const imageComponent = component as ImageJSON;
        const imageColorCorrection = imageComponent.layout.layer_properties.color_correction;
        if (imageColorCorrection && typeof imageColorCorrection != 'string') {
          const colors_ref = imageColorCorrection.colors_ref;
          if (colors_ref) {
            if (colors_ref.color?.reference === colorRef) {
              indexToUpdate = 0;
            } else if (colors_ref.color_1?.reference === colorRef) {
              indexToUpdate = 1;
            } else if (colors_ref.color_2?.reference === colorRef) {
              indexToUpdate = 2;
            } else if (colors_ref.color_3?.reference === colorRef) {
              indexToUpdate = 3;
            }
          }
        }
      }
    }

    if (ref) {
      const colorCorrection = ref.v.colorCorrection;
      if (colorCorrection) {
        switch (colorCorrection.type) {
          case 'duotone1':
          case 'tritone1': {
            if (indexToUpdate === 0) {
              colorCorrection.color = color;
            }
            break;
          }
          case 'duotone2': {
            if (indexToUpdate == 1) {
              colorCorrection.color_1 = color;
            } else if (indexToUpdate === 2) {
              colorCorrection.color_2 = color;
            }
            break;
          }
          case 'tritone3': {
            if (indexToUpdate == 1) {
              colorCorrection.color_1 = color;
            } else if (indexToUpdate === 2) {
              colorCorrection.color_2 = color;
            } else if (indexToUpdate === 3) {
              colorCorrection.color_3 = color;
            }
            break;
          }
        }
      }
      this.touch(id, tNow);
    }
  }

  patchSlateParagraphVisibility(id: string, hidden: boolean) {
    const paragraph = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (paragraph) {
      Object.assign(paragraph.v, { hidden: hidden });
      this.touch(id, time);
    }
  }

  patchSlateCompositeForceInvisibility(id: string, isInvisible: boolean) {
    const composite = this._refRegistry.composite[id];
    const time = Date.now();
    if (composite) {
      const ui = composite.v.ui
        ? { ...composite.v.ui, forceInvisible: isInvisible }
        : { selected: false, forceInvisible: isInvisible };
      Object.assign(composite.v, { ...composite.v, ui });
    }

    composite.v.componentIds.forEach((c) => {
      const ref =
        this._refRegistry.composite[c.id] ??
        this._refRegistry.image[c.id] ??
        this._refRegistry.paragraph[c.id] ??
        this._refRegistry.video[c.id];
      if (!ref) {
        return;
      }
      if (ref.v.type === 'composite') {
        this.patchSlateCompositeForceVisibility(ref.v.id, isInvisible);
      } else {
        const ui = ref.v.ui
          ? { ...ref.v.ui, forceInvisible: isInvisible }
          : { selected: false, forceInvisible: isInvisible };
        Object.assign(ref.v, { ...ref.v, ui });
      }
    });

    this.touch(id, time);
  }

  patchSlateCompositeForceVisibility(id: string, visibility: boolean) {
    const composite = this._refRegistry.composite[id];
    const time = Date.now();
    if (composite) {
      const ui = composite.v.ui
        ? { ...composite.v.ui, forceVisible: visibility }
        : { selected: false, forceVisible: visibility };
      Object.assign(composite.v, { ...composite.v, ui });
    }

    composite.v.componentIds.forEach((c) => {
      const ref =
        this._refRegistry.composite[c.id] ??
        this._refRegistry.image[c.id] ??
        this._refRegistry.paragraph[c.id] ??
        this._refRegistry.video[c.id];
      if (!ref) {
        return;
      }
      if (ref.v.type === 'composite') {
        this.patchSlateCompositeForceVisibility(ref.v.id, visibility);
      } else {
        const ui = ref.v.ui
          ? { ...ref.v.ui, forceVisible: visibility }
          : { selected: false, forceVisible: visibility };
        Object.assign(ref.v, { ...ref.v, ui });
      }
    });

    this.touch(id, time);
  }

  patchSlateWatermarkCtaUrl(id: string, url: string) {
    const component: SlateRef<Slate.WatermarkJSON> = this._refRegistry.watermark[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { url });
      this.touch(id, time);
    }
  }

  patchSlateTransitionTime(
    id: string,
    properties: {
      tStart?: Slate.seconds;
      tEnd?: Slate.seconds;
    },
  ) {
    const component: SlateRef<Slate.TransitionJSON> = this._refRegistry.transition[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, properties);
      this.touch(id, time);
    }
  }

  patchSlateImageUrl(id: string, url: string) {
    const component: SlateRef<Slate.ImageJSON> = this._refRegistry.image[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { url });
      this.touch(id, time);
    }
  }

  putSlateParagraph(id: string, paragraph: Slate.ParagraphJSON) {
    const paraRef = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (paraRef) {
      Object.assign(paraRef.v, paragraph);
      this.touch(id, time);
    }
  }

  public patchToNearestFrame(rate: number) {
    const root = this.getSlateRoot();
    root.blocks.forEach((b) => {
      this.toNearestFrame(b.id, rate);
    });

    root.audioBlocks.forEach((a) => {
      this.toNearestFrame(a.id, rate);
    });

    root.transitions.forEach((t) => {
      this.toNearestFrame(t.id, rate);
    });
  }

  private toNearestFrame(id: string, rate: number) {
    this.toNearestFrameSubTree(id, rate);

    const ref = this.getRefById(id);
    if ('parentId' in ref.v && ref.v.parentId) {
      this.toNearestFramePathToRoot(ref.v.parentId, rate);
    }
    const time = performance.now();
    this.touch(id, time);
  }

  private toNearestFrameSubTree(id: string, rate: number) {
    const ref = this.getRefById(id);

    if ('tStart' in ref.v) {
      ref.v.tStart = toNearestFrame(ref.v.tStart, rate);
      ref.v.tEnd = toNearestFrame(ref.v.tEnd, rate);
    }

    if ('tIn' in ref.v) {
      ref.v.tIn = toNearestFrame(ref.v.tIn, rate);
      ref.v.tOut = toNearestFrame(ref.v.tOut, rate);
    }
    if ('componentIds' in ref.v) {
      ref.v.componentIds.forEach((identifier) => this.toNearestFrameSubTree(identifier.id, rate));
    }
  }

  private toNearestFramePathToRoot(id: string, rate: number) {
    const ref = this.getRefById(id);
    if (ref) {
      if ('tStart' in ref.v) {
        ref.v.tStart = toNearestFrame(ref.v.tStart, rate);
        ref.v.tEnd = toNearestFrame(ref.v.tEnd, rate);
      }

      if ('tIn' in ref.v) {
        ref.v.tIn = toNearestFrame(ref.v.tIn, rate);
        ref.v.tOut = toNearestFrame(ref.v.tOut, rate);
      }

      if ('parentId' in ref.v && ref.v.parentId) {
        this.toNearestFramePathToRoot(ref.v.parentId, rate);
      }
    }
  }

  public forceTouch(id: string) {
    const tNow = Date.now();
    this.touch(id, tNow);
  }

  private touch(id: string, tNow: number) {
    this.touchSubTree(id, tNow);

    const ref = this.getRefById(id);
    if ('parentId' in ref.v && ref.v.parentId) {
      this.touchPathToRoot(ref.v.parentId, tNow);
    }
  }

  private touchPathToRoot(id: string, tNow: number) {
    const ref = this.getRefById(id);
    if (ref) {
      ref.t = tNow;
      ref.v.cacheKey = `${tNow}-${ref.v.id}`;

      if ('parentId' in ref.v && ref.v.parentId) {
        this.touchPathToRoot(ref.v.parentId, tNow);
      }
    }
  }

  private touchSubTree(id: string, tNow: number) {
    const ref = this.getRefById(id);
    ref.t = tNow;
    ref.v.cacheKey = `${tNow}-${ref.v.id}`;

    if ('componentIds' in ref.v) {
      ref.v.componentIds.forEach((identifier) => this.touchSubTree(identifier.id, tNow));
    }
  }

  private parseShadowToSlateLayer(layer: Shadow.LayerJSON) {
    if (!this.hasChanged('layer', layer)) return;
    this.parse('layer', layer, () => parseLayer(layer));
  }

  private parseShadowToSlateBlock(
    block: Shadow.BlockJSON,
    parentData: ParentData,
    videoAudioBlocks: Shadow.AudioBlockJSON[],
    startTransitionDelay: number,
    endTransitionDelay: number,
    optimize: boolean,
  ) {
    if (!this.hasChanged('block', block)) return;

    const idx = block.components.findIndex((it) =>
      isPlaceholderLogoComposite(it as Shadow.CompositeJSON),
    );
    if (idx > -1) {
      const [logoJson] = block.components.splice(idx, 1);
      block.components.push(logoJson);
    }
    const slateBlock = this.parse('block', block, () =>
      parseBlock(block, parentData, block.index, startTransitionDelay, endTransitionDelay),
    );
    const parsableChildren = Object.fromEntries(
      slateBlock.componentIds.map((cid) => [cid.id, cid.type]),
    );

    block.components.forEach((comp, idx) => {
      // TODO: throw error if componentType !== 'composite'
      const compType = parsableChildren[comp.id];
      if (!compType) return null;

      const componentParentData: ParentData = {
        size: parentData.size,
        duration: {
          t0: block.duration.absolute_start,
          t1: block.duration.absolute_end,
        },
        absoluteAngle: parentData.absoluteAngle,
        isLogoPlaceHolderHidden: parentData.isLogoPlaceHolderHidden,
      };
      return this.parseShadowToSlateComposite(
        comp as Shadow.CompositeJSON,
        componentParentData,
        idx,
        videoAudioBlocks,
        optimize,
      );
    });
  }

  private parseSlateGap(block: Shadow.BlockJSON, layers: Shadow.LayerJSON[]) {
    const slateBlock = this.getSlateBlock(block.id);
    if (!slateBlock) return;

    const composites = slateBlock.componentIds
      .filter((c) => {
        const composite = this.getSlateComposite(c.id);
        if (composite?.type === 'composite' && composite.visible) return true;
        return false;
      })
      .map(({ id }) => this.getSlateComposite(id))
      .filter(isPresent);

    this.generateSceneGaps(composites, block.id);
    layers.forEach((l) => {
      const c = composites.filter((c) => c.layerId == l.id);
      const sceneRange = { start: slateBlock.tStart, end: slateBlock.tEnd };
      if (l.layer_type !== 'audio') this.generateLayerGaps(c, sceneRange, l.id, block.id);
    });
  }

  parseSlateAudioGaps(audioLayers: Shadow.LayerJSON[], bounds: TimeRange) {
    const allAudios = this.getSlateRoot()
      .audioBlocks.map((a) => this.getSlateAudio(a.id))
      .filter(isPresent);
    for (const layer of audioLayers) {
      const audios = allAudios.filter(
        (a) => a.layerId === layer.id && a.audioType !== AUDIO_TYPE.BG_VIDEO,
      );
      this.generateLayerGaps(audios, bounds, layer.id);
    }
  }

  private createGap(
    id: string,
    properties: {
      tStart: number;
      tEnd: number;
      layerId?: string | null;
      blockId?: string | null;
      gapType: GapType;
    },
  ) {
    if (properties.tEnd - properties.tStart === 0) {
      return;
    }
    // TODO - elegant fix to TIM - 1491
    properties.tStart = clamp(properties.tStart, 0);
    const tNow = Date.now();
    this._refRegistry.root.gaps.push({
      id,
      type: 'gap',
    });
    this._refRegistry.gap[id] = {
      t: tNow,
      v: Object.assign({}, {
        ...properties,
        type: 'gap',
        cacheKey: `${tNow}`,
        id,
      } as Slate.GapJSON),
    };
  }

  private generateSceneGaps(composites: Slate.CompositeJSON[], blockId: string) {
    const block = this.getSlateBlock(blockId);
    if (!composites || !composites.length || !block) return [];
    const sortedComposities = composites
      .map((c) => ({ start: c.tStart, end: c.tEnd }))
      .sort((a, b) => a.start - b.start);

    // treating scene point range as single point
    const timeRangeList = [
      {
        start: block.tStart,
        end: block.tStart,
      },
      ...sortedComposities,
    ];
    let refEnd = timeRangeList[0].end;

    for (const comp of timeRangeList) {
      if (comp.start > refEnd) {
        const id = uuid();
        this.createGap(id, {
          tStart: refEnd,
          tEnd: comp.start,
          blockId: blockId,
          gapType: 'scene-gap',
        });
      }
      refEnd = Math.max(refEnd, comp.end);
    }
  }

  private generateLayerGaps(
    composities: (Slate.CompositeJSON | Slate.AudioJSON)[],
    timeRange: TimeRange,
    layerId: string,
    blockId?: string,
  ) {
    if (!timeRange) return [];

    const sortedClips = composities.sort((a, b) => a.tStart - b.tStart);

    sortedClips.forEach((c, idx) => {
      if (idx === 0) {
        if (c.tStart > timeRange.start) {
          const id = `${c.id}-${blockId}`;

          const gapRange = { start: timeRange.start, end: c.tStart };

          this.createGap(id, {
            tStart: gapRange.start,
            tEnd: gapRange.end,
            layerId: layerId,
            blockId: blockId,
            gapType: 'layer-gap',
          });
        }
      }

      const nextClip = sortedClips[idx + 1];

      if (nextClip && toFixed(nextClip.tStart) !== toFixed(c.tEnd)) {
        const id = `${c.id}-${nextClip.id}`;

        const gapRange = { start: c.tEnd, end: nextClip.tStart };

        this.createGap(id, {
          tStart: gapRange.start,
          tEnd: gapRange.end,
          layerId: c.layerId,
          blockId: blockId,
          gapType: 'layer-gap',
        });
      }
    });
  }

  private parseShadowToSlateTransition(
    id: string,
    index: number,
    prevBlock: Shadow.BlockJSON,
    nextBlock: Shadow.BlockJSON,
    dim: Shadow.DimensionJSON,
  ) {
    if (!this.hasChanged('transition', prevBlock)) return;
    const oldRef = this._refRegistry['transition'][id];
    this._refRegistry['transition'][id] = {
      v: Object.assign(
        oldRef?.v ?? {},
        parseTransition(id, index, prevBlock, nextBlock, dim) as any,
      ),
      t: prevBlock.tUpdated,
    };
    return this._refRegistry['transition'][id].v;
  }

  addPlaceholderBlockAtIndex(index: number, duration: number) {
    const id = TIMELINE_DROP_PLACEHOLDER_ID;
    {
      const idx = this.getSlateRoot().blocks.findIndex((b) => b.id === id);
      if (idx !== -1 && idx === index) return;
      //remove placeholder if it already exists elsewhere
      delete this._refRegistry.block[id];
      if (idx !== -1) {
        this._refRegistry.root.blocks.splice(idx, 1);
      }
    }

    const prevBlock = this.getSlateRoot().blocks.find((_, idx) => idx === index - 1);
    const prevBlockJson = prevBlock ? this.getSlateBlock(prevBlock.id) : null;

    const tStart = prevBlockJson?.tEnd ?? 0;
    const tSceneStart = prevBlockJson?.tSceneEnd ?? 0;
    const tSceneEnd = tSceneStart + duration;
    const tEnd = tStart + duration;

    const tNow = Date.now();

    this._refRegistry.root.blocks.splice(index, 0, {
      id,
      type: 'block',
    });

    this._refRegistry.block[id] = {
      v: Object.assign(
        {},
        {
          ...getEmptySlateBlock(),
          index,
          uid: id,
          id,
          tSceneStart,
          tSceneEnd,
          tStart,
          tEnd,
          hidden: true,
        },
      ),
      t: tNow,
    };
    this.touch(id, tNow);

    // shift remaining slate Blocks
    this._refRegistry.root.blocks.forEach((b, idx) => {
      if (idx > index) {
        const block = this.getSlateBlock(b.id);
        if (!block) return;
        this.shiftSlateBlock(b.id, {
          index: block.index + 1,
          tStart: block.tStart + duration,
          tEnd: block.tEnd + duration,
          tIn: block.tIn + duration,
          tOut: block.tOut + duration,
          tSceneStart: block.tSceneStart + duration,
          tSceneEnd: block.tSceneEnd + duration,
        });
        block.componentIds.forEach((c) => {
          const clip = this.getSlateComposite(c.id);
          if (!clip) return;
          this.patchSlateCompositeTime(c.id, {
            tStart: clip.tStart + duration,
            tEnd: clip.tEnd + duration,
            tIn: clip.tIn + duration,
            tOut: clip.tOut + duration,
          });
        });
      }
    });
  }

  private _parseShadowToSlateWatermark(
    componentJson: Shadow.ImageJSON | Shadow.VideoJSON,
    compositeData: ParentData,
  ) {
    const subTypes: Slate.WatermarkSubTypes[] = ['overlay', 'badge', 'cta'];
    subTypes.forEach((subType) => {
      const clonedComponentJson = cloneJSON(componentJson);
      const parentPrefix = clonedComponentJson.uid;
      const id = `${clonedComponentJson.id}.${subType}`;
      const idSuffix =
        id
          .split('.')
          .filter((p) => p.length >= 2)
          .slice(-1)[0] ?? 'xx';

      clonedComponentJson.id = id;
      clonedComponentJson.uid = `${parentPrefix}>${idSuffix.substring(0, 2)}`;

      this.parseIfNeeded(
        'watermark',
        clonedComponentJson,
        () => parseWatermark(clonedComponentJson, compositeData, subType),
        JSON.stringify(compositeData),
      );
    });
  }

  private parseShadowToSlateComposite(
    composite: Shadow.CompositeJSON,
    parentData: ParentData,
    index: number,
    videoAudioBlocks: Shadow.AudioBlockJSON[],
    optimize: boolean,
  ) {
    const isParentPositionComposite = parentData.isPositionComposite;
    if (!this.hasChanged('composite', composite) && !isParentPositionComposite) return;
    if (isParentPositionComposite) {
      composite = { ...composite, tUpdated: Date.now() };
    }
    const slateComposite = this.parse('composite', composite, () =>
      parseComposite(composite, parentData, index),
    );
    const parsableChildren = Object.fromEntries(
      slateComposite.componentIds.map((cid) => [cid.id, cid.type]),
    );
    const compositeData: ParentData = {
      ...parentData,
      derivesBlendModeFromChildren: slateComposite.blendMode !== 'normal',
      size: {
        w: slateComposite.width,
        h: slateComposite.height,
      },
      duration: {
        t0: slateComposite.tStart,
        t1: slateComposite.tEnd,
      },
      absoluteAngle: parentData.absoluteAngle + slateComposite.angle,
    };
    composite.components.forEach((comp, idx) => {
      const compType = parsableChildren[comp.id];
      if (!compType) return null;

      // slate registry never deletes component on replace
      // so the componen will be cached in _registry.image with same id
      // and return old component
      const type = comp.type;
      if (type === 'video') {
        if (this._refRegistry.image[comp.id]) {
          delete this._refRegistry.image[comp.id];
        }
      } else if (type === 'image') {
        if (this._refRegistry.video[comp.id]) {
          delete this._refRegistry.video[comp.id];
        }
      }

      switch (compType) {
        case 'image':
          this._parseShadowToSlateWatermark(comp as Shadow.ImageJSON, compositeData);
          const defaultUploadLogo =
            composite.sub_type === 'placeholder_logo' && !isUserDesignerOrCreator();
          return this.parseIfNeeded(
            'image',
            comp,
            () => parseImage(comp as Shadow.ImageJSON, compositeData, optimize, defaultUploadLogo),
            JSON.stringify(compositeData),
          );
        case 'video': {
          const shadowVideoJSON = comp as Shadow.VideoJSON;
          const audioBlock = videoAudioBlocks.find(
            (audioBlock) => shadowVideoJSON.audio_id === audioBlock.id,
          );
          this._parseShadowToSlateWatermark(shadowVideoJSON, compositeData);
          return this.parseIfNeeded(
            'video',
            comp,
            () => parseVideo(shadowVideoJSON, compositeData, optimize, audioBlock),
            `${JSON.stringify(compositeData)}${JSON.stringify(audioBlock)}`,
          );
        }
        case 'paragraph':
          return this.parseShadowToSlateParagraph(comp as Shadow.TextJSON, compositeData);
        case 'composite':
          return this.parseShadowToSlateComposite(
            comp as Shadow.CompositeJSON,
            { ...compositeData, isPositionComposite: true },
            idx,
            videoAudioBlocks,
            optimize,
          );
        default:
          return null;
      }
    });
  }

  private parseShadowToSlateParagraph(paragraph: Shadow.TextJSON, parentData: ParentData) {
    let ref = this._refRegistry.paragraph[paragraph.id];
    if (!ref) {
      // add blank para
      let blankSlatePara = parseParagraph(paragraph, parentData);
      blankSlatePara = {
        ...blankSlatePara,
        ui: { selected: false, forceVisible: false, forceInvisible: false },
      };
      ref = {
        v: Object.assign({}, blankSlatePara),
        t: paragraph.tUpdated,
      };
      this._refRegistry.paragraph[paragraph.id] = ref;
    }
    if (ref.t === paragraph.tUpdated && ref.v.cacheKey) {
      return;
    }

    if (!paragraph.metrics) return;
    let para = parseParagraph(paragraph, parentData);
    para = {
      ...para,
      ui: ref?.v?.ui ?? {
        selected: ref?.v.ui.selected,
        forceVisible: ref?.v.ui.forceVisible,
        forceInvisible: ref?.v.ui.forceInvisible,
      },
    };
    this._refRegistry.paragraph[paragraph.id] = {
      v: Object.assign(ref?.v ?? {}, para),
      t: paragraph.tUpdated,
    };
    this.putSlateParagraph(paragraph.id, para);
    return;
  }

  private parseShadowToSlateAudio(audio: Shadow.AudioBlockJSON, optimize: boolean) {
    this.parseIfNeeded('audio', audio, () => {
      const video = audio.component_ref_id ? this.getSlateVideo(audio.component_ref_id) : null;
      return parseAudio(audio, video, optimize);
    });
  }

  private hasChanged<SlateEntity extends SlateEntities>(
    type: SlateEntity['type'],
    json: Shadow.LayerJSON | Shadow.BlockJSON | Shadow.ComponentJSON | Shadow.AudioBlockJSON,
  ) {
    const ref = this._refRegistry[type][json.id];
    if (!ref || ref.t !== json.tUpdated || !ref.v.cacheKey) {
      return true;
    }

    return false;
  }

  private parse<SlateEntity extends SlateEntities>(
    type: SlateEntity['type'],
    json: Shadow.LayerJSON | Shadow.BlockJSON | Shadow.ComponentJSON | Shadow.AudioBlockJSON,
    parser: () => SlateEntity,
  ): SlateEntity {
    const oldRef = this._refRegistry[type][json.id];
    this._refRegistry[type][json.id] = {
      v: Object.assign(oldRef?.v ?? {}, parser() as any),
      t: json.tUpdated,
    };

    return this._refRegistry[type][json.id].v as SlateEntity;
  }

  private parseIfNeeded<SlateEntity extends SlateEntities>(
    type: SlateEntity['type'],
    json: Shadow.BlockJSON | Shadow.ComponentJSON | Shadow.AudioBlockJSON,
    parser: () => SlateEntity,
    parentIndentifier = '',
  ): SlateEntity {
    const ref = this._refRegistry[type][json.id];
    if (
      !ref || // force break
      ref.t !== json.tUpdated ||
      !ref.v.cacheKey ||
      ref.parentKey !== parentIndentifier
    ) {
      this._refRegistry[type][json.id] = {
        v: Object.assign(ref?.v ?? {}, parser() as any),
        t: json.tUpdated,
        parentKey: parentIndentifier,
      };
    }
    return this._refRegistry[type][json.id].v as SlateEntity;
  }

  private get shadowDimensionJSON() {
    return this._shadowDimensionJSON;
  }

  reset() {
    this._shadowDimensionJSON = null;
    this._refRegistry = {
      root: { layers: [], blocks: [], audioBlocks: [], transitions: [], gaps: [] },
      image: {},
      video: {},
      paragraph: {},
      audio: {},
      composite: {},
      layer: {},
      block: {},
      watermark: {},
      transition: {},
      gap: {},
    };
    this._ongoingAsyncParsers.clear();
    this._errors = [];
  }

  patchSlateParagraphBlendMode(id: string, blendMode: Slate.BlendMode) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { blendMode });
      this.touch(id, time);
    }
  }
  patchDropShadowToComposite(
    id: string,
    dropShadow: {
      blur: number;
      opacity: number;
      distance: number;
      angle: number;
      color: ColorJSON;
    },
  ) {
    const component: SlateRef<Slate.CompositeJSON> = this._refRegistry.composite[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { dropShadow });
      this.touch(id, time);
    }
  }

  patchDropShadowToParagraph(
    id: string,
    dropShadow: {
      blur: number;
      opacity: number;
      distance: number;
      angle: number;
      color: ColorJSON;
    },
  ) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v.style, { textShadow: dropShadow });
      this.touch(id, time);
    }
  }

  patchSlateCompositeBlendMode(id: string, blendMode: Slate.BlendMode) {
    const component: SlateRef<Slate.CompositeJSON> = this._refRegistry.composite[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { blendMode });
      this.touch(id, time);
    }
  }

  patchSlateCompositeFxFilters(id: string, fxFilters: Slate.FxFilterJSON[]) {
    const component: SlateRef<Slate.CompositeJSON> = this._refRegistry.composite[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v, { fxFilters });
      this.touch(id, time);
    }
  }

  patchSlateParagraphOutline(id: string, outline: Slate.Outline) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v.style, { outline });
      this.touch(id, time);
    }
  }

  patchSlateParagraphFontOpacity(id: string, opacity: number) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v.style.defaultTextStyle.color, { a: opacity });
      Object.assign(component.v.style.highlightTextStyle.color, { a: opacity });
      this.touch(id, time);
    }
  }

  patchSlateParagraphBandOpacity(id: string, opacity: number) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v.style.defaultTextStyle.backgroundColor, { a: opacity });
      Object.assign(component.v.style.highlightTextStyle.backgroundColor, { a: opacity });
      this.touch(id, time);
    }
  }

  patchSlateParagraphFontMainColor(id: string, color: Slate.ColorJSON) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      const { r, g, b } = color;
      Object.assign(component.v.style.defaultTextStyle.color, { r, g, b });
      this.touch(id, time);
    }
  }

  patchSlateParagraphFontHighlightColor(id: string, color: Slate.ColorJSON) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      const { r, g, b } = color;
      Object.assign(component.v.style.highlightTextStyle.color, { r, g, b });
      this.touch(id, time);
    }
  }

  patchSlateParagraphBackgroundColor(id: string, color: Slate.ColorJSON) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      const { r, g, b } = color;
      Object.assign(component.v.style.defaultTextStyle.backgroundColor, { r, g, b });
      Object.assign(component.v.style.highlightTextStyle.backgroundColor, { r, g, b });
      this.touch(id, time);
    }
  }

  patchSlateParagraphLineHeight(id: string, lineHeight: number) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v.style.defaultTextStyle, { lineHeight });
      Object.assign(component.v.style.highlightTextStyle, { lineHeight });
      this.touch(id, time);
    }
  }

  patchSlateParagraphCharacterSpacing(id: string, spacing: number) {
    const component: SlateRef<Slate.ParagraphJSON> = this._refRegistry.paragraph[id];
    const time = Date.now();
    if (component) {
      Object.assign(component.v.style.defaultTextStyle, { letterSpacing: spacing });
      Object.assign(component.v.style.highlightTextStyle, { letterSpacing: spacing });
      this.touch(id, time);
    }
  }
}

function isUserDesignerOrCreator(): boolean {
  const userDetails = Store.user.userData;
  if (!userDetails) return false;
  const isUserDesigner = userDetails?.role === 'design';
  const isUserCreator = userDetails?.role === 'creator';
  return isUserDesigner || isUserCreator;
}

export interface TimeBounds {
  tStart: number;
  tEnd: number;
}

export interface TimeRange {
  start: number;
  end: number;
}

export interface TimeCode {
  hours: string;
  minutes: string;
  seconds: string;
  frames: string;
}

export enum TemplateType {
  Blank = 'Blank',
  Storyboard = 'Text to Video',
  Quick = 'Quick Video',
  Stock = 'Stock',
  Storyteller = 'Storyteller'
}

export enum WorkFlowType {
  Blank = 'Blank Canvas',
  Quick = 'Quick Videos',
  Article = 'Article to video',
  Stock = 'Stock media',
  Scratch = 'Start from Scratch',
  QuickVideo = 'Quick Video',
  Storyteller = 'Storyteller'
}

import * as Project from '../project';
import * as Ui from '../ui';
import * as Video from '../video';
import * as User from '../user';
import * as Payment from '../payment';

import {
  applyMiddleware,
  combineReducers,
  configureStore,
  createStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import { from } from 'rxjs';
import { InteropObservable } from 'rxjs/internal/types';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

const reducers = {
  project: Project.slice.reducer,
  ui: Ui.slice.reducer,
  video: Video.slice.reducer,
  user: User.slice.reducer,
  payment: Payment.slice.reducer,
};

export const reduxIsTracing = /reduxTrace=1/.test((window as any).location.href); // TODO: check if we are in development

const options = {
  name: `shellStore (${(window as any).location.host.substring(0, 15)})`,
  maxAge: 36,
  instanceId: 'shell',
  trace: reduxIsTracing,
};
const rootReducer = combineReducers({
  ...reducers,
});

const composeEnhancers = composeWithDevTools(options);
export const globalStore = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      ...getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false,
      }),
    ),
    // other store enhancers if any
  ),
);

export type StoreState = ReturnType<typeof globalStore.getState>;
export type StoreDispatch = typeof globalStore.dispatch;

export const setupStore: (preloadedState?: PreloadedState<StoreState>) => any = (
  preloadedState?: PreloadedState<StoreState>,
) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export const store$ = from(globalStore as unknown as InteropObservable<StoreState>);
export const stores = {
  project$: store$.pipe(
    map((st) => st.project),
    distinctUntilChanged(),
  ),
  video$: store$.pipe(
    map((st) => st.video),
    distinctUntilChanged(),
  ),
  ui$: store$.pipe(
    map((st) => st.ui),
    distinctUntilChanged(),
  ),
  user$: store$.pipe(
    map((st) => st.user),
    distinctUntilChanged(),
  ),
  payment$: store$.pipe(
    map((st) => st.payment),
    distinctUntilChanged(),
  ),
};

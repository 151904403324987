import { Master, Slate } from '../../jsonTypes';
import {
  LayerAudioSubType,
  LayerSubType,
  LayerType,
  LayerVideoSubType,
} from '../../jsonTypes/MasterJSON';
import { TimelineInteractionVariant } from '../../ui/types';
import { cloneJSON } from '../../utils';
import { TimeRange } from '../types';
import {
  isInvisibleBackgroundComposite,
  isLogoPlaceholderComposite,
  isTransitionComposite,
} from './getCompositeType/getCompositeTypes';
import { componentType, timeUtils } from './index';
import uuid from './uuid';

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
export const head = <T>([head]: T[]): T => head;

export const makeLayer = (
  type: LayerType,
  subType: LayerSubType,
  index: number,
  name: string,
  isVisible: boolean,
): Master.LayerJSON => {
  const layer: Master.LayerJSON = {
    id: `.${uuid()}`,
    index,
    name,
    visible: isVisible,
    type: 'layer',
    layer_type: type,
    layer_sub_type: subType,
  };
  return layer;
};

interface StaticLayer {
  type: LayerType;
  subType: LayerSubType;
  index: number;
  visible: boolean;
  locked: boolean;
  name?: string;
}

const staticLayerIds = {
  [LayerVideoSubType.TRANSITION]: 10002,
  [LayerVideoSubType.LOGO_PLACEHOLDER]: 10001,
  [LayerVideoSubType.CANVAS_BACKGROUND]: 1000,
  [LayerAudioSubType.BG_VIDEO]: 999,
  [LayerAudioSubType.VOICE_OVER]: 998,
  [LayerAudioSubType.BG_MUSIC]: 997,
};

const topVideoLayers: StaticLayer[] = [
  {
    type: 'video',
    subType: LayerVideoSubType.TRANSITION,
    index: staticLayerIds[LayerVideoSubType.TRANSITION],
    visible: false,
    locked: false,
  },
  {
    type: 'video',
    subType: LayerVideoSubType.LOGO_PLACEHOLDER,
    index: staticLayerIds[LayerVideoSubType.LOGO_PLACEHOLDER],
    visible: false,
    locked: false,
  },
];
const bottomVideoLayers: StaticLayer[] = [
  {
    type: 'video',
    subType: LayerVideoSubType.CANVAS_BACKGROUND,
    index: staticLayerIds[LayerVideoSubType.CANVAS_BACKGROUND],
    visible: false,
    locked: false,
  },
];
const staticAudioLayers: StaticLayer[] = [
  {
    type: 'audio',
    subType: LayerAudioSubType.BG_VIDEO,
    index: staticLayerIds[LayerAudioSubType.BG_VIDEO],
    visible: false,
    locked: false,
  },
  {
    type: 'audio',
    subType: LayerAudioSubType.VOICE_OVER,
    name: 'A0',
    index: staticLayerIds[LayerAudioSubType.VOICE_OVER],
    visible: true,
    locked: false,
  },
  {
    type: 'audio',
    subType: LayerAudioSubType.BG_MUSIC,
    name: 'A1',
    index: staticLayerIds[LayerAudioSubType.BG_MUSIC],
    visible: true,
    locked: false,
  },
];

const staticLayers: StaticLayer[] = [...topVideoLayers, ...bottomVideoLayers, ...staticAudioLayers];

const createStaticLayers = (dim: Master.DimensionJSON) => {
  staticLayers.forEach((lyr) => {
    const layer = dim.layers.find((l) => l.layer_sub_type === lyr.subType);
    if (layer) return;
    const newLayer = makeLayer(
      lyr.type,
      lyr.subType,
      lyr.index,
      lyr.name || lyr.subType,
      lyr.visible,
    );
    dim.layers.push(newLayer);
  });
};

const createOneEmptyLayers = (dim: Master.DimensionJSON) => {
  createNewVideoLayer(dim);
  createNewAudioLayer(dim);
};

const deleteExtraBgComposites = (dim: Master.DimensionJSON) => {
  // server adds bg composites to masterJSON based on some incorrect logic.
  // These unnecessary bs composites are removed from here
  const deleteBgComposites = (scene: Master.BlockJSON) => {
    let canvasLayersLength = 0;
    do {
      const canvasLayersIndices: number[] = scene.components
        .map((c, index) => {
          if (isInvisibleBackgroundComposite(c)) {
            return index;
          }
          return null;
        })
        .filter(notEmpty);

      canvasLayersLength = canvasLayersIndices.length;
      if (canvasLayersLength > 1) {
        const [toDelete] = canvasLayersIndices;
        scene.components.splice(toDelete, 1);
      }
    } while (canvasLayersLength > 1);
  };
  dim.blocks.forEach(deleteBgComposites);
};

const setStaticLayerIdsToComposites = (dim: Master.DimensionJSON, layers: Master.LayerJSON[]) => {
  const staticLayerIdByType: { [key in LayerSubType]?: string } = {};
  layers.forEach((l) => {
    staticLayerIdByType[l.layer_sub_type] = l.id;
  });

  const setStaticLayerIds = (scene: Master.BlockJSON) => {
    scene.components.forEach((c) => {
      if (c.layer_id && isLayerPresent(dim, c.layer_id)) return;
      if (isInvisibleBackgroundComposite(c)) {
        const id = staticLayerIdByType[LayerVideoSubType.CANVAS_BACKGROUND];
        c.layer_id = id ?? null;
      }
      if (isLogoPlaceholderComposite(c)) {
        const id = staticLayerIdByType[LayerVideoSubType.LOGO_PLACEHOLDER];
        c.layer_id = id ?? null;
      }
      if (isTransitionComposite(c)) {
        const id = staticLayerIdByType[LayerVideoSubType.TRANSITION];
        c.layer_id = id ?? null;
      }
    });
  };
  dim.blocks.forEach(setStaticLayerIds);
};

const getAudioLayerSubType = (audio: Master.AudioBlockJSON): LayerAudioSubType => {
  switch (audio.sub_type) {
    case 'bg_music':
      return LayerAudioSubType.BG_MUSIC;

    case 'bg_video':
      return LayerAudioSubType.BG_VIDEO;

    case 'voice_over':
      return LayerAudioSubType.VOICE_OVER;
    default:
      return LayerAudioSubType.AUDIO;
  }
};

const setLayerIdsToAudioComposites = (dim: Master.DimensionJSON, layers: Master.LayerJSON[]) => {
  const staticLayerIdByType: { [key in LayerSubType]?: string } = {};
  layers.forEach((l) => {
    staticLayerIdByType[l.layer_sub_type] = l.id;
  });

  dim.audio_blocks.forEach((a) => {
    if (a.layer_id && isLayerPresent(dim, a.layer_id)) return;
    const layerSubType = getAudioLayerSubType(a);
    const layerId = staticLayerIdByType[layerSubType];
    if (layerId) {
      a.layer_id = layerId;
    }
  });
};

export const getIndicesBetween = (
  below: number | undefined,
  above: number | undefined,
  n: number,
) => {
  let start: number;
  let step: number;
  if (below !== undefined && above !== undefined) {
    // Put items between
    step = (above - below) / (n + 1);
    start = below + step;
  } else if (below === undefined && above !== undefined) {
    // Put items below (bottom of the list)
    step = above / (n + 1);
    start = step;
  } else if (below !== undefined && above === undefined) {
    // Put items above (top of the list)
    start = below + 1;
    step = 1;
  } else {
    throw Error('Must have either a below or an above.');
  }
  return Array.from(Array(n)).map((_, i) => start + i * step);
};

export const getLayerInsertPosition = (
  layers: Master.LayerJSON[],
  block: Master.BlockJSON,
  id: string,
) => {
  let c = null;
  if (block['components']) {
    for (let i = 0; i < block['components'].length; i++) {
      const composite = block.components[i];
      if (composite.id === id) continue;
      if (
        composite.sub_type &&
        (composite.sub_type === 'pre_video_asset_composite' ||
          composite.sub_type === 'persistent_bg_composite')
      ) {
        c = composite;
      } else if (
        composite.sub_type &&
        composite.sub_type === 'bg_media_composite' &&
        !componentType.isMaskComposite(block['components'][i])
      ) {
        c = composite;
      }
    }
  }
  let belowLayerId = null;
  let aboveLayerId = null;
  if (c && c.layer_id) {
    belowLayerId = c.layer_id;
    const layer = getLayerAbove(layers, belowLayerId, block.components);
    if (layer) {
      aboveLayerId = layer.id;
    }
  }
  return { belowLayerId, aboveLayerId };
};

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export const getCompositesOfLayer = (layerId: string, composites: Master.CompositeJSON[]) => {
  return composites.filter((comp) => comp.layer_id === layerId);
};

const isLayerEmpty = (layerId: string, blockComposites: Master.CompositeJSON[]) => {
  const clips = getCompositesOfLayer(layerId, blockComposites).filter(
    componentType.isVisibleComposite,
  );

  return !clips.length;
};

export const getLayerAbove = (
  _layers: Master.LayerJSON[],
  layerId: string,
  blockComposites: Master.CompositeJSON[],
) => {
  const layers = _layers
    .filter(isPresent)
    .filter((l) => l.layer_type === 'video' && l.visible)
    .filter((l, idx) => {
      if (idx === 0) return true;
      return !isLayerEmpty(l.id, blockComposites);
    })
    .sort((a, b) => a.index - b.index);
  const layer = layers.find((l) => l.id === layerId);
  if (!layer) return null;
  const layerAbove = layers.find((l) => l.index > layer.index);
  if (!layerAbove) return null;
  return layerAbove;
};

export const createVideoLayerBetweenIndices = (
  dim: Master.DimensionJSON,
  below?: string | null,
  above?: string | null,
) => {
  if (!below && !above) {
    console.error('Must have either a below or an above.');
    return;
  }
  const layers = dim.layers;
  const findLayer = (id: string) => layers.find((l) => l.id === id);

  const logoPlaceHolder = layers.find(
    (l) => l.layer_sub_type === Slate.LayerVideoSubType.LOGO_PLACEHOLDER,
  );

  const canvasBG = layers.find(
    (l) => l.layer_sub_type === Slate.LayerVideoSubType.CANVAS_BACKGROUND,
  );

  let belowLayer = findLayer(logoPlaceHolder?.id ?? '');
  let aboveLayer = findLayer(canvasBG?.id ?? '');

  if (below) {
    belowLayer = findLayer(below);
  }

  if (above) {
    aboveLayer = findLayer(above);
  }

  const [index] = getVideoIndicesBetween(belowLayer?.index, aboveLayer?.index, 1);

  const newLayer = makeLayer('video', Slate.LayerVideoSubType.VIDEO, index + 1, '', true);
  const inserIndex = dim.layers.findIndex((l) => l.index < newLayer.index);
  dim.layers.splice(inserIndex, 0, newLayer);
  return newLayer;
};

export const reorderLayersByClipOrder = (
  dim: Master.DimensionJSON,
  blockId: string,
  compositeId: string,
) => {
  const block = dim.blocks.find((b) => b.id === blockId);
  if (!block) return dim;
  const layers = dim.layers;
  const { belowLayerId, aboveLayerId } = getLayerInsertPosition(layers, block, compositeId);
  if (belowLayerId || aboveLayerId) {
    const layer = createVideoLayerBetweenIndices(dim, belowLayerId, aboveLayerId);
    const composite = block.components.find((c) => c.id === compositeId) as Master.CompositeJSON;
    if (composite && layer) composite.layer_id = layer.id;
  }
  return dim;
};

export const getVideoIndicesBetween = (
  below: number | undefined,
  above: number | undefined,
  n: number,
) => {
  return getIndicesBetween(below, above, n);
};

export const getAudioIndicesBetween = (
  below: number | undefined,
  above: number | undefined,
  n: number,
) => {
  return getIndicesBetween(above, below, n);
};

export const sortLayerByIndex = (
  a: Master.LayerJSON | Slate.LayerJSON,
  b: Master.LayerJSON | Slate.LayerJSON,
) => {
  return b.index - a.index;
};

const getCompositeInLayer = (scene: Master.BlockJSON, layerId: string) => {
  return scene.components.filter((c) => {
    return c.layer_id === layerId;
  });
};

const createNewVideoLayer = (dim: Master.DimensionJSON) => {
  const belowIndex = staticLayerIds[LayerVideoSubType.LOGO_PLACEHOLDER];
  let aboveIndex = staticLayerIds[LayerVideoSubType.CANVAS_BACKGROUND];
  const layers = dim.layers.filter((l) => l.layer_type === 'video' && l.visible);
  const lastLayerIndex = head([...layers].sort((a, b) => b.index - a.index))?.index;
  if (lastLayerIndex) {
    aboveIndex = lastLayerIndex;
  }
  const [index] = getVideoIndicesBetween(belowIndex, aboveIndex, 1);
  const name = '';
  const layer = makeLayer('video', LayerVideoSubType.VIDEO, index, name, true);
  dim.layers.push(layer);
  return layer;
};

const createNewAudioLayer = (dim: Master.DimensionJSON) => {
  const audioLayers = dim.layers.filter((l) => l.layer_type === 'audio' && l.visible);
  const layers = audioLayers.sort((a, b) => a.index - b.index);
  let belowIndex = staticLayerIds[LayerVideoSubType.CANVAS_BACKGROUND];
  const lastLayerIndex = head(layers)?.index;
  if (lastLayerIndex) {
    belowIndex = lastLayerIndex;
  }
  const [index] = getAudioIndicesBetween(belowIndex, undefined, 1);
  const name = '';
  const hasMusicLayer = layers.find((l) => l.layer_sub_type === Master.LayerAudioSubType.BG_MUSIC);
  const layer = makeLayer(
    'audio',
    hasMusicLayer ? LayerAudioSubType.AUDIO : LayerAudioSubType.BG_MUSIC,
    index,
    name,
    true,
  );
  dim.layers.push(layer);
  return layer;
};

const getEmptyLayerIdInScene = (dim: Master.DimensionJSON, id: string) => {
  const clipByLayerId: { [key: string]: Master.CompositeJSON[] } = {};
  const layers = dim.layers.filter((l) => l.layer_type === 'video' && l.visible);
  const scene = dim.blocks.find((b) => b.id === id);
  if (!scene) return null;

  layers.forEach((l) => {
    clipByLayerId[l.id] = getCompositeInLayer(scene, l.id);
  });

  const sortedLayers = [...layers].sort((a, b) => b.index - a.index);
  const topEmptyLayers = [];

  for (const layer of sortedLayers) {
    const isEmpty = clipByLayerId[layer.id]?.length === 0;
    if (isEmpty) {
      topEmptyLayers.push(layer);
    } else {
      break;
    }
  }

  const emptyLayer = [...topEmptyLayers]
    .sort((a, b) => a.index - b.index)
    .find((l) => {
      return clipByLayerId[l.id]?.length === 0;
    });

  return emptyLayer?.id || null;
};

const isLayerPresent = (dim: Master.DimensionJSON, id: string) => {
  return !!getLayerById(dim, id);
};

const createVideoCompositeLayers = (dim: Master.DimensionJSON) => {
  dim.blocks.forEach((b) => {
    b.components.forEach((c) => {
      if (c.layer_id && isLayerPresent(dim, c.layer_id)) return;
      const emptyLayerId = getEmptyLayerIdInScene(dim, b.id);
      if (emptyLayerId) {
        c.layer_id = emptyLayerId;
      } else {
        const layer = createNewVideoLayer(dim);
        c.layer_id = layer.id;
      }
    });
  });
};

const deleteLayer = (dim: Master.DimensionJSON, id: string) => {
  dim.layers = dim.layers.filter((l) => l.id !== id);
};

const deleteEmptyLayers = (dim: Master.DimensionJSON) => {
  const clipByLayerId: { [key: string]: string[] } = {};
  const videoLayers = dim.layers.filter((l) => l.layer_type === 'video' && l.visible);
  const audioLayers = dim.layers.filter((l) => l.layer_type === 'audio' && l.visible);

  videoLayers.forEach((l) => {
    clipByLayerId[l.id] = [];
  });

  dim.blocks.forEach((b) => {
    videoLayers.forEach((l) => {
      clipByLayerId[l.id].push(...getCompositeInLayer(b, l.id).map((c) => c.id));
    });
  });
  audioLayers.forEach((l) => {
    clipByLayerId[l.id] = [];
    dim.audio_blocks.forEach((a) => {
      if (a.layer_id === l.id) {
        clipByLayerId[a.layer_id].push(a.id);
      }
    });
  });

  const emptyLayers = [...videoLayers, ...audioLayers]
    .filter((l) => {
      return clipByLayerId[l.id]?.length === 0;
    })
    .map((l) => l.id);
  emptyLayers.forEach((id) => deleteLayer(dim, id));
};

export const reindex = (dim: Master.DimensionJSON) => {
  const videoLayers = getVisibleVideoLayers(dim);
  const belowIndex = staticLayerIds[LayerVideoSubType.LOGO_PLACEHOLDER];
  const aboveIndex = staticLayerIds[LayerVideoSubType.CANVAS_BACKGROUND];
  const layerNo = videoLayers.length;
  const indices = getIndicesBetween(aboveIndex, belowIndex, layerNo);
  videoLayers.forEach((v, i) => {
    v.index = indices[i];
  });
};

const updateLayerName = (dim: Master.DimensionJSON) => {
  const videoLayers = dim.layers.filter((l) => l.layer_type === 'video' && l.visible);
  [...videoLayers]
    .sort((a, b) => a.index - b.index)
    .forEach((l, idx) => {
      l.name = `V${idx}`;
    });

  const audioLayers = dim.layers.filter((l) => l.layer_type === 'audio' && l.visible);
  [...audioLayers]
    .sort((a, b) => b.index - a.index)
    .forEach((l, idx) => {
      l.name = `A${idx}`;
    });
};

const getLayerById = (dim: Master.DimensionJSON, id: string) => {
  return dim.layers.find((l) => l.id === id);
};

export const reorderClipByLayerIndex = (dim: Master.DimensionJSON) => {
  const reorderForScene = (scene: Master.BlockJSON) => {
    const clipByLayerId: { [key: string]: Master.CompositeJSON[] } = {};
    scene.components.forEach((c) => {
      if (!c.layer_id) return null;
      const layer = getLayerById(dim, c.layer_id);
      if (!layer) return null;
      if (!clipByLayerId[layer.id]) {
        clipByLayerId[layer.id] = [];
      }
      clipByLayerId[layer.id].push(c);
    });

    Object.values(clipByLayerId).forEach((clips) => {
      clips.sort((a, b) => b.duration.absolute_start - a.duration.absolute_start);
    });

    const clips = Object.entries(clipByLayerId)
      .sort((a, b) => {
        const alayerId = a[0];
        const blayerId = b[0];
        const alayer = getLayerById(dim, alayerId);
        const blayer = getLayerById(dim, blayerId);
        if (!alayer || !blayer) return -1;
        return alayer.index - blayer.index;
      })
      .reduce<Master.CompositeJSON[]>((acc, a) => {
        const clips = a[1];
        return [...acc, ...clips];
      }, []);

    scene.components = clips;
  };
  dim.blocks.forEach(reorderForScene);
};

export const isGapAvailableInLayer = (
  dim: Master.DimensionJSON,
  layerId: string,
  dur: TimeRange,
) => {
  const layer = dim.layers.find((l) => l.id === layerId);
  if (!layer) return false;
  const clips: Master.CompositeJSON[] = [];
  dim.blocks.forEach((scene) => {
    clips.push(...getCompositeInLayer(scene, layer.id));
  });
  for (const clip of clips) {
    const otherStart = clip.duration.absolute_start;
    const otherEnd = clip.duration.absolute_end;
    const other = { start: otherStart, end: otherEnd };
    if (!timeUtils.emptyTimeRange(dur, other)) {
      return false;
    }
  }
  return true;
};

const getVisibleVideoLayers = (dim: Master.DimensionJSON) => {
  return dim.layers
    .filter((l) => l.layer_type === 'video' && l.visible)
    .sort((a, b) => a.index - b.index);
};

const getVideoClipByLayerId = (dim: Master.DimensionJSON) => {
  const clipByLayerId: { [key: string]: Master.CompositeJSON[] } = {};
  const videoLayers = getVisibleVideoLayers(dim);

  videoLayers.forEach((l) => {
    clipByLayerId[l.id] = [];
  });
  dim.blocks.forEach((b) => {
    videoLayers.forEach((l) => {
      const clips = getCompositeInLayer(b, l.id);
      clipByLayerId[l.id].push(...clips);
    });
  });
  return clipByLayerId;
};

export const getVideoLayerBelowIndex = (dim: Master.DimensionJSON, layerId: string) => {
  const videoLayers = dim.layers.filter((l) => l.layer_type === 'video' && l.visible);

  const layer = dim.layers.find((l) => l.id === layerId);
  if (!layer) return [];
  return videoLayers.filter((l) => l.index < layer.index).sort((a, b) => b.index - a.index);
};

const findLayerWithGap = (
  dim: Master.DimensionJSON,
  layers: Master.LayerJSON[],
  timeRange: TimeRange,
) => {
  let layer: Master.LayerJSON | null = null;
  for (const l of layers) {
    if (isGapAvailableInLayer(dim, l.id, timeRange)) {
      layer = l;
    } else {
      break;
    }
  }
  return layer;
};

export const updateClipLayer = (dim: Master.DimensionJSON, clip: Master.CompositeJSON) => {
  if (!clip.layer_id) return;
  const layersBelow = getVideoLayerBelowIndex(dim, clip.layer_id);
  const timeRange = { start: clip.duration.absolute_start, end: clip.duration.absolute_end };
  const emptyLayer = findLayerWithGap(dim, layersBelow, timeRange);
  if (!emptyLayer) return;
  clip.layer_id = emptyLayer.id;
};

export const packClipsToLayer = (dim: Master.DimensionJSON) => {
  const clipByLayerId = getVideoClipByLayerId(dim);
  const videoLayers = getVisibleVideoLayers(dim);
  videoLayers.forEach((l) => {
    const clips = clipByLayerId[l.id];
    clips.forEach((c) => {
      updateClipLayer(dim, c);
    });
  });
};

export const syncLayers = (
  dim: Master.DimensionJSON,
  interactionVariant: TimelineInteractionVariant,
  reorderComponent = true,
) => {
  const fcp = interactionVariant === TimelineInteractionVariant.B;
  if (!dim.layers) {
    dim.layers = [];
  }
  deleteExtraBgComposites(dim);
  createStaticLayers(dim);
  const invisibleLayers = dim.layers.filter((l) => l.layer_type === 'video' && !l.visible);
  setStaticLayerIdsToComposites(dim, invisibleLayers);
  createVideoCompositeLayers(dim);
  const audioLayers = dim.layers.filter((l) => l.layer_type === 'audio');
  setLayerIdsToAudioComposites(dim, audioLayers);
  deleteEmptyLayers(dim);
  if (fcp) {
    createOneEmptyLayers(dim);
  }
  dim.layers.sort((a, b) => b.index - a.index);
  reindex(dim);
  dim.layers.sort((a, b) => b.index - a.index);
  updateLayerName(dim);
  if (reorderComponent) {
    reorderClipByLayerIndex(dim);
  }
};

export const isClipsOrderByLayerIndex = (dimJSON: Master.DimensionJSON) => {
  const dim = cloneJSON(dimJSON);
  const reorderForScene = (scene: Master.BlockJSON) => {
    const clipByLayerId: { [key: string]: Master.CompositeJSON[] } = {};
    scene.components.forEach((c) => {
      if (!c.layer_id) return null;
      const layer = getLayerById(dim, c.layer_id);
      if (!layer) return null;
      if (!clipByLayerId[layer.id]) {
        clipByLayerId[layer.id] = [];
      }
      clipByLayerId[layer.id].push(c);
    });

    Object.values(clipByLayerId).forEach((clips) => {
      clips.sort((a, b) => b.duration.absolute_start - a.duration.absolute_start);
    });

    const clips = Object.entries(clipByLayerId)
      .sort((a, b) => {
        const alayerId = a[0];
        const blayerId = b[0];
        const alayer = getLayerById(dim, alayerId);
        const blayer = getLayerById(dim, blayerId);
        if (!alayer || !blayer) return -1;
        return alayer.index - blayer.index;
      })
      .reduce<Master.CompositeJSON[]>((acc, a) => {
        const clips = a[1];
        return [...acc, ...clips];
      }, []);

    return clips.map((c) => c.id);
  };

  const areArraysEqual = <T>(array1: T[], array2: T[]) => {
    if (array1 === array2) return false;
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; ++i) {
      if (array1[i] !== array2[i]) return false;
    }
    return true;
  };
  if (!dim.layers.length) {
    return true;
  }
  const isOrderedByLayerIndex = dim.blocks.every((s) => {
    const ids = s.components.map((c) => c.id);
    const sortedIds = reorderForScene(s);
    return areArraysEqual(ids, sortedIds);
  });
  return isOrderedByLayerIndex;
};

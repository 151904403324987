import {
  AnimationJSON,
  CompositeJSON,
  DurationJSON,
  FilterProperties,
  ImageJSON,
  InputJSON,
  LayoutJSON,
  PositionJSON,
  VideoJSON,
} from '../jsonTypes/MasterJSON';

export enum KeyboardContext {
  APP = 'app',
  SLATE = 'slate',
  TIMELINE = 'timeline',
  PANELS = 'panels',
  TRIM_MODAL = 'trim-modal',
  CROP_MODAL = 'crop-modal',
  MEDIA_MASK_MODAL = 'media-mask-modal',
  TEXT_INPUT = 'text-input',
  STORYBOARD = 'storyboard',
  FULLSCREEN_PREVIEW = 'fullscreen-preview',
}

export type Mode = 'normal' | 'expensive';

export interface ApplyAllJSON {
  type: 'font' | 'duplicate' | null;
  status: 'none' | 'in-progress';
}

export type Module =
  | 'slate'
  | 'header'
  | 'timeline'
  | 'leftPanel'
  | 'topPanel'
  | 'rightPanel'
  | 'slateOverlays'
  | 'editor'
  | 'storyboard'
  | 'storyboardScaffold'
  | 'storyboardEditor'
  | 'ivstoryboard'
  | 'ivpanels';

export type ConfirmationState = 'invisible' | 'visible' | 'confirm' | 'deny';

export type TransitionState = {
  selectedTransitionIndex: number | null;
  transitions: Transition[];
  transitionData: any;
  transitionComposites: TransitionCompositesState;
  transitionNames: any;
  defaultTransition: DefaultTransitionState | null;
  addTransition: {
    status: boolean;
    transitionID: number | null;
    transitionIndex: number | null;
    addToAll: boolean;
    source?: 'addTemplateTransition';
    time?: number;
    properties?: FilterProperties;
    transitionColors?: any;
  };
  removeTransition: {
    status: boolean;
    transitionIndex: number | null;
  };
};

export interface TransitionHistoryEntry {
  properties: FilterProperties;
}

export interface TransitionHistory {
  [key: string]: TransitionHistoryEntry;
}

export interface Transition {
  transition_id: number;
  name: string;
  type: 'animation' | 'overlay' | 'filter';
  url: string | null;
  preview_url: string;
  delay: number;
  animation_in: string | null;
  animation_out: string | null;
  in_effect_factor: number | null;
  out_effect_factor: number | null;
  dimensions: string;
  blend_effect: string | null;
  steps: StepJSON;
  color_correction: string | null;
  sub_type: string;
  fit_type: string | null;
  duration: number | null;
  frame_time: number | null;
}

export type TransitionCompositesState = {
  composite: CompositeJSON | null;
  defaultConfig: DefaultTransitionConfig | null;
  imageComponent: ImageJSON | null;
  videoComponent: VideoJSON | null;
};

export type DefaultTransitionState =
  | OverlayTransitionState
  | AnimationTransitionState
  | MaskTransitionState;

export interface OverlayTransitionState {
  type: 'overlay';
  url: string;
}

export interface AnimationTransitionState {
  type: 'animation';
  animation_in: string;
  animation_out: string;
  in_effect_factor: number;
}

export interface StepJSON {
  start_time: number;
  end_time: number;
  delta: number;
  bezier_curve: string;
}

export interface MaskTransitionState {
  type: 'mask';
  animation_in: string;
  url: string;
  steps: StepJSON;
  in_effect_factor: number;
}

export interface AnimationResponse {
  label: string;
  key: string;
  id: number;
  type: 'IN' | 'OUT' | 'IN_PLACE';
  template_id: number;
  category: string;
  sub_type?: string;
  animation: number;
  transition: number;
  json: {
    animation_time: number;
    overflow: boolean;
    animation_type: string[];
    properties: any[];
    reverse: boolean;
    animationBezier: boolean;
  };
}

export interface RepaintState {
  needsRepaint: boolean;
  repaintType: RepaintType;
  showLoader: boolean;
  componentRepaintId: string | null;
}

export enum PageLoaderType {
  EDITOR = 'editor',
  STORYBOARD = 'storyboard',
  EXPORT = 'export',
  OUTPUT = 'output',
  AUTO = 'auto',
}

export enum PageLoaderSubType {
  ERROR = 'error',
  DEFAULT = 'default',
  SHIMMER = 'shimmer',
  IVA_INTRO = 'iva-intro',
  LIHP_LOADER = 'lihp-loader',
}

export enum ProjectAutoSaveStatus {
  SAVED = 'saved',
  SAVING = 'saving',
  PENDING = 'pending',
}

export type DraggedElement = { type: 'media'; media: DraggedMedia };
export type DraggedElementJSON = { type: 'media'; media: DraggedMediaJSON };

export type DraggedMedia = DraggedIvMedia | DraggedUploadedMedia | DraggedStoryboardMedia;
export type DraggedMediaJSON = DraggedIvMediaJSON | DraggedUploadedMediaJSON;

export interface DraggedIvMedia extends DraggedIvMediaJSON {
  draggedElement: HTMLElement;
  droppedTo?: DropSource;
  dropElementId?: string;
  onDragCompleted?: (cancelled: boolean) => void;
}

export interface DraggedStoryboardMedia extends DraggedIvMedia {
  childComponentId?: string;
  action: 'replace' | 'append';
}

export interface DraggedIvMediaJSON {
  sourceComponent: string | 'imagebox' | 'uploads' | 'uploadbox' | 'elementbox';
  mediaType: string | 'image' | 'video' | 'audio';
  resultIndex: number;
  componentType: string;
  defaultAction?: string;
  isLogo?: boolean;
  sceneThumbnail?: SceneThumbnail;
  draggedFrom: DraggedFromSources;
  dragAction: DragAction;
  componentID?: string;
}

export interface UploadedMedia {
  id: number;
  filename: string;
  url: string;
  width: number;
  height: number;
  media_type: string;
  thumbnail_url: string;
  media_url: string;
  type: string;
  duration?: number;
  audioPresent?: boolean;
  preview_video_link?: string;
}

export interface DraggedUploadedMedia extends DraggedUploadedMediaJSON {
  draggedElement: HTMLElement;
  droppedTo?: DropSource;
  dropElementId?: string;
  onDragCompleted?: (cancelled: boolean) => void;
}

export interface DraggedUploadedMediaJSON {
  id: string;
  mediaType: string | 'image' | 'video' | 'audio';
  defaultAction?: string;
  status: string;
  sourceComponent?: string;
  isLogo?: boolean;
  sceneThumbnail?: SceneThumbnail;
  draggedFrom: DraggedFromSources;
  dragAction: DragAction;
  componentID?: string;
}

export enum DraggedFromSources {
  image = 'image',
  logo = 'logo',
  mask = 'mask',
  music = 'music',
  mymedia = 'mymedia',
  overlay = 'overlay',
  shape = 'shape',
  sticker = 'sticker',
  stockmedia = 'stockmedia',
  templates = 'templates',
  text = 'text',
  uploads = 'uploads',
  video = 'video',
}

export enum AddedIn {
  existing = 'Existing Scene',
  new = 'New Scene',
}

export enum DropSource {
  basicTimeline = 'Basic Timeline',
  advancedTimeline = 'Advanced Timeline',
  canvas = 'Canvas',
  storyBoard = 'Storyboard',
  mediaEar = 'Media Ear',
}

export enum DragAction {
  drag = 'Drag & Drop',
  click = 'Click',
}

export enum IsSelectedScene {
  True = 'Selected',
  False = 'Not Selected',
}

export enum AddedScenePosition {
  First = 'First',
  Middle = 'Middle',
  Last = 'Last',
}

export enum AdvancedEditorTypes {
  Blank = 'Blank Canvas',
  Storyboard = 'Text to Video',
  Quick = 'Quick Video',
}

export interface DropMetaData {
  'Mouse Action': DragAction;
  'Added In': AddedIn;
  'Selected Scene': IsSelectedScene;
  'Advanced Editor': AdvancedEditorTypes;
  Drop?: DropSource;
  'Added Scene Position'?: AddedScenePosition;
}

export enum UploadSource {
  LEFT_PANEL = 'left-panel',
  FONTS_RIGHT_PANEL = 'fonts-right-panel',
  LOGO = 'logo',
  HORIZONTAL_END_CLIP = 'horizontal-end-clip',
  VERTICLE_END_CLIP = 'vertical-end-clip',
  SQUARE_END_CLIP = 'square-end-clip',
  RECORDER = 'recorder',
  VOICE_OVER_DROPDOWN = 'voice-over-dropdown',
  UPLOADS = 'Uploads',
  FOLDERS = 'Folders',
  LOGO_LEFT_PANEL = 'logo-left-panel',
  SnapshotUploader = 'snapshot-uploader',
  TIMELINE_UPLOAD_YOUR_VO = 'timeline-upload-your-vo',
  TIMELINE_IMPORT_VO = 'timeline-import-vo',
  TIMELINE_RECORD_VO = 'timeline-record-vo',
  GLOBAL_UPLOAD_BUTTON = 'global-upload-icon',
}
export interface Folder {
  account_id?: number;
  folder_id: number;
  folder_name: string;
  user_id?: number;
}

export enum UploadFileTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  ALL = 'all',
  FONT = 'font',
  GIF = 'gif',
}

export type UploadPayload = {
  files: File[] | null;
  source: UploadSource | null;
  folder: Folder | Partial<Folder> | null;
  allowedFileTypes: UploadFileTypes[] | null;
  blobUrl?: string;
  activeLeftPanelTab?: ActiveTab;
};

export type RecentUploadStatus = 'success' | 'processed';

export type RecentUpload = {
  blobUrl: string;
  uploadedUrl: string;
  type: string;
  status: RecentUploadStatus;
};

export enum VideoTrimModalOpenLocation {
  MY_MEDIA_PLUS_BUTTON = 'MY_MEDIA_PLUS_BUTTON', // done
  MY_MEDIA_DOUBLE_CLICK = 'MY_MEDIA_DOUBLE_CLICK', // done
  STOCK_PANEL_PLUS_BUTTON = 'STOCK_PANEL_PLUS_BUTTON', // done
  STOCK_PANEL_DOUBLE_CLICK = 'STOCK_PANEL_DOUBLE_CLICK', // done
  STORYBOARD_REPLACE = 'STORYBOARD_REPLACE', // done
  STORYBOARD_APPEND = 'STORYBOARD_APPEND', // done
  REPLACE = 'REPLACE', // done
  ADD_AS_LAYER = 'ADD_AS_LAYER', // done
  DND_NON_REPLACEABLE_COMPOSITE = 'DND_NON_REPLACEABLE_COMPOSITE', // done
  DND_REEL_TIMELINE = 'DND_REEL_TIMELINE', // done
  DND_SCENE_TIMELINE = 'DND_SCENE_TIMELINE', // done
  DND_ADD_SCENE_BUTTON = 'DND_ADD_SCENE_BUTTON',
  TOP_PANEL = 'TOP_PANEL',
}

export enum VideoTrimModalCloseLocation {
  CLOSE_BUTTON = 'CLOSE_BUTTON',
  ADD_BUTTON = 'ADD_BUTTON',
  TIMEOUT_EXCCEDED = 'TIMEOUT_EXCCEDED',
}

interface PreviewStoryMedia {
  preview_url: string;
  thumbnail_url: string;
  website_thumbnail_url: string;
}

export interface TemplateStockImage {
  image_id: string;
  media_type: string;
  image_type: string;
  url: string;
  source: string;
  partner_image_id: string;
  width: number;
  height: number;
  word: string;
  search_license: string;
  image_url: string;
  thumbnail_url: string;
  image_link: string;
  api_type: string;
}

export interface TemplateStockVideo {
  preview_link: string;
  thumbnail_link: string;
  video_link: string;
  source: string;
  partner_video_id: string;
  video_id: string;
  metadata: {
    premium: boolean;
  };
  search_term: string;
  video_format: string;
  media_type: string;
  url: string;
  description: string;
  tags: string;
  width: number;
  height: number;
  aspect: number;
  duration: number;
  aspect_ratio: string;
  frame_rate: number;
  api_type: string;
}
export interface CurrentTemplate {
  name: string;
  creator_id: number;
  creator_name?: string;
  description?: string;
  dimensions: string[];
  is_exclusive: number;
  preview_list: {
    story: {
      '16:9': PreviewStoryMedia;
      default: PreviewStoryMedia;
    };
  };
  preview_url: string;
  thumbnail_url?: string;
  id: number;
  template_type: string;
  config: {
    workflow: {
      listicle: boolean;
      story: boolean;
    };
    enable_one_click?: boolean;
    account_id: number;
    overwrite_brand_properties: number;
  };
  category?: string;
  created_at?: string;
  priority?: number;
  new?: boolean;
  paid: boolean;
  creator: {
    first_name: string;
    last_name: string;
    id: number;
    landing_page_url: string;
    profile_thumbnail_url: string;
  };
  price: number;
  is_paid_template?: boolean;
  score?: number;
  is_favourite_template?: boolean;
  dimension_favourite_mapping?: unknown;
  stock_media: TemplateStockImage | TemplateStockVideo;
}

export interface StockVideoTemplateInfo {
  templateItemWidth: number;
  dummyTemplateObject: unknown;
  stockVideoTemplateObjectArray: unknown[];
  traysResponse: {
    trays: unknown[];
    total_trays: number;
  };
  stockVideoOffset: number;
  makeRequest: boolean;
  apisVideoCount: unknown;
  videoSearchSubscription: unknown;
  searchTerm: string;
  totalTrays: number;
  currentTemplate: CurrentTemplate;
  stockVideoTemplatesFound: boolean;
  templateRequested: boolean;
  selectedDimension: string;
  videosCount: number;
}

export interface TrimModalData {
  blob_src: string;
  poster: string;
  file: {
    name: string;
    originalName: string;
    size: string;
  };
  source: string;
  locked: boolean;
  media: any;
  type: 'image' | 'video';
  thumbnail_url: string;
  differencePercentage: number;
  purpose: string;
  uploadsTab: string | undefined | null;
  playbackRate: number;
  dropCompositeId?: string;
  dropAddSceneButtonIndex?: number;
}
export enum RepaintComponentType {
  LITE = 'lite',
  FULL = 'full',
  STORYBOARD = 'storyboard',
}

export enum TimelineMediaAddType {
  DEFAULT = 'default',
  LIST = 'list',
}

export interface StoryboardSceneBGMediaPayload {
  action: 'add' | 'replace';
  component_id: string;
}

export interface ZoomLevelData {
  fitsDuration: number;
  timeFrequency: number;
  pixelsPerSecond: number;
  bezierZoomIn?: string;
  bezierZoomOut?: string;
  percentage?: string;
}

export enum UndoRedoSource {
  TOP_PANEL = 'Top Panel',
  KEYBOARD = 'Keyboard',
  TEXT_EDITOR = 'Lexical Text Editor',
}

export type InteractionMode = 'normal' | 'dragging' | 'resizing' | 'scene-resize' | 'scene-drag';

export type InteractionBehavior =
  | 'overwrite'
  | 'insert-push'
  | 'insert-trim'
  | 'add-layer'
  | 'invalid';

export interface TimelineInteraction {
  mode: InteractionMode;
  clipId: string | null;
}

export enum TimelineType {
  PROJECT = 'Project',
  SCENE = 'Scene',
  REEL = 'Reel',
}

export enum ResizeType {
  COMPOSITE = 'Composite',
  SCENE = 'Scene',
  AUTO = 'Auto', // programatically triggered
}

export enum RepaintType {
  BLOCK = 'block',
  SELECTED_COMPONENT = 'selected-component',
  COMPONENT = 'component',
  DURATION_ONLY = 'duration-only',
  TEXT_ONLY = 'text-only',
  WINDOW_RESIZE = 'window-resize',
}

export interface CopyPasteState {
  paste: boolean;
}

export interface CopyPasteAction {
  copy: boolean;
  paste: boolean;
  source?: string;
}

export interface CopyPaste {
  // currently used in top panel global properties
  // ui state - whether to enable copy & paste
  state: CopyPasteState;
  // actions - triggers copy / paste in the editor module
  action: CopyPasteAction;
}

export enum StoryboardNavigationRoute {
  NONE = 'NONE',
  ADVANCE_EDITOR = 'ADVANCE_EDITOR',
  STORYBOARD = 'STORYBOARD',
}

export enum EditorNavigationRoute {
  NONE = 'none',
  REVIEW = 'review',
}

export interface StoryboardNavigationData {
  route: StoryboardNavigationRoute;
  optionalQueryParams: { [key: string]: string };
}

export enum TimelineVariant {
  TIMELINE = 'TIMELINE',
  IV_TIMELINE = 'IV_TIMELINE',
  NONE = 'NONE',
}

// export enum LeftPanelVariant {
//   PANELS = 'PANELS',
//   IV_LEFT_PANELS = 'IV_LEFT_PANELS',
//   NONE = 'NONE',
// }

export enum TemplatesVariant {
  LEGACY = 'LEGACY',
  IV_TEMPLATES = 'IV_TEMPLATES',
  NONE = 'NONE',
}

export enum MyMediaVariant {
  LEGACY = 'LEGACY',
  IV_MY_MEDIA = 'IV_MY_MEDIA',
  NONE = 'NONE',
}

export enum StockVariant {
  LEGACY = 'LEGACY',
  IV_STOCK = 'IV_STOCK',
  NONE = 'NONE',
}

export enum MusicVariant {
  LEGACY = 'LEGACY',
  IV_MUSIC = 'IV_MUSIC',
  NONE = 'NONE',
}

export enum TextVariant {
  LEGACY = 'LEGACY',
  IV_TEXT = 'IV_TEXT',
  NONE = 'NONE',
}

export enum LogoVariant {
  LEGACY = 'LEGACY',
  IV_LOGO = 'IV_LOGO',
  NONE = 'NONE',
}

export enum ColorVariant {
  LEGACY = 'LEGACY',
  IV_COLOR = 'IV_COLOR',
  NONE = 'NONE',
}

export enum ElementsVariant {
  LEGACY = 'LEGACY',
  IV_ELEMENTS = 'IV_ELEMENTS',
  NONE = 'NONE',
}

export enum CollageVariant {
  LEGACY = 'LEGACY',
  IV_COLLAGE = 'IV_COLLAGE',
  NONE = 'NONE',
}

export enum SocialVariant {
  LEGACY = 'LEGACY',
  IV_SOCIAL = 'IV_SOCIAL',
  NONE = 'NONE',
}

export enum TimelineInteractionVariant {
  A,
  B,
}

export enum ResizeEdge {
  Left = 'Left',
  Right = 'Right',
}

export enum VIDEO_LOOP {
  OFF = 0,
  ON = 1,
}

export enum LayeredTimelineVariant {
  A,
  B,
  C,
}

export enum LogoPlaceholderModalAction {
  SELECTED_SCENE = 'SELECTED_SCENE',
  ALL_SCENE = 'ALL_SCENE',
  NONE = 'NONE',
}

export interface LogoPlaceholderModalPayload {
  componentId: string | null;
  isVisible: boolean;
  action?: LogoPlaceholderModalAction | null;
}

export enum FontLoadingStatus {
  NOT_LOADED = 'not-loaded',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export interface FontModel {
  id: string;
  path: string;
  family: string;
  familyPreviewUrl: string;
  languages: Array<string>;
  type: FontType;
  random_id: string;
  class?: string;
  filename?: string;
  name?: string;
  modifiedFamily?: string;
}

export enum FontType {
  UPLOADED_FONTS = 'Uploaded Fonts',
  INVIDEO_FONTS = 'Invideo Fonts',
}

export enum EditorLoadingStatus {
  NOT_LOADED = 'not-loaded',
  LOADING = 'loading',
  LOADED = 'loaded',
}

export interface DefaultTransitionConfig {
  transition_asset: {
    possible_types: 'video';
    url: string;
    layout: LayoutJSON;
    animation?: AnimationJSON;
    duration?: DurationJSON;
  };
}
export interface TransitionComposite {
  name: 'video_transition';
  type: 'composite';
  sub_type: 'transition_composite';
  id: string;
  components: [];
  duration: DurationJSON;
  position: PositionJSON;
  layout: LayoutJSON;
  input: InputJSON;
  animation: AnimationJSON;
  screen_edit: number;
  screen_click: number;
}
export enum ActiveTab {
  Script = 'script',
  Videos = 'videos',
  Images = 'images',
  Templates = 'templates',
  Music = 'music',
  Text = 'text',
  Logo = 'logo',
  Colors = 'colors',
  Folders = 'folders',
  SocialPost = 'social-post',
  Layers = 'layers',
  Enhancers = 'enhancers',
  DesignElements = 'designElements',
  BrandPresets = 'brandPresets',
  Collage = 'collage',
  MyMedia = 'MyMedia',
  Brand = 'brand',
  StockMedia = 'StockMedia',
  Elements = 'Elements',
  More = 'More',
}

export enum ActiveSection { // used to represent which sub section is active inside a panel
  Logo = 'logo',
  Colors = 'colors',
  Collage = 'collage',
  Social = 'social',
}

export enum LeftPanelActionSource {
  LeftPanel = 'leftPanel',
  StoryboardV2 = 'storyboardV2',
  TIMELINE_IMPORT_VO = 'TIMELINE_IMPORT_VO',
  TIMELINE_UPLOAD_VO = 'TIMELINE_UPLOAD_VO',
  ADD_SCENE_POPUP = 'ADD_SCENE_POPUP',
}

export interface AnalyticsState {
  type: AnalyticsTypes;
  action: AnalyticsActions;
  payload: AnalyticsPayload;
}
export type AnalyticsTypes = 'TEMPLATES';
export type AnalyticsActions = 'ADD_SCENE';
export type AnalyticsPayload = AddSceneAnalyticsPayload;
export enum IAddTemplateSource {
  Timeline = 'timeline',
  LeftPanel = 'leftPanel',
  LeftPanelThumbnail = 'leftPanelThumbnail',
  LeftPanelViewAllScenes = 'leftPanelViewAllScenes',
  BottomPanel = 'bottomPanel',
  OtherTemplatesButton = 'otherTemplatesButton',
  SceneOptionsTimeline = 'sceneOptionsTimeline',
  AddScenePopup = 'addScenePopup',
}
export interface AddSceneAnalyticsPayload {
  source: IAddTemplateSource;
  sceneType: 'Blank' | 'From Templates';
}

export interface SceneThumbnail {
  id: string;
  componentJSON: any;
  screenType: string;
  spanJSON?: any;
  position?: number;
  canRemove?: boolean;
  canDuplicate?: boolean;
  selectedTimeInSeconds?: number;
  defaultAspectRatio?: string;
}

export interface SceneLimitModal {
  visible: boolean;
  readonly limit: number;
  alerted?: boolean;
}

export interface RearrangeModal {
  visible: boolean;
  alerted?: boolean;
  rearrange?: boolean;
}

export interface TimelinePrompts {
  extendScene: boolean;
}

export enum ScrollIntentType {
  SEEKER_START = 'seeker-start',
  SEEKER_MID = 'seeker-mid',
}

export enum ScrollBehavior {
  AUTO = 'auto',
  SMOOTH = 'smooth',
}
export interface TimelineScrollIntent {
  type: ScrollIntentType | 'none';
  time: number | null; // stores the time of the last action
  behavior: ScrollBehavior;
  forced?: boolean;
}

export interface TimelineTypeIntent {
  type: TimelineType;
}

export interface RouterEvent {
  path: string;
  queryParams?: Record<string, string>;
}

export interface TransitionColors {
  primary?: number[];
  secondary?: number[];
  tertiary?: number[];
}

export interface LihpPostProcessParams {
  type: 'signup' | 'login' | null;
  redirectUrl: string | null;
}

export interface PaymentModalParams {
  page: 'lihp' | null; // add more possible pages here as and when needed
  heading: string | null;
}

export enum MediaProcessingTranscodingType {
  vod = 'vod',
  frame = 'frame',
  metadata = 'metadata',
}

export type MediaProcessingJobType = 'VIDEO_TRANSCODE' | 'BG_MATTED_VIDEO_TRANSCODE';
export type MediaProcessingJobProvider = 'FFMPEG' | 'AWS_MEDIA_CONVERT';

export type MediaProcessingStorageType = 'AWS_S3';
export interface MediaProcessingS3Storage {
  key: string;
  bucket: string;
  region: string;
}

export interface MediaProcessingJobStorage {
  type: MediaProcessingStorageType;
  location: MediaProcessingS3Storage;
}

export type MediaProcessingJobDestination = {
  [key in MediaProcessingTranscodingType]?: MediaProcessingJobStorage;
};

export interface MediaProcessingJob {
  type: MediaProcessingJobType;
  provider: MediaProcessingJobProvider;
  source: MediaProcessingJobStorage;
  destination: MediaProcessingJobDestination;
  preset_id: string;
}

export interface ProxyLocation {
  config: MediaProcessingJobStorage;
  url: string;
}

export type ProxyDataDestination = {
  [key in MediaProcessingTranscodingType]?: ProxyLocation;
};

export interface ProxyJobData {
  id: string;
  source: ProxyLocation;
  destination: ProxyDataDestination;
}

export interface ProxyJobResult {
  preview: ProxyJobData;
  keyframe: ProxyJobData;
}

export type REMOVE_VIDEO_BG_STATUS =
  | 'INIT'
  | 'VIDEO_MATTING'
  | 'VIDEO_TRANSCODING'
  | 'FAILED'
  | 'NOT_PICKED'
  | 'FINISHED';

export enum VALID_DROP_LOCATIONS {
  REPLACEABLE_COMPOSITE = 'REPLACEABLE_COMPOSITE',
  NON_REPLACEABLE_COMPOSITE = 'NON_REPLACEABLE_COMPOSITE',
  BLACK_CANVAS_OVERLAY = 'BLACK_CANVAS_OVERLAY',
  SCENE_TIMELINE = 'SCENE_TIMELINE',
  REEL_TIMELINE = 'REEL_TIMELINE',
  ADD_SCENE_BUTTON = 'ADD_SCENE_BUTTON',
  LOGO_PLACEHOLDER = 'LOGO_PLACEHOLDER',
  MEDIA_EAR = 'MEDIA_EAR',
  UNKNOWN = 'UNKNOWN',
}

export enum LeftPanelViewType {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum DragStatus {
  INITIAL = 'INITIAL',
  DRAGGING = 'DRAGGING',
  DROPPED = 'DROPPED',
}
export type LeftPanelMediaFileType = 'video' | 'audio' | 'image' | 'scene' | 'folder' | 'none';
export type DraggableLeftPanelMediaType = Exclude<LeftPanelMediaFileType, 'folder' | 'none'>;

export type DragRequestId = ReturnType<Date['getTime']>;
export type DnDStatus = {
  dragStatus: DragStatus;
  dropLocation: VALID_DROP_LOCATIONS | null;
  hoveredSelectionBoxId: string | null;
  hoveredAddSceneButtonIndex: number | null;
};
export interface SourceMediaDragState {
  isDragging: boolean;
  sourceMediaType: DraggableLeftPanelMediaType | null;
  dropLocation: VALID_DROP_LOCATIONS | null;
  clipId: string | null;
  dragRequestId: DragRequestId | null;
  dndStatus: DnDStatus | null;
}

export enum PaymentConfirmModalMode {
  UPLOAD_LOGO = 'UPLOAD_LOGO',
}

export interface RemoveVideoBgPostProcessRequest {
  original_filename: string;
  filename: string;
  unit: string;
  size: number[];
  dimension: { width: number; height: number };
  type: string;
  url: string;
  urls: string[];
  thumbnail_urls: string[];
  durations: number[];
  audio_present: boolean;
  folder_id?: string;
  media_info: { audioPresent: boolean };
  add_only_to_db: boolean;
}

export type ModalType =
  | 'UPDATE_PASSWORD'
  | 'AUTH'
  | 'CANCEL_RESUME'
  | 'TYPE_FORM'
  | 'LITE_MODAL'
  | 'CONFIRM_CANCEL'
  | 'CONFIRM_LITE_MODAL'
  | 'CONFIRM_PAUSE'
  | 'ALERT'
  | 'PAYMENT_CONFIRMATION'
  | 'OneButtonModal'
  | 'TwoButtonModal'
  | 'TEAM_INVITE'
  | 'PAYMENT_MODAL'
  | 'APP_PROMO_MODAL'
  | 'CHECKOUT_ERROR_MODAL'
  | 'UPDATE_CARD_MODAL'
  | 'IMAGE_CROP_MODAL'
  | 'VIDEO_CROP_MODAL'
  | 'IMAGE_MASK_MODAL'
  | 'VIDEO_MASK_MODAL'
  | 'MEDIA_OPTIONS_MODAL'
  | 'THREE_DS_RECURLY_CONTAINER';

export type InspectorSelectedProperty =
  | null
  | 'Animate'
  | 'Effects'
  | 'FillColor'
  | 'Filters'
  | 'FitType'
  | 'FontSelect'
  | 'LineAndLetterSpacingSelect'
  | 'TextColor'
  | 'TextEdit'
  | 'Volume'
  | 'FillColor'
  | 'TTS'
  | 'Transitions';

export type TrimState = 'cancelled' | 'added';
export type TrimCompletedHandler = (status: TrimState) => void;
export type ConfirmationHandler = (status: 'confirmed' | 'denied') => void;

export interface ConfirmationModalOptions {
  title: string | null;
  body: string | null;
  primaryActionText: string | null;
  secondaryActionText: string | null;
  isSecondaryActionVisible: boolean;
}

export interface AnimationData {
  family: string;
  id: string;
  title: string;
  selected: boolean;
  subList?: AnimationDropDownData[];
  direction?: string;
  json?: any;
  previewUrl?: string;
}

export interface AnimationDropDownData {
  id: string;
  title: string;
  selected: boolean;
  hidden?: boolean;
  subList?: AnimationDropDownData[];
  previewUrl?: string;
  audioPreviewUrl?: string;
}

export interface ImageCropModalProps {
  url: string;
  width: number;
  height: number;
  componentId: string;
  compositeId: string;
}

export interface VideoCropModalProps {
  url: string;
  width: number;
  height: number;
  componentId: string;
  compositeId: string;
}

export interface ImageMaskModalProps {
  maskId: string;
  imageUrl: string;
  maskUrl: string;
  componentId: string;
  compositeId: string;
}

export enum Panels {
  MY_MEDIA = 'Uploads',
  STOCK_PANEL = 'Stock Panel',
}

export interface DeviceInfo {
  userAgent: string;
  platform: string;
  browserName: string | null;
  browserVersion: string | null;
  osName: string | null;
  osVersion: string | null;
  deviceVendor: string | null;
  deviceModel: string | null;
  deviceRam: number | null;
  deviceCPUCores: number | null;
  deviceType: string | null;
  cpuArchitecture: string | null;
  webglSupported: boolean | null;
  language: string | null;
}

export interface NetworkInfo {
  downLink: number | null;
  effectiveType: string | null;
}

export interface StorageInfo {
  usage: number | null;
  quota: number | null;
  percentage: number | null;
}

export interface AppConfig {
  env: {
    siteId: string;
    environment: 'feature' | 'staging' | 'production';
  };
  site: {
    name: string;
    domain: string;
    hostUrl: string;
    title: string;
    description: string;
    supportEmail: string;
    noReplyEmail: string;
    helpPageUrl: string;
    privacyPageUrl: string;
    termsPageUrl: string;
  };
  brand: {
    logoUrl: string;
    whiteLogoUrl: string;
    monogramUrl: string;
    textLogoUrl: {
      webp: string;
      png: string;
    };
    watermark: {
      horizontal: string;
      vertical: string;
      square: string;
      rectangle: string;
    };
    assets: {
      inviteTeamEmailBanner: string;
      accountActivatedEmailBanner: string;
    };
    blueLogoURL: string;
    greenLogoURL: string;
    purpleLogoURL: string;
    blueMonogramLogoURL: string;
    greenMonogramLogoURL: string;
    purpleMonogramLogoURL: string;
  };
  features: {
    [name: string]: boolean;
  };
  account: {
    referrer: string;
    source: string;
  };
  social: {
    twitterHandle: string;
    twitter: string;
    facebook: string;
    instagram: string;
    youtube: string;
    linkedin: string;
    pinterest: string;
    reviews: boolean;
  };
  payments: {
    watermarkAssetUrl: string;
  };
  experimentIds: {
    [name: string]: string;
  };
  maintenance?: {
    showNotification?: boolean;
    maintenanceStartDate?: string; // Date str, eg. '07-25-2021 4:30 AM GMT+5:30'
  };
  experiments?: {
    moduleFederation: {
      cookie: string;
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivFrame: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivTimeline: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivLayeredTimeline: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivAudio: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivFrameRenderer: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivMediaProcessingService: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivStitcherFrame: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivVMRegion: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
    };
    ivStoryboardV2: {
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      buckets: Array<'A' | 'B' | 'C' | 'D' | 'E'>;
    };
    ivAIBgRemoval: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles?: Array<string>;
      whilelistedEmailDomains?: Array<string>;
      buckets?: Array<'A' | 'B' | 'C' | 'D' | 'E'>;
    };
    ivPanels: {
      accountLowerBound: number;
      ignoredAccounts: Array<number>;
      whitelistedAccounts: Array<number>;
      percentTargetUsers: number;
      percentTargetOldUsers: number;
      whitelistedRoles: Array<string>;
      whilelistedEmailDomains: Array<string>;
    };
  };
  constants: {
    maxFileSizeInMb: {
      legacy: {
        freeUser: number;
        paidUser: number;
      };
      mediaProcessingService: {
        freeUser: number;
        paidUser: number;
      };
    };
  };
}

export interface TemplateOptions {
  template: unknown;
  templateName: unknown;
  templateUsedCount: unknown;
  isFavouriteTemplate: unknown;
  isNewTemplate: unknown;
  availableTemplateDimensions: unknown;
  templateType: unknown;
  selectedTemplateDimension: unknown;
  videoDurationInSeconds: unknown;
  miscOptions: unknown;
  preferredDimension: unknown;
}

export interface TemplateInfo {
  templates: unknown[];
  route: unknown;
  category: unknown;
  templateOptions: TemplateOptions;
  workflow: unknown;
  marketing: unknown;
  favouriteTemplates: unknown[];
  purchasedTemplates: unknown[];
  allFavouriteTemplatesId: unknown[];
  allFavouriteTemplatesMapping: unknown;
  favouriteTemplatesOptions: unknown[];
  purchasedTemplatesOptions: unknown[];
  allTemplates: unknown[];
  currentSelectedTemplateCategory: unknown;
  currentCategoryBasedTemplates: unknown[];
  templatesForDifferentCategories: unknown;
  subscriptions: unknown[];
  selectedTemplateDimension: unknown;
  selectedTemplateDimensionForSaved: string;
  selectedTemplateDimensionForFavourites: string;
  selectedTemplateDimensionForPurchased: string;
  showTemplatesContainerFlag: boolean;
  showSavedTemplatesFlag: boolean;
  showFavouriteTemplatesFlag: boolean;
  showPurchasedTemplatesFlag: boolean;
  availableTemplateDimensions: unknown[];
  allTemplatesSubscription$: unknown;
  favouriteTemplatesCount: number;
  purchasedTemplatesCount: number;
  freePremiumAssetsPrompt: boolean;
  marketingTemplatesCategories$: unknown;
  marketingTemplatesCategories: unknown[];
  favouriteTemplates$: unknown;
  marketingTemplatesCategoriesObjects: unknown[];
  getTemplatesByCategory$: unknown;
  getAllTemplates2$: unknown;
  currentCategoryBasedDimensions: unknown[];
  templatesRuleSet: unknown[];
  templatesRuleSet$: unknown;
  templatesCategoryForRuleSet: unknown[];
  searchBasedTemplates$: unknown;
  searchBasedTemplates: unknown;
  searchTerm: unknown;
  viewPurhcasedTemplatesRequested: boolean;
  storytellingCategories: unknown[];
  favouriteTemplatesForCurrentDimension: unknown[];
  purchasedTemplatesForCurrentDimension: unknown[];
  purchasedTemplates$: unknown;
  currentPageTemplates: unknown[];
  offset: number;
  displayTemplatesOptions: unknown[];
  dimensionSwitched: boolean;
  someTemplatePreviewed: boolean;
  redirectedFromWebsite: boolean;
  ivaIntro: boolean;
  ivaIntroWaitTime: unknown;
  stopIvaPageLoader: boolean;
  ivaIntroDone: boolean;
  isIvaIntro: boolean;
  showLogoUploader: boolean;
  selectedTrayIndex: number;
  selectedTemplateIndex: number;
  stockVideoTemplate: boolean;
  stockVideoTemplateData: { searchTerm: string };
  makeTemplateDimensionRequest: boolean;
  makeTemplateDimensionRequestPreviousDimension: string;
  makeTemplateDimensionRequestDimension: string;
}

export interface DropMetaData {
  'Mouse Action': DragAction;
  'Added In': AddedIn;
  'Selected Scene': IsSelectedScene;
  'Advanced Editor': AdvancedEditorTypes;
  Drop?: DropSource;
  'Added Scene Position'?: AddedScenePosition;
}

export enum AddedIn {
  existing = 'Existing Scene',
  new = 'New Scene',
}

export enum DropSource {
  basicTimeline = 'Basic Timeline',
  advancedTimeline = 'Advanced Timeline',
  canvas = 'Canvas',
  storyBoard = 'Storyboard',
  mediaEar = 'Media Ear',
}

export enum DragAction {
  drag = 'Drag & Drop',
  click = 'Click',
}

export enum IsSelectedScene {
  True = 'Selected',
  False = 'Not Selected',
}

export enum AddedScenePosition {
  First = 'First',
  Middle = 'Middle',
  Last = 'Last',
}

export enum AdvancedEditorTypes {
  Blank = 'Blank Canvas',
  Storyboard = 'Text to Video',
  Quick = 'Quick Video',
}

export type PaymentPlan =
  | 'BUSINESS_WATERMARK'
  | 'FILMR_PRO'
  | 'FREE'
  | 'PROFESSIONAL'
  | 'BUSINESS_TRIAL'
  | 'BUSINESS'
  | 'UNLIMITED'
  | 'PREMIUM';

export type PurchasedMedia = {
  media_count: number;
  media_partner_id: string;
}[];

export interface UserLimit {
  limit: {
    total: number;
    remaining: number;
    postExport: number;
    availableToSpend: number;
  };
  exceededMediaCount: number;
  isFreePassEligible: boolean;
}

export interface Experiments {
  advTimelineExperiment: string;
}

export interface YoutubeCopyrightsInfo {
  isYoutubeWhitelisted: boolean;
  isCopyrightBannerDismissed: boolean;
}

export interface GoogleConnectedInfo {
  isGoogleConnected: boolean;
}

export interface FacebookConnectedInfo {
  isFacebookConnected: boolean;
}

export interface TwitterConnectedInfo {
  isTwitterConnected: boolean;
}

export type UserData = {
  user_id?: string;
  bucket: 'A' | 'B' | 'C' | 'D' | 'E';
  sub_bucket: SubBucket;
  role: string;
  email: string;
  signup_source?: string;
  email_archetype?: string;
  verified_email?: boolean;
  is_odd?: boolean;
  account?: number;
  account_name?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  token_id?: number;
  staging_features?: any;
  assign_check?: number;
  account_details?: AccountData;
  onboardings?: Onboardings;
  is_demo_done?: boolean;
  version?: string;
  created_at?: number;
  phone?: string;
  app_sumo_user?: boolean;
  partner_user?: boolean;
  payment_plan?: string;
  payment_plan_status?: string;
  plan_duration?: string;
  video_count_remaining?: number;
  user_personalisation_data?: UserPersonalisationData;
  export_video_count: number;
  export_project_count: number;
  result?: string;
} | null;

export type AccountData = {
  imageBackgroundColor?: string;
  highlightColor?: string;
  image_url?: string;
  facebook_url?: string;
  instagram_url?: string;
  twitter_url?: string;
  youtube_url?: string;
  account_id: number;
  account_name?: string;
  logo_url?: string;
  default_video?: string;
  guest_user?: boolean;
  end_clip_url?: string;
  vertical_end_clip_url?: string;
  square_end_clip_url?: string;
  one_step_video?: boolean;
  staging_features?: any;
  primary_color?: string;
  secondary_color?: string;
  tertiary_color?: string;
  quaternary_color?: string;
  primary_font?: string;
  secondary_font?: string;
  tertiary_font?: string;
  payment_status?: string;
  payment_plan: string;
  video_count_remaining?: number;
  duration?: string;
  company_id?: number;
  referrer?: string;
  created_at?: number;
  total_videos?: number;
  display_name?: string;
  video_duration_limit?: number;
  total_coupon_used_count?: number;
  media_pack_status?: string;
  media_count_remaining?: number;
  base_media_count?: number;
  media_topup_count_remaining?: number;
  bg_remove_count?: number;
} | null;

export type Onboardings = {
  [key in OnboardingTypes]?: OnboardingData;
};

export enum OnboardingTypes {
  personaSurvey = 'persona-survey',
  guidedOnboarding = 'guided-onboarding',
  satisfactionSurvey = 'satisfaction-survey-v2',
  satisfactionSurveyV3 = 'satisfaction-survey-v3',
}

export interface OnboardingData {
  completed?: boolean | 'force_skipped';
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserPersonalisationData {}

export interface PremiumMediaEvent {
  event: 'add' | 'replace' | 'delete';
  data: {
    mediaJson: any;
    blockIndex: number;
  };
}
export type CardDetails = {
  expiryMonth: number;
  expiryYear: number;
  last4: number;
  brand: string;
};

export interface ColorPalette {
  id: number;
  name: string;
  colorPalette: Array<string>;
}

export enum ExperimentalFeatures {
  IV_FE_REACT = 'iv-fe-react',
  IV_LIHP = 'iv-lihp',
  IV_MY_MEDIA = 'iv-my-media',
  IV_MOBILE_LIHP = 'iv-mobile-lihp',
  IV_VM_DOWNLOAD_PARALLELLY = 'iv-vm-download-parallelly',
  IV_GUIDED_ONBOARDING = 'iv-guided-onboarding',
  IV_LIHP_PERSONALIZATION = 'iv-lihp-personalization',
  IV_STORYBOARD_V2 = 'iv-storyboard-v2',
  IV_EXPORT_PAYWALL = 'iv-export-paywall',
  IV_VM_RENDER_TYPE = 'iv-vm-render-type',
  IV_PERSONA_SURVEY = 'iv-persona-survey',
  IV_VM_ADD_INTERVAL_TIME = 'iv-vm-add-interval-time',
  IV_VM_PREVENT_PRE_DOWNLOAD = 'iv-vm-prevent-pre-download',
  IV_REACT_RIGHT_PANEL_EXPERIMENT = 'iv-react-right-panel-exp',
  IV_VM_DOWNLOAD_MEDIA_TO_TEMP_S3 = 'iv-vm-download-media-to-temp-s3',
  IV_STOCK_VIDEO_MEDIA_PROCESSING = 'iv-stock-video-media-processing',
  IV_RUDDERSTACK_ANALYTICS = 'iv-rudderstack',
}
export type Bucket = 'A' | 'B' | 'C' | 'D' | 'E';
export type Timestamp = string;

type SubBucketNumbers = 0 | 1 | 2 | 3;

export type SubBucket = `${Bucket}${SubBucketNumbers}` | 'E';

export type FeatureConfig = {
  [key in ExperimentalFeatures]: {
    blacklist_accounts: number[];
    new: { [key in Bucket]: Timestamp };
    status: boolean;
    whitelisted_email_domains: string[];
    whitelisted_accounts: number[];
    whitelisted_roles: string[];
    buckets: Array<Bucket>;
    sub_buckets: Array<SubBucket>;
  };
};

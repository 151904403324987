import { Master } from '../jsonTypes';
import {
  isVideoComponent,
  isImageComponent,
} from '../project/utils/getCompositeType/getCompositeTypes';

const isBlobUrl = (url: string) => {
  if (url?.includes('blob:')) {
    return true;
  }
  return false;
};

export function removeCompositesWithBlobUrl(dimensionJSON: Master.DimensionJSON) {
  removeVideoCompositesWithBlobUrl(dimensionJSON);
  removeImageCompositesWithBlobUrl(dimensionJSON);
  removeAudioBlocksWithBlobUrl(dimensionJSON);
}

const removeAudioBlockWithId = (dimensionJSON: Master.DimensionJSON, id: string) => {
  const index = dimensionJSON.audio_blocks.findIndex((a) => a.id === id);
  if (index >= 0) {
    dimensionJSON.audio_blocks.splice(index, 1);
  }
};

const removeAudioBlocksWithBlobUrl = (dimensionJSON: Master.DimensionJSON) => {
  // Purge audio composites
  const audioCompositesWithBlobUrls = dimensionJSON.audio_blocks // force break
    .filter((ab) => {
      if (ab.sub_type === 'bg_video') return false;
      const isUrlPresent = Boolean(ab.url);
      if (!isUrlPresent) return ab;

      return isBlobUrl(ab.url!) ? ab : null;
    });
  audioCompositesWithBlobUrls.forEach((ab) => {
    removeAudioBlockWithId(dimensionJSON, ab.id);
  });
};

const removeVideoCompositesWithBlobUrl = (dimensionJSON: Master.DimensionJSON) => {
  // Purge video composites
  dimensionJSON.blocks.forEach((block) => {
    const composites = block.components;

    const videoCompositesWithBlobUrls = composites // force break
      .filter((composite) => {
        return composite.components.find((c) => isVideoComponent(c) && isBlobUrl(c.url));
      });
    videoCompositesWithBlobUrls.forEach((composite) => {
      const index = block.components.findIndex((c) => composite.id === c.id);
      if (index >= 0) {
        block.components.splice(index, 1);
      }
      const videoComponent = composite.components.find(isVideoComponent);
      videoComponent &&
        videoComponent.audio_id &&
        removeAudioBlockWithId(dimensionJSON, videoComponent.audio_id);
    });
  });
};

const removeImageCompositesWithBlobUrl = (dimensionJSON: Master.DimensionJSON) => {
  // Purge video composites
  dimensionJSON.blocks.forEach((block) => {
    const composites = block.components;

    const imageCompositesWithBlobUrls = composites // force break
      .filter((composite) => {
        return composite.components.find((c) => isImageComponent(c) && isBlobUrl(c.url));
      });

    imageCompositesWithBlobUrls.forEach((composite) => {
      const index = block.components.findIndex((c) => composite.id === c.id);
      if (index >= 0) {
        block.components.splice(index, 1);
      }
    });
  });
};

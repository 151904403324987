import {
  DragStatus,
  DragRequestId,
  SourceMediaDragState,
  DnDStatus,
} from '../../../ui';

const initialDragState: SourceMediaDragState = {
  isDragging: false,
  sourceMediaType: null,
  dropLocation: null,
  clipId: null,
  dragRequestId: null,
  dndStatus: null,
};

export class LeftPanelDrag {
  private static instance: LeftPanelDrag;
  private leftPanelDnDQueue: {
    [key: DragRequestId]: SourceMediaDragState['dndStatus'];
  } = {};

  private _dragState = initialDragState;

  public static get shared(): LeftPanelDrag {
    if (!LeftPanelDrag.instance) {
      LeftPanelDrag.instance = new LeftPanelDrag();
    }
    return LeftPanelDrag.instance;
  }

  public setDragState(state: Partial<SourceMediaDragState>) {
    this._dragState = {
      ...this.getDragState(),
      ...state,
    };

    const { dndStatus, dragRequestId } = this._dragState;
    if (!dragRequestId) {
      throw new Error(`DragRequestId cannot be null`);
    }
    if (!dndStatus) {
      throw new Error(`DndStatus cannot be null`);
    }
    this.leftPanelDnDQueue[dragRequestId] = dndStatus;
  }

  public getDragState(): SourceMediaDragState {
    return this._dragState || initialDragState;
  }

  public resetDragState() {
    this._dragState = initialDragState;
  }

  public getDnDStatusByRequestId(dragRequestId: DragRequestId): DnDStatus {
    const dndStatus = this.leftPanelDnDQueue[dragRequestId];
    if (!dndStatus) {
      const initialDndStatus = {
        dragStatus: DragStatus.INITIAL,
        dropLocation: null,
        hoveredSelectionBoxId: null,
        hoveredAddSceneButtonIndex: null
      };
      return initialDndStatus;
    }
    return dndStatus;
  }
}

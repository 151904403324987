import { BehaviorSubject } from 'rxjs';

export type Stripe = unknown;

export class Libraries {
  private static instance?: Libraries;
  private constructor() {}

  public static get shared(): Libraries {
    if (!Libraries.instance) {
      Libraries.instance = new Libraries();
    }
    return Libraries.instance;
  }

  private _stripe: undefined | null | Stripe = undefined;
  //   private _canvaskit: null | CanvasKit;

  set stripe(value: Stripe | null) {
    if (this._stripe) {
      throw new Error(`Stripe has already been loaded!`);
    }
    this._stripe = value;
    Libraries.shared.stripe$.next(this._stripe);
  }

  get stripe(): Stripe {
    if (!this._stripe) {
      throw new Error(`Stripe accessed before loading!`);
    }
    return this._stripe;
  }

  get hasStripeLoaded(): boolean {
    return !!this._stripe;
  }

  get isStripeLoading(): boolean {
    return this._stripe !== undefined;
  }

  public stripe$ = new BehaviorSubject<Stripe | undefined>(undefined);
}

export class TimelineRender {
  private static instance: TimelineRender;

  private _shouldRender = true;

  public static get shared(): TimelineRender {
    if (!TimelineRender.instance) {
      TimelineRender.instance = new TimelineRender();
    }
    return TimelineRender.instance;
  }

  public get shouldRender(): boolean {
    return this._shouldRender;
  }

  public set shouldRender(value: boolean) {
    this._shouldRender = value;
  }
}

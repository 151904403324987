import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_PLANS } from './constants';
import {
  CouponState,
  Gateway,
  ModalType,
  PaymentModalRouteConfig,
  PaymentModalState,
  PaymentState,
  Plan,
  PricingModalText,
  PricingPlans,
  UserGateWay,
  UserGeoInfo,
} from './types';

export const INITIAL_STATE: PaymentState = {
  pricingPlans: INITIAL_PLANS,
  selectedPlan: null,
  geoInfo: {
    country: '',
    countryCode: '',
    region: '',
    regionName: '',
  },
  countryCode: '',
  gateway: Gateway.none,
  userGateway: '',
  currency: '',
  durationSwitch: true,
  coupon: {
    couponCode: '',
    discountCouponApplied: false,
    couponResponse: null,
    couponType: '',
  },
  receiptData: null,
  checkoutError: null,
  pricingModal: null,
  paymentModalState: {
    modalType: 'NONE',
    isOpen: false,
  },
  paymentSuccessProps: null,
  paymentModalRoute: {
    route: ModalType.none,
    couponCode: '',
  },
};

export const slice = createSlice({
  name: 'paymentState',
  initialState: INITIAL_STATE,
  reducers: {
    updatePricingPlans: (state, action: PayloadAction<PricingPlans>) => {
      state.pricingPlans = action.payload;
    },
    updateSeletedPlan: (state, action: PayloadAction<Plan | null>) => {
      state.selectedPlan = action.payload;
    },
    updateGeoInfo: (state, action: PayloadAction<UserGeoInfo>) => {
      state.geoInfo = action.payload;
    },
    updateCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
    updateGateway: (state, action: PayloadAction<Gateway>) => {
      state.gateway = action.payload;
    },
    updateCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    updateDurationSwitch: (state, action: PayloadAction<boolean>) => {
      state.durationSwitch = action.payload;
    },
    updateCouponState: (state, action: PayloadAction<CouponState>) => {
      state.coupon = action.payload;
    },
    updateCouponCode: (state, action: PayloadAction<string>) => {
      state.coupon.couponCode = action.payload;
    },
    updateDiscountCouponApplied: (state, action: PayloadAction<boolean>) => {
      state.coupon.discountCouponApplied = action.payload;
    },
    updateCouponResponse: (state, action: PayloadAction<Record<string, any>>) => {
      state.coupon.couponResponse = action.payload;
    },
    updateCouponType: (state, action: PayloadAction<string>) => {
      state.coupon.couponType = action.payload;
    },
    updateReceiptData: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.receiptData = action.payload;
    },
    updateCheckoutError: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.checkoutError = action.payload;
    },
    updatePricingModal: (state, action: PayloadAction<PricingModalText | null>) => {
      state.pricingModal = action.payload;
    },
    updatePaymentModalState: (state, action: PayloadAction<PaymentModalState>) => {
      state.paymentModalState = action.payload;
    },
    updatePaymentModalRoute: (state, action: PayloadAction<PaymentModalRouteConfig>) => {
      state.paymentModalRoute = action.payload;
    },
    updateUserGateway: (state, action: PayloadAction<UserGateWay>) => {
      state.userGateway = action.payload;
    },
    updatePaymentSuccessProps: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.paymentSuccessProps = action.payload;
    },
  },
});

import { Master } from '../../jsonTypes';
import { STORYTELLER_TO_STORYBOARD_V2 } from '../constants';

export function toStoryboardV2(id: number): number {
  return STORYTELLER_TO_STORYBOARD_V2[id.toString()] || 21013;
}

export function isStoryteller(id: number) {
  return !!STORYTELLER_TO_STORYBOARD_V2[id.toString()];
}

export async function sanitizeToStoryboardV2({
  baseUrl,
  json,
  templateId,
  dimension,
}: {
  baseUrl: string;
  json: Master.JSON;
  templateId: number;
  dimension: Master.Dimension;
}): Promise<Master.JSON> {
  if (json.hasOwnProperty('template_id')) {
    delete (json as any)['template_id'];
  }
  const id = toStoryboardV2(templateId) ?? templateId;

  json.workflow = 'storyboard-v2';
  json.type = 'storyboard-v2';

  const dimensionJSON = json['16:9'] ?? json['1:1'] ?? json['9:16'];
  if (!dimensionJSON) throw new Error('Dimension not found');

  dimensionJSON.template_id = id;

  dimensionJSON.templateBlocks = await getTemplateBlocks({ baseUrl, templateId: id, dimension });
  return json;
}

function getTemplateBlocks({
  templateId,
  dimension,
  baseUrl,
}: {
  templateId: number;
  dimension: Master.Dimension;
  baseUrl: string;
}): Promise<Array<Master.BlockJSON>> {
  const url = `${baseUrl}/v3/get_master_json_from_template`;
  const headers = { authToken: localStorage.getItem('authToken') ?? '' };
  const request = {
    template_id: templateId,
    dimensions: [dimension],
    no_update: 1,
    do_not_update: true,
  };
  return fetch(url, { headers, method: 'POST', body: JSON.stringify(request) })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('could not get masterjson');
      }
    })
    .then((data: Master.JSON) => {
      return data[dimension]?.templateBlocks ?? [];
    })
    .catch(() => []);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Master, Shadow, Template } from '../jsonTypes';
import { DimensionJSON } from '../jsonTypes/MasterJSON';
import { cloneJSON, isPresent } from '../utils';
import {
  NULL_CLIP_ID,
  NULL_COMPONENT_ID,
  NULL_DIMENSION,
  NULL_DURATION,
  NULL_GAP_ID,
  NULL_VIDEO,
  orderedDimensions,
} from './constants';
import * as Registry from './masterRegistry';
import * as ShadowRegistry from './shadowRegistry';
import * as SlateRegistry from './slateRegistry';
import { TemplateType } from './types';
import { getCompositeType } from './utils/getCompositeType';
import { getCompositeIdFromChildId } from './utils/master-json-utils';

export enum SelectableProperty {
  // Property of the current component the user is manipulating
  NONE = 'NONE',
  ANIMATE_IN = 'ANIMATE_IN', // subtabs within the property panel
  ANIMATE_OUT = 'ANIMATE_OUT', // subtabs within the property panel
  IMAGE_FIT_TYPE = 'IMAGE_FIT_TYPE',
  VOICE_OVER_TTS = 'VOICE_OVER_TTS',
  VOLUME = 'VOLUME',
  // ONLY those properties that other module/app can set (blur, speed, are not such properties)
}

export enum ProjectMode {
  STORY = 'story',
  EDITOR = 'editor',
  RENDERER = 'renderer',
}
export type analyticsEvent = {
  evtName: string;
  evtData?: any;
  userData?: any;
  operatorData?: any;
  isBeaconRequest?: boolean;
};

export type ExportSettings = {
  env: 'prod' | 'preprod' | 'staging' | 'feature';
  version: string;
  rev: string;
  hash: string;
  source: string;
};

export type AudioBlockPreviousValues = {
  id: string;
  audio_volume: number;
  is_ducking: boolean;
  bg_volume: number;
  panner_pan: number;
  fade_in_time: number;
  fade_out_time: number;
};

export type RenderSettings = {
  // If this field is enabled, then backend(run_video.py) will not replace the video urls (cropped videos),
  // with hard cropped urls.
  // This field will be used during rendering to decide whether to apply crop or not.
  soft_crop_videos: boolean;
  download_large_file_parallelly: boolean;
  render_type: 'webcodecs' | 'ffmpeg';
  prevent_pre_download: boolean;
  download_media_to_temp_s3: boolean;
};

export type Clipboard = {
  data: Master.CompositeJSON | Master.BlockJSON | Master.AudioBlockJSON;
  pasteCount: {
    [key in string]: number;
  };
};

export type State = {
  mode: ProjectMode;
  templateType: TemplateType;
  storyJson: Master.StoryJSON | null;
  masterJson: Master.JSON;
  fps: number;
  duration: number;
  dimension: Master.Dimension;
  dimensionJson: Master.DimensionJSON;
  selectedBlockIndex: number;
  selectedComponentId: string; // component or an audio block
  selectedCompositeId: string;
  selectedGapId: string;
  selectedProperty: SelectableProperty;
  fonts: any;
  projectScenes: Master.BlockJSON[];
  updateBlockline: { option: 'addBlock'; index: number } | null;
  templateJson: Template.JSON;
  payment: {
    stripe: boolean;
  };
  analyticsEventToBeSent: null | analyticsEvent;
  optimizeUrl: boolean;
  selectedReviewBoxId: string | null;
  exportSettings: null | ExportSettings;
  audio: {
    blocksPreviousValues: {
      [id: string]: AudioBlockPreviousValues;
    };
  };
  clipboard: Clipboard | null;
};

export const INITIAL_STATE: State = {
  exportSettings: null,
  mode: ProjectMode.EDITOR,
  templateType: TemplateType.Blank,
  storyJson: null,
  masterJson: NULL_VIDEO,
  dimension: NULL_DIMENSION,
  fps: 30,
  duration: NULL_DURATION,
  dimensionJson: NULL_VIDEO[NULL_DIMENSION]!,
  selectedBlockIndex: 0,
  selectedComponentId: NULL_COMPONENT_ID,
  selectedCompositeId: NULL_CLIP_ID,
  selectedGapId: NULL_GAP_ID,
  selectedProperty: SelectableProperty.NONE,
  fonts: null,
  updateBlockline: null,
  projectScenes: [],
  templateJson: {
    name: '',
    id: 0,
    price: 0,
    creator_id: -1,
    paid: false,
    dimensions: {},
  },
  payment: {
    stripe: false,
  },
  analyticsEventToBeSent: null,
  optimizeUrl: true,
  selectedReviewBoxId: null,
  audio: {
    blocksPreviousValues: {},
  },
  clipboard: null,
};

function cleanMasterJSON(
  masterjson: Master.JSON | Shadow.JSON,
  exportSettings: ExportSettings | null,
): Master.JSON | Shadow.JSON {
  // console.time('cleanMasterJSON');
  function cleanTrimSection(dimensionJSON: DimensionJSON) {
    return {
      ...dimensionJSON,
      audio_blocks: dimensionJSON.audio_blocks
        .map((block) => {
          if (
            block.trim_section &&
            !('start_time' in block.trim_section || 'end_time' in block.trim_section)
          ) {
            return { ...block, trim_section: null };
          }
          return block;
        })
        .map((block) => {
          // In some cases -0.13 is added as start_time of a trim section.
          // We need to change this to 0
          // TODO: figure out how the json went into this state.
          if (block.trim_section && block.trim_section.start_time < 0) {
            return { ...block, trim_section: { ...block.trim_section, start_time: 0 } };
          } else {
            return block;
          }
        }),
    };
  }
  function cleanBGVideoAudioBlockDuration(dimensionJSON: DimensionJSON) {
    return {
      ...dimensionJSON,
      audio_blocks: dimensionJSON.audio_blocks.map((block) => {
        if (block.sub_type === 'bg_video') {
          return { ...block, duration: null };
        }
        return block;
      }),
    };
  }
  function addCompositeType(dimensionJSON: DimensionJSON) {
    return {
      ...dimensionJSON,
      blocks: dimensionJSON.blocks.map((block) => {
        return {
          ...block,
          components: block.components.map((component) => {
            return { ...component, compositeType: getCompositeType(component) };
          }),
        };
      }),
    };
  }
  function addExportSettings(dimensionJSON: DimensionJSON) {
    if (exportSettings != null) {
      return { ...dimensionJSON, export_settings: exportSettings };
    } else {
      return dimensionJSON;
    }
  }
  function cleanDimensionJSON(dimensionJSON: DimensionJSON) {
    return addExportSettings(
      addCompositeType(cleanTrimSection(cleanBGVideoAudioBlockDuration(dimensionJSON))),
    );
  }
  const sixteennine = masterjson['16:9'] ? cleanDimensionJSON(masterjson['16:9']) : null;
  const ninesixteen = masterjson['9:16'] ? cleanDimensionJSON(masterjson['9:16']) : null;
  const oneone = masterjson['1:1'] ? cleanDimensionJSON(masterjson['1:1']) : null;
  masterjson = sixteennine ? { ...masterjson, ['16:9']: sixteennine } : masterjson;
  masterjson = ninesixteen ? { ...masterjson, ['9:16']: ninesixteen } : masterjson;
  masterjson = oneone ? { ...masterjson, ['1:1']: oneone } : masterjson;
  // console.timeEnd('cleanMasterJSON');
  return masterjson;
}

const updateMasterJson = (state: State, json: Master.JSON | Shadow.JSON | null) => {
  if (!json) {
    state.masterJson = INITIAL_STATE.masterJson;
    state.dimension = INITIAL_STATE.dimension;
    state.dimensionJson = INITIAL_STATE.dimensionJson;
    state.duration = INITIAL_STATE.duration;
    state.selectedBlockIndex = INITIAL_STATE.selectedBlockIndex;
    state.selectedComponentId = INITIAL_STATE.selectedComponentId;
    state.selectedProperty = INITIAL_STATE.selectedProperty;
    Registry.reset();
    ShadowRegistry.reset();
    return;
  }
  const typedjson = cleanMasterJSON(json, state.exportSettings);
  const dimension = orderedDimensions.find((d) => typedjson[d]);
  if (!dimension) {
    throw new Error(`Failed to update video: dimension not found.`);
  }

  if (state.masterJson.master_video_id === 0) {
    const dim = typedjson[dimension];
    if (dim && dim.layers === undefined) {
      dim.layers = [];
    }
  }

  state.dimension = dimension;
  const tNow = performance.now();
  ShadowRegistry.update(typedjson, tNow); // will fail if json is invalid
  state.masterJson = ShadowRegistry.getMasterJson(); // cleans up shadow properties if any
  const dim = state.masterJson[dimension]!;
  state.duration = Math.round(dim.duration.absolute_duration * 10000) / 10000;
  state.dimensionJson = dim;
  Registry.register(dim);

  if (state.selectedBlockIndex >= dim.blocks.length) {
    state.selectedBlockIndex = dim.blocks.length - 1;
  }
  if (!Registry.getById(state.dimensionJson, state.selectedComponentId)) {
    const selectedBlock = dim.blocks[state.selectedBlockIndex]!;
    state.selectedComponentId = selectedBlock.components[0].id;
    state.selectedProperty = SelectableProperty.NONE;
  }
  SlateRegistry.update(ShadowRegistry.getShadowJson(), state.optimizeUrl);
};

const updateSelectedBlockIndex = (state: State, index: number) => {
  if (index >= state.dimensionJson.blocks.length) {
    throw new Error(`Failed to update selected scene: out of index error`);
  }
  state.selectedBlockIndex = index;
};

export const slice = createSlice({
  name: 'master',
  initialState: INITIAL_STATE,
  reducers: {
    modeUpdated: (state, action: PayloadAction<{ mode: ProjectMode }>) => {
      state.mode = action.payload.mode;
    },
    fpsUpdated: (state, action: PayloadAction<{ fps: number }>) => {
      state.fps = action.payload.fps;
    },
    storyJsonUpdated: (
      state,
      action: PayloadAction<{
        json: Master.StoryJSON | Shadow.StoryJSON | null;
      }>,
    ) => {
      if (!action.payload.json) {
        state.storyJson = null;
        updateMasterJson(state, null);
        return;
      }

      const cJson = cloneJSON(action.payload.json);

      const dimension = orderedDimensions.find((d) => cJson[d]);
      if (!dimension) {
        throw new Error(`Failed to update video: dimension not found.`);
      }
      state.dimension = dimension;
      const tNow = performance.now();
      ShadowRegistry.updateStory(cJson, tNow); // will fail if json is invalid
      state.storyJson = ShadowRegistry.getStoryJson(); // cleans up shadow properties if any

      const cDim = cJson[state.dimension]!;
      const cBlocks =
        cDim.blocks?.filter(isPresent).filter((b) => (b?.components.length ?? 0) > 0) ?? [];
      const cAudioBlocks = cDim.audio_blocks?.filter(isPresent) ?? [];
      const cMasterJson = {
        ...cJson,
        [state.dimension]: {
          ...cDim,
          blocks: cBlocks,
          audio_blocks: cAudioBlocks,
        },
      };

      if (cBlocks.length > 0) {
        updateMasterJson(state, cMasterJson as Master.JSON);
      }
    },
    shadowPublished: (state, action: PayloadAction<{ json: Master.JSON | Shadow.JSON | null }>) => {
      updateMasterJson(state, action.payload.json);
    },
    shadowMutated: (_state) => {},
    masterJsonUpdated: (
      state,
      action: PayloadAction<{
        json: Master.JSON | Shadow.JSON | null;
        selectedBlockIndex?: number;
      }>,
    ) => {
      updateMasterJson(state, action.payload.json);
      const selectedBlockIndex = action.payload.selectedBlockIndex;

      if (selectedBlockIndex !== undefined) {
        updateSelectedBlockIndex(state, selectedBlockIndex);
      }
    },
    // Almost always call this.
    dimensionJsonUpdated: (
      state,
      action: PayloadAction<{ json: Master.DimensionJSON | Shadow.DimensionJSON }>,
    ) => {
      const json = cleanMasterJSON(
        {
          ...state.masterJson,
          [state.dimension]: action.payload.json,
        },
        state.exportSettings,
      );
      const tNow = performance.now();
      ShadowRegistry.update(json, tNow); // will fail if json is invalid
      state.masterJson = ShadowRegistry.getMasterJson(); // cleans up shadow properties if any
      const dim = state.masterJson[state.dimension]!;
      state.duration = Math.round(dim.duration.absolute_duration * 10000) / 10000;
      state.dimensionJson = dim;
      Registry.register(dim);

      if (state.selectedBlockIndex >= dim.blocks.length) {
        state.selectedBlockIndex = dim.blocks.length - 1;
      }
      if (!Registry.getById(state.dimensionJson, state.selectedComponentId)) {
        const selectedBlock = dim.blocks[state.selectedBlockIndex]!;
        state.selectedComponentId = selectedBlock.components[0].id;
        state.selectedProperty = SelectableProperty.NONE;
      }
      SlateRegistry.update(ShadowRegistry.getShadowJson(), state.optimizeUrl);
    },
    componentOrBlockUpdated: (
      state,
      action: PayloadAction<{ json: Master.AnyJSON | Shadow.AnyJSON }>,
    ) => {
      const cJson = ShadowRegistry.scrubComponentOrBlock(action.payload.json);
      const tNow = performance.now();
      const oldJson = Registry.getById(state.dimensionJson, cJson.id);
      if (!oldJson) {
        throw new Error(
          `Failed to update video: the component being updated does not exist. ${cJson.id}`,
        );
      }
      const dim = Registry.updateBlockOrComponent(state.dimensionJson, cJson);
      state.masterJson = { ...state.masterJson, [state.dimension]: dim };
      state.dimensionJson = state.masterJson[state.dimension]!;
      ShadowRegistry.update(state.masterJson, tNow);

      if (!Registry.getById(state.dimensionJson, state.selectedComponentId)) {
        const selectedBlock = dim.blocks[state.selectedBlockIndex]!;
        state.selectedComponentId = selectedBlock.components[0].id;
        state.selectedProperty = SelectableProperty.NONE;
      }
      SlateRegistry.update(ShadowRegistry.getShadowJson(), state.optimizeUrl);
    },
    componentUpdated: (
      state,
      action: PayloadAction<{ json: Master.AnyComponentJSON | Shadow.ComponentJSON }>,
    ) => {
      const cJson = ShadowRegistry.scrubComponent(action.payload.json);
      const tNow = performance.now();
      const oldJson = Registry.getById(state.dimensionJson, cJson.id);
      if (!oldJson) {
        throw new Error(
          `Failed to update video: the component being updated does not exist. ${cJson.id}`,
        );
      }
      const dim = Registry.updateComponent(state.dimensionJson, cJson);
      state.masterJson = { ...state.masterJson, [state.dimension]: dim };
      state.dimensionJson = state.masterJson[state.dimension]!;
      ShadowRegistry.update(state.masterJson, tNow);

      if (!Registry.getById(state.dimensionJson, state.selectedComponentId)) {
        const selectedBlock = dim.blocks[state.selectedBlockIndex]!;
        state.selectedComponentId = selectedBlock.components[0].id;
        state.selectedProperty = SelectableProperty.NONE;
      }
      SlateRegistry.update(ShadowRegistry.getShadowJson(), state.optimizeUrl);
    },
    blockUpdated: (state, action: PayloadAction<{ json: Master.BlockJSON | Shadow.BlockJSON }>) => {
      const bJson = ShadowRegistry.scrubBlock(action.payload.json);
      const tNow = performance.now();

      const oldJson = Registry.getById(state.dimensionJson, bJson.id);
      if (!oldJson) {
        throw new Error(
          `Failed to update video: the component being updated does not exist. ${bJson.id}`,
        );
      }
      const dim = Registry.updateBlock(state.dimensionJson, bJson);
      state.masterJson = { ...state.masterJson, [state.dimension]: dim };
      state.dimensionJson = state.masterJson[state.dimension]!;
      ShadowRegistry.update(state.masterJson, tNow);

      if (!Registry.getById(state.dimensionJson, state.selectedComponentId)) {
        const selectedBlock = dim.blocks[state.selectedBlockIndex]!;
        state.selectedComponentId = selectedBlock.components[0].id;
        state.selectedProperty = SelectableProperty.NONE;
      }
      SlateRegistry.update(ShadowRegistry.getShadowJson(), state.optimizeUrl);
    },
    audioBlockUpdated: (state, action: PayloadAction<{ json: Master.AudioBlockJSON }>) => {
      const aJson = ShadowRegistry.scrubAudioBlock(action.payload.json);
      const tNow = performance.now();
      const oldJson = Registry.getById(state.dimensionJson, aJson.id);
      if (!oldJson) {
        throw new Error(
          `Failed to update video: the component being updated does not exist. ${aJson.id}`,
        );
      }
      const dim = Registry.updateAudioBlock(state.dimensionJson, aJson);
      state.masterJson = { ...state.masterJson, [state.dimension]: dim };
      state.dimensionJson = state.masterJson[state.dimension]!;
      ShadowRegistry.update(state.masterJson, tNow);

      if (!Registry.getById(state.dimensionJson, state.selectedComponentId)) {
        const selectedBlock = dim.blocks[state.selectedBlockIndex]!;
        state.selectedComponentId = selectedBlock.components[0].id;
        state.selectedProperty = SelectableProperty.NONE;
      }
      SlateRegistry.update(ShadowRegistry.getShadowJson(), state.optimizeUrl);
    },

    blockIndexSelected: (state, action: PayloadAction<{ index: number }>) => {
      const index = action.payload.index;
      updateSelectedBlockIndex(state, index);
    },
    componentSelected: (
      state,
      action: PayloadAction<{ id: string; selectedProperty?: SelectableProperty }>,
    ) => {
      state.selectedComponentId = action.payload.id;
      const compositeId = getCompositeIdFromChildId(action.payload.id);
      state.selectedCompositeId = compositeId;
      state.selectedGapId = 'none';
      state.selectedProperty = action.payload.selectedProperty ?? SelectableProperty.NONE;
    },
    gapSelected: (state, action: PayloadAction<{ id: string }>) => {
      state.selectedGapId = action.payload.id;
    },
    propertySelected: (
      state,
      action: PayloadAction<{ selectableProperty: SelectableProperty }>,
    ) => {
      state.selectedProperty = action.payload.selectableProperty;
    },
    fontsChanged: (state, action: PayloadAction<any>) => {
      state.fonts = action.payload.fonts;
    },
    setUpdateBlockline: (state, action: PayloadAction<{ option: 'addBlock'; index: number }>) => {
      state.updateBlockline = action.payload;
    },
    reset: (state) => {
      Object.keys(INITIAL_STATE)
        .filter((key) => key != 'exportSettings')
        .forEach((key) => {
          (state as any)[key] = INITIAL_STATE[key as keyof State];
        });
      Registry.reset();
      ShadowRegistry.reset();
      ShadowRegistry.resetStory();
      SlateRegistry.reset();
    },
    setTemplateJson: (state, action: PayloadAction<Template.JSON>) => {
      state.templateJson = action.payload;
    },
    sendAnalyticsEvent: (state, action: PayloadAction<analyticsEvent>) => {
      // we make an assumption that redux observables are fired synchronously
      // so that if two events are fired one after the other, then there would not be an
      // issue when the first event is handled and the state is changed to null
      state.analyticsEventToBeSent = action.payload;
    },
    analyticsEventSent: (state) => {
      state.analyticsEventToBeSent = null;
    },
    setTemplateType: (state, action: PayloadAction<TemplateType>) => {
      state.templateType = action.payload;
    },
    projectSceneAdded: (state, action: PayloadAction<{ scene: Master.BlockJSON }>) => {
      state.projectScenes.push(action.payload.scene);
    },
    projectScenesUpdated: (state, action: PayloadAction<{ scenes: Master.BlockJSON[] }>) => {
      state.projectScenes = action.payload.scenes;
    },
    setUrlOptimizationEnabled: (state, action: PayloadAction<boolean>) => {
      state.optimizeUrl = action.payload;
    },
    selectedReviewBoxIdUpdated: (state, action: PayloadAction<null | string>) => {
      state.selectedReviewBoxId = action.payload;
    },
    updateExportSettings: (state, action: PayloadAction<ExportSettings>) => {
      // This function is duplicated at publish.service.ts(retro), projectslice(shell) and publish.service.ts(storyboard)
      function isKnownOrigin(origin: string) {
        return (
          origin.includes('videocreek.com') ||
          origin.includes('invideo.io') ||
          origin.includes('kizoa.com') ||
          origin.includes('imagetovideo.com') ||
          origin.includes('iv0.in') ||
          origin.includes('localhost')
        );
      }
      const exportSettings = action.payload;
      if (isKnownOrigin(exportSettings.source)) {
        state.exportSettings = action.payload;
      } else {
        state.exportSettings = { ...action.payload, source: 'https://invideo.io' };
      }
    },
    saveAudioBlockPreviousValuesOnMute: (
      state,
      action: PayloadAction<AudioBlockPreviousValues>,
    ) => {
      state.audio.blocksPreviousValues = {
        ...state.audio.blocksPreviousValues,
        [action.payload.id]: action.payload,
      };
    },

    removeAudioBlockPreviousValueOnUnmute: (state, action: PayloadAction<string>) => {
      delete state.audio.blocksPreviousValues?.[action.payload];
    },

    setClipboardData(state, action: PayloadAction<Master.CompositeJSON | Master.AudioBlockJSON>) {
      state.clipboard = { data: action.payload, pasteCount: {} };
    },

    clipboardDataPasted(state, action: PayloadAction<{ sceneId: string }>) {
      const { sceneId } = action.payload;
      if (state.clipboard) {
        if (state.clipboard.pasteCount[sceneId]) {
          state.clipboard.pasteCount[action.payload.sceneId]++;
        } else {
          state.clipboard.pasteCount[action.payload.sceneId] = 1;
        }
      }
    },
  },
});

/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
  BgRemovalJob,
  ClipTopPanelButtonClickStatus,
  EditorModeSource,
  ImageBgRemovalJob,
  LeftPanelMediaFileMetadata,
  slice,
  State,
  StoryboardGeneratingScenePayload,
  StoryboardV2State,
} from './uiSlice';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  DraggedMedia,
  KeyboardContext,
  ProjectAutoSaveStatus,
  RepaintComponentType,
  TrimModalData,
  UploadPayload,
  RecentUpload,
  RecentUploadStatus,
  StoryboardSceneBGMediaPayload,
  ZoomLevelData,
  TimelineMediaAddType,
  TimelineType,
  RepaintType,
  Module,
  CopyPasteAction,
  StoryboardNavigationRoute,
  ApplyAllJSON,
  ResizeType,
  UndoRedoSource,
  LogoPlaceholderModalAction,
  FontLoadingStatus,
  FontModel,
  EditorLoadingStatus,
  TimelineVariant,
  ActiveTab,
  AnalyticsState,
  DropSource,
  LeftPanelActionSource,
  EditorNavigationRoute,
  RouterEvent,
  LayeredTimelineVariant,
  TransitionColors,
  TemplatesVariant,
  StockVariant,
  MusicVariant,
  TextVariant,
  LogoVariant,
  ColorVariant,
  ElementsVariant,
  CollageVariant,
  LihpPostProcessParams,
  PaymentModalParams,
  TransitionHistoryEntry,
  ConfirmationState,
  ProxyJobResult,
  ActiveSection,
  PaymentConfirmModalMode,
  RemoveVideoBgPostProcessRequest,
  VideoTrimModalOpenLocation,
  VideoTrimModalCloseLocation,
  ModalType,
  TrimCompletedHandler,
  AnimationData,
  StockVideoTemplateInfo,
  REMOVE_VIDEO_BG_STATUS,
} from './types';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import * as MasterStore from '../store/masterStore';
import * as DragRegistry from './dragRegistry';
import {
  frameLoader,
  TimelineInteractionVariant,
  TimelineInteraction,
  TimelineScrollIntent,
  upgradeModalSource,
  EditorMode,
  AudioTopPanelButtonClickStatus,
  VolumePanelCloseButtonClickStatus,
} from '.';
import { TransitionCompositesState } from './types';
import debug from 'debug';
import { Subject } from 'rxjs';
import { Master } from '../jsonTypes';
import * as OverlayRegistry from './overlayRegistry';

const log = debug('shell-uiapi');

export class Api {
  constructor(private stateFetcher: () => State, private dispatch: Dispatch<AnyAction>) {}

  private get state(): State {
    return this.stateFetcher();
  }

  private get actions() {
    return slice.actions;
  }

  private sliceObservable<SliceT>(selector: (state: State) => SliceT) {
    return MasterStore.stores.ui$.pipe(
      map((st) => selector(st)),
      distinctUntilChanged()
    );
  }

  observables = {
    draggedElement$: this.sliceObservable((st) => st.draggedElement).pipe(
      map((_) => DragRegistry.getDraggedElement())
    ),
    lastDroppedElement$: this.sliceObservable((st) => st.lastDroppedElement).pipe(
      map((_) => DragRegistry.getLastDroppedElement())
    ),
    hoveredSelectionBox$: this.sliceObservable((st) => st.hoveredSelectionBox),
    hoveredAddSceneButton$: this.sliceObservable((st) => st.hoveredAddSceneButton),
    dropCompleted$: this.sliceObservable((st) => st.lastHoveredSelectionBox),
    mode$: this.sliceObservable((st) => st.mode),
    filesForUploads$: this.sliceObservable((st) => st.filesForUploads),
    recentUploadsAdded$: this.sliceObservable((st) => st.recentUploads).pipe(
      filter((arr) => arr.length >= 1),
      map((arr) => arr[arr.length - 1])
    ),
    editorLoadingStatus$: this.sliceObservable((st) => st.editorLoadingStatus),
    keyboardContext$: this.sliceObservable((st) => st.keyboardContext),
    gridlines$: this.sliceObservable((st) => st.gridlines),
    recordingCountdown$: this.sliceObservable((st) => st.recordingCountdown),
    transitionsTray$: this.sliceObservable((st) => st.transitionsTray),
    transitionsTrayConfirmation$: this.sliceObservable((st) => st.transitionsTray.confirmation),
    autoPreview$: this.sliceObservable((st) => st.transitionsTray.autoPreview),
    animation$: this.sliceObservable((st) => st.animation),
    videoTrimModal$: this.sliceObservable((st) => st.videoTrimModal),
    lastVideoTrimData$: this.sliceObservable((st) => st.lastVideoTrimData),
    mediaCropModal$: this.sliceObservable((st) => st.mediaCropModal),
    setsceneLoadedForDuplicate$: this.sliceObservable((st) => st.sceneLoadedForDuplicate),
    showSlateOverlayLoader$: this.sliceObservable((st) => st.slateOverlayLoader),
    storyboardSceneBGMedia$: this.sliceObservable((st) => st.storyboardSceneBGMedia),
    projectAutoSave$: this.sliceObservable((st) => st.projectAutoSave),
    slate$: this.sliceObservable((st) => st.slate),
    repaint$: this.sliceObservable((st) => st.slate.repaint),
    modules$: this.sliceObservable((st) => st.modules),
    filmrModal$: this.sliceObservable((st) => st.filmrModal),
    setPasteState$: this.sliceObservable((st) => st.copyPaste.state.paste),
    setCopyPasteAction$: this.sliceObservable((st) => st.copyPaste.action),
    leftPanelTransitionEnded$: this.sliceObservable((st) => st.leftPanel.hasTransitionEnded),
    guestUserSignupModal$: this.sliceObservable((st) => st.guestSignupModal),
    isMaskModalVisible$: this.sliceObservable(
      (st) =>
        (st.ivCommonModal.modalType === 'IMAGE_MASK_MODAL' ||
          st.ivCommonModal.modalType === 'VIDEO_MASK_MODAL') &&
        st.ivCommonModal.isOpen
    ),
    pricingModal$: this.sliceObservable((st) => st.pricingModal),
    paymentConfirmModal$: this.sliceObservable((st) => st.paymentConfirmModal),
    upgradeModal$: this.sliceObservable((st) => st.upgradeModal),
    editorNavigationEvent$: this.sliceObservable((st) => st.editorNavigationRoute),
    storyboardNavigationEvent$: this.sliceObservable((st) => st.storyboardNavigation),
    blankCanvasOverlay$: this.sliceObservable((st) => st.blankCanvasOverlay),
    microphonePermissionModal$: this.sliceObservable((st) => st.microphonePermissionModal),
    confirmAudioTrim$: this.sliceObservable((st) => st.confirmAudioTrim),
    postGuestSignupExport$: this.sliceObservable((st) => st.postGuestSignupExport),
    removeWatermarkBadge$: this.sliceObservable(
      (st) => st.leftPanel.removeWatermarkBadge.isVisible
    ),
    logoTray$: this.sliceObservable((st) => st.leftPanel.logoTray),
    textAnimationActiveTab$: this.sliceObservable((st) => st.leftPanel.textAnimationActiveTab),
    lastMoveUploadItemToLogo$: this.sliceObservable((st) => st.lastMoveUploadItemToLogo),
    undoRedoSnapshotRequests$: this.sliceObservable((st) => st.undoRedo.snapshotRequests),
    canUndo$: this.sliceObservable((st) => st.undoRedo.canUndo),
    canRedo$: this.sliceObservable((st) => st.undoRedo.canRedo),
    undoRequests$: this.sliceObservable((st) => st.undoRedo.undoRequests),
    redoRequests$: this.sliceObservable((st) => st.undoRedo.redoRequests),
    undoCompleted$: this.sliceObservable((st) => st.undoRedo.undoCompleted),
    redoCompleted$: this.sliceObservable((st) => st.undoRedo.redoCompleted),
    undoRedoUi$: MasterStore.stores.ui$.pipe(
      map((st) => ({ canUndo: st.undoRedo.canUndo, canRedo: st.undoRedo.canRedo })),
      distinctUntilChanged((x, y) => {
        return x.canUndo === y.canUndo && x.canRedo === y.canRedo;
      })
    ),
    socialBlock$: this.sliceObservable((st) => st.socialBlock),
    isRightPanelVisible$: this.sliceObservable((st) => st.rightPanel.isVisible),
    logoPlaceholderModal$: this.sliceObservable((st) => st.logoPlaceholderModal),
    sceneWarnLimitModal$: this.sliceObservable((st) => st.sceneLimitModals.warnModal),
    sceneMaxLimitModal$: this.sliceObservable((st) => st.sceneLimitModals.maxLimitModal),
    rearrangeModal$: this.sliceObservable((st) => st.rearrangeModal),
    fonts$: this.sliceObservable((st) => st.fonts),
    timelineType$: this.sliceObservable((st) => st.timeline.type),
    timelineVariant$: this.sliceObservable((st) => st.timeline.variant),
    timelineInteractionMode$: this.sliceObservable((st) => st.timeline.interaction.mode),
    timelinePrompts$: this.sliceObservable((st) => st.timeline.prompts),
    timelineSnapTime$: this.sliceObservable((st) => st.timeline.snapTime),
    timelineScrollIntent$: this.sliceObservable((st) => st.timeline.scrollIntent),
    timelineTypeIntent$: this.sliceObservable((st) => st.timeline.timelineTypeIntent),
    timelineZoom$: this.sliceObservable((st) => st.timeline.zoom),
    openLeftPanelTab$: this.sliceObservable((st) => st.leftPanel.tab),
    analytics$: this.sliceObservable((st) => st.analytics),
    pricingModalOpen$: this.sliceObservable((st) => st.pricingModal.isOpen),
    creditCardModalOpen$: this.sliceObservable((st) => st.creditCardModal.isOpen),
    exportVideo$: this.sliceObservable((st) => st.exportVideo),
    logOurRequestStatus$: this.sliceObservable((st) => st.logOutRequestStatus),
    isTeamSettingsModalInReviewDisplayed$: this.sliceObservable(
      (st) => st.isTeamSettingsModalInReviewDisplayed
    ),
    routerEvent$: this.sliceObservable((st) => st.routerEvent),
    reviewPageDownloadButtonStatus$: this.sliceObservable(
      (st) => st.reviewPageDownloadButtonStatus
    ),
    isAudioLocked$: this.sliceObservable((st) => st.isAudioLocked),
    clipTopPanelButtonClickStatus$: this.sliceObservable((st) => st.clipTopPanelButtonClickStatus),
    audioTopPanelButtonClickStatus$: this.sliceObservable(
      (st) => st.audioTopPanelButtonClickStatus
    ),
    editorMode$: this.sliceObservable((st) => st.editorMode),
    fullScreenPreviewActive$: this.sliceObservable((st) => st.fullscreenPreview.active),
    volumePanelCloseButtonClickStatus$: this.sliceObservable(
      (st) => st.volumePanelCloseButtonClickStatus
    ),
    timelineInteractionVariant$: this.sliceObservable((st) => st.timeline.interactionVariant),
    layeredTimelineVariant$: this.sliceObservable((st) => st.timeline.layeredTimelineVariant),
    timelineSelectedClipDelete$: this.sliceObservable((st) => st.timeline.deleteSelectedClip),
    timelineHeight$: this.sliceObservable((st) => st.timeline.height),
    timelineWidth$: this.sliceObservable((st) => st.timeline.width),
    timelinePosition$: this.sliceObservable((st) => st.timeline.position),
    timelineScroll$: this.sliceObservable((st) => st.timeline.scroll),
    storyboardV2State$: this.sliceObservable((st) => st.storyboardV2.state),
    projectAutoSaveForce$: this.sliceObservable((st) => st.projectAutoSave.forceSave),
    showAlertModal$: this.sliceObservable((st) => st.showAlertModal),
    timelineShouldRender$: this.sliceObservable((st) => st.timeline.shouldRender),
    audioLevelMeterVisibility$: this.sliceObservable((st) => st.audioLevelMeter.visible),
    audioLevelMeterEnabled$: this.sliceObservable((st) => st.audioLevelMeter.isEnabled),
    keyboardShortcutPanel$: this.sliceObservable((st) => st.keyboardShortcutPanel),
    leftPanelSelectedTab$: this.sliceObservable((st) => st.leftPanel.selectedTab),
    isStoryboardVOClickHandled$: this.sliceObservable((st) => st.isStoryboardVOClickHandled),
    frameExternalLoader$: this.sliceObservable((st) => st.frame.externalLoader),
    lihpPostExportModal$: this.sliceObservable((st) => st.lihpPostExportModal),
    lihpAuthPostProcessParams$: this.sliceObservable((st) => st.lihpAuthPostProcessParams),
    creditCardUpdated$: new Subject(),
    bgRemovalJobs$: this.sliceObservable((st) => st.bgRemovalJobs),
    uploadingClipDeleteStatusUpdated$: this.sliceObservable(
      (st) => st.leftPanel.requestedUploadingClipDelete
    ),
    sourceMonitorPlayState$: this.sliceObservable((st) => st.leftPanel.sourceMonitorPlayState),
    redirectionRequested$: this.sliceObservable((st) => st.redirectRequest),
    lastImageBgRemoveJob$: this.sliceObservable((st) => st.lastImageBgRemoveJob),
    lastVideoBgRemoveJob$: this.sliceObservable((state) => state.lastVideoBgRemoveJob),
    folderCreationRequests$: this.sliceObservable((state) => state.folderCreationRequests),
    uploadQueueLength$: this.sliceObservable((state) => state.uploadQueueLength),
    saveCustomTextStyle$: this.sliceObservable((state) => state.saveCustomTextStyleValue),
  };

  get asDebugView() {
    return this.state;
  }

  get isAudioLocked() {
    return this.state.isAudioLocked;
  }

  // PUBLIC API

  requestLogOut() {
    this.dispatch(this.actions.requestedLogOut());
  }

  handledLogOutRequest() {
    this.dispatch(this.actions.handledLogOutRequest());
  }

  get repaintComponentType() {
    return this.state.repaintCompomentType;
  }

  set repaintComponentType(componentType: RepaintComponentType) {
    this.dispatch(this.actions.repaintComponentTypeUpdated(componentType));
  }

  get isTeamSettingsModalInReviewDisplayed() {
    return this.state.isTeamSettingsModalInReviewDisplayed;
  }

  get timelineMediaAddType() {
    return this.state.timelineMediaAddType;
  }

  set timelineMediaAddType(addType: TimelineMediaAddType) {
    this.dispatch(this.actions.timelineMediaAddTypeUpdated(addType));
  }

  get timelineResizeType() {
    return this.state.timelineResizeType;
  }

  set timelineResizeType(resizeType: ResizeType) {
    this.dispatch(this.actions.timelineResizeTypeUpdated({ resizeType }));
  }

  get draggedElement() {
    return DragRegistry.getDraggedElement();
  }
  get lastDroppedElement() {
    return DragRegistry.getLastDroppedElement();
  }
  get filesForUploads() {
    return this.state.filesForUploads;
  }
  set filesForUploads(files: UploadPayload) {
    this.dispatch(this.actions.filesForUploadsUpdated(files));
  }
  get keyboardContext() {
    return this.state.keyboardContext;
  }

  set keyboardContext(context: KeyboardContext) {
    if (context === this.keyboardContext) {
      log('set keyboardContext is noop');
      return;
    }
    this.dispatch(this.actions.keyboardContextUpdated({ context }));
  }
  get projectAutoSave() {
    return this.state.projectAutoSave;
  }
  get gridlines() {
    return this.state.gridlines;
  }
  get recordingCountdown() {
    return this.state.recordingCountdown;
  }
  get transitionsTray() {
    return this.state.transitionsTray;
  }
  get videoTrimModal() {
    return this.state.videoTrimModal;
  }
  get mediaCropModal() {
    return this.state.mediaCropModal;
  }

  get logoPlaceholderModal() {
    return this.state.logoPlaceholderModal;
  }

  get frameLoader() {
    return this.state.frameLoader;
  }

  get leftPanelActionSource() {
    return this.state.leftPanel.source;
  }

  getAddSceneIndex() {
    return this.state.leftPanel.addSceneIndex;
  }

  set frameLoader(loader: frameLoader) {
    this.dispatch(this.actions.updateFrameLoader(loader));
  }

  set autoPreview(state: boolean) {
    this.dispatch(this.actions.transitionsAutoPreviewSet(state));
  }

  get autoPreview() {
    return this.state.transitionsTray.autoPreview;
  }

  get videoTrimVisible() {
    return this.state.videoTrimModal.isVisible;
  }

  set videoTrimVisible(visible: boolean) {
    this.dispatch(this.actions.videoTrimModalVisible(visible));
  }

  showLogoPlaceholderModal(id: string) {
    this.dispatch(
      this.actions.updateLogoPlaceholderModal({
        isVisible: true,
        componentId: id,
        action: null,
      })
    );
  }

  updateLogoPlaceholderModal(
    isVisible: boolean,
    actionInfo: LogoPlaceholderModalAction,
    componentId: string | null
  ) {
    this.dispatch(
      this.actions.updateLogoPlaceholderModal({ isVisible, componentId, action: actionInfo })
    );
  }

  showSceneLimitWarningModal() {
    this.dispatch(this.actions.updateSceneWarnLimitModal({ visible: true }));
  }

  showTeamSettingsModalInReview() {
    this.dispatch(this.actions.showTeamSettingsModalInReview());
  }

  hideTeamSettingsModalInReview() {
    this.dispatch(this.actions.hideTeamSettingsModalInReview());
  }

  hideSceneLimitWarningModal() {
    this.dispatch(this.actions.updateSceneWarnLimitModal({ visible: false }));
  }
  showSceneLimitExceedModal() {
    this.dispatch(this.actions.updateSceneMaxLimitModal({ visible: true }));
  }

  hideSceneLimitExceedModal() {
    this.dispatch(this.actions.updateSceneMaxLimitModal({ visible: false }));
  }

  showRearrangeModal() {
    this.dispatch(this.actions.updateRearrangeModal({ visible: true, alerted: true }));
  }

  hideRearrangeModal(rearrange: boolean, alerted: boolean) {
    this.dispatch(this.actions.updateRearrangeModal({ visible: false, alerted, rearrange }));
  }

  updateSceneMaxLimitModal(visible: boolean) {
    return this.dispatch(this.actions.updateSceneMaxLimitModal({ visible }));
  }

  get sceneWarnLimitState() {
    return this.state.sceneLimitModals.warnModal;
  }

  get sceneMaxLimitState() {
    return this.state.sceneLimitModals.maxLimitModal;
  }

  get currentTab(): string | null {
    return this.state.currentTab;
  }

  get slateOverlayLoaderMessage(): string | null {
    return this.state.slateOverlayLoader.message;
  }

  get timelineInteraction() {
    return this.state.timeline.interaction;
  }

  get isRearrangeShown() {
    return this.state.rearrangeModal.alerted;
  }

  set timelineInteraction(payload: TimelineInteraction) {
    const interaction = this.state.timeline.interaction;
    if (interaction.mode === payload.mode && interaction.clipId === payload.clipId) {
      log('optimization: set timelineInteraction is noop');
      return;
    }
    this.dispatch(this.actions.timelineInteractionUpdated(payload));
  }

  get timelineInteractionVariant() {
    return this.state.timeline.interactionVariant;
  }

  set timelineInteractionVariant(variant: TimelineInteractionVariant) {
    if (this.state.timeline.interactionVariant === variant) {
      log('optimization: set timelineInteractionVariant is noop');
      return;
    }
    this.dispatch(this.actions.timelineInteractionVariantUpdated(variant));
  }

  get layeredTimelineVariant() {
    return this.state.timeline.layeredTimelineVariant;
  }

  set layeredTimelineVariant(variant: LayeredTimelineVariant) {
    if (this.state.timeline.layeredTimelineVariant === variant) {
      log('optimization: set layeredTimelineVariant is noop');
      return;
    }
    this.dispatch(this.actions.layeredTimelineVariantUpdate(variant));
  }

  get timelineZoom() {
    return this.state.timeline.zoom;
  }

  get timelineType() {
    return this.state.timeline.type;
  }

  set timelineType(type: TimelineType) {
    if (this.state.timeline.type === type) {
      log('optimization: set timelineType is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineType(type));
  }

  get timelineVariant() {
    return this.state.timeline.variant;
  }

  set timelineVariant(variant: TimelineVariant) {
    this.dispatch(this.actions.timelineVariantUpdated({ variant }));
  }

  get timelinePrompts() {
    return this.state.timeline.prompts;
  }

  get timelineSnapTime() {
    return this.state.timeline.snapTime;
  }

  get timelineScrollIntent() {
    return this.state.timeline.scrollIntent;
  }

  get animation() {
    return this.state.animation;
  }

  get leftPanelTransitionEnded(): boolean {
    return this.state.leftPanel.hasTransitionEnded;
  }

  get pricingModal() {
    return this.state.pricingModal;
  }
  get creditCardModal() {
    return this.state.creditCardModal;
  }
  get ivCommonModal() {
    return this.state.ivCommonModal;
  }
  get modules() {
    return this.state.modules;
  }

  get removeWatermarkBadge() {
    return this.state.leftPanel.removeWatermarkBadge;
  }

  get blankCanvasOverlay() {
    return this.state.blankCanvasOverlay.isVisible;
  }

  set blankCanvasOverlay(val) {
    this.dispatch(this.actions.setBlankCanvasOverlay(val));
  }

  get applyAll() {
    return this.state.applyAll;
  }

  set applyAll(state: ApplyAllJSON) {
    this.dispatch(this.actions.applyAllUpdated(state));
  }

  get undoRedoSnapshotRequests() {
    return this.state.undoRedo.snapshotRequests;
  }

  get undoRequests() {
    return this.state.undoRedo.undoRequests;
  }

  get redoRequests() {
    return this.state.undoRedo.redoRequests;
  }

  get undoCompleted() {
    return this.state.undoRedo.undoCompleted;
  }

  get redoCompleted() {
    return this.state.undoRedo.redoCompleted;
  }

  get canUndo() {
    return this.state.undoRedo.canUndo;
  }

  get canRedo() {
    return this.state.undoRedo.canRedo;
  }

  get undoRedoUi() {
    return { canUndo: this.canUndo, canRedo: this.canRedo };
  }

  lastMoveUploadItemToLogo({ file, applyToCurrentScene }: State['lastMoveUploadItemToLogo']) {
    this.dispatch(this.actions.lastMoveUploadItemToLogo({ file, applyToCurrentScene }));
  }

  set logoDeleted(deleted: boolean) {
    this.dispatch(this.actions.logoDeleted({ deleted }));
  }

  get analytics() {
    return this.state.analytics;
  }

  set analytics(state: AnalyticsState | null) {
    this.dispatch(this.actions.analyticsUpdated(state));
  }

  requestUndoRedoSnapshot(source?: string) {
    this.dispatch(this.actions.snapshotRequested({ source }));
  }

  undo(source: UndoRedoSource) {
    this.dispatch(this.actions.undoRequested({ source }));
  }

  redo(source: UndoRedoSource) {
    this.dispatch(this.actions.redoRequested({ source }));
  }

  undoComplete() {
    this.dispatch(this.actions.undoCompleted());
  }

  redoComplete() {
    this.dispatch(this.actions.redoCompleted());
  }

  setUndoRedoUi(canUndo: boolean, canRedo: boolean, debug?: unknown) {
    this.dispatch(this.actions.canUndoRedoUpdated({ canUndo, canRedo, debug }));
  }

  addRecentUpload(upload: RecentUpload) {
    this.dispatch(this.actions.recentUploadAdded({ upload }));
  }

  updateRecentUpload(blobUrl: string, status: RecentUploadStatus) {
    this.dispatch(this.actions.recentUploadUpdated({ blobUrl, status }));
  }

  requestUploadingClipDelete(payload: { status: boolean; id: string }) {
    this.dispatch(this.actions.updateRequestedUploadingClipDelete(payload));
  }

  get sourceMonitorPlayState() {
    return this.state.leftPanel.sourceMonitorPlayState;
  }

  set sourceMonitorPlayState(playState: 'play' | 'paused') {
    this.dispatch(this.actions.setSourceMonitorPlayState(playState));
  }

  resetUploadingClipDelete() {
    this.dispatch(this.actions.updateRequestedUploadingClipDelete({ status: false, id: '' }));
  }

  get recentUploads() {
    return this.state.recentUploads;
  }

  get storyboardV2State() {
    return this.state.storyboardV2.state;
  }

  get isStoryboardBlankState() {
    return this.state.storyboardV2.state.type === 'blank';
  }

  get isStoryboardGeneratingScenesState() {
    return this.state.storyboardV2.state.type === 'generating_scenes';
  }

  get isStoryboardProcessingTextState() {
    return this.state.storyboardV2.state.type === 'processing_text';
  }

  get isStoryboardReadyState() {
    return this.state.storyboardV2.state.type === 'ready';
  }

  setStoryboardReadyState() {
    this.setStoryboardV2State({ type: 'ready' });
  }

  setStoryboardProcessingTextState() {
    this.setStoryboardV2State({ type: 'processing_text' });
  }

  setStoryboardBlankState() {
    this.setStoryboardV2State({ type: 'blank' });
  }

  setStoryboardGeneratingScenesState(payload?: StoryboardGeneratingScenePayload) {
    this.setStoryboardV2State({ type: 'generating_scenes', payload });
  }

  updateAnimationData(data: any) {
    this.dispatch(this.actions.updateAnimationData(data));
  }

  updateInAnimations(data: AnimationData[]) {
    this.dispatch(this.actions.updateInAnimations(data));
  }

  updateOutAnimations(data: AnimationData[]) {
    this.dispatch(this.actions.updateOutAnimations(data));
  }

  updateInPlaceAnimations(data: any) {
    this.dispatch(this.actions.updateInPlaceAnimations(data));
  }

  updateInTransitions(data: AnimationData[]) {
    this.dispatch(this.actions.updateInTransitions(data));
  }

  updateOutTransitions(data: AnimationData[]) {
    this.dispatch(this.actions.updateOutTransitions(data));
  }

  setEditorLoadingStatus(status: EditorLoadingStatus) {
    this.dispatch(this.actions.editorLoadingStatusUpdated(status));
  }

  setCurrentTab(currentTab: string) {
    this.dispatch(this.actions.updateCurrentTab(currentTab));
  }

  get templateVariant() {
    return this.state.leftPanel.panels.templatesVariant;
  }

  set templateVariant(variant: TemplatesVariant) {
    this.dispatch(this.actions.templatesVariantUpdated({ variant }));
  }

  get stockVariant() {
    return this.state.leftPanel.panels.stockVariant;
  }

  set stockVariant(variant: StockVariant) {
    this.dispatch(this.actions.stockVariantUpdated({ variant }));
  }

  get musicVariant() {
    return this.state.leftPanel.panels.musicVariant;
  }

  set musicVariant(variant: MusicVariant) {
    this.dispatch(this.actions.musicVariantUpdated({ variant }));
  }

  get textVariant() {
    return this.state.leftPanel.panels.textVariant;
  }

  set textVariant(variant: TextVariant) {
    this.dispatch(this.actions.textVariantUpdated({ variant }));
  }

  get logoVariant() {
    return this.state.leftPanel.panels.logoVariant;
  }

  set logoVariant(variant: LogoVariant) {
    this.dispatch(this.actions.logoVariantUpdated({ variant }));
  }

  get colorVariant() {
    return this.state.leftPanel.panels.colorVariant;
  }

  set colorVariant(variant: ColorVariant) {
    this.dispatch(this.actions.colorVariantUpdated({ variant }));
  }

  get elementsVariant() {
    return this.state.leftPanel.panels.elementsVariant;
  }

  set elementsVariant(variant: ElementsVariant) {
    this.dispatch(this.actions.elementsVariantUpdated({ variant }));
  }

  get collageVariant() {
    return this.state.leftPanel.panels.collageVariant;
  }

  set collageVariant(variant: CollageVariant) {
    this.dispatch(this.actions.collageVariantUpdated({ variant }));
  }

  moduleHasLoaded(
    module: Module,
    version: 'frame' | 'classic' | 'iv-timeline' | 'iv-left-panels' | 'iv-storyboard' | 'iv-panels'
  ) {
    this.dispatch(this.actions.moduleLoaded({ module, version }));
  }

  setStoryboardV2State(state: StoryboardV2State) {
    this.dispatch(this.actions.updateStoryboardV2State(state));
  }

  moduleLoadHasFailed(module: Module) {
    this.dispatch(this.actions.moduleLoadFailed({ module }));
  }

  moduleHasUnloaded(module: Module) {
    this.dispatch(this.actions.moduleUnloaded({ module }));
  }
  //
  //// drag
  //

  startDrag(media: DraggedMedia) {
    DragRegistry.startDrag(media);
    const element = DragRegistry.getDraggedElementJSON();
    element && this.dispatch(this.actions.dragStarted({ element }));
  }

  cancelDrag() {
    DragRegistry.cancelDrag();
    this.dispatch(this.actions.dragCancelled());
  }

  completeDrag(media: DraggedMedia) {
    DragRegistry.completeDrag(media);
    const element = DragRegistry.getLastDroppedElementJSON();
    element && this.dispatch(this.actions.dragCompleted({ element }));
  }

  // iv-left-panels drop

  get hoveredSelectionBox() {
    return this.state.hoveredSelectionBox;
  }

  get lastHoveredSelectionBox() {
    return this.state.lastHoveredSelectionBox;
  }

  hoverSelectionBox(box: { boxId: string } | null) {
    this.dispatch(this.actions.selectionBoxHovered(box));
  }

  get hoverAddSceneButton() {
    return this.state.hoveredAddSceneButton;
  }
  set hoverAddSceneButton(props: { sceneIndex: number } | null) {
    this.dispatch(this.actions.addSceneButtonHovered(props));
  }

  completeDrop() {
    this.dispatch(this.actions.dropCompleted());
  }

  cancelDrop() {}

  //
  //// toggles
  //
  toggleGridlines() {
    this.dispatch(this.actions.gridlinesToggled());
  }
  toggleTransitionsTray() {
    this.dispatch(this.actions.transitionsTrayToggled());
  }
  toggleRecoredingCountdown() {
    this.dispatch(this.actions.recoredingCountdownToggled());
  }

  //TODO- @Nirmit to add the source
  openVideoTrimModal({
    trimModalData,
    source,
    onTrimCompleted,
  }: {
    trimModalData: TrimModalData;
    source?: VideoTrimModalOpenLocation;
    onTrimCompleted?: TrimCompletedHandler;
  }) {
    if (onTrimCompleted) OverlayRegistry.registerTrimHandlers(onTrimCompleted);
    this.dispatch(this.actions.videoTrimModalOpened({ trimModalData, source }));
  }

  updateVideoTrimData(trimModalData: TrimModalData) {
    this.dispatch(this.actions.videoTrimModalUpdated(trimModalData));
  }

  //TODO- @Nirmit to add the source
  closeVideoTrimModal(source: VideoTrimModalCloseLocation) {
    if (this.state.videoTrimModal.isVisible === false) {
      console.log('closeVideoTrimModal is noop');
      return;
    }
    this.dispatch(this.actions.videoTrimModalClosed(source));
    if (source === VideoTrimModalCloseLocation.ADD_BUTTON) OverlayRegistry.completeTrim();
    else OverlayRegistry.cancelTrim();
    OverlayRegistry.resetTrimHandlers();
  }

  get confirmation() {
    return this.state.overlays.confirm;
  }

  openConfirmationModal({
    title,
    body,
    primaryActionText = 'Confirm',
    secondaryActionText = 'Cancel',
    isSecondaryActionVisible = true,
    onConfirm,
  }: {
    title: string;
    body: string;
    primaryActionText: string;
    secondaryActionText: string;
    isSecondaryActionVisible: boolean;
    onConfirm?: (s: 'confirmed' | 'denied') => void;
  }) {
    if (onConfirm) OverlayRegistry.registerConfirmHandlers(onConfirm);
    this.dispatch(
      this.actions.confirmationOverlayUpdated({
        title,
        body,
        primaryActionText,
        secondaryActionText,
        isSecondaryActionVisible,
      })
    );
  }

  updateConfirmationStatus(status: 'confirmed' | 'denied') {
    OverlayRegistry.confirm(status);
    this.dispatch(
      this.actions.confirmationOverlayUpdated({
        title: null,
        body: null,
        primaryActionText: 'Confirm',
        secondaryActionText: 'Cancel',
        isSecondaryActionVisible: true,
      })
    );
    OverlayRegistry.resetConfirmHandlers();
  }

  get lastVideoTrimData() {
    return this.state.lastVideoTrimData;
  }

  set lastVideoTrimData(
    props: {
      hasTrimmed: boolean;
      trimSections: Array<Master.VideoTrimSectionJSON>;
    } | null
  ) {
    this.dispatch(this.actions.lastVideoTrimData(props));
  }

  get paymentConfirmModalMode() {
    return this.state.paymentConfirmModal.mode;
  }

  toggleMediaCropModal() {
    this.dispatch(this.actions.mediaCropModalToggled());
  }

  updateProjectAutoSave(status: boolean) {
    this.dispatch(this.actions.updateProjectAutoSave(status));
  }
  updateProjectForceSave(status: boolean) {
    this.dispatch(this.actions.updateProjectForceSave(status));
  }
  updateProjectAutoSaveStatus(status: ProjectAutoSaveStatus) {
    if (JSON.stringify(status) === JSON.stringify(this.state.projectAutoSave.status)) {
      log('optimization: updateProjectAutoSaveStatus is noop');
      return;
    }
    this.dispatch(this.actions.updateProjectAutoSaveStatus(status));
  }

  //
  //// transitions
  //
  resetAddTransition() {
    this.dispatch(this.actions.addTransitionWasReset());
  }
  resetRemoveTransition() {
    this.dispatch(this.actions.removeTransitionWasReset());
  }
  resetSelectedTransitionIndex() {
    this.dispatch(this.actions.selectedTransitionIndexWasReset());
  }

  setTransitionIndex(index: number | null) {
    this.dispatch(this.actions.transitionIndexUpdated({ index }));
  }
  setTransitionData(data: any) {
    this.dispatch(this.actions.transitionDataUpdated(data));
  }
  setTransitionNames(data: any) {
    this.dispatch(this.actions.transitionNamesUpdated(data));
  }
  setTransitions(data: any) {
    this.dispatch(this.actions.transitionsUpdated(data));
  }
  setDefaultTransition(data: any) {
    this.dispatch(this.actions.defaultTransitionUpdated(data));
  }
  setAddTransition(
    transitionId: number,
    transitionIndex: number | null,
    addToAll: boolean,
    source?: 'addTemplateTransition',
    transitionColors?: TransitionColors,
    time?: number,
    properties?: Master.FilterProperties
  ) {
    this.dispatch(
      this.actions.addTransitionUpdated({
        transitionId,
        transitionIndex,
        addToAll,
        source: source,
        transitionColors,
        time,
        properties,
      })
    );
  }
  setRemoveTransition(index: number) {
    this.dispatch(this.actions.removeTransitionUpdated(index));
  }
  setsceneLoadedForDuplicate(sceneNumber: number | string) {
    if (
      this.state.sceneLoadedForDuplicate.isVisible &&
      this.state.sceneLoadedForDuplicate.sceneNumber === sceneNumber
    ) {
      log('optimization: sceneLoadedForDuplicate is noop');
      return;
    }
    this.dispatch(this.actions.sceneLoadedForDuplicate({ sceneNumber }));
  }
  showSlateOverlayLoader(message: string | null) {
    this.dispatch(this.actions.showSlateOverlayLoader(message));
  }
  hideSlateOverlayLoader() {
    this.dispatch(this.actions.hideSlateOverlayLoader());
  }
  updateStoryboardSceneBGMedia(payload: StoryboardSceneBGMediaPayload) {
    this.dispatch(this.actions.storyboardSceneBGMediaUpdated(payload));
  }
  updateTimelineZoomLevel(level: number) {
    if (this.state.timeline.zoom.level === level) {
      log('optimization: updateTimelineZoomLevel is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineZoomLevel(level));
  }
  updateTimelineZoomLevelData(data: ZoomLevelData) {
    if (JSON.stringify(this.state.timeline.zoom.data) === JSON.stringify(data)) {
      log('optimization: updateTimelineZoomLevelData is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineZoomLevelData(data));
  }
  updateTimelineSceneExtendPrompt(isVisible: boolean) {
    if (this.state.timeline.prompts.extendScene === isVisible) {
      log('optimization: updateTimelineSceneExtendPrompt is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineSceneExtendPrompt({ isVisible }));
  }
  updateTimelineSnapTime(time: number | null) {
    if (this.state.timeline.snapTime === time) {
      log('optimization: updateTimelineSnapTime is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineSnapTime({ time }));
  }

  updateTimelineScrollIntent(payload: TimelineScrollIntent) {
    this.dispatch(this.actions.updateTimelineScrollIntent(payload));
  }

  updateTimelineHeight(height: number) {
    if (this.state.timeline.height === height) {
      log('optimization: updateTimelineHeight is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineHeight(height));
  }

  updateTimelineWidth(width: number) {
    if (this.state.timeline.width === width) {
      log('optimization: updateTimelineWidth is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineWidth(width));
  }

  updateTimelinePosition(position: { x: number; y: number }) {
    if (
      this.state.timeline.position.x === position.x &&
      this.state.timeline.position.y === position.y
    ) {
      log('optimization: updateTimelinePosition is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelinePosition(position));
  }

  updateTimelineScroll(scroll: { scrollX: number; scrollY: number }) {
    if (
      this.state.timeline.scroll.scrollX === scroll.scrollX &&
      this.state.timeline.scroll.scrollY === scroll.scrollY
    ) {
      log('optimization: updateTimelineScroll is noop');
      return;
    }
    this.dispatch(this.actions.updateTimelineScroll(scroll));
  }

  markNeedsRepaint(repaintType: RepaintType = RepaintType.BLOCK, options = { showLoader: false }) {
    this.dispatch(
      this.actions.repaintRequested({
        repaintType,
        showLoader: options.showLoader,
      })
    );
  }
  markRepainted() {
    this.dispatch(
      this.actions.repainted({
        repaintType: RepaintType.BLOCK,
        showLoader: false,
      })
    );
  }
  markComponentNeedsRepaint(id: string) {
    this.dispatch(
      this.actions.componentRepaintRequested({
        repaintType: RepaintType.COMPONENT,
        id,
      })
    );
  }
  markComponentRepainted() {
    this.dispatch(
      this.actions.componentRepainted({
        repaintType: RepaintType.BLOCK,
        id: null,
      })
    );
  }
  resetFilesForUpload() {
    this.dispatch(this.actions.resetFilesForUpload());
  }
  setLeftPanelTransitionEnded(state: boolean) {
    this.dispatch(this.actions.markLeftPanelTransitionEnded({ hasTransitionEnded: state }));
  }
  showFilmrModal(source: string) {
    this.dispatch(this.actions.showFilmrModal({ source }));
  }
  hideFilmrModal() {
    this.dispatch(this.actions.hideFilmrModal());
  }
  updatePasteState(payload: boolean) {
    this.dispatch(this.actions.pasteStateUpdate(payload));
  }
  updateCopyPasteAction(payload: CopyPasteAction) {
    this.dispatch(this.actions.copyPasteActionUpdate(payload));
  }
  selectlogoPlaceHolder(id: string, source = '') {
    this.dispatch(this.actions.logoPlaceHolderSelected({ id, source }));
  }
  unselectLogoPlaceholder() {
    this.dispatch(this.actions.logoPlaceHolderUnselected());
  }
  reset() {
    this.dispatch(this.actions.reset());
    DragRegistry.resetLastDroppedElement();
  }
  showGuestSignupModal({ action = '', authModalState = '' }) {
    this.dispatch(this.actions.showGuestSignupModal({ action, authModalState }));
  }
  hideGuestSignupModal() {
    this.dispatch(this.actions.hideGuestSignupModal());
  }
  showUpgradeModal(source: upgradeModalSource) {
    this.dispatch(this.actions.showUpgradeModal(source));
  }
  hideUpgradeModal() {
    this.dispatch(this.actions.hideUpgradeModal());
  }
  updatePricingModalTitle(title: string) {
    this.dispatch(this.actions.updatePricingModalTitle({ title }));
  }
  updatePricingModalPackPriorityFeature(feature: string) {
    this.dispatch(this.actions.updatePricingModalPackPriorityFeature({ feature }));
  }
  updatePricingModalShowContinueSession(show: boolean) {
    this.dispatch(this.actions.updatePricingModalShowContinueSession(show));
  }
  updatePricingModalContinueFlow(next: string) {
    this.dispatch(this.actions.updatePricingModalContinueFlow(next));
  }
  updatePricingModalOpenState(isOpen: boolean) {
    this.dispatch(this.actions.updatePricingModalOpenState(isOpen));
  }
  updatePaymentConfirmModalMode(mode: PaymentConfirmModalMode | null) {
    this.dispatch(this.actions.updatePaymentConfirmModalMode(mode));
  }
  updateCreditCardModalOpenState(isOpen: boolean) {
    this.dispatch(this.actions.updateCreditCardModalOpenState(isOpen));
  }
  triggerNormalMode() {
    if (this.state.mode === 'normal') {
      log('optimization: triggerNormalMode is noop');
      return;
    }
    this.dispatch(this.actions.normalModeRestored());
  }
  triggerExpensiveMode() {
    if (this.state.mode === 'expensive') {
      log('optimization: triggerExpensiveMode is noop');
      return;
    }
    this.dispatch(this.actions.expensiveModeTriggered());
  }

  resetStoryboardNavigation() {
    this.dispatch(
      this.actions.storyboardNavigationRouteUpdate({
        route: StoryboardNavigationRoute.NONE,
        optionalQueryParams: {},
      })
    );
  }

  navigateToAdvanceEditor(params?: { [key: string]: string }) {
    this.dispatch(
      this.actions.storyboardNavigationRouteUpdate({
        route: StoryboardNavigationRoute.ADVANCE_EDITOR,
        optionalQueryParams: params ?? {},
      })
    );
  }

  navigateToReview() {
    this.dispatch(this.actions.editorNavigationRouteUpdate(EditorNavigationRoute.REVIEW));
  }

  navigateToStoryboard(params?: { [key: string]: string }) {
    this.dispatch(
      this.actions.storyboardNavigationRouteUpdate({
        route: StoryboardNavigationRoute.STORYBOARD,
        optionalQueryParams: params ?? {},
      })
    );
  }

  triggerPostGuestSignupActions() {
    this.dispatch(this.actions.postGuestSignupExport());
  }

  showRemoveWatermarkBadge() {
    this.dispatch(this.actions.showRemoveWatermarkBadge());
  }
  hideRemoveWatermarkBadge() {
    this.dispatch(this.actions.hideRemoveWatermarkBadge());
  }

  toggleSocialBlockAdded() {
    this.dispatch(this.actions.toggleSocialBlockAdded());
  }

  setLeftPanelSelectedTab(selectedTab: ActiveTab | null, source?: LeftPanelActionSource) {
    this.dispatch(this.actions.setLeftPanelSelectedTab({ selectedTab, source }));
  }

  setLeftPanelSelectedSection(selectedSection: ActiveSection | null) {
    this.dispatch(this.actions.setLeftPanelSelectedSection({ selectedSection }));
  }

  get leftPanelSelectedTab() {
    return this.state.leftPanel.selectedTab;
  }

  get leftPanelSelectedSection() {
    return this.state.leftPanel.selectedSection;
  }
  get leftPanelTemplateState() {
    return this.state.leftPanel.templateState;
  }
  set leftPanelTemplateState(state: any) {
    this.dispatch(this.actions.setLeftPanelTemplateState({ state }));
  }
  openLeftPanelTab(
    tab: ActiveTab | null,
    fromPanel: LeftPanelActionSource = LeftPanelActionSource.LeftPanel
  ) {
    this.dispatch(this.actions.setLeftPanelActiveTab({ tab, fromPanel }));
  }

  get leftPanelTab() {
    return this.state.leftPanel.tab;
  }

  setAddSceneIndex(index: number) {
    this.dispatch(this.actions.setAddSceneIndex({ index }));
  }

  setTextAnimationActiveTab(value: 'Text' | 'Textbox') {
    this.dispatch(this.actions.setTextAnimationActiveTab(value));
  }

  toggleMicPermissionModal(value: boolean) {
    this.dispatch(this.actions.toggleMicPermissionModal(value));
  }

  toggleAudioTrimConfirmModal(value: boolean) {
    this.dispatch(this.actions.toggleAudioTrimConfirmModal(value));
  }

  updateAudioTrimConfirmConsent(value: boolean | null) {
    this.dispatch(this.actions.updateAudioTrimConfirmConsent(value));
  }

  setLogoTrayFilesForUpload(files: FileList | null) {
    this.dispatch(this.actions.setLogoTrayFilesForUpload(files));
  }

  getTimelineDropSource() {
    const timelineType = this.state.timeline.type;
    return timelineType === TimelineType.PROJECT
      ? DropSource.basicTimeline
      : DropSource.advancedTimeline;
  }

  get socialBlockAdded() {
    return this.state.socialBlock.isAdded;
  }

  get textPostProcessing() {
    return this.state.slate.textPostProcessing;
  }

  set textPostProcessing(value: {
    status: boolean;
    componentId: string;
    isUnlockFontSize: boolean;
  }) {
    this.dispatch(this.actions.updateTextPostProcessing(value));
  }

  get uploadQueueLength() {
    return this.state.uploadQueueLength;
  }

  set uploadQueueLength(length: number) {
    this.dispatch(this.actions.updateUploadQueueLength(length));
  }
  updatedIsRightPanelVisible(isVisible: boolean) {
    this.dispatch(this.actions.updatedIsRightPanelVisible(isVisible));
  }

  get isRightPanelVisible() {
    return this.state.rightPanel.isVisible;
  }

  get isIvLayeredTimeline() {
    return this.state.timeline.variant === TimelineVariant.IV_TIMELINE;
  }

  get fonts() {
    return this.state.fonts;
  }
  set fonts(payload: { loadingStatus: FontLoadingStatus; data: FontModel[] | null }) {
    this.dispatch(this.actions.updateFonts(payload));
  }

  get transitions() {
    return this.state.transitionsTray.currentTransition.transitions;
  }

  get textAnimationActiveTab() {
    return this.state.leftPanel.textAnimationActiveTab;
  }

  get defaultTransition() {
    return this.state.transitionsTray.currentTransition.defaultTransition;
  }

  get transitionComposites() {
    return this.state.transitionsTray.currentTransition.transitionComposites;
  }

  set transitionComposites(payload: TransitionCompositesState) {
    this.dispatch(this.actions.transitionCompositesUpdated(payload));
  }

  get sceneAddedFromLP() {
    return this.state.sceneAddedFromLP;
  }

  set sceneAddedFromLP(value: boolean) {
    this.dispatch(this.actions.updateSceneAddedFromLP(value));
  }

  set timelineSelectedClipDelete(
    value: {
      isRequestPending: true;
      uxSource: string;
      uxFlow: string;
    } | null
  ) {
    this.dispatch(this.actions.updateTimelineSelectedClipDelete(value));
  }

  get reviewPageDownloadButtonStatus() {
    return this.state.reviewPageDownloadButtonStatus;
  }

  get timelineHeight() {
    return this.state.timeline.height;
  }

  get timelineWidth() {
    return this.state.timeline.width;
  }

  get timelinePosition() {
    return this.state.timeline.position;
  }

  get timelineScroll() {
    return this.state.timeline.scroll;
  }

  get timelineShouldRender() {
    return this.state.timeline.shouldRender;
  }

  set timelineShouldRender(value: boolean) {
    this.dispatch(this.actions.timelineShouldRenderUpdated(value));
  }

  get keyboardShortcutPanelState() {
    return this.state.keyboardShortcutPanel;
  }

  get lihpPostExportModalState() {
    return this.state.lihpPostExportModal;
  }

  get lihpAuthPostProcessParams() {
    return this.state.lihpAuthPostProcessParams;
  }

  get paymentModalParams() {
    return this.state.paymentModalParams;
  }

  set paymentModalParams(value: PaymentModalParams) {
    this.dispatch(this.actions.updatePaymentModalParams(value));
  }

  updateRouterEvent(value: RouterEvent) {
    this.dispatch(this.actions.updateRouterEvent(value));
  }
  clickedDownloadButtonInReviewPage() {
    this.dispatch(this.actions.clickedDownloadButtonInReviewPage());
  }
  loadingDownloadButtonInfoInReviewPage() {
    this.dispatch(this.actions.loadingDownloadButtonInfoInReviewPage());
  }
  handledDownloadButtonClickInReviewPage() {
    this.dispatch(this.actions.handledDownloadButtonClickInReviewPage());
  }

  clipTopPanelButtonClickStatusUpdated(payload: ClipTopPanelButtonClickStatus) {
    this.dispatch(this.actions.clipTopPanelButtonClickStatusUpdated(payload));
  }

  audioTopPanelButtonClickStatusUpdated(payload: AudioTopPanelButtonClickStatus) {
    this.dispatch(this.actions.audioTopPanelButtonClickStatusUpdated(payload));
  }
  volumePanelCloseButtonClickStatusUpdated(payload: VolumePanelCloseButtonClickStatus) {
    this.dispatch(this.actions.volumePanelCloseButtonClickStatusUpdated(payload));
  }

  isAudioLockedUpdated(isAudioLocked: boolean) {
    this.dispatch(this.actions.isAudioLockedUpdated(isAudioLocked));
  }

  set editorMode(mode: EditorMode | null) {
    if (mode === null) {
      throw new Error('Editor mode cannot be null!');
    }
    this.dispatch(this.actions.updateEditorMode(mode));
  }

  get editorMode(): EditorMode | null {
    return this.state.editorMode;
  }

  get exportVideo() {
    return this.state.exportVideo;
  }
  set exportVideo(exportOptions) {
    this.dispatch(this.actions.clickExportVideo(exportOptions));
  }
  get isExportModalVisible() {
    return this.state.editorExportModal.isVisible;
  }
  set isExportModalVisible(value) {
    this.dispatch(this.actions.updateExportModalVisible(value));
  }
  get editorModeSource() {
    return this.state.editorModeSource;
  }

  set editorModeSource(source: EditorModeSource) {
    this.dispatch(this.actions.updateEditorModeSource(source));
  }

  set isStoryboardVOClickHandled(handled: boolean) {
    this.dispatch(this.actions.updateIsStoryboardVOClickHandled(handled));
  }

  get isStoryboardVOClickHandled() {
    return this.state.isStoryboardVOClickHandled;
  }

  get isEditorMode() {
    return this.state.editorMode === 'editor';
  }

  get isStoryboardMode() {
    return this.state.editorMode === 'storyboard';
  }

  get isFullScreenPreviewActive() {
    return this.state.fullscreenPreview.active;
  }

  hideFullScreenPreview() {
    this.dispatch(this.actions.hideFullscreenPreview());
  }

  showFullScreenPreview() {
    this.dispatch(this.actions.showFullscreenPreview());
  }

  set frameContainerHeight(height: number) {
    this.dispatch(this.actions.updateFrameContainerHeight(height));
  }

  get frameContainerHeight() {
    return this.state.frame.containerHeight;
  }

  get editorLoaded() {
    return this.state.editorLoadingStatus === 'loaded';
  }

  showAlertModal({ title = '', message = '' }) {
    this.dispatch(this.actions.showAlertModal({ title, message }));
  }

  isAudioLevelMeterVisible(): boolean {
    return this.state.audioLevelMeter.visible;
  }

  setLeftPanelModalStatus(status: boolean) {
    this.dispatch(this.actions.setLeftPanelModalStatus({ status }));
  }

  get leftPanelModalStatus() {
    return this.state.leftPanel.modalStatus;
  }

  toggleAudioLevelMeter() {
    if (this.state.audioLevelMeter.visible) {
      this.dispatch(this.actions.updateAudioLevelMeterVisibility(false));
    } else {
      this.dispatch(this.actions.updateAudioLevelMeterVisibility(true));
    }
  }

  hideAudioLevelMeter() {
    this.dispatch(this.actions.updateAudioLevelMeterVisibility(false));
  }

  isAudioLevelMeterEnabled(): boolean {
    return this.state.audioLevelMeter.isEnabled;
  }

  updateTransitionTrayType(type: 'old' | 'new') {
    this.dispatch(this.actions.transitionTrayTypeUpdated(type));
  }

  enableAudioLevelMeter() {
    this.dispatch(this.actions.updateAudioLevelMeterEnabled(true));
  }

  disableAudioLevelMeter() {
    this.dispatch(this.actions.updateAudioLevelMeterEnabled(false));
  }

  togglekeyboardShortcutPanel(visible: boolean) {
    this.dispatch(this.actions.keyboardShortcutPanelToggled({ visible }));
  }

  setTimelineType(type: TimelineType) {
    this.dispatch(this.actions.updateTimelineTypeIntent({ type }));
  }
  showFrameExternalLoader(message?: string) {
    this.dispatch(
      this.actions.updateFrameExternalLoader({ visible: true, message: message ?? '' })
    );
  }

  hideFrameExternalLoader() {
    this.dispatch(this.actions.updateFrameExternalLoader({ visible: false, message: '' }));
  }

  toggleLihpPostExportModalVisibility(visible: boolean) {
    this.dispatch(this.actions.lihpPostExportModalVisibilityToggled({ visible }));
  }

  toggleLihpAuthPostProcessParams(params: LihpPostProcessParams) {
    this.dispatch(this.actions.lihpAuthPostProcessParamsUpdated(params));
  }

  updatePaymentModalParams(params: PaymentModalParams) {
    this.dispatch(this.actions.updatePaymentModalParams(params));
  }

  setTransitionConfirmation(state: ConfirmationState) {
    this.dispatch(this.actions.transitionConfirmationSet(state));
  }

  pushTransitionHistory(id: string, entry: TransitionHistoryEntry) {
    this.dispatch(this.actions.transitionHistoryPushed({ id, entry }));
  }

  getTransitionParameters(id: string): TransitionHistoryEntry | null {
    return this.state.transitionsTray.transitionHistory[id];
  }
  openIvCommonModal<T = any>(modalType: ModalType, props: T) {
    this.dispatch(this.actions.openIvCommonModal({ isOpen: true, modalType, props }));
  }
  closeIvCommonModal() {
    this.dispatch(this.actions.openIvCommonModal({ isOpen: false }));
  }
  updateCustomTextStyles(styles: State['customTextStyles']) {
    this.dispatch(this.actions.updateCustomTextStyles(styles));
  }
  addBgRemovalJob(job: BgRemovalJob) {
    this.dispatch(this.actions.addBgRemovalJob(job));
  }
  removeBgRemovalJob(jobId: string) {
    this.dispatch(this.actions.removeBgRemovalJob(jobId));
  }
  removeBgRemovalJobByComponentId(componentId: string) {
    this.dispatch(this.actions.removeBgRemovalJobByComponentId(componentId));
  }

  updateBackgroundRemoveLimitAlert(backgroundRemoveLimitAlert: boolean) {
    this.dispatch(this.actions.updateBackgroundRemoveLimitAlert(backgroundRemoveLimitAlert));
  }

  updateBgRemovalJobStatus(jobId: string, status: REMOVE_VIDEO_BG_STATUS) {
    this.dispatch(this.actions.updateBgRemovalJobStatus({ jobId, status }));
  }
  updateBgRemovalJobProxyData(jobId: string, proxyData: ProxyJobResult) {
    this.dispatch(this.actions.updateBgRemovalJobProxyData({ jobId, proxyData }));
  }

  getBgRemovalJobs() {
    return this.state.bgRemovalJobs;
  }
  clearBgRemovalJobs() {
    this.dispatch(this.actions.clearBgRemovalJobs());
  }

  requestRedirectionToEditor() {
    this.dispatch(this.actions.setRedirectionRequest({ path: 'editor' }));
  }

  clearRedirection() {
    this.dispatch(this.actions.clearRedirection());
  }

  set lastImageBgRemoveJob(
    job: { url: string; imageMediaProperties: Master.ImageMediaPropertiesJSON } | null
  ) {
    this.dispatch(this.actions.setLastImageBgRemoveJob(job));
  }
  get lastImageBgRemoveJob(): {
    url: string;
    imageMediaProperties: Master.ImageMediaPropertiesJSON;
  } | null {
    return this.state.lastImageBgRemoveJob;
  }

  set lastVideoBgRemoveJob(
    payload: { url: string; videoMediaProperties: RemoveVideoBgPostProcessRequest } | null
  ) {
    this.dispatch(this.actions.setLastVideoBgRemoveJob(payload));
  }

  get lastVideoBgRemoveJob() {
    return this.state.lastVideoBgRemoveJob;
  }

  get isOnline() {
    return this.state.isOnline;
  }

  set isOnline(status: boolean) {
    this.dispatch(this.actions.setOnlineStatus(status));
  }

  requestFolderCreation(payload: { activeTab: ActiveTab }) {
    this.dispatch(this.actions.folderCreationRequested(payload));
  }

  savedCustomTextStyle() {
    this.dispatch(this.actions.savedCustomTextStyle());
  }

  saveCustomTextStyle() {
    this.dispatch(this.actions.saveCustomTextStyle());
  }

  refetchedCustomTextStyles() {
    this.dispatch(this.actions.refetchedCustomTextStyles());
  }

  updateInspectorSelectedProperty(payload: State['inspector']['selectedProperty']) {
    this.dispatch(this.actions.updateInspectorSelectedProperty(payload));
  }

  get selectedInspectorProperty() {
    return this.state.inspector.selectedProperty;
  }

  updateSceneWarnLimitStateAlerted(payload: boolean) {
    this.dispatch(this.actions.updateSceneWarnLimitStateAlerted(payload));
  }

  fetchedInitialMasterJSON() {
    this.dispatch(this.actions.fetchedInitialMasterJSON());
  }

  get stockVideoTemplateInfo() {
    return this.state.stockVideoTemplate;
  }
  set stockVideoTemplateInfo(info: StockVideoTemplateInfo | null) {
    this.dispatch(this.actions.updateStockVideoTemplateData(info));
  }
  updateIsMyMediaBackButtonHidden(payload: boolean) {
    this.dispatch(this.actions.updateIsMyMediaBackButtonHidden(payload));
  }
  showUploadMediaModal() {
    this.dispatch(this.actions.showUploadMediaModal());
  }
  closeUploadMediaModal() {
    this.dispatch(this.actions.closeUploadMediaModal());
  }
  addImageBgRemovalJob(job: ImageBgRemovalJob) {
    this.dispatch(this.actions.addImageBgRemovalJob(job));
  }
  updateImageBgRemovalJob(job: ImageBgRemovalJob) {
    this.dispatch(this.actions.updateImageBgRemovalJobForComponent(job));
  }
  removeImageBgRemovalJob(componentId: string) {
    this.dispatch(this.actions.removeImageBgRemovalJobByComponentId(componentId));
  }
  addBlobURL(payload: { blobURL: string; fileMetadata: LeftPanelMediaFileMetadata }) {
    this.dispatch(this.actions.addBlobURL(payload));
  }
  get blobUrlMap() {
    return this.state.blobURLMap;
  }

  uploadFailed(payload: { blobURL: string; key: string }) {
    this.dispatch(this.actions.uploadFailed(payload));
  }

  get failedUploadsBlobURLs() {
    return this.state.failedUploadsBlobURLs;
  }

  deletedUploadingAsset(payload: string) {
    this.dispatch(this.actions.deletedUploadingAsset(payload));
  }

  get deletedUploadsBlobURLs() {
    return this.state.deletedBlobURLs;
  }

  logoBgRemoved() {
    this.dispatch(this.actions.logoBgRemoved());
  }

  refetchedLogos() {
    this.dispatch(this.actions.refetchedLogos());
  }

  get maintenanceBanner() {
    return this.state.maintenanceBanner.isOpen;
  }

  set maintenanceBanner(data: boolean) {
    this.dispatch(this.actions.updateMaintenaceBanner(data));
  }

  get featurePromoBanner() {
    return this.state.featurePromoBanner.isOpen;
  }

  set featurePromoBanner(data: boolean) {
    this.dispatch(this.actions.updateFeaturePromoBanner(data));
  }

  closeStudioBanner() {
    this.dispatch(this.actions.updateStudioBanner(false));
  }
}

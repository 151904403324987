export enum PAYMENT_PLANS {
  BUSINESS_WATERMARK = 'BUSINESS_WATERMARK',
  FILMR_PRO = 'FILMR_PRO',
  FREE = 'FREE',
  PROFESSIONAL = 'PROFESSIONAL',
  BUSINESS_TRIAL = 'BUSINESS_TRIAL',
  BUSINESS = 'BUSINESS',
  UNLIMITED = 'UNLIMITED',
  PREMIUM = 'PREMIUM',
}
interface PlanPrice {
  monthly: {
    currency: string;
    price: number;
    basePrice?: number;
    subText: string;
    starText?: string;
  };
  annually: {
    currency: string;
    price: number;
    basePrice?: number;
    subText: string;
    starText?: string;
  };
}
interface CTA {
  monthly: {
    text: string;
    url: string;
    action?: string;
    backgroundColorClass: string;
    textColorClass: string;
  };
  annually: {
    text: string;
    url: string;
    action?: string;
    backgroundColorClass: string;
    textColorClass: string;
  };
}

interface Feature {
  highlightedText: string;
  plainText: string;
}
interface Redirection {
  url: string;
  type: string;
  modal?: boolean;
}
interface AppPromo {
  firstLine: string;
  secondLine: string;
  CTAText: string;
  backgroundGradientClass: string;
  appImage: string;
}

export interface PlanAction {
  actionText: string;
  action: string;
  actionUrl: string;
  displayText: string;
}

export interface Stripe {
  gatewayPlanId: string;
  inrPlanId: string;
  usdPlanId: string;
  productId: string;
}

export interface Recurly {
  gatewayPlanId: string;
}

export interface PlanAction {
  actionText: string;
  action: string;
  actionUrl: string;
  displayText: string;
}

export interface GatewayData {
  gatewayPlanId?: string;
  inrPlanId?: string;
  usdPlanId?: string;
  usPlanId?: string;
  productId?: string;
}

export interface DurationObject {
  durationInDays: number;
  durationDisplay: string;
  planAction?: PlanAction;
  basePrice: number;
  displayPrice?: number | string;
  displayBasePrice?: number | string;
  sellingPrice: number;
  stripe: GatewayData;
  recurly: GatewayData;
  paddle?: GatewayData;
  product_code: string;
  monthly_price: number;
  monthlyPrice?: number;
  monthly_price_display: number;
}

export interface Plan {
  name: string;
  description: string;
  bannerText?: string;
  planPrice?: PlanPrice;
  CTA: CTA;
  feature: Feature[];
  features: string[];
  key_features: string[];
  currency_symbol: string;
  label: string;
  rank: number;
  tagline: string;
  featuresCheckImage: string;
  redirection: Redirection;
  appPromo?: AppPromo;
  durations: DurationObject[];
  id: number;
}

export type PricingPlans = Plan[];

export interface UserGeoInfo {
  as?: string;
  city?: string;
  country?: string;
  countryCode: string;
  isp?: string;
  lat?: number;
  lon?: number;
  org?: string;
  query?: string;
  region: string;
  regionName?: string;
  status?: string;
  timezone?: string;
  zip?: string;
}

export interface CouponState {
  couponCode: string;
  discountCouponApplied: boolean;
  couponResponse: Record<string, any> | null;
  couponType: string;
}

export enum Gateway {
  stripe = 'stripe',
  paddle = 'paddle',
  recurly = 'recurly',
  none = 'none',
}

export enum PaymentStatus {
  TRIAL = 'TRIAL',
  UPGRADETRIAL = 'UPGRADE_TRIAL',
  DOWNGRADETRIAL = 'DOWNGRADE_TRIAL',
  CANCELTRIAL = 'CANCEL_TRIAL',
  TRIALEND = 'TRIAL_END',
  ACTIVE = 'ACTIVE',
  CANCEL = 'CANCELLED',
  PAUSE = 'PAUSE',
}

export enum ModalType {
  watermarkUpgrade = 'watermarkUpgrade',
  plans = 'plans',
  checkout = 'checkout',
  paymentSuccess = 'paymentSuccess',
  none = 'none',
}

export interface PaymentModalRouteConfig {
  route: ModalType;
  couponCode: string;
}
export type PricingModalText = {
  title?: PricingCardTitle;
  upgradeType?: UpgradeType;
  priorityFeature?: PricingPlanPriorityFeature;
  isClosed: boolean;
};

export type UserGateWay =
  | 'PADDLE'
  | 'STRIPE'
  | 'STRIPE_SG'
  | 'STRIPE_US'
  | 'RECURLY'
  | 'INVIDEO'
  | '';

export type PaymentModalType =
  | 'NONE'
  | 'PRICING_CARDS'
  | 'CHECKOUT'
  | 'PAYMENT_SUCCESS'
  | 'WATERMARK_UPGRADE';

export interface PaymentModalState {
  modalType: PaymentModalType;
  isOpen: boolean;
  props?: any;
  onSuccessCb?: (response?: any) => any;
  exitCtaText?: string;
}

export type PaymentState = {
  pricingPlans: PricingPlans;
  geoInfo: UserGeoInfo;
  countryCode: string;
  gateway: Gateway;
  userGateway: UserGateWay;
  currency: string;
  durationSwitch: boolean;
  coupon: CouponState;
  selectedPlan: Plan | null;
  receiptData: Record<string, any> | null;
  checkoutError: Record<string, any> | null;
  pricingModal: null | PricingModalText;
  paymentModalState: PaymentModalState;
  paymentSuccessProps: Record<string, any> | null;
  paymentModalRoute: PaymentModalRouteConfig;
};

export enum PricingCardTitle {
  SUBSCRIBE = '<div>Upgrade to remove watermark on all videos </div>',
  REMOVE_BG = '<div>Upgrade to get remove background credits </div>',
  EXPORT_1080P = '<div>1080p export is available with subscription plans only </div>',
  EXCEEDED_QUOTA = 'Upgrade to Unlimited plan to get more iStock clips',
  UPLOAD_LIMIT_EXCEEDED = '<div>Upgrade your plan to upload files over 200MB </div>',
  PREMIUM_MEDIA = 'Upgrade to use Premium/iStock media without watermark',
  UNLIMITED = '<div>Upgrade to Unlimited plan to get additional benefits </div>',
  FONT_UPLOAD = '<div class="heading-text"><span class="highlighted-text">Upgrade to InVideo</span> subscription plans <span class="new-line">and start to upload your own fonts</span></div>',
  COLOR_PALLET = '<div class="heading-text"><span class="highlighted-text">Upgrade to InVideo</span> subscription plans <span class="new-line"> to create your own palettes</span></div>',
  UPLOAD_UPGRADE = '<div class="heading-text"><span class="highlighted-text">Upgrade to InVideo</span> subscription plans <span class="new-line"> and get upload limit upto 800MB</span></div>',
  UPLOAD = '<div class="heading-medium-text">Looks like you’re trying to upload a file that is more than 200MB. <span class="new-line heading-small-text pt-2"><span class="highlighted-text">Upgrade to InVideo</span> subscription plans and get unlimited video export</span></div>',
  UPLOAD_LOGO = '<div class="heading-text"><span class="highlighted-text">Upgrade to InVideo</span> subscription plans <span class="new-line"> and start to upload as many logos as you want</span></div>',
  REMOVE_WATERMARK = '<div>Upgrade to remove watermark on all videos </div>',
  UPGRADE_TO_EXPORT = '<div>Upgrade to export your video </div>',
}

export enum UpgradeType {
  REMOVE_WATERMARK = 'remove_watermark',
  REMOVE_BG = 'remove_bg',
  ISTOCK = 'istock',
  DEFAULT = 'default',
  EXPORT_1080P = '1080p',
}

export enum PricingPlanPriorityFeature {
  FONT_UPLOAD = 'Upload your own fonts',
  COLOR_PALLET = 'Create and save your palettes',
  UPLOAD_LOGO = 'Upload more than 2 logos',
}

export enum PaymentMode {
  ONBOARDING = 'onboarding',
}

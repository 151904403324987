import {
  CanvasKit,
  LineMetrics,
  Paragraph,
  ParagraphStyle,
  TypefaceFontProvider,
} from './canvaskitTypes';
import {
  FontAsset,
  Granularity,
  RichTextStyle,
  Size,
  StyledTextChunks,
  TextStyle,
} from './skiaTypes';
import {
  addSoftBreaksForWrap,
  fixSoftBreaks,
  isSurrogateChar,
  MAX_FONT_SIZE,
  NEWLINE_FOR_WRAP,
} from './SkiaUtil';

declare global {
  interface Window {
    CanvasKit: CanvasKit;
  }
}

export interface ParagraphMetrics {
  size: Size;
  lineMetrics: LineMetrics[];
  grainBounds: { x: number; y: number; width: number; height: number }[];
  fontSize: number;
}

export interface ParagraphData {
  paragraph: Paragraph;
  paragraphText: string;
  lineMetrics: LineMetrics[];
  fontSize: number;
  minIntrinsicWidth: number;
}

type word = { text: string; chunksCount: number };
const LINE_BREAKS = ['\n', NEWLINE_FOR_WRAP];
const WORD_BREAKS = [...LINE_BREAKS, ' '];
const SPLIT_STR = '21c9434ce78c03be0bd1ed913ea4b13140a9c26b8e73a74b02478f3c68f9e765'; // random string for splitting text with spaces
const countTrailingSpaces = (str: string) => [...str].reverse().join('').search(/\S/);
const lastOf = (input: string | word[]): string | word => input[input.length - 1];

export class Skia {
  fontManager: TypefaceFontProvider;
  _registeredFonts: string[] = [];

  private static instance?: Skia;

  public static get singleton(): Skia {
    if (!Skia.instance) {
      Skia.instance = new Skia();
    }
    return Skia.instance;
  }

  private constructor() {
    const CK = (window as any).CanvasKit as CanvasKit;
    this.fontManager = CK.TypefaceFontProvider.Make();
  }

  registerFonts(fonts: FontAsset[]) {
    for (let i = 0; i < fonts.length; i++) {
      const font = fonts[i]; // eg. key = '/Muli-Bold.ttf'
      const fontName = font.src;
      if (!this._registeredFonts.includes(fontName)) {
        this._registeredFonts.push(fontName);
        this.fontManager.registerFont(font.data, fontName);
      }
    }
  }

  get registeredFonts() {
    return this._registeredFonts;
  }

  makeStyle(attributes: TextStyle | null, rtl: boolean): ParagraphStyle {
    const CK = (window as any).CanvasKit as CanvasKit;
    const textStyle = this.makeTextStyle(attributes, null);
    const textDirection = rtl
      ? window.CanvasKit.TextDirection.RTL
      : window.CanvasKit.TextDirection.LTR;
    const paraStyle = new CK.ParagraphStyle({
      textStyle,
      textAlign: CK.TextAlign.Left,
      textDirection,
    });
    return paraStyle;
  }

  hasBlankLines(styledTextChunks: StyledTextChunks): boolean {
    if (styledTextChunks.length < 2) return false;
    for (let index = 0; index < styledTextChunks.length - 1; index++) {
      const chunk = styledTextChunks[index];
      const nextChunk = styledTextChunks[index + 1];
      if (chunk.text === '\n' && chunk.text === nextChunk.text) return true;
    }
    return false;
  }

  getParagraphForLayout(
    styledTextChunks: StyledTextChunks,
    style: RichTextStyle,
    maxLayoutSize: Size,
    bandPaddingX: number,
    maxFontSize: number,
    granularity: Granularity,
  ): ParagraphData {
    const styleCopy = JSON.parse(JSON.stringify(style));
    const MIN_FONT_SIZE = 12;
    const FONT_SIZE_STEP = 1;

    // Helper methods
    const f = (fontSize: number) => (fontSize - (fontSize % 3)) * 0.98;

    const sanitize = (styledTextChunks: StyledTextChunks): StyledTextChunks => {
      let foundNewLine = false;
      const isWholeAnim = granularity === 'whole';
      const isCenterAlign = style.textAlignment === 'center';
      return styledTextChunks.map((chunk, i) => {
        if (chunk.text !== '\n') {
          foundNewLine = false;
          const text = chunk.text;
          const nextChunkText = i < styledTextChunks.length - 1 ? styledTextChunks[i + 1].text : '';
          const isNextChunkNewLine = nextChunkText === '\n';
          const isLastCharSpace = [String.fromCharCode(160), ' '].includes(text[text.length - 1]);
          if (isNextChunkNewLine && isWholeAnim && isCenterAlign && isLastCharSpace) {
            const newText = text.substr(0, text.length - 1);
            return { ...chunk, text: newText };
          }
          return { ...chunk };
        }
        if (foundNewLine) {
          // is blank line
          return { ...chunk, text: `${String.fromCharCode(160)}\n` };
        }
        foundNewLine = true;
        return { ...chunk };
      });
    };

    const adjustForBlankLines = () => {
      if (style.defaultTextStyle.fontSize <= 0) {
        styleCopy.defaultTextStyle.fontSize = styleCopy.highlightTextStyle.fontSize = f(iFontSize);
      }
      const textChunks = sanitize(styledTextChunks);
      const paragraphForCorrectLineMetrics = this.buildParagraph(
        textChunks,
        styleCopy,
        bandPaddingX,
        maxLayoutSize.width,
      ).paragraph;
      const correctLineMetrics = paragraphForCorrectLineMetrics.getLineMetrics();
      paragraphForCorrectLineMetrics.delete();
      correctLineMetrics.forEach((lineMetric, i) => {
        if (i < prevParagraphData.lineMetrics.length)
          prevParagraphData.lineMetrics[i].height = lineMetric.height;
      });
    };
    const textChunks = sanitize(styledTextChunks);
    const build = (fontSize?: number): ParagraphData => {
      if (fontSize) {
        styleCopy.defaultTextStyle.fontSize = styleCopy.highlightTextStyle.fontSize = f(fontSize);
      }
      return this.buildParagraph(textChunks, styleCopy, bandPaddingX, maxLayoutSize.width);
    };

    // Start building paragraph
    let prevParagraphData: ParagraphData, currentParagraphData: ParagraphData;
    let iFontSize = MIN_FONT_SIZE;
    if (styleCopy.isFontSizeFixed) {
      prevParagraphData = build();
    } else {
      prevParagraphData = build(iFontSize);
      currentParagraphData = build(iFontSize);
      if (styledTextChunks.length > 0) {
        while (
          currentParagraphData.paragraph.getHeight() < maxLayoutSize.height &&
          currentParagraphData.minIntrinsicWidth < maxLayoutSize.width &&
          (!maxFontSize || iFontSize * 0.98 <= maxFontSize)
        ) {
          prevParagraphData.paragraph.delete();
          prevParagraphData = currentParagraphData;
          currentParagraphData = build(iFontSize);
          iFontSize += FONT_SIZE_STEP;
        }
      }
      // Set iFontSize back to the last used font size
      iFontSize -= FONT_SIZE_STEP;
      currentParagraphData.paragraph.delete();
    }
    if (this.hasBlankLines(styledTextChunks)) {
      if (!styleCopy.isFontSizeFixed) {
        styleCopy.defaultTextStyle.fontSize = styleCopy.highlightTextStyle.fontSize = f(iFontSize);
        prevParagraphData.paragraph.delete();
        prevParagraphData = this.buildParagraph(
          styledTextChunks,
          styleCopy,
          bandPaddingX,
          maxLayoutSize.width,
        );
      }
      adjustForBlankLines();
    }
    return prevParagraphData;
  }

  makeTextStyle(attribs: TextStyle | null, fallbackFonts: string[] | null) {
    const attributes: TextStyle | { [key: string]: undefined } = attribs || {};
    const CK = (window as any).CanvasKit as CanvasKit;
    const color = attributes.color
      ? CK.Color(attributes.color.r, attributes.color.g, attributes.color.b, attributes.color.a)
      : CK.BLACK;
    const backgroundColor = attributes.backgroundColor
      ? CK.Color(
          attributes.backgroundColor.r,
          attributes.backgroundColor.g,
          attributes.backgroundColor.b,
          attributes.backgroundColor.a / 255,
        )
      : CK.BLACK;
    const fontSize = attributes.fontSize || 25;
    const _fallbackFonts = (fallbackFonts ?? []).map((f) => f);
    const fontFamilies = attributes.font
      ? [attributes.font, ..._fallbackFonts]
      : ['NotoSans-Regular', ..._fallbackFonts];
    const heightMultiplier = attributes.lineHeight || 1.4;
    const letterSpacing = attributes.letterSpacing ?? 0;
    const decoration = attributes.underline ? 1 : 0;
    const decorationStyle = CK.DecorationStyle.Solid;
    const decorationThickness = 1;
    const fontStyle = {
      weight: attributes.bold ? CK.FontWeight.Bold : CK.FontWeight.Normal,
      slant: attributes.italic ? CK.FontSlant.Italic : CK.FontSlant.Upright,
    };
    const textStyle = new CK.TextStyle({
      color,
      backgroundColor,
      fontSize,
      fontFamilies,
      heightMultiplier,
      letterSpacing,
      decoration,
      decorationStyle,
      decorationThickness,
      fontStyle,
      shadows: [],
    });
    return textStyle;
  }

  getFontManager() {
    return this.fontManager;
  }

  // In Shell, buildParagraph is always called only for measuring text metrics
  buildParagraph(
    styledTextChunks: StyledTextChunks,
    style: RichTextStyle,
    bandPaddingX: number,
    maxWidth: number,
  ): ParagraphData {
    const CK = (window as any).CanvasKit as CanvasKit;
    const defaultParaStyle = this.makeStyle(null, style.rtl);
    const builder = CK.ParagraphBuilder.MakeFromFontProvider(defaultParaStyle, this.fontManager);
    let text = '';
    styledTextChunks.forEach((chunk) => {
      const grainStyle =
        chunk.type === 'highlight' ? style.highlightTextStyle : style.defaultTextStyle;
      if (chunk.text !== '\n') {
        let textStyle = this.makeTextStyle(grainStyle, style.fallbackFonts);
        textStyle = new CK.TextStyle(textStyle);
        builder.pushStyle(textStyle);
      }
      text += chunk.text;
      builder.addText(chunk.text);
    });
    const paragraph = builder.build();
    builder.delete();
    const paragraphText = text;
    paragraph.layout(Number.POSITIVE_INFINITY);
    const minIntrinsicWidth = paragraph.getMinIntrinsicWidth();
    paragraph.layout(maxWidth - 2 * bandPaddingX * style.defaultTextStyle.fontSize);
    return {
      minIntrinsicWidth,
      paragraph,
      paragraphText,
      lineMetrics: paragraph.getLineMetrics(),
      fontSize: style.defaultTextStyle.fontSize,
    };
  }

  getGrainsMetrics(
    styledTextChunks: StyledTextChunks,
    style: RichTextStyle,
    maxLayoutSize: Size,
    granularity: Granularity,
    bandPaddingX: number,
    maxFontSize?: number,
  ): { wrappedStyledTextChunks: StyledTextChunks; paragraphMetrics: ParagraphMetrics } {
    const { paragraph, paragraphText, lineMetrics, fontSize } = this.getParagraphForLayout(
      styledTextChunks,
      style,
      maxLayoutSize,
      bandPaddingX,
      maxFontSize ?? MAX_FONT_SIZE,
      granularity,
    );
    const ceil = Math.ceil;
    let width = 0;
    let height = 0;
    lineMetrics.forEach((lineMetric) => {
      width = Math.max(width, lineMetric.width);
      height += lineMetric.height; // * style.defaultTextStyle.lineHeight;
    });
    width = ceil(width);
    height = ceil(height);
    let grainBounds: ParagraphMetrics['grainBounds'] = [];
    switch (granularity) {
      case 'chars': {
        styledTextChunks = fixSoftBreaks(addSoftBreaksForWrap(styledTextChunks, lineMetrics));
        const chars: word[] = Array.from(paragraphText)
          .map((c) => {
            return { text: c, chunksCount: 1 };
          })
          .reduce((acc: word[], curr: word) => {
            /*
            Array.from() returns emoji and modifier/surrogate seperately
            1. Array.from('👍🏻') = ['👍', '🏻']
            2. Array.from('👍') = ['👍']
            */
            if (isSurrogateChar(curr.text) && acc.length > 0) {
              acc[acc.length - 1].text = acc[acc.length - 1].text + curr.text;
            } else {
              acc.push(curr);
            }
            return acc;
          }, []);
        grainBounds = this.getBoundingRects(chars, paragraph, true);
        if (this.hasBlankLines(styledTextChunks)) {
          grainBounds = this.adjustY(grainBounds, lineMetrics);
        }
        break;
      }
      case 'words': {
        styledTextChunks = addSoftBreaksForWrap(styledTextChunks, lineMetrics);
        const chunkTextArray: string[] = [];
        styledTextChunks.forEach((chunk) => {
          if (LINE_BREAKS.includes(chunk.text)) {
            chunkTextArray.push(chunk.text);
          } else {
            const markedText = chunk.text.replaceAll(/[ ]+/g, (s) => `${s}${SPLIT_STR}`);
            const textArray: string[] = markedText.split(SPLIT_STR).filter((t) => t !== '');
            chunkTextArray.push(...textArray);
          }
        });
        styledTextChunks = fixSoftBreaks(styledTextChunks);

        const wordsArray = chunkTextArray.reduce((acc: word[], curr: string) => {
          // `curr.trim() === ''` is for combining words with highlights in the end. In this case the trailing space goes to next chunk
          if (
            acc.length > 0 &&
            !LINE_BREAKS.includes(curr) &&
            !LINE_BREAKS.includes((lastOf(acc) as word).text) &&
            lastOf((lastOf(acc) as word).text) !== ' ' &&
            (curr.charAt(0) !== ' ' || curr.trim() === '')
          ) {
            acc[acc.length - 1].text += curr;
            if (curr.trim() !== '') acc[acc.length - 1].chunksCount++;
          } else {
            acc.push({ text: curr, chunksCount: 1 });
          }
          return acc;
        }, []);
        grainBounds = this.getBoundingRects(wordsArray, paragraph, false);
        if (this.hasBlankLines(styledTextChunks)) {
          grainBounds = this.adjustY(grainBounds, lineMetrics);
        }
        break;
      }
      case 'lines': {
        styledTextChunks = fixSoftBreaks(addSoftBreaksForWrap(styledTextChunks, lineMetrics));
        let lineY = 0;
        grainBounds = lineMetrics.map((lineMetric) => {
          const grainBound = {
            x: 0,
            y: lineY,
            width: width,
            height: ceil(lineMetric.height),
          };
          lineY += lineMetric.height;
          return grainBound;
        });
        break;
      }
      default: {
        grainBounds = [
          {
            x: 0,
            y: 0,
            height: height,
            width: width,
          },
        ];
      }
    }
    paragraph.delete();
    return {
      wrappedStyledTextChunks: styledTextChunks,
      paragraphMetrics: {
        size: { width: width, height: height },
        grainBounds: grainBounds,
        lineMetrics: lineMetrics,
        fontSize,
      },
    };
  }

  adjustY(
    grainBounds: ParagraphMetrics['grainBounds'],
    lineMetrics: LineMetrics[],
  ): ParagraphMetrics['grainBounds'] {
    if (grainBounds.length === 0) return grainBounds;
    let currentLineIdx = 0;
    let prevLineMetric = lineMetrics[currentLineIdx];
    let prevGrainY = grainBounds[0].y;
    let yFromLineMetric = 0;
    const adjustedGrainBounds = grainBounds.map((grain) => {
      if (grain.y !== prevGrainY) {
        do {
          yFromLineMetric += prevLineMetric.height;
          prevLineMetric = lineMetrics[++currentLineIdx];
        } while (prevLineMetric && prevLineMetric.width === 0);
      }
      prevGrainY = grain.y;
      return { ...grain, y: yFromLineMetric };
    });
    return adjustedGrainBounds;
  }

  getBoundingRects(textArray: word[], paragraph: Paragraph, splitByChars = false) {
    const CK = (window as any).CanvasKit as CanvasKit;
    const rectCollection: { x: number; y: number; width: number; height: number }[] = [];
    const splitByWords = !splitByChars;
    const push = (rect: Float32Array, text: string) => {
      if (!WORD_BREAKS.includes(text))
        rectCollection.push({
          x: rect[0],
          y: rect[1],
          width: Math.ceil(rect[2] - rect[0]),
          height: Math.ceil(rect[3] - rect[1]),
        });
    };

    for (let i = 0, j = 0; i < textArray.length; i++) {
      let textPart = textArray[i].text;
      if (splitByWords) {
        if (WORD_BREAKS.includes(textPart)) {
          if (textPart !== NEWLINE_FOR_WRAP) j++;
          continue;
        }
        textPart = textPart.trim();
        if (textPart === '') {
          j += textArray[i].text.length;
          continue;
        }
      }
      const length = textPart.length;

      const now = j;
      const next = now + length;
      j = next + (splitByChars ? 0 : countTrailingSpaces(textArray[i].text));

      const rects: Float32Array[] = paragraph.getRectsForRange(
        now,
        next,
        CK.RectHeightStyle.Max,
        CK.RectWidthStyle.Max,
      );
      if ((window as any).SKIA_DEBUG) {
        console.log('SKIA_DEBUG', textArray[i], rects);
      }
      if (splitByChars || rects.length === 1) {
        push(rects[0], textPart);
      } else if (rects.length > 1) {
        const firstRect = rects[0];
        const nextEntry = textArray[i + 1];
        if (nextEntry && rects.length > textArray[i].chunksCount) {
          rects.pop();
        }
        const lastRect = rects[rects.length - 1];

        if (firstRect[1] === lastRect[1]) {
          const newRect = new Float32Array(firstRect);
          newRect[2] = lastRect[2]; // use x2 of last rect
          push(newRect, textPart);
        } else {
          const compressedRects = rects.reduce((acc: Float32Array[], curr: Float32Array) => {
            const lastRect = acc[acc.length - 1];
            if (lastRect && lastRect[1] === curr[1]) {
              lastRect[2] = curr[2];
            } else {
              const newRect = new Float32Array(curr);
              acc.push(newRect);
            }
            return acc;
          }, []);
          compressedRects.forEach((rect) => push(rect, textPart));
        }
      }
    }
    return rectCollection;
  }
}

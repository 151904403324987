import { BLANK_TEMPLATE_ID } from '../project/constants';
import { Template } from '../jsonTypes/';
import { TemplateType } from '../project/types';

export function getTemplateType(template: Template.JSON): TemplateType {
  return template?.id === BLANK_TEMPLATE_ID
    ? TemplateType.Blank
    : template?.id === 14132
    ? TemplateType.Stock
    : template?.config?.enable_one_click
    ? TemplateType.Quick
    : TemplateType.Storyboard;
}

import { Store } from '../store/index';
import * as Project from '../project/index';
import { Slate } from '../jsonTypes';

export const getCompositiesOfLayer = (layerId: string, sceneId: string) => {
  const scene = Store.project.getSlateBlock(sceneId);
  const composities = Store.project
    .getSlateCompositeChildren(scene)
    ?.filter((c: Slate.CompositeJSON) => c.layerId === layerId);

  return composities;
};

export const isGapAvailableInLayer = (layerId: string, sceneId: string, dur: Project.TimeRange) => {
  const clips = getCompositiesOfLayer(layerId, sceneId);

  for (const clip of clips) {
    const otherStart = clip.tStart;
    const otherEnd = clip.tEnd;
    const other = { start: otherStart, end: otherEnd };
    if (!Project.timeUtils.emptyTimeRange(dur, other)) {
      return false;
    }
  }
  return true;
};

export const createVideoLayer = (
  below?: string | undefined | null,
  above?: string | undefined | null,
) => {
  if (!below && !above) {
    console.error('Must have either a below or an above.');
    return;
  }
  const layers = Store.project.getSlateLayers();
  const logoPlaceHolderId = layers.find((l: Slate.ComponentIdentifier<'layer'>) => {
    const layer = Store.project.getSlateLayer(l.id);
    if (!layer) return null;
    if (layer.layerSubType === Slate.LayerVideoSubType.LOGO_PLACEHOLDER) {
      return true;
    }
    return null;
  });

  const canvasBGId = layers.find((l: Slate.ComponentIdentifier<'layer'>) => {
    const layer = Store.project.getSlateLayer(l.id);
    if (!layer) return null;
    if (layer.layerSubType === Slate.LayerVideoSubType.CANVAS_BACKGROUND) {
      return true;
    }
    return null;
  });

  let belowLayer: Slate.LayerJSON | null = logoPlaceHolderId
    ? Store.project.getSlateLayer(logoPlaceHolderId.id)
    : null;
  let aboveLayer: Slate.LayerJSON | null = canvasBGId
    ? Store.project.getSlateLayer(canvasBGId.id)
    : null;

  if (below) {
    belowLayer = Store.project.getSlateLayer(below);
  }

  if (above) {
    aboveLayer = Store.project.getSlateLayer(above);
  }

  const [index] = Project.layerManager.getVideoIndicesBetween(
    belowLayer?.index,
    aboveLayer?.index,
    1,
  );

  const id = `.${Project.uuid()}`;
  const newLayer: Slate.LayerJSON = {
    uid: id,
    id,
    name: '',
    cacheKey: `${Date.now()}`,
    hidden: false,
    visible: true,
    type: 'layer',
    layerType: 'video',
    layerSubType: Slate.LayerVideoSubType.VIDEO,
    index: index,
  };
  Store.project.addSlateLayer(newLayer);
  return Store.project.getSlateLayer(newLayer.id);
};

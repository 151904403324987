import * as ShadowRegistry from '../shadowRegistry';
import * as SlateRegistry from '../slateRegistry';
import { Shadow, Master } from '../../jsonTypes';
import * as Slate from '../../jsonTypes/SlateJSON';

export const isPresent = <T>(t: T | undefined | null | void): t is T => {
  return t !== undefined && t !== null;
};

const updateDim = () => {
  const root = SlateRegistry.getSlateRoot();
  // Update Dimension
  const lastScene = root.blocks[root.blocks.length - 1];
  const slateScene = SlateRegistry.getSlateBlock(lastScene.id);
  const dim = ShadowRegistry.getShadowDimensionJson();
  if (slateScene) {
    if (slateScene.tEnd <= 0) {
      throw new Error(`last slatescene tEnd can not be ${slateScene.tEnd}`);
    }
    dim.duration.absolute_duration = slateScene.tEnd;
    dim.duration.absolute_start = 0;
    dim.duration.absolute_end = slateScene.tEnd;
  }
};

const updateVideoElement = (slate: Slate.VideoJSON, element: Shadow.VideoJSON) => {
  const trim = element.layout.trim || [];
  const savedValues = trim && trim[0] ? { ...trim[0] } : { ceil: 0, floor: 0 };
  if (slate.trim) {
    element.layout.trim = slate.trim?.map((trim) => {
      const duration = trim.endTime - trim.startTime;
      return {
        ...savedValues,
        duration: duration,
        start: trim.startTime,
        startTime: trim.startTime,
        end: trim.endTime,
        endTime: trim.endTime,
      };
    });
  }

  const precalculatedDuration =
    slate.trim?.reduce((acc, t) => {
      acc += t.endTime - t.startTime;
      return acc;
    }, 0) ||
    element.duration.precalculated_duration ||
    0;

  element.duration.element_duration = precalculatedDuration / slate.speed;
  element.duration.precalculated_duration = precalculatedDuration;
  element.layout.loop = slate.loop ? 1 : 0;
  element.layout.playback_rate = slate.speed;
  element.playback_rate_strategy = slate.playbackRateStrategy;
};

const updateElement = (id: string) => {
  const slateElement = SlateRegistry.getSlateComponent(id);
  const shadowElement = ShadowRegistry.getShadowComponentById(id);
  if (!slateElement || !shadowElement) return;
  const duration = slateElement.tEnd - slateElement.tStart;
  shadowElement.duration.absolute_duration = duration;
  shadowElement.duration.absolute_start = slateElement.tStart;
  shadowElement.duration.absolute_end = slateElement.tEnd;

  if (slateElement.type === 'video') {
    updateVideoElement(slateElement, shadowElement as Shadow.VideoJSON);
  }

  shadowElement.components.filter(isPresent).forEach((c) => updateElement(c.id));
};

const updateClips = () => {
  const root = SlateRegistry.getSlateRoot();
  // Update clips
  root.blocks.forEach((block) => {
    const slateScene = SlateRegistry.getSlateBlock(block.id);
    const shadowScene = ShadowRegistry.getShadowBlockById(block.id);
    if (!slateScene || !shadowScene) return;
    const clips = slateScene.componentIds;

    clips.forEach((clip) => {
      const slateClip = SlateRegistry.getSlateComposite(clip.id);
      const shadowClip = ShadowRegistry.getShadowComponentById(clip.id) as Shadow.CompositeJSON;
      if (!slateClip || !shadowClip) return;
      const duration = slateClip.tEnd - slateClip.tStart;
      shadowClip.duration.absolute_duration = duration;
      shadowClip.duration.element_duration = duration;
      shadowClip.duration.absolute_start = slateClip.tStart;
      shadowClip.duration.absolute_end = slateClip.tEnd;
      shadowClip.layer_id = slateClip.layerId;
      updateEffect(clip.id);
      slateClip.componentIds.forEach((c) => updateElement(c.id));
    });
  });
};

const updateScenes = () => {
  const root = SlateRegistry.getSlateRoot();
  // Update scenes
  root.blocks.forEach((block) => {
    const slateScene = SlateRegistry.getSlateBlock(block.id);
    const shadowScene = ShadowRegistry.getShadowBlockById(block.id);
    if (!slateScene || !shadowScene) return;
    const duration = slateScene.tEnd - slateScene.tStart;
    shadowScene.duration.absolute_duration = duration;
    shadowScene.duration.fixed_duration = duration;
    shadowScene.duration.absolute_start = slateScene.tStart;
    shadowScene.duration.absolute_end = slateScene.tEnd;
  });
};

const invlerp = (a: number, b: number, v: number) => {
  return (v - a) / (b - a);
};

const updateComponentRelative = (id: string, parentData: { tStart: number; tEnd: number }) => {
  const component = ShadowRegistry.getShadowComponentById(id);
  if (!component) return;
  const startTime = invlerp(parentData.tStart, parentData.tEnd, component.duration.absolute_start);
  const endTime = invlerp(parentData.tStart, parentData.tEnd, component.duration.absolute_end);
  component.duration.start_time = startTime;
  component.duration.end_time = endTime;

  if (component.type === 'composite') {
    component.duration.fixed_start_time = startTime;
    component.duration.fixed_end_time = endTime;
  }

  const pData = {
    tStart: component.duration.absolute_start,
    tEnd: component.duration.absolute_end,
  };
  component.components.filter(isPresent).forEach((c) => updateSceneRelative(c.id, pData));
};

const updateSceneRelative = (id: string, parentData: { tStart: number; tEnd: number }) => {
  const scene = ShadowRegistry.getShadowBlockById(id);
  if (!scene) return;
  const startTime = invlerp(parentData.tStart, parentData.tEnd, scene.duration.absolute_start);
  const endTime = invlerp(parentData.tStart, parentData.tEnd, scene.duration.absolute_end);
  scene.duration.start_time = startTime;
  scene.duration.end_time = endTime;

  const pData = {
    tStart: scene.duration.absolute_start,
    tEnd: scene.duration.absolute_end,
  };
  scene.components.filter(isPresent).forEach((c) => updateComponentRelative(c.id, pData));
};

const updateRelativeTime = () => {
  const dim = ShadowRegistry.getShadowDimensionJson();
  dim.duration.start_time = 0;
  dim.duration.end_time = 1;

  const pData = {
    tStart: dim.duration.absolute_start,
    tEnd: dim.duration.absolute_end,
  };
  dim.blocks.forEach((block) => {
    updateSceneRelative(block.id, pData);
  });
};

const updateEffect = (id: string) => {
  let slateComponent: Slate.ComponentJSON | null = SlateRegistry.getSlateBlock(id);
  let shadowComponent: Shadow.ComponentJSON | Shadow.BlockJSON | null =
    ShadowRegistry.getShadowBlockById(id);
  if (!slateComponent) {
    slateComponent = SlateRegistry.getSlateComponent(id);
    shadowComponent = ShadowRegistry.getShadowComponentById(id);
  }
  if (!slateComponent || !shadowComponent) return;
  const duration = slateComponent.tEnd - slateComponent.tStart;
  if (shadowComponent.animation.animation_in !== 'None') {
    const inAnimationDuration = slateComponent.tIn - slateComponent.tStart;
    const effectTime = invlerp(0, duration, inAnimationDuration);
    if (shadowComponent.animation.animation_in_parameters) {
      shadowComponent.animation.animation_in_parameters.effect_time = effectTime;
      shadowComponent.animation.animation_in_parameters.in_effect_factor = inAnimationDuration;
    }
  }
  if (shadowComponent.animation.animation_out !== 'None') {
    const outAnimationDuration = slateComponent.tEnd - slateComponent.tOut;
    const effectTime = invlerp(0, duration, outAnimationDuration);
    if (shadowComponent.animation.animation_out_parameters) {
      shadowComponent.animation.animation_out_parameters.effect_time = effectTime;
      shadowComponent.animation.animation_out_parameters.out_effect_factor = outAnimationDuration;
    }
  }
};

const updateFilterTransitionDuration = (prevBlockId: string, blockId: string) => {
  const prevClip = SlateRegistry.getSlateBlock(prevBlockId);
  const clip = SlateRegistry.getSlateBlock(blockId);
  const prevShadowBlock = ShadowRegistry.getShadowBlockById(prevBlockId);
  const shadowBlock = ShadowRegistry.getShadowBlockById(blockId);
  if (prevClip && clip && prevShadowBlock && shadowBlock) {
    const transitionDuration = prevClip.tEnd - clip.tStart;
    if (prevShadowBlock.filter_transition?.out)
      prevShadowBlock.filter_transition.out.duration = transitionDuration;
    if (shadowBlock.filter_transition?.in)
      shadowBlock.filter_transition.in.duration = transitionDuration;
  }
};

const updateDelay = (prevBlockId: string, blockId: string) => {
  const prevClip = SlateRegistry.getSlateBlock(prevBlockId);
  const clip = SlateRegistry.getSlateBlock(blockId);
  const shadowBlock = ShadowRegistry.getShadowBlockById(blockId);
  if (prevClip && clip && shadowBlock) {
    shadowBlock.duration.delay = clip.tStart - prevClip.tEnd;
  }
};

const updateEffectTime = () => {
  const dim = ShadowRegistry.getShadowDimensionJson();
  dim.blocks.reduce<Shadow.BlockJSON | null>((prev, block) => {
    updateEffect(block.id);
    if (prev) {
      updateDelay(prev.id, block.id);
      updateFilterTransitionDuration(prev.id, block.id);
    }
    return block;
  }, null);
};

const updateAudio = () => {
  const root = SlateRegistry.getSlateRoot();
  root.audioBlocks.forEach((audio) => {
    const slateAudio = SlateRegistry.getSlateAudio(audio.id);
    const shadowAudio = ShadowRegistry.getShadowAudioBlockById(audio.id);
    if (!slateAudio || !shadowAudio) return;
    if (shadowAudio.sub_type === 'bg_video') return;
    if (
      slateAudio.audioType === Slate.AUDIO_TYPE.BG_MUSIC ||
      slateAudio.audioType === Slate.AUDIO_TYPE.VOICE_OVER
    ) {
      if (shadowAudio.sub_type !== slateAudio.audioType)
        shadowAudio.sub_type = slateAudio.audioType;
    }
    shadowAudio.layer_id = slateAudio.layerId;
    const duration = slateAudio.tEnd - slateAudio.tStart;
    shadowAudio.duration.absolute_duration = duration;
    shadowAudio.duration.absolute_start = slateAudio.tStart;
    shadowAudio.duration.absolute_end = slateAudio.tEnd;
    shadowAudio.loop = slateAudio.loop ? 1 : 0;
    if (shadowAudio.trim_section) {
      shadowAudio.trim_section.start_time = slateAudio.trimSection.startTime;
      shadowAudio.trim_section.end_time = slateAudio.trimSection.endTime;
    }
    shadowAudio.audio_volume = slateAudio.volume;
    shadowAudio.bg_volume = slateAudio.duckedVolume;
    shadowAudio.is_ducking = slateAudio.isDucked;

    const audioList: Master.AudioListItemJSON[] = slateAudio.fadeSections
      .slice(0, -1)
      .map((fade) => {
        return {
          absolute_start: 0,
          absolute_end: 0,
          absolute_duration: 0,
          audio_volume: fade.vEnd,
          fade_start: fade.tStart,
          fade_time: fade.tEnd - fade.tStart,
        };
      });

    shadowAudio.audio_list = audioList;
  });
};

const updateLayer = () => {
  const root = SlateRegistry.getSlateRoot();
  const layers = root.layers
    .map((layer) => {
      const slateLayer = SlateRegistry.getSlateLayer(layer.id);
      if (!slateLayer) return null;
      const l = {
        isDestroyed: false,
        uid: slateLayer.uid,
        shadowType: 'layer',
        tUpdated: performance.now(),
        id: slateLayer.id,
        index: slateLayer.index,
        visible: slateLayer.visible,
        type: 'layer',
        layer_type: slateLayer.layerType,
        layer_sub_type: slateLayer.layerSubType,
        name: slateLayer.name,
      };
      return l as Shadow.LayerJSON;
    })
    .filter(isPresent)
    .sort((a, b) => b.index - a.index);

  const dim = ShadowRegistry.getShadowDimensionJson();
  dim.layers = layers;
};

export const publishTimeMutation = () => {
  updateDim();
  updateLayer();
  updateScenes();
  updateClips();
  updateAudio();
  updateRelativeTime();
  updateEffectTime();
};

import { ExportSettings, RenderSettings } from '../project';

export type AnyJSON = SelectableJSON | BlockJSON | LayerJSON;
export type SelectableJSON = AudioBlockJSON | AnyComponentJSON;
export type AnyComponentJSON = ComponentJSON | IgnoredComponentJSON;

export enum TimelineMode {
  Smart = 'smart',
  Dumb = 'dumb',
}

export enum Prompts {
  BASIC_TIMELINE_INFO_ALERT = 'basic_timeline_info_alert',
  HIDE_ADVANCED_SELECTIVELY = 'hide_adv_selectively',
  BLANK_CANVAS_DROP = 'blank_canvas_drop',
  HIDE_TRIM_SCENE_SELECTIVELY = 'hide_trim_scene_selectively',
}

export type ComponentJSON = TextJSON | CompositeJSON | ImageJSON | VideoJSON | OtherComponentJSON;
// components with bad properties
export type IgnoredComponentJSON = IgnoredMissingText;

export type Dimension = '16:9' | '9:16' | '1:1';
export type GifOrientation = 'horizontal' | 'width' | 'square';
export type workflow = 'marketing' | 'storyboard-v2' | 'Storyteller';
export type Color = [string, string, string, number];
// TODO: strongly type when needed by storyboard
export type ColorType = 'primary' | 'quaternary' | 'secondary' | 'tertiary';
export type VideoProperties = {
  fonts: { primary: string; secondary: string; tertiary: string };
  colors: {
    [key in ColorType]: [string, string, string, number];
  };
  template_properties: {
    colors: {
      [key in ColorType]: [string, string, string, number];
    };
  };
  colors_ref?: {
    colors: {
      [key in ColorType]: [string, string, string, number];
    };
  };
  applied_colors: string;
};
export type AudioParams = unknown;
export type EpicResult = unknown;

export type Upload = {
  original_filename: string;
  filename: string;
  unit: string;
  size: number[];
  dimension: { height: number; width: number };
  type: string;
  url: string;
  urls: string[];
  uploads_v2: boolean;
  property_type?: string | null;
  ids: number[];
  image_link: string;
};
export interface PremiumAlert {
  paid_free_pass: boolean;
  paid_limit_exceed: boolean;
}

export interface JSON {
  master_video_id: number;
  '16:9'?: DimensionJSON;
  '9:16'?: DimensionJSON;
  '1:1'?: DimensionJSON;
  dimensions: string[];
  headline?: string | null;
  text?: string | null;
  is_colors_applied?: boolean;
  colors_pallet?: Array<string>;
  colors_applied_name?: string;
  applied_colors_id?: number;
  colors_shuffle_value?: number;
  uploads?: Upload[];
  extraFonts?: Array<string>;
  logo_placeholder_hidden?: boolean;
  premium_alerts?: PremiumAlert;
  type?: string;
  listicle_flag: number;
  logo_url?: string;
  recent_fonts?: Array<string>;
  workflow?: workflow;
}
export interface StoryJSON {
  master_video_id: number;
  '16:9'?: StoryDimensionJSON;
  '9:16'?: StoryDimensionJSON;
  '1:1'?: StoryDimensionJSON;
  dimensions: string[];
  headline?: string | null;
  text?: string | null;
  uploads: Upload[];
}
export interface DimensionJSON {
  export_settings?: ExportSettings;
  render_settings?: RenderSettings;
  template_id: number | string;
  master_video_id: number;
  screen_size: number[];
  blocks: BlockJSON[];
  layers: LayerJSON[];
  duration: DurationJSON;
  audio_blocks: AudioBlockJSON[];
  end_clip_url?: string;
  video_transition?: VideoTransitionJSON;
  account_id?: string | number;
  default_transition?: number;
  content_type?: string;
  processing_type?: string;
  listicle_flag?: number;
  headline?: string;
  text?: string;
  step?: number;
  video_properties: VideoProperties;
  spans?: (SpanJSON | OtherSpanJSON)[];
  transitions?: number[];
  template_name: string;
  premium: Array<unknown>;
  disable_music_auto_extend?: boolean;
  timeline_config?: {
    mode: string;
  };
  templateBlocks?: BlockJSON[];
  prompts?: {
    [key in Prompts]: boolean;
  };
  analytics?: {
    'Started With Route': string;
    'Started With Keyword': string;
  };
  brand_watermark?: boolean;
  versions?: {
    textEngine: number;
    forDimension?: string;
  };
  resolution?: '720' | '1080';
  script?: string;
  scenes?: Array<Scene>;
  storyboardFrom?: string;
  scene_generation_config?: SceneGenerationConfig;
  logo_url?: string;
  interval_time?: number;
}

export interface Scene {
  pretext: string;
  text: string;
  posttext: string;
  type: string;
  sub_type: string;
  keywords: string[];
  id: string;
  blockGenerationStatus: 'INIT' | 'GENERATING' | 'GENERATED';
  media: Array<{ type: 'image' | 'video'; resource: ImageResult | VideoResult }>;
}

export interface ImageResult {
  image_id: string;
  media_type: string;
  image_type: string;
  url: string;
  source: string;
  partner_image_id: string;
  width: number;
  height: number;
  word: string;
  search_license: string;
  image_url: string;
  thumbnail_url: string;
  image_link: string;
  api_type: string;
  search_term: string;
}

export interface VideoResult {
  preview_link: string;
  thumbnail_link: string;
  video_link: string;
  source: string;
  partner_video_id: string;
  video_id: string;
  metadata: { premium: boolean };
  search_term: string;
  video_format: string;
  media_type: string;
  url: string;
  description: string;
  tags: string;
  width: number;
  height: number;
  aspect: number;
  duration: number;
  aspect_ratio: string;
  frame_rate: number;
  api_type: string;
  word: string;
}

export interface SceneGenerationConfig {
  is_free_assets: boolean;
  is_istock_assets: boolean;
  is_premium_assets: boolean;
}
export interface StoryDimensionJSON {
  template_id: number | string;
  screen_size: number[];
  blocks?: (BlockJSON | null)[];
  duration?: DurationJSON;
  audio_blocks?: (AudioBlockJSON | null)[];
  end_clip_url?: string;
  video_transition?: VideoTransitionJSON;
  account_id?: string | number;

  content_type?: string;
  processing_type?: string;
  listicle_flag?: number;

  headline: string;
  text: string;
  step: number;
  template_name?: string;
  template_type?: string;
  emotion?: string;
  video_properties: VideoProperties;

  spans: (SpanJSON | OtherSpanJSON)[];
  transitions: number[];
  default_transition: number;
  audio_url?: string;
  audio_duration?: number;
  audio_params?: AudioParams;
}

export interface SpanTextJSON {
  value: string;
  html: string;
  highlighted_strings: string[];
  highlighted_text: string;
}

export interface SpanNounJSON {
  word: string;
  word_context: string;
  type: string;
  subtype: string;
  media_type: string;
}

export interface SpanJSON {
  id: string;
  type: 'headline' | 'story' | 'quote' | 'question' | 'social';
  sub_type?: string;
  pretext?: string[];
  text?: SpanTextJSON[];
  posttext?: string[];
  nouns?: SpanNounJSON[];
  epic_result?: EpicResult;
}
type NotSpan<T> = T extends 'headline' | 'story' | 'quote' | 'question' | 'social' ? never : T;
export interface OtherSpanJSON {
  id: string;
  type: NotSpan<string>;
  sub_type?: string;
  pretext?: string[];
  text?: SpanTextJSON[];
  posttext?: string[];
  nouns?: SpanNounJSON[];
  epic_result?: EpicResult;
}

export interface BlockJSON {
  id: string;
  type: string;
  components: CompositeJSON[];
  layout: LayoutJSON;
  duration: AbsoluteDurationJSON;
  position: OtherPositionJSON;
  animation: AnimationJSON;
  filter_transition?: FilterTransitionJSON;
  block_id?: string | number | null;
  name: string;
  sub_type?: string;
  default_endclip?: string | boolean;
  screen_size?: number[];
  benefits?: BenefitsJSON;
  no_watermark?: boolean;
  block_type?: string;
  thumbnail_url?: string;
  thumbnail_updated_at?: number;
  thumbnail_hash?: number;
  isAccurateThumbnail?: boolean;
  tag?: BlockTag;
  edits?: number;
  block_saved?: boolean;
}

export type BlockTag =
  | 'custom_block'
  | 'headline_block'
  | 'story_block'
  | 'quote_block'
  | 'question_block';

export type LayerType = 'audio' | 'video';

export enum LayerVideoSubType {
  TRANSITION = 'transition',
  LOGO_PLACEHOLDER = 'logo-placeholder',
  VIDEO = 'video',
  CANVAS_BACKGROUND = 'canvas-background',
}

export enum LayerAudioSubType {
  AUDIO = 'audio',
  BG_MUSIC = 'bg-music',
  VOICE_OVER = 'voice-over',
  BG_VIDEO = 'bg-video',
}

export type LayerSubType = LayerVideoSubType | LayerAudioSubType;

export interface LayerJSON {
  id: string;
  index: number;
  visible: boolean;
  type: 'layer';
  layer_type: LayerType;
  layer_sub_type: LayerSubType;
  name: string;
  components?: never[];
}

interface IgnoredMissingDuration {
  name: string;
  id: string;
  type: string;
  position: PositionJSON;
  input: InputJSON;
  layout: LayoutJSON;
  duration: {
    absolute_duration: number;
    absolute_start: number;
    absolute_end: number;
    start_time: undefined;
    end_time: undefined;
  };
  animation: AnimationJSON;
  components: (ComponentJSON | IgnoredComponentJSON)[];
  is_lock?: boolean;
  is_visible?: boolean;
}

export interface IgnoredMissingText {
  name: string;
  id: string;
  type: 'text';
  sub_type: string;
  text?: string;
  highlighted_text?: undefined;
  initial_highlighted_text?: string;
  highlighted_strings?: string[];
  position: PositionJSON;
  input: InputJSON;
  layout: TextLayoutJSON;
  duration: TextAbsouluteDurationJSON;
  animation: AnimationJSON;
  is_lock?: boolean;
  is_visible?: boolean;
  audio?: Record<string, unknown>;
  screen_edit: number;
  screen_click: number;
  components?: never[];
}

export type StyledTextChunks = StyledTextChunk[];
export type StyledTextChunk = { text: string; type: 'default' | 'highlight' };
export type Grain = {
  richText: StyledTextChunks;
  size: { width: number; height: number };
  offset: { x: number; y: number };
  padding: { h: number; v: number };
};
export type Grains = Grain[];
export type TextMetricsJSON = {
  size: { width: number; height: number };
  grains: Grains;
  style: { font_size: number };
};

export interface TextJSON {
  name: string;
  id: string;
  type: 'text';
  sub_type: string;
  text: string;
  highlighted_text: string;
  initial_highlighted_text: string;
  highlighted_strings: string[];
  position: PositionJSON;
  input: InputJSON;
  layout: TextLayoutJSON;
  metrics: TextMetricsJSON;
  duration: TextAbsouluteDurationJSON;
  animation: AnimationJSON;
  is_lock?: boolean;
  is_visible?: boolean;
  audio?: Record<string, unknown>;
  screen_edit: number;
  screen_click: number;
  components?: never[];
}

export enum CompositeType {
  BACKGROUND = 'Background',
  LOGO = 'Logo',
  ELEMENT = 'Element',
  STICKER_IMAGE = 'StickerImage',
  STICKER_VIDEO = 'StickerVideo',
  SHAPE_IMAGE = 'ShapeImage',
  SHAPE_VIDEO = 'ShapeVideo',
  TEXT = 'Text',
  IMAGE = 'Image',
  VIDEO = 'Video',
  GROUP = 'Group',
  OVERLAY = 'Overlay',
  WIDGET = 'Widget',
  AUDIO = 'Audio',
  CANVAS_BACKGROUND = 'CanvasBackground',
  LOGO_PLACEHOLDER = 'LogoPlaceholder',
  TRANSITION = 'Transition',
  DEFAULT = 'Default',
}

export interface CompositeJSON {
  name: string;
  id: string;
  type: 'composite';
  position: PositionJSON;
  input: InputJSON;
  layout: LayoutJSON;
  duration: AbsoluteDurationJSON;
  animation: AnimationJSON;
  compositeType: CompositeType;
  components: (ComponentJSON | IgnoredComponentJSON)[];
  original_position?: PositionJSON;
  clip_color?: number[];
  is_lock?: boolean;
  is_visible?: boolean;
  is_hidden?: boolean;
  logo_placeholder_hidden?: boolean;
  gif_orientation?: GifOrientation;
  sub_type: string;
  layer_name: string;
  layer_id: string | null;
  layer_visible: boolean;
  timeline_config?: {
    mode: string;
  };
  sr_no?: number;
  tag?: string;
}
export interface ImageJSON {
  name: string;
  id: string;
  type: 'image' | 'svg_image';
  sub_type?: string;
  orientation: string;
  url: string;
  thumbnail_url?: string;
  position: PositionJSON;
  input: InputJSON;
  layout: ImageLayoutJSON;
  duration: AbsoluteDurationJSON;
  animation: AnimationJSON;
  word?: string;
  is_lock?: boolean;
  is_visible?: boolean;
  media_type?: string;
  media_properties?: ImageMediaPropertiesJSON;
  components?: never[];
  watermark?: boolean;
  isPending?: boolean;
  removed_background?: boolean;
  bg_removals?: ImageBgRemovalsJSON;
  backup_url?: string; // used in case of logos to get the original logo url in case of logo cropping
  position_copy?: PositionJSON; // used in case of logos to reset the placeholder when added logo is deleted
  layout_copy?: ImageLayoutJSON; // used in case of logos to reset the placeholder when added logo is deleted
  animation_copy?: AnimationJSON; // used in case of logos to reset the placeholder when added logo is deleted
}
export interface VideoJSON {
  name: string;
  id: string;
  type: 'video';
  sub_type?: string;
  orientation: string;
  url: string;
  thumbnail_url: string;
  position: PositionJSON;
  input: InputJSON;
  layout: VideoLayoutJSON;
  duration: AbsoluteDurationJSON;
  animation: AnimationJSON;
  word?: string;
  is_lock?: boolean;
  is_visible?: boolean;
  audio_id: string;
  media_type?: string;
  media_properties?: VideoMediaPropertiesJSON;
  components?: never[];
  watermark?: boolean;
  isPending?: boolean;
  sr_no?: number;
  playback_rate_strategy?: 'mute' | 'preservesPitch';
}
type NotComponent<T> = T extends 'text' | 'video' | 'image' | 'svg_image' | 'composite' ? never : T;
export interface OtherComponentJSON {
  name: string;
  id: string;
  type: NotComponent<string>;
  sub_type?: string;
  position: OtherPositionJSON;
  input: InputJSON;
  layout: LayoutJSON;
  duration: DurationJSON;
  animation: AnimationJSON;
  components: ComponentJSON[];
  is_lock?: boolean;
  is_visible?: boolean;
}
/* Postion */
export interface OtherPositionJSON {
  top_x?: number;
  bottom_x?: number;
  top_y?: number;
  bottom_y?: number;
  pivot_x?: number;
  pivot_y?: number;
  orientation?: string;
  rotate?: number;
}
export interface ImageMediaPropertiesJSON extends MediaPropertiesJSON {
  image_link?: string;
  search_license?: string;
  image_id?: number;
}
export type ImageBgRemovalType = 'semantic' | 'panoptic';
export type ImageBgRemovalJob = { url: string; thumbnail_url: string; type: ImageBgRemovalType };
export interface ImageBgRemovalsJSON {
  enabled: boolean;
  latest_bg_removal_type: ImageBgRemovalType;
  original_image_url: string;
  original_image_thumbnail_url: string;
  jobs: Array<ImageBgRemovalJob>;
}
export interface VideoMediaPropertiesJSON extends MediaPropertiesJSON {
  id?: string;
  preview_link?: string;
  thumbnail_link?: string;
  video_link?: string;
  video_id?: string;
  search_term?: string;
  video_format?: string;
  media_type?: string;
  description?: string;
  tags?: string;
  aspect?: number;
  duration?: number;
  aspect_ratio?: string;
  frame_rate?: number;
  precalculated_duration?: number | 'None';
  audio_present?: boolean;
  thumbnail_time?: number;
  playback_rate?: number;
}
export interface MediaPropertiesJSON {
  source?: string;
  metadata?: MediaPropertiesMetadataJSON;
  partner_image_id?: string;
  partner_video_id?: string;
  url?: string;
  width?: number;
  height?: number;
  word?: string;
  thumbnail_link?: string;
  thumbnail_url?: string;
  api_type?: string;
  media_type?: string;
  possible_animations?: string[];
}

export interface MediaPropertiesMetadataJSON {
  premium: boolean;
}
export interface PositionJSON {
  top_x: number;
  bottom_x: number;
  top_y: number;
  bottom_y: number;
  pivot_x?: number;
  pivot_y?: number;
  orientation?: string;
  rotate?: number;
}

export interface FilterTransitionJSON {
  in: {
    name: string;
    duration: number;
    parameters: FilterProperties;
  } | null;
  out: {
    name: string;
    duration: number;
    parameters: FilterProperties;
  } | null;
}

/* Animations */
export interface AnimationJSON {
  animation_in?: string;
  animation?: string;
  animation_out?: string;
  animation_in_parameters?: {
    // effect_time will be used by mastercleaner to calculate t-in
    effect_time?: number;
    in_effect_factor?: number;
    type?: string;
  };
  animation_out_parameters?: {
    effect_time?: number;
    out_effect_factor?: number;
    type?: string;
  };
}
/* Durations */
export interface DurationJSON {
  delay?: number;
  split_self?: number;
  split_others?: number;
  priority?: number;
  absolute_duration: number;
  element_duration?: number;
  start_time: number;
  end_time: number;
  absolute_start: number;
  absolute_end: number;
  precalculated_duration?: number;
  fixed_duration?: number;
  fixed_start_time?: number;
  fixed_end_time?: number;
  minimum_duration?: number;
  block_minimum_duration?: number;
}
export interface AbsoluteDurationJSON extends DurationJSON {
  absolute_duration: number;
  absolute_start: number;
  absolute_end: number;
}
export interface AudioDurationJSON {
  absolute_start: number;
  absolute_end: number;
  absolute_duration: number;
  element_duration?: number;
  priority?: number;
  delay?: number;
  // fields which are still present and are set in default audio block durations
  split_self?: number;
  split_others?: number;
  start_time?: number;
  end_time?: number;
}
export interface TextAbsouluteDurationJSON extends AbsoluteDurationJSON {
  seconds_per_character: number;
}
/* Input */
export interface InputJSON {
  type: string;
  allow_multiple: number;
  placeholder: string;
  hidden: number;
  key?: string;
  value?: string;
  required?: number;
  component_config?: string | object;
}
/* Layouts */
export interface LayoutJSON {
  layer_properties: LayerPropertiesJSON;
  overlay?: string;
  fit_type?: string;
  overflow?: string;
}
export interface TextLayoutJSON {
  xpad: number;
  ypad: number;
  max_font_factor: number;
  main_font: FontJSON;
  highlight_font: HighlightFontJSON;
  horizontal_text_alignment: string;
  vertical_text_alignment: string;
  horizontal_band_factor?: number;
  vertical_band_factor?: number;
  band_type?: string;
  band_opacity: number;
  layer_properties: LayerPropertiesJSON;
  fit_type: string;
  resize_logic: string;
  font_spec: string[];
  lineHeight: string;
  letterSpacing?: number;
  text_transform: string;
  font_size: number;
  fixed: boolean;
  loop?: number;
  bullets?: BulletJSON;
}

export interface BulletJSON {
  type: 'round' | 'numbered';
  color?: number[];
}
export interface ImageLayoutJSON {
  layer_properties: LayerPropertiesJSON;
  fit_type: string;
  overflow?: string;
  clip?: (number | string)[];
  fit_alignment?: { x: number; y: number };
}

export interface VideoTrimSectionJSON {
  duration: number;
  floor: number;
  ceil: number;
  start: number;
  end: number;
  startTime: number;
  endTime: number;

  // TODO:- Check if add functionality breaks without setting these
  // step: number;
  // noSwitching: boolean;
  // minRange: number;
  // minLimit: number;
  // maxLimit: number;
  // draggableRange: boolean;
  // animate: boolean;
}
export interface VideoLayoutJSON {
  layer_properties: LayerPropertiesJSON;
  fit_type: string;
  overflow?: string;
  blur: number;
  playback_rate?: number;
  playback_type: string;
  loop: number;
  mute: number;
  trim?: VideoTrimSectionJSON[];
  clip?: (number | string)[];
}
/* Fonts */
export interface FontJSON {
  background_color: (number | string)[];
  foreground_color: (number | string)[];
  font_style: string;
  colors_ref?: {
    background_color: {
      user_flag: number;
      reference: ColorType;
    };
    foreground_color: {
      user_flag: number;
      reference: ColorType;
    };
    band_opacity: {
      user_flag: number;
      reference: number;
    };
  };
}
export interface HighlightFontJSON extends FontJSON {
  highlight_type: string;
  tooltip?: string;
}
/* Layer Properties */
export interface LayerPropertiesJSON {
  opacity?: number;
  is_blend_enabled?: boolean;
  blend_effect?: string;
  filter?: string;
  color_correction?: string | ColorCorrectionJSON;
  outline?: OutlineJSON;
  color_overlay?: string;
  drop_shadow?: string | DropShadowJSON;
  blur?: number;

  /** Effects applied to Image / Video, such as LUT, Blur, etc. */
  effects?: Effect[];

  /** Effects applied to Composite. */
  fx_filters?: FxFilterJSON[];

  applied_filter?: string;
  applied_filter_id?: number;
  applied_filter_intensity?: number;
  adjustment_filters?: AdjustmentFilterJSON;
  mask?: {
    height: number;
    id: string;
    width: number;
  };
  flip?: {
    horizontal?: boolean;
    vertical?: boolean;
  };
  opacity_ref?: {
    user_flag: number;
    reference: number;
  };
  stroke_color?: Array<number>;
}
export interface ColorCorrectionJSON {
  isEnabled?: boolean;
  type: string;
  color?: (string | number)[];
  color_1?: (string | number)[];
  color_2?: (string | number)[];
  color_3?: (string | number)[];
  colors_ref?: {
    // reference can be "primary" | "secondary" | "tertiary" | "quaternary" or Array in a string format "[255,255,255,1]"
    color?: { reference: ColorType | string; user_flag: number };
    color_1?: { reference: ColorType | string; user_flag: number };
    color_2?: { reference: ColorType | string; user_flag: number };
    color_3?: { reference: ColorType | string; user_flag: number };
    opacity?: { reference: number; user_flag: number };
  };
}

export interface OutlineJSON {
  enabled: boolean;
  color: number[];
  opacity: number;
  size: number;
}
export interface DropShadowJSON {
  enabled: string | boolean;
  distance: number;
  angle: number;
  blur: number;
  name: string;
  opacity: number;
  drop_shadow_color: (number | string)[];
  shadow_factor?: number;
  colors_ref: {
    drop_shadow_color: {
      user_flag: number;
      reference: number[];
    };
  };
}
/* Video */
export interface VideoTransitionJSON {
  components: {
    transition_asset: {
      layout: {
        layer_properties: LayerPropertiesJSON;
      };
      url: string;
    };
  };
}
export interface TrimSectionJSON {
  start_time: number;
  end_time: number;
}
/* Audio */
export interface AudioListItemJSON {
  absolute_start: number;
  absolute_end: number;
  absolute_duration: number;
  audio_volume: number;
  fade_time: number;
  fade_start: number;
}

export type AudioBlockJSON = BgVideoAudioBlockJSON | FileAudioBlockJSON;

export type BgVideoAudioBlockJSON = {
  sub_type: 'bg_video';
  duration: null;
  component_ref_id: string;
} & AudioBlockCommonProperties;

export type FileAudioBlockJSON = {
  sub_type: 'voice_over' | 'bg_music';
  duration: AudioDurationJSON;
  component_ref_id: null;
} & AudioBlockCommonProperties;

type AudioBlockCommonProperties = {
  type: string;
  id: string;
  is_lock?: boolean;
  is_visible?: boolean;
  layer_name: string;
  layer_id: string | null;
  loop: number;
  bg_volume: number;
  playback_rate: number;
  playback_rate_strategy?: 'mute' | 'preservesPitch';
  fade_in_time: number;
  fade_out_time: number;
  audio_list: AudioListItemJSON[];
  audio_volume: number;
  // TODO: find a better way to express this
  // for global blocks
  url?: string | null;
  trim_section?: TrimSectionJSON | null;
  // adding missing fields. TODO:- more exhaustive representation
  audio_priority?: number;
  standalone?: number;
  is_pending?: boolean;
  is_ducking?: boolean;

  components?: never[];

  // Panner FX
  panner_pan?: number;

  // Compressor FX
  compressor_attack?: number;
  compressor_bypass?: boolean;
  compressor_gain?: number;
  compressor_knee?: number;
  compressor_ratio?: number;
  compressor_release?: number;
  compressor_threshold?: number;
};

export interface BenefitsJSON {
  is_benefits_applied: boolean;
  benefits_type: string;
  hook_name: string;
}

/* Color Correction Additions Start */
export type LUT = 'lut';
export type ImageOverlay = 'imageOverlay';
export type Blur = 'gaussianBlur';
export type WhiteColorPicker = 'whiteColorPicker';
export type AiBgRemoval = 'aiBgRemoval';
export type EffectType = LUT | ImageOverlay | Blur | WhiteColorPicker | AiBgRemoval;

/** Data for image / video effects implemented on ShaderEffect. */
export type Effect = LUTJSON | ImageOverlayJSON | BlurJSON | WhiteColorPickerJSON;
export interface BlurJSON {
  type: Blur;
  blurRadius: number;
  blurBlendRatio: number;
}
export interface LUTJSON {
  type: LUT;
  lutUrl: string;
  lut: number;
  lutBlendRatio: number;
}
export interface ImageOverlayJSON {
  type: ImageOverlay;
  imageOverlayUrl: string;
  imageOverlayOpacity: number;
  imageOverlayBlendMode: string;
}
export interface AdjustmentFilterJSON {
  brightness?: number;
  contrast?: number;
  saturation?: number;
  sharpness?: number;
  vibrance?: number;
  exposure?: number;
  shadow?: number;
  highlight?: number;
  offset?: number;
  temperature?: number;
  tint?: number;
  vignette?: number;
  hue?: number;
}

export interface WhiteColorPickerJSON {
  type: WhiteColorPicker;
  color: [number, number, number, number];
}
/* Color Correction Additions End */

/* Transitions + Filter properties */
type Direction = 'up' | 'down' | 'left' | 'right';
type Orientation = 'horizontal' | 'vertical';
type Zoom = 'in' | 'out';
type Ease =
  | 'linear'
  | 'expo.in'
  | 'expo.out'
  | 'expo.inOut'
  | 'power1.out'
  | 'power1.in'
  | 'power1.inOut'
  | 'power2.out'
  | 'power2.in'
  | 'power2.inOut'
  | 'power3.out'
  | 'power3.in'
  | 'power3.inOut'
  | 'power4.out'
  | 'power4.in'
  | 'power4.inOut'
  | 'circ.out'
  | 'circ.in'
  | 'circ.inOut'
  | 'sine.out'
  | 'sine.in'
  | 'sine.inOut'
  | 'elastic.out'
  | 'elastic.in'
  | 'elastic.inOut';

/**
 * Conditions to show the property on the UI.
 * The property will be displayed only if all the conditions are met.
 */
export type PropertyDisplayCondition =
  | { type: 'Number'; name: string; value: number }
  | { type: 'List'; name: string; value: number };

export interface PropertyNumber {
  value: number;
  range: [number, number];
  step?: number;
  type: 'Number';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyNumber2 {
  value: [number, number];
  range: [[number, number], [number, number]];
  step?: [number, number];
  labels?: [string, string];
  type: 'Number2';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyColor {
  value: number;
  type: 'Color';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyList {
  value: number;
  range: number[];
  steps: number;
  type: 'List';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyEasing {
  value: Ease;
  range: Ease[];
  type: 'Easing';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyDirection {
  value: Direction;
  range: Direction[];
  type: 'Direction';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyOrientation {
  value: Orientation;
  range: Orientation[];
  type: 'Orientation';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyZoom {
  value: Zoom;
  range: Zoom[];
  type: 'Zoom';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export interface PropertyBlendMode {
  value: number;
  type: 'BlendMode';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayCondition[];
}

export type FilterProperty =
  | PropertyNumber
  | PropertyNumber2
  | PropertyColor
  | PropertyList
  | PropertyEasing
  | PropertyDirection
  | PropertyOrientation
  | PropertyZoom
  | PropertyBlendMode;

export interface FilterProperties {
  [key: string]: FilterProperty;
}

export type FxFilterType = string;

/**
 * Data for composite effects implemented on FxFilter.
 */
export type FxFilterJSON = {
  id: string;
  type: FxFilterType;
  params: FilterProperties;
  isEnabled: boolean;
  showAdvancedParams?: boolean;
};

/* End of Transition + Filter properties */
export interface AiBgRemovalJSON {
  type: AiBgRemoval;
  convertedVideoUrl: string;
}

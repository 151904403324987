import { CompositeType } from './MasterJSON';

export type AnyComponentJSON = ImageJSON | VideoJSON | ParagraphJSON;
export type AnyJSON =
  | BlockJSON
  | CompositeJSON
  | ImageJSON
  | VideoJSON
  | ParagraphJSON
  | WatermarkJSON;
/*
 * @deprecated use AnyJSON instead
 */
export type ComponentJSON = AnyJSON;
export type ComponentIdentifier<T = ComponentJSON['type']> = { type: T; id: string };
// [tStart, tIn) -> in animation
// [tIn, tOut] -> in place animation
// (tOut, tEnd] -> out animation

export interface BoxJSON {
  uid: string;
  id: string;
  parentId: string | null;
  blockId: string | null;
  cacheKey: string | null;
  hidden: boolean;
  locked: boolean;
  inAnimation: string | null;
  outAnimation: string | null;
  tStart: seconds;
  tIn: seconds;
  tOut: seconds;
  tEnd: seconds;
  width: dpx;
  zoom: ZoomJSON | null;
  pan: PanJSON | null;
  height: dpx;
  blur: dpx;
  opacity: number;
  filterType: FilterType | null;
  blendMode: BlendMode;
  offsetX: dpx;
  offsetY: dpx;
  angle: degree;
  absoluteAngle: degree;
  tEdit: seconds;
  name: string;
  ui: {
    selected: boolean;
    forceVisible: boolean;
    forceInvisible: boolean; // When an asset is being dragged from the left-panel, force the visibili
  };
  flipHorizontally: boolean;
  flipVertically: boolean;
}

/* block */
export interface BlockJSON extends BoxJSON {
  type: 'block';
  componentIds: ComponentIdentifier[];
  index: number;
  tSceneStart: seconds; // the point at which the basic timeline shows the block as starting
  tSceneEnd: seconds; // the point at which the basic timeline shows the block as ending
  thumbnailUrl: string | null;
  inTransition: string | null;
  inTransitionParameters: FilterProperties;
  outTransition: string | null;
  outTransitionParameters: FilterProperties;
}

/* layer */
export type LayerType = 'audio' | 'video';

export enum LayerVideoSubType {
  VIDEO = 'video',
  LOGO_PLACEHOLDER = 'logo-placeholder',
  TRANSITION = 'transition',
  CANVAS_BACKGROUND = 'canvas-background',
}

export enum LayerAudioSubType {
  AUDIO = 'audio',
  BG_MUSIC = 'bg-music',
  VOICE_OVER = 'voice-over',
  BG_VIDEO = 'bg-video',
}

export type LayerSubType = LayerVideoSubType | LayerAudioSubType;

export interface LayerJSON {
  uid: string;
  id: string;
  name: string;
  cacheKey: string | null;
  hidden: boolean;
  visible: boolean;
  type: 'layer';
  layerType: LayerType;
  layerSubType: LayerSubType;
  index: number;
}

export type GapType = 'scene-gap' | 'layer-gap';
export interface GapJSON {
  id: string;
  type: 'gap';
  gapType: GapType;
  tStart: seconds;
  tEnd: seconds;
  blockId: string;
  layerId: string | null;
  cacheKey: string | null;
}

/* composite */
export type SelectionType =
  | 'TEXT'
  | 'DELETABLE_LOGO'
  | 'LOGO'
  | 'MAINTAIN_ASPECT_RATIO'
  | 'GROUP'
  | 'BACKGROUND'
  | 'DEFAULT';

export interface CompositeJSON extends BoxJSON {
  type: 'composite';
  subType?: 'placeholder_logo' | 'position_composite' | 'transition_composite';
  componentIds: ComponentIdentifier[];
  compositeType: CompositeType;
  clipColor?: ColorJSON;
  mask: ImageJSON | null;
  visible: boolean;
  isBackground: boolean;
  isLogoPlaceholderHidden?: boolean;
  selectionType: SelectionType;
  isLogoHiddenForRender?: boolean;
  layerId: string | null;
  index: number;
  thumbnailUrl: string | null;
  dropShadow: ShadowJSON;
  fxFilters?: FxFilterJSON[];
}

export interface MediaProperties {
  isWatermark: boolean;
  source: string;
}

export type ColorRef = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | string;
export type ColorData = { [key in ColorRef]?: ColorJSON };
export type ColorCorrection =
  | { type: 'duotone1'; color: ColorJSON }
  | { type: 'duotone2'; color_1: ColorJSON; color_2: ColorJSON }
  | { type: 'tritone1'; color: ColorJSON }
  | { type: 'tritone3'; color_1: ColorJSON; color_2: ColorJSON; color_3: ColorJSON };
/* image */
export interface ImageJSON extends BoxJSON {
  type: 'image';
  url: string;
  fit: BoxFit;
  colorCorrection: ColorCorrection | null;
  clip: ClipJSON | null;
  backgroundBlur: dpx;
  relativeForegroundOffset: { x: unit; y: unit };
  isBackground: boolean;
  effects: Array<Effect>;

  brightness: number;
  contrast: number;
  saturation: number;
  sharpness: number;
  vibrance: number;
  exposure: number;
  shadow: number;
  highlight: number;
  offset: number;
  temperature: number;
  tint: number;
  vignette: number;
  hue: number;
}

export type WatermarkSubTypes = 'overlay' | 'badge' | 'cta';
export type WatermarkSources = 'shutterstock' | 'istock' | 'storyblock';

export interface WatermarkJSON extends BoxJSON {
  type: 'watermark';
  source: WatermarkSources;
  subType: WatermarkSubTypes;
  url: string;
}

/* video */
export interface VideoJSON extends BoxJSON {
  type: 'video';
  componentRefId: string | null;
  url: string;
  backgroundUrl?: string;
  fit: BoxFit;
  colorCorrection: ColorCorrection | null;
  clip: ClipJSON | null;
  backgroundBlur: dpx;
  loop: boolean;
  muted: boolean;
  speed: number;
  playbackRateStrategy: 'mute' | 'preservesPitch';

  durations: { t0: number; t1: number }[];
  trim: { startTime: seconds; endTime: seconds }[] | null;
  sourceDuration: seconds;
  fadeSections?: Array<FadeSection>;
  effects: Array<Effect>;

  brightness: number;
  contrast: number;
  saturation: number;
  sharpness: number;
  vibrance: number;
  exposure: number;
  shadow: number;
  highlight: number;
  offset: number;
  temperature: number;
  tint: number;
  vignette: number;
  hue: number;
}

/* text */
export interface ParagraphJSON extends BoxJSON {
  type: 'paragraph';
  highlightedText: string;
  granularity: Granularity;
  grains: ParagraphGrainJSON[];
  boxPadding: { v: dpx; h: dpx };
  style: RichTextStyle;
}

export interface ParagraphGrainJSON {
  uid: string;
  id: string;
  cacheKey: string | null;
  hidden: boolean;
  inAnimation: string | null;
  outAnimation: string | null;
  tStart: seconds;
  tIn: seconds;
  tOut: seconds;
  tEnd: seconds;
  height: dpx;
  width: dpx;

  type: Granularity;
  grain: {
    richText: StyledText;
    offset: { x: dpx; y: dpx };
    size: { width: dpx; height: dpx };
    padding: { h: dpx; v: dpx };
  };
  paddingHorizontal: dpx;
  style: RichTextStyle;
}

export interface RichTextStyle {
  textAlignment: TextAlignment;
  verticalAlignment: VerticalAlignment;
  defaultTextStyle: TextStyle;
  highlightTextStyle: TextStyle;
  textShadow: ShadowJSON;
  isFontSizeFixed: boolean;
  rtl: boolean;
  fallbackFonts: string[];
  bullets?: Bullets;
  outline?: Outline;
}

export interface Bullets {
  type: 'round' | 'numbered';
  color: ColorJSON;
}

export interface Outline {
  enabled: boolean;
  thickness: number;
  opacity: number;
  color: ColorJSON;
}

export interface TextStyle {
  font: string;
  fontSize: number;
  lineHeight: number;
  letterSpacing: number;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  color: ColorJSON;
  backgroundColor: ColorJSON;
}

/* audio */
export interface FadeSection {
  tStart: seconds;
  tEnd: seconds;
  vStart: seconds;
  vEnd: seconds;
}

export enum AUDIO_TYPE {
  VOICE_OVER = 'voice_over',
  BG_MUSIC = 'bg_music',
  BG_VIDEO = 'bg_video',
}

export interface AudioJSON {
  type: 'audio';
  uid: string;
  id: string;
  cacheKey: string | null;
  hidden: boolean;
  locked: boolean;
  tStart: seconds;
  tEnd: seconds;
  sourceDuration: seconds;
  url: string;
  trimSection: { startTime: seconds; endTime: seconds };
  videoTrimSections?: { startTime: seconds; endTime: seconds }[] | null;
  fadeSections: Array<FadeSection>;
  loop: boolean;
  playbackRate: number;
  playbackRateStrategy: 'mute' | 'preservesPitch';

  component_ref_id?: string | null;
  layerId: string | null;
  audioType: AUDIO_TYPE;
  name: string;

  // Required for ducking calculation inside iv-audio
  volume: number;
  isDucked: boolean;
  duckedVolume: number;
  fadeInDuration: number;
  fadeOutDuration: number;

  // Panner FX
  pannerPan: number;

  // Compressor FX
  compressorAttack: number;
  compressorBypass: boolean;
  compressorGain: number;
  compressorKnee: number;
  compressorRatio: number;
  compressorRelease: number;
  compressorThreshold: number;

  // TODO: handle these
  // audio_list: AudioListItemJSON[];
  // duration?: AudioDurationJSON | null;
  // is_pending?: boolean;
}

export interface ClipJSON {
  top: unit;
  right: unit;
  bottom: unit;
  left: unit;
}

export interface TransitionJSON {
  id: string;
  type: 'transition';
  transitionType: 'animation' | 'overlay' | 'none';
  index: number;
  transitionId: number | null;
  cacheKey: string | null;
  tStart: seconds;
  tEnd: seconds;
  prevRefId: string;
  nextRefId: string;
}

/* helpers */
export type dpx = number; // display pixels
export type degree = number; // x % 360 is the angle in degrees
export type unit = number; // between -1 to 1
export type seconds = number;
export type Granularity = 'whole' | 'lines' | 'words' | 'chars';
export type StyledText = { text: string; type: 'default' | 'highlight' }[];
export type TextAlignment = 'left' | 'right' | 'center';
export type Bullet = 'round' | 'numbered';
export type VerticalAlignment = 'top' | 'bottom' | 'middle';
export type BoxFit = 'contain' | 'cover' | 'fill';
export type TransitionType = 'animation' | 'overlay' | 'none';

export interface ColorJSON {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface ShadowJSON {
  blur: number;
  opacity: number;
  angle: number;
  distance: number;
  color: ColorJSON;
  enabled: boolean;
}

export interface ZoomJSON {
  originX: number;
  originY: number;
  z0: number;
  z1: number;
}

export interface PanJSON {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
}

export type FilterType =
  | 'filter-1977'
  | 'filter-aden'
  | 'filter-amaro'
  | 'filter-ashby'
  | 'filter-brannan'
  | 'filter-brooklyn'
  | 'filter-charmes'
  | 'filter-clarendon'
  | 'filter-crema'
  | 'filter-dogpatch'
  | 'filter-earlybird'
  | 'filter-gingham'
  | 'filter-ginza'
  | 'filter-hefe'
  | 'filter-helena'
  | 'filter-hudson'
  | 'filter-inkwell'
  | 'filter-juno'
  | 'filter-kelvin'
  | 'filter-lark'
  | 'filter-lofi'
  | 'filter-ludwig'
  | 'filter-maven'
  | 'filter-mayfair'
  | 'filter-moon'
  | 'filter-nashville'
  | 'filter-perpetua'
  | 'filter-poprocket'
  | 'filter-reyes'
  | 'filter-rise'
  | 'filter-sierra'
  | 'filter-skyline'
  | 'filter-slumber'
  | 'filter-stinson'
  | 'filter-sutro'
  | 'filter-toaster'
  | 'filter-valencia'
  | 'filter-vesper'
  | 'filter-walden'
  | 'filter-willow'
  | 'filter-xpro-ii';

export type BlendMode =
  | 'multiply'
  | 'screen'
  | 'overlay'
  | 'darken'
  | 'lighten'
  | 'color-dodge'
  | 'color-burn'
  | 'difference'
  | 'exclusion'
  | 'hue'
  | 'saturation'
  | 'color'
  | 'luminosity'
  | 'soft-light'
  | 'hard-light'
  | 'normal';

export type LUT = 'lut';
export type ImageOverlay = 'imageOverlay';
export type Blur = 'gaussianBlur';
export type WhiteColorPicker = 'whiteColorPicker';
export type AiBgRemoval = 'aiBgRemoval';
export type EffectType = LUT | ImageOverlay | Blur | WhiteColorPicker | AiBgRemoval;

/** Data for image / video effects implemented on ShaderEffect. */
export type Effect = LUTJSON | ImageOverlayJSON | BlurJSON | WhiteColorPickerJSON;

export interface BlurJSON {
  type: Blur;
  blurRadius: number;
  blurBlendRatio: number;
}

export interface LUTJSON {
  type: LUT;
  lutUrl: string;
  lut: number;
  lutBlendRatio: number;
}

export interface ImageOverlayJSON {
  type: ImageOverlay;
  imageOverlayUrl: string;
  imageOverlayOpacity: number;
  imageOverlayBlendMode: string;
}

export interface WhiteColorPickerJSON {
  type: WhiteColorPicker;
  color: [number, number, number, number];
}

export interface AiBgRemovalJSON {
  type: AiBgRemoval;
  convertedVideoUrl: string;
}

export type AdjustmentFilterProperties =
  | 'brightness'
  | 'contrast'
  | 'saturation'
  | 'sharpness'
  | 'vibrance'
  | 'exposure'
  | 'shadow'
  | 'highlight'
  | 'offset'
  | 'temperature'
  | 'tint'
  | 'vignette'
  | 'hue';

/* Transitions + Filter properties */
type Direction = 'up' | 'down' | 'left' | 'right';
type Orientation = 'horizontal' | 'vertical';
type Zoom = 'in' | 'out';
type Ease =
  | 'linear'
  | 'expo.in'
  | 'expo.out'
  | 'expo.inOut'
  | 'power1.out'
  | 'power1.in'
  | 'power1.inOut'
  | 'power2.out'
  | 'power2.in'
  | 'power2.inOut'
  | 'power3.out'
  | 'power3.in'
  | 'power3.inOut'
  | 'power4.out'
  | 'power4.in'
  | 'power4.inOut'
  | 'circ.out'
  | 'circ.in'
  | 'circ.inOut'
  | 'sine.out'
  | 'sine.in'
  | 'sine.inOut'
  | 'elastic.out'
  | 'elastic.in'
  | 'elastic.inOut';

/**
 * Conditions to show the property on the UI.
 * The property will be displayed only if all the conditions are met.
 */
type PropertyDisplayConditions =
  | { type: 'Number'; name: string; value: number }
  | { type: 'List'; name: string; value: number };

export interface PropertyNumber {
  value: number;
  range: [number, number];
  type: 'Number';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyNumber2 {
  value: [number, number];
  range: [[number, number], [number, number]];
  type: 'Number2';
  labels?: [string, string];
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyColor {
  value: number;
  type: 'Color';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyList {
  value: number;
  range: number[];
  steps: number;
  type: 'List';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyEasing {
  value: Ease;
  range: Ease[];
  type: 'Easing';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyDirection {
  value: Direction;
  range: Direction[];
  type: 'Direction';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyOrientation {
  value: Orientation;
  range: Orientation[];
  type: 'Orientation';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyZoom {
  value: Zoom;
  range: Zoom[];
  type: 'Zoom';
  name: string;
  menu: string[];
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export interface PropertyBlendMode {
  value: number;
  type: 'BlendMode';
  name: string;
  isAdvanced?: boolean;
  displayConditions?: PropertyDisplayConditions[];
}

export type FilterProperty =
  | PropertyNumber
  | PropertyNumber2
  | PropertyColor
  | PropertyList
  | PropertyEasing
  | PropertyDirection
  | PropertyOrientation
  | PropertyZoom
  | PropertyBlendMode;

export interface FilterProperties {
  [key: string]: FilterProperty;
}

export type FxFilterType = string;

/**
 * Data for composite effects implemented on FxFilter.
 */
export type FxFilterJSON = {
  id: string;
  type: FxFilterType;
  params: FilterProperties;
  isEnabled: boolean;
  showAdvancedParams?: boolean;
};

/* End of Transition + Filter properties */
